import { ChartOptions, Plugin } from 'chart.js';

export const DashboardPluginGraphBarLegend = (id: string): Plugin<'bar' | 'line'> => ({
	id: 'legend',
	afterUpdate(chart) {
		if (!chart.options.plugins?.legend?.labels) return;
		const items = chart.options.plugins.legend.labels.generateLabels?.(chart);
		if (!items) return;

		const jsLegend = document.getElementById(id);
		if (!jsLegend) return;

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const createLegendItem = (item: any) => {
			const boxSpan = document.createElement('span');
			boxSpan.style.backgroundColor = item.fillStyle?.toString() || 'transparent';
			const meta = chart.getDatasetMeta(item.datasetIndex);

			const li = document.createElement('div');
			li.appendChild(boxSpan);
			li.appendChild(document.createTextNode(item.text));
			li.style.textDecoration = meta.hidden ? 'line-through' : 'none';

			li.addEventListener('click', () => {
				meta.hidden = !meta.hidden;
				chart.update();
			});

			return li;
		};

		const box = document.createElement('div');
		box.style.display = 'flex';
		box.style.flexDirection = 'column';

		items.forEach((item) => {
			box.appendChild(createLegendItem(item));
		});

		// Remove old legend items before appending the new ones
		while (jsLegend.firstChild) {
			jsLegend.removeChild(jsLegend.firstChild);
		}

		jsLegend.appendChild(box);
	}
});


export const DashboardPluginGraphBarPercentageOnTop = (): Plugin<'bar'> => ({
	id: 'diffPercentText',
	afterDatasetsDraw(chart) {
		const datasets = chart.data.datasets;
		const ctx = chart.ctx;

		const barTotalValues: (number | null)[] = [];
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const lastBar: any[] = [];

		datasets.forEach((dataset, i) => {
			const meta = chart.getDatasetMeta(i);
			if (!meta.hidden) {
				meta.data
					.forEach((bar, index: number) => {
						const data = dataset.data[index];
						if (data === null) {
							barTotalValues[index] = null;
							lastBar[index] = null;
							return;
						}
						const barTotalValue = barTotalValues[index] ?? 0;
						barTotalValues[index] = barTotalValue + (data as number);
						lastBar[index] = bar;
					});
			}
		});

		const firstBarValue = barTotalValues.filter(d => d != null)[0];
		const firstBar = lastBar.filter((d, i) => barTotalValues[i] != null)[0];
		barTotalValues.forEach((barTotalValue, index) => {
			if (barTotalValue === null || firstBarValue === null) return;
			const bar = lastBar[index];
			if (bar === null || bar == firstBar) return;
			const x = bar.x;
			const y = bar.y;
			const percent = ((barTotalValue - firstBarValue) / firstBarValue) * 100;
			const text = `${percent.toFixed(0)}%`;
			ctx.save();
			ctx.fillStyle = '#000000';
			ctx.font = 'bold 14px Arial';
			ctx.textAlign = 'center';
			ctx.fillText(text, x, y - 5);
		});
	}
});

/**
 * Plugin to display the scope and the difference in percentage between each bar and the reference value on top of the bar
 * Reference here is a year
 */
export const DashboardEvolutionPluginGraphBarRefDiff = (reverseComparison?: boolean): Plugin<'bar'> => ({
	id: 'scopeText',
	afterDatasetsDraw(chart) {
		const referenceYear = (chart.options as ChartOptions).datasetRef;

		const ctx = chart.ctx;
		const datasets = chart.data.datasets;

		const refIndex = datasets.findIndex(d => d.label === referenceYear);
		if (refIndex === -1) return;

		const refData = datasets[refIndex].data;

		datasets.forEach((dataset, i) => {
			const meta = chart.getDatasetMeta(i);
			if (!meta.hidden) {
				meta.data
					.forEach((bar, index: number) => {
						const data = dataset.data[index];
						const refValue = refData[index];
						if (data === null) return;
						// If there is no refValue for this index, or we are currently on the refIndex, we write the scope
						if (i === refIndex || refValue === null || refValue === 0) {
							return;
						}
						const x = bar.x;
						const y = bar.y;
						const diff = (data as number) - (refValue as number);
						const percent = diff / (refValue as number) * 100;
						const text = `${diff < 0 ? '' : '+'}${percent.toFixed(0)}%`;


						// Then, write difference in percentage
						let color = '#f00';
						if (reverseComparison) {
							color = diff > 0 ? '#00ad00' : '#f00';
						} else {
							color = diff > 0 ? '#f00' : '#00ad00';
						}
						ctx.save();
						ctx.fillStyle = color;
						ctx.font = 'bold 10px Arial';
						ctx.textAlign = 'center';
						ctx.fillText(text, x, y - 2);
					});
			}
		});
	}
});

/**
 * Plugin to display the difference in percentage between each bar and the reference value on top of the bar
 *
 * If there is only one dataset (BarChart), the difference is calculated between each bar and the reference value
 * If there are multiple datasets (StackedBarChart), we will calculate the total value of each bar and display the difference between the total value and the reference value
 */
export const DashboardComparisonPluginGraphBarRefDiff = (reverseComparison?: boolean): Plugin<'bar'> => ({
	id: 'refDiffTextComparison',
	afterDatasetsDraw(chart) {
		const datasetRef = (chart.options as ChartOptions).datasetRef;
		if (!datasetRef) return;

		const datasets = chart.data.datasets;
		const refIndex = chart.data.labels?.map(l => {
			if (Array.isArray(l))
				return l.join(' ');
			return l;
		}).findIndex(l => l === datasetRef);
		if (refIndex === -1 || refIndex === undefined) return;

		if (datasets.length === 0) return;

		const ctx = chart.ctx;

		/**
		 * Create the percentage text on top of the bar
		 * @param data - the value of the bar
		 * @param refData - the value of the reference bar
		 * @param bar - the bar object containing the x and y position
		 */
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const createPercentage = (data: number | null, refData: number | null, bar: any) => {
			if (data === null || refData === null) return;
			const x = bar.x;
			const y = bar.y;
			const diff = (data as number) - (refData as number);
			const percent = diff / (refData as number) * 100;
			const text = `${diff < 0 ? '' : '+'}${percent.toFixed(0)}%`;
			let color = '#f00';
			if (reverseComparison) {
				color = diff > 0 ? '#00ad00' : '#f00';
			} else {
				color = diff > 0 ? '#f00' : '#00ad00';
			}
			ctx.save();
			ctx.fillStyle = color;
			ctx.font = 'bold 12px Arial';
			ctx.textAlign = 'center';
			ctx.fillText(text, x, y - 5);
		};

		if (datasets.length === 1) {
			const meta = chart.getDatasetMeta(0);
			meta.data.forEach((bar, index: number) => {
				if (index === refIndex) return;
				const data = datasets[0].data[index];
				const refData = datasets[0].data[refIndex];
				createPercentage(data as number, refData as number, bar);
			});
			return;
		}

		const barTotalValues: (number | null)[] = [];
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const bars: any[] = [];
		let refTotalValue: number | null = null;

		datasets.forEach((dataset, i) => {
			const meta = chart.getDatasetMeta(i);
			if (!meta.hidden) {
				meta.data
					.forEach((bar, index: number) => {
						const data = dataset.data[index];
						if (data === null) {
							barTotalValues[index] = null;
							bars[index] = null;
							return;
						}
						const barTotalValue = barTotalValues[index] ?? 0;
						barTotalValues[index] = barTotalValue + (data as number);
						bars[index] = bar;
						if (index === refIndex) {
							if (refTotalValue === null) refTotalValue = 0;
							refTotalValue += data as number;
						}
					});
			}
		});

		barTotalValues.forEach((barTotalValue, index) => {
			if (barTotalValue === null || refTotalValue === null || index === refIndex) return;
			const bar = bars[index];
			createPercentage(barTotalValue, refTotalValue, bar);
		});
	}
});
