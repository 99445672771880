import { gql } from '@apollo/client';

export const MUTATE_EDIT_LCA_INVENTORY = gql`
    mutation MutateEditLcaInventory(
        $id: String!
        $name: String!
        $description: String!
        $equipments: [LcaInventoryEquipmentType]!
        $settings: [LcaInventorySettingType]!
        $delete: Boolean
    ){
        mutateEditLcaInventory(
            id: $id
            name: $name
            description: $description
            equipments: $equipments
            settings: $settings
            delete: $delete
        ){
            inventory {
                id
                name
                description
                organizedEquipments {
                    id
                    equipmentType
                    block
                }
                organizedSettings {
                    id
                    settingType
                    block
                }
            }
        }
    }
`;
