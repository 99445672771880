import { ApolloClientProvider } from '../ApolloClient/ApolloClientProvider';
import { ResponseStaticPage, ResponseStaticPages } from '../../domain/data/ResponseStaticPage';
import { IAPIProps } from '../../domain/interfaces/IAPIProps';

export class ApolloClientQueryStaticPage extends ApolloClientProvider<ResponseStaticPage, { id: string }> {
	exec({ token, lang, adapter }: IAPIProps, data: { id: string }): Promise<ResponseStaticPage | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientQueryStaticPages extends ApolloClientProvider<ResponseStaticPages> {}
