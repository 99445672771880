import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import RouterExternal from './router/RouterExternal';
import RouterInternal from './router/RouterInternal';
import { EConnectionStatus } from './domain/interfaces/EConnectionStatus';
import Loading from './viewComponents/Loading';
import NoMobileView from './views/NoMobileView/NoMobileView';
import ErrorBoundaryFallback from './viewComponents/error/ErrorBoundaryFallback/ErrorBoundaryFallback';
import './App.scss';
import { useUser } from './viewComponents/hooks/useUser';

/**
 * Main Application
 * Provided the application as a whole.
 * It brings together all the workings of React from itself
 * It also ensures that the application does not run on mobile requiring a minimum size
 * It provides maintenance and error reporting of the application during the internal router
 * @constructor
 * @return JSX.Element
 * @author
 */
const Application = (): JSX.Element => {
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const { entity: userEntity, status, data: user } = useUser();
	const handleSetWindowWidth = () => {
		setWindowWidth(window.innerWidth);
	};

	const oldStorageToken = localStorage.getItem('token');
	const handleStorageChange = (event: StorageEvent) => {
		window.removeEventListener('storage', handleStorageChange);
		if (event.key !== 'token') return;
		if (!event.newValue?.length) {
			userEntity?.queryUserLogout();
		} else {
			if (oldStorageToken?.length) return;
			userEntity?.queryUser();
		}
	};

	useEffect(() => {
		window.addEventListener('resize', handleSetWindowWidth);
		window.addEventListener('storage', handleStorageChange);
		return () => {
			window.removeEventListener('resize', handleSetWindowWidth);
			window.removeEventListener('storage', handleStorageChange);
		};
	}, []);

	const handleError = (error: Error, info: { componentStack: string }) => {
		const uri = window.location.href;
		const email = user?.email;
		const message = error.message;
		error = new Error(`
		Error Information
		User: ${email ?? 'Not Logged'}
		URI: ${uri}
		Type : ${message}
		`);
		userEntity?.mutateReportError(error, info, message);
	};

	if (windowWidth < 565) return (<NoMobileView/>);
	switch (status) {
		case EConnectionStatus.CONNECTED:
			return <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}
				onError={handleError}><RouterInternal/></ErrorBoundary>;
		case EConnectionStatus.PENDING:
			return <Loading/>;
		default:
			return <RouterExternal/>;
	}
};

export default Application;
