import { IBlock } from '../../domain/interfaces/IBlock';
import { useSelector } from 'react-redux';
import { State } from '../../store';
import { BlockEntity } from '../../domain/entities/BlockEntity';
import { IDataDTO } from '../../domain/interfaces/DTO/IDataDTO';

type BlocksHookType = IDataDTO & { entity: BlockEntity | undefined, blocks: IBlock[] };

export const useBlocks = (selected?: string[]): BlocksHookType => {
	const { loading, error, data: entity } = useSelector((state: State) => state.dataBlocksStatus);
	const blocks = entity?.get() ?? [];
	if (!selected) return { loading, error, entity, blocks };
	return { loading, error, entity, blocks: blocks.filter((block: IBlock) => selected.includes(block.id)) };
};