import { gql } from '@apollo/client';

export const QUERY_BLOCKS = gql`
	  query {
		  blocks {
			  id
			  name
			  shortName
			  private
			  order
			  studyIds
          }
      }
`;
