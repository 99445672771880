import React from 'react';
import { IModal } from '../../../domain/interfaces/IModal';
import { translate } from '../../../infrastructure/translations/translate';
import { Alert } from '../../../viewComponents/Alerts';
import { useModal } from '../../../viewComponents/modal/useModal';

export const createDashboardModalEvolutionBenchmarkNotUsed = (
	open: boolean,
	setOpen: (value: boolean) => void,
): IModal | undefined => {
	if (!open) return;

	const clear = () => {
		useModal(false, { visible: false, body: undefined, header: '' });
	};

	return {
		body: <div className="dashboard_modal">
			<Alert variant={'warning'}>
				{translate('dashboard.evolution.benchmark.not.usable')}
			</Alert>
		</div>,
		header: translate('dashboard.evolution.alert.benchmark.not.usable'),
		footer: <div>
			<button className="btn btn-primary" onClick={() => clear()}>{translate('continue')}</button>
		</div>,
		onClose(): void {
			clear();
			setOpen(false);
		},
		visible: true
	};
};