import { gql } from '@apollo/client';

export const QUERY_LCA_MATURITY = gql`
    query QueryLcaMaturity{
        lcaModels {
            maturities {
                id
                name
                description
                goodPractices
            }
        }
    }
`;
