import {
	RelatedDatasetEntity,
	Entity,
	injectInputSetter,
	InputProperty,
	ValueEntity,
	DataMutate
} from '../core/Entity';
import { numNullToString } from '../../utils/numNullToString';
import { Core } from '../core/Core';
import { ResponseDataValidation } from '../data/ResponseDataValidation';
import { Validation } from '../data/entries/Validation';
import { ResponseMutateLineValidationSave } from '../data/ResponseDatas';
import { ICmdbInfos } from '../interfaces/ICmdbInfos';
import { IMutateValidateCmdbLineInput, IMutateValidateCmdbLineOutput } from '../interfaces/IMutateValidateCmdbLine';
import {
	ApolloClientMutateValidateCmdbLine,
	ApolloClientMutateValidatedLaunchCompute,
	ApolloClientMutateValidationLine,
	ApolloClientQueryDataValidation
} from '../../infrastructure/ApolloClass/ValidationClass';
import { MUTATE_VALIDATE_CMDB_LINE } from '../../infrastructure/ApolloClient/requests/MUTATE_VALIDATE_CMDB_LINE';
import { MUTATE_LAUNCH_COMPUTE } from '../../infrastructure/ApolloClient/requests/MUTATE_LAUNCH_COMPUTE';
import { QUERY_DATA_VALIDATION } from '../../infrastructure/ApolloClient/requests/QUERY_DATA_VALIDATION';
import { MUTATE_VALIDATION_LINE } from '../../infrastructure/ApolloClient/requests/MUTATE_VALIDATION_LINE';
import { store } from '../../store';
import { ILaunchCompute } from '../interfaces/ILaunchCompute';
import { ICategoryComparison } from '../interfaces/ICategoryComparison';

export class ValidationDataEntity extends Entity {
	public data: undefined | ValidationDatasetEntity[];
	private _isQuerying = false;

	initialization() {
		this.app.adapter.mutateDataValidation ??= this.app.installer(ApolloClientMutateValidationLine, MUTATE_VALIDATION_LINE);
		this.app.adapter.mutateValidateCmdbLine ??= this.app.installer(ApolloClientMutateValidateCmdbLine, MUTATE_VALIDATE_CMDB_LINE);
		this.app.adapter.mutateLaunchCompute ??= this.app.installer(ApolloClientMutateValidatedLaunchCompute, MUTATE_LAUNCH_COMPUTE);
		this.app.adapter.queryDataValidation ??= this.app.installer(ApolloClientQueryDataValidation, QUERY_DATA_VALIDATION);
		this.app.adapter.storeDataValidation?.({ loading: false, error: null, data: this });
	}

	get(): ValidationDatasetEntity[] | undefined {
		if (!this.data) {
			if (this._isQuerying) return;
			this.queryDataValidation();
		}
		return this.data;
	}

	set(obj: ResponseDataValidation['dataset'] | undefined): void {
		this.data = obj?.map(i => new ValidationDatasetEntity(this.app, this, i));
		this.change();
	}

	change(): Promise<void> | undefined {
		return this.app.adapter.storeDataValidation?.({ loading: false, error: null, data: this });
	}
	
	update(line: ResponseMutateLineValidationSave['mutateValidateEquipmentLine']['equipment']) {
		const equipment = this.data?.reduce<ValidationEquipmentEntity | undefined>(
			(vp, vc) => vp ?? vc.data.equipments.reduce<ValidationEquipmentEntity | undefined>(
				(ep, ec) => ep ?? (ec.data.id === line.id ? ec : undefined),
				undefined),
			undefined);
		if (!equipment) return;
		equipment.define(line);
		this.change();
	}

	updateCmdb(data: IMutateValidateCmdbLineOutput['mutateValidateCmdbLine']) {
		this.data?.find(d => d.data.name === data.data.block)?.defineCmdb(data.data);
		this.change();
	}

	private _storeError(error: Error) {
		this.set(undefined);
		this.app.adapter.storeDataValidation?.({
			loading: false,
			error: error ?? new Error('Authentication Fail'),
			data: this
		});
	}

	/***************************************************
	 * 					API CALLS					   *
	 ***************************************************/

	async queryDataValidation() {
		this._isQuerying = true;
		await this.app.adapter.storeDataValidation?.({ loading: true, error: null, data: undefined });
		const data = await this.callApi(this.app.adapter.queryDataValidation, { datasetId: store.getState().datasetID || '' }).catch(err => {
			this._storeError(err);
			this._isQuerying = false;
			return undefined;
		});
		this._isQuerying = false;
		if (!data) return;
		this.set(data.dataset);
	}

	async mutateValidateCmdbLine(input: IMutateValidateCmdbLineInput) {
		const data = await this.callApi(this.app.adapter.mutateValidateCmdbLine, input);
		if (data.mutateValidateCmdbLine.status !== 200) {
			throw new Error(data.mutateValidateCmdbLine.errors.join('; '));
		}
		this.updateCmdb(data.mutateValidateCmdbLine);
	}

	async mutateLaunchCompute(input: ILaunchCompute): Promise<string> {
		const data = await this.callApi(this.app.adapter.mutateLaunchCompute, input);
		if (!data.mutateLaunchCompute || data.mutateLaunchCompute.status !== 200) {
			throw data?.mutateLaunchCompute?.resultLauncher?.computeLogs?.split('\n').map((m: string) => new Error(m.trim()));
		}

		// Set selectedIndicators to indicators used for computation when computing only one dataset
		if (input.datasetsIds.length === 1) {
			this.app.entities.datasets.changeSelectedIndicators(input.datasetsIds[0], data.mutateLaunchCompute.computedIndicators);
		}
		// Update the datasets used for computation
		if (data.mutateLaunchCompute.datasets.length) {
			data.mutateLaunchCompute.datasets.forEach(d => {
				this.app.entities.datasets.update(d);
			});
		}

		return data.mutateLaunchCompute.resultLauncher.computeLogs ?? '';
	}

	async mutateValidationLine(input: DataMutate<Validation>): Promise<ResponseMutateLineValidationSave | undefined> {
		const data = await this.callApi(this.app.adapter.mutateDataValidation, input).catch(err => {
			this._storeError(err);
			return undefined;
		});
		if (!data || data.mutateValidateEquipmentLine.status !== 200) return;
		this.update(data.mutateValidateEquipmentLine.equipment);
		return data;
	}
}

export class ValidationDatasetEntity extends RelatedDatasetEntity {
	public data: { id: string, name: string, equipments: ValidationEquipmentEntity[], cmdb: ICmdbInfos[], categoryComparison?: ICategoryComparison[] };

	constructor(app: Core, parent: ValidationDataEntity, obj: ResponseDataValidation['dataset'][number]) {
		super(app, parent);
		this.data = {
			...obj,
			equipments: obj.equipments.map(e => new ValidationEquipmentEntity(app, this, e))
		};
	}

	defineCmdb(obj: IMutateValidateCmdbLineOutput['mutateValidateCmdbLine']['data']) {
		const index = this.data.cmdb.findIndex(c => c.id === obj.id);
		if (index > -1) {
			this.data.cmdb[index] = obj;
		}
	}

	/*paste(equipment: ValidationEquipmentEntity, name: keyof Validation, value: string): void {
		let idx = this.data.equipments.indexOf(equipment) - 1;
		const propIdx = this.data.equipments[idx].tablePasteOrder.indexOf(name);
		const block = value.trim();
		let n;
		for (const line of block.split('\r\n')) {
			idx++;
			n = propIdx - 1;
			if (!this.data.equipments[idx]) continue;
			for (const entry of line.split('\t')) {
				n++;
				const prop = this.data.equipments[idx].tablePasteOrder[n];
				if (!prop) continue;
				const input = this.data.equipments[idx].data[prop];
				if (typeof input === 'object') input.set(entry);
			}
		}
	}*/
}

export class ValidationEquipmentEntity extends ValueEntity {
	public data: InputProperty<Validation>;
	public readonly tablePasteOrder: (keyof InputProperty<Validation>)[] = ['validated', 'validationQuantity', 'validationLifetime'];
	public validationQuantity: number | null = null;
	public validationLifetime: number | null = null;

	constructor(app: Core, parent: ValidationDatasetEntity, obj: Validation) {
		super(app, parent);
		this.data = this.define(obj);
	}

	define(obj: Validation): InputProperty<Validation> {
		// By default, validationQuantity and validationLifetime will take the value of quantity and lifetime
		// This allows the user to have a pre-filled validation table when inventory is done
		const update = {
			...obj,
			validated: injectInputSetter('validated', obj.validated ? 'true' : 'false', this),
			validationQuantity: injectInputSetter('validationQuantity', numNullToString(obj.validationQuantity ?? obj.weightedQuantity), this),
			validationLifetime: injectInputSetter('validationLifetime', numNullToString(obj.validationLifetime ?? obj.lifetime), this),
			comment: injectInputSetter('comment', obj.comment ?? '', this),
			consultComment: injectInputSetter('consultComment', obj.consultComment ?? '', this),
		};
		if (this.isChanged.includes('validated')) update.validated.set(this.data.validated.get());
		if (this.isChanged.includes('validationQuantity')) update.validationQuantity.set(this.data.validationQuantity.get());
		if (this.isChanged.includes('validationLifetime')) update.validationLifetime.set(this.data.validationLifetime.get());
		if (this.isChanged.includes('comment')) update.comment.set(this.data.comment.get());
		if (this.isChanged.includes('consultComment')) update.consultComment.set(this.data.consultComment.get());
		if (!obj.validated) {
			this.isChanged.push('validationQuantity');
			this.isChanged.push('validationLifetime');
		}
		this.validationQuantity = obj.validationQuantity;
		this.validationLifetime = obj.validationLifetime;
		this.data = update;
		return this.data;
	}

	preSave(skip = false): void {
		if (this.data.validationQuantity.get().length > 0 && this.data.validationLifetime.get().length > 0 && this.data.validated.get() !== 'true') {
			this.data.validated.set('true');
			this.isChanged.push('validated');
		}
		super.preSave(skip);
	}

	save(): void {
		this.app.entities.validation.mutateValidationLine(
			{
				id: this.data.id,
				values: {
					validated: this.data.validated.get() === 'true',
					validationQuantity: this.data.validationQuantity.get().length ? parseFloat(this.data.validationQuantity.get()) : null,
					validationLifetime: this.data.validationLifetime.get().length ? parseFloat(this.data.validationLifetime.get()) : null,
					comment: this.data.comment.get(),
					consultComment: this.data.consultComment.get(),
				}
			}
		).then((res) => {
			const fail = !res || res.mutateValidateEquipmentLine.status !== 200;
			if (fail) this.data.validated.set('false');
			// Event sent so react can update the UI accordingly
			this.event.emit('change', !fail);
		});
	}
}
