import { useSelector } from 'react-redux';
import { State } from '../../store';
import { CompanyData, CompanyEntity } from '../../domain/entities/CompanyEntity';

type CompanyHookType = { error: Error | null, entity: CompanyEntity | undefined, company: CompanyData, loading: boolean };

export const useCompany = (): CompanyHookType => {
	const { error, data: entity, loading } = useSelector((state: State) => state.company);
	const company = entity?.get() ?? { userList: [], lambdaList: [] };
	return { error, entity, company, loading };
};