import React, { useEffect, useState } from 'react';
import { translate } from '../../infrastructure/translations/translate';
import { resultValue } from './utils/resultValue';
import { ResultCardError } from '../error/ResultCardError/ResultCardError';
import { useTransformedResultTableData } from '../../utils/useTransformedResultTableData';
import { IResultTableProps, ResultTable } from './ResultTable';
import Spinner from '../utils/Spinner/Spinner';
import { IndicatorTable } from './utils/IndicatorTable/IndicatorTable';
import { groupSmallValues } from '../../utils/GraphsUtils';
import { PolarChart } from '../graphs/polars/PolarChart';
import { ResultArrowComparison, ResultComparison } from './ResultArrowComparison';

export type ResultPlanetaryBoundariesType = { data: { indicator: string, unit: string, value: number }[] }
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isResultMaturityScore = (o: any): o is ResultPlanetaryBoundariesType => {
	if (typeof o === 'object') return 'schema' in o && 'data' in o;
	return false;
};
export const ResultPlanetaryBoundaries = ({ data, isRelative }: { data: string, isRelative: boolean }): JSX.Element => {
	if (!isResultMaturityScore(data)) return (<ResultCardError/>);

	// transform data from id to name
	const transformedData = useTransformedResultTableData(data.data);
	const [dataTable, setDataTable] = useState<IResultTableProps | null>(null);

	useEffect(() => {
		if (!transformedData || transformedData instanceof Error) return;
		// set the table for the ResultTable component
		setDataTable({
			columns: [
				{ name: translate('results.indicator') as string, valueType: 'right' },
				{ name: translate('results.unit') as string, valueType: 'right', style: { textAlign: 'left' } },
				{ name: translate('results.resultValue') as string, valueType: 'right' },
			],
			values: transformedData.map((d) => [
				{ value: <IndicatorTable indicator={d.indicator ?? ''} icon={d.icon}/>, valueType: 'left' },
				{ value: isRelative ? '%' : 'PBCI', valueType: 'left', style: { textAlign: 'left' } },
				{ value: (
					<>
						{resultValue(d.value as number)}
						<ResultArrowComparison arrow={ResultComparison({
							value: d.value as number,
							mean: d.mean,
							min: d.min,
							max: d.max,
						})}/>
					</>
				), valueType: 'right' },
			]),
		});
	}, [transformedData]);

	if (transformedData instanceof Error) return <ResultCardError/>;
	if (!dataTable) return <Spinner/>;
	return <ResultTable dataTable={dataTable} />;
};

export const PlanetaryBoundariesGraph = ({ data }: { data: string }): JSX.Element => {
	if (!isResultMaturityScore(data)) return (<ResultCardError/>);
	const transformedData = useTransformedResultTableData(data.data);
	if (!transformedData || transformedData instanceof Error) return (<ResultCardError/>);
	const filteredValues = groupSmallValues(transformedData, 'indicator', 1);
	const polarData = filteredValues.map((obj) => {
		return {
			labels: obj.indicator ?? '',
			values: obj.value ?? 0,
			color: obj.indicatorColor
		};
	});
	return <PolarChart data={polarData} limit={1} />;
};
