import React from 'react';
import { translate } from '../../../infrastructure/translations/translate';
import './ModeSelectionButton.scss';

export const onlyImportCreationMode = [
	translate('inventory.addEquipment.modal.import') as string
];

export const equipmentCreationModes = [
	translate('inventory.addEquipment.modal.explore') as string,
	translate('inventory.addEquipment.modal.configuration') as string,
	translate('inventory.addEquipment.modal.import') as string
];

export const studyParameterModes = [
	translate('userManage.studies.colorSettings.blockMode') as string,
	translate('userManage.studies.colorSettings.indicatorMode') as string,
	translate('userManage.studies.colorSettings.lcsMode') as string,
];

export type Mode = typeof equipmentCreationModes[number] | typeof studyParameterModes[number];

export const ModeSelectionButton = ({ selectedMode, setSelectedMode, mode }: {
	selectedMode: Mode;
	setSelectedMode: (value: Mode) => void;
	mode: Mode;
}) => {
	return (
		<button
			className={`modes_buttons__title${selectedMode === mode ? '__selected' : ''}`}
			onClick={() => {
				setSelectedMode(mode);
			}}
		>
			{mode}
		</button>
	);
};