import { DashboardComparisonData } from '../../domain/interfaces/DashboardComparisonData';

export const reduceEquipments = (equipments: DashboardComparisonData['datasets'][number]['equipments'], e: {
    name: string;
    shortName: string;
    specification: string;
	domain: string;
}) => {
	const equipment = equipments
		.filter(eq => eq.name === e.name && eq.shortName === e.shortName && eq.specification === e.specification && eq.domain === e.domain)
		.reduce<DashboardComparisonData['datasets'][number]['equipments'][number] | undefined>((acc, e) => acc ? {
			...acc,
			quantity: acc.quantity + e.quantity,
			lifetime: acc.lifetime + e.lifetime * e.quantity
		} : { ...e, lifetime: e.lifetime * e.quantity }, undefined);
	if (!equipment) return undefined;
	equipment.lifetime /= equipment.quantity;
	return equipment;
};