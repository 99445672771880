import React from 'react';
import './SearchBar.css';

const SearchBar = ({ searchValue, setSearchValue, navigationInfo, style, onFocus, onBlur }: {
	navigationInfo?: string,
	style?: React.CSSProperties,
	searchValue: string,
	setSearchValue: (search: string) => void,
	onFocus?: () => void,
	onBlur?: () => void,
}): JSX.Element =>
{
	// call the onBlur after 200ms to prevent the click on the search result from being ignored
	const onBlurTimeoutFunction = () => {
		setTimeout(() => {
			if (onBlur) onBlur();
		}, 200);
	};

	return (
		<div className={'search_bar'} style={style}>
			<input
				onFocus={onFocus}
				onBlur={onBlurTimeoutFunction}
				type="text"
				placeholder={navigationInfo}
				aria-label={navigationInfo}
				value={searchValue}
				onChange={(e) => setSearchValue(e.target.value)}/>
			<i className="fa-solid fa-magnifying-glass" style={{ color: '#a8a8a8' }}/>
		</div>
	);
};

export default SearchBar;
