import { hexToRgb } from './hexToRgb';

/**
 * Converts a hex color to an HSL color.
 * @param {string} hex - The hex color to convert.
 * @returns {string} The HSL color.
 *
 * @author Maximilien Valenzano
 */
export const hexToHsl = (hex: string): [number, number, number] => {
	let [r, g, b] = hexToRgb(hex);
	r /= 255;
	g /= 255;
	b /= 255;
	const max = Math.max(r, g, b), min = Math.min(r, g, b);
	let h = 0, s, l = (max + min) / 2;

	if (max == min) {
		h = s = 0;
	} else {
		const d = max - min;
		s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
		switch (max) {
			case r:
				h = (g - b) / d + (g < b ? 6 : 0);
				break;
			case g:
				h = (b - r) / d + 2;
				break;
			case b:
				h = (r - g) / d + 4;
				break;
		}
		h /= 6;
	}

	s = s * 100;
	s = Math.round(s);
	l = l * 100;
	l = Math.round(l);
	h = Math.round(360 * h);

	return [h, s, l];
};

/**
 * Converts a hsl to a hex color.
 * @param {number} h - The hue.
 * @param {number} s - The saturation.
 * @param {number} l - The lightness.
 * @returns {string} The hex color.
 *
 * @author Maximilien Valenzano
 */
export const hslToHex = (h: number, s: number, l: number): string => {
	l /= 100;
	const a = s * Math.min(l, 1 - l) / 100;
	const f = (n: number) => {
		const k = (n + h / 30) % 12;
		const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
		return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
	};
	return `#${f(0)}${f(8)}${f(4)}`;
};
