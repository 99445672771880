// Loading comonent, full screen, overlay

import React from 'react';
import logo from '../media/logo.svg';
import Modal from './modal/Modal';
import Spinner from './utils/Spinner/Spinner';
import { translate } from '../infrastructure/translations/translate';
import { useUser } from './hooks/useUser';

// We should use this component (or another custom spinner if pertinant) to show loading screen for every request
const Loading = (): JSX.Element => {
	const { data: user } = useUser();
	const logoImg = user?.myCompany?.style ? user?.myCompany?.logo : null;
	return (
		<Modal>
			<div className={'card col ai-center'} style={{ padding: '10px 30px' }}>
				<div style={{ margin: '10px 0', display: 'flex', width: 'auto' }}>
					{logoImg ?
						<img alt={'Company logo'} src={logoImg} width={'50px'}/>
						:
						<img alt="Resilio logo" src={logo} width={'50px'} />
					}
				</div>
				<div className={'col ai-center'} style={{ marginBottom: '10px', display: 'flex', width: 'auto' }}>
					<Spinner />
					<span style={{ textAlign: 'center', margin: '10px 0', display: 'none' }} className="visually-hidden">{translate('loading')}...</span>
				</div>
			</div>
		</Modal>
	);
};

export default Loading;
