import React from 'react';
import { translate } from '../../../infrastructure/translations/translate';
import bugImg from '../../../media/error_svg_images/bug_fixing_man.svg';
import './ResultCardError.css';

export const ResultCardError = ({ errorMsg }: {errorMsg?: string}): JSX.Element => {
	return (
		<div className='result_card_error'>
			<img src={bugImg} alt={'img_bug'}/>
			<p id="error_message">{errorMsg === undefined ? translate('results.errorHappens') : errorMsg}</p>
		</div>
	);
};
