import React, { useEffect, useState } from 'react';
import { translate } from '../../infrastructure/translations/translate';
import { ResultArrowComparison, ResultArrowComparisonType, ResultComparison } from './ResultArrowComparison';
import { ResultCardError } from '../error/ResultCardError/ResultCardError';
import { resultValue } from './utils/resultValue';
import { IResultTableProps, ResultTable } from './ResultTable';
import { useTransformedResultTableData } from '../../utils/useTransformedResultTableData';
import Spinner from '../utils/Spinner/Spinner';

export type ResultNormalizedFootprintDetailedType = { schema: { fields: { name: string, type: string }[], primaryKey: string[], pandas_version: string }, data: { indicator: string, unit: string, life_cycle_step: string, block: string, value: number, mean?: number, min?: number, max?: number, std?: number }[] }
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isResultNormalizedFootprintDetailed = (o: any): o is ResultNormalizedFootprintDetailedType => {
	if (typeof o === 'object') return 'schema' in o && 'data' in o;
	return false;
};
export const ResultNormalizedFootprintDetailed = ({ data }: { data: string }): JSX.Element => {
	if (!isResultNormalizedFootprintDetailed(data)) return (<ResultCardError/>);

	// transform the data from id to name
	const transformedData = useTransformedResultTableData(data.data);
	const [indicators, setIndicators] = useState<{ value: string, name: string }[]>([]);
	const [selectedIndic, setSelectedIndic] = useState<{ value: string, name: string } | undefined>(undefined);
	const [dataTable, setDataTable] = useState<IResultTableProps | null>(null);

	useEffect(() => {
		if (!transformedData || transformedData instanceof Error) return;
		
		const newIndicators: { value: string, name: string }[] = [];
		for (const d of transformedData) {
			if (!newIndicators.find(i => i.value === d.indicator)) {
				newIndicators.push({
					value: d.indicator ?? '',
					name: `${d.indicator} - PBCI`
				});
			}
		}
		setIndicators(newIndicators);

		// Ensure selected indicator is valid or select the first available
		const validSelectedIndic = newIndicators.find(i => i.value === selectedIndic?.value) || newIndicators[0];
		if (!selectedIndic || validSelectedIndic.value !== selectedIndic.value) {
			setSelectedIndic(validSelectedIndic);
		}
	}, [transformedData]);

	useEffect(() => {
		if (!transformedData || transformedData instanceof Error || !selectedIndic) return;

		const table: { indicator: string, unit: string, block: string, values: { life_cycle_step: string, value: number, arrow: ResultArrowComparisonType }[] }[] = [];

		for (const d of transformedData) {
			if (d.indicator !== selectedIndic.value) continue;

			let t = table.find(t => t.block == d.block);
			if (!t) {
				t = {
					indicator: d.indicator ?? '',
					unit: d.unit ?? '',
					block: d.block ?? '',
					values: []
				};
				table.push(t);
			}

			const arrow = ResultComparison({ value: d.value ?? 0, mean: d.mean, min: d.min, max: d.max });
			t.values.push({ life_cycle_step: d.life_cycle_step ?? '', value: d.value ?? 0, arrow });
		}

		if (!table.length) return;

		setDataTable({
			columns: [
				{ name: translate('results.block') as string },
				{ name: translate('results.unit') as string, style: { textAlign: 'left' } },
				...table[0].values.map((vs) => ({
					name: translate(`results.lifeCycleStep.${vs.life_cycle_step}`) as string,
				})),
			],
			values: table.map((d) => [
				{ value: d.block, valueType: 'left' },
				{ value: translate('results.valuePBCI') as string, valueType: 'left', style: { textAlign: 'left' } },
				...d.values.map((vs) => ({
					value: (
						<>
							{resultValue(vs.value)}
							<ResultArrowComparison arrow={vs.arrow} />
						</>
					),
					valueType: 'right',
				})),
			]),
			selector: {
				selected: selectedIndic,
				options: indicators,
				onSelect: (selected) => setSelectedIndic(selected),
			},
		});
	}, [transformedData, selectedIndic, indicators]);

	if (transformedData instanceof Error) return <ResultCardError/>;
	if (!dataTable) return <Spinner/>;
	return <ResultTable dataTable={dataTable} />;
};
