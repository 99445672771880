import { IDataDTO } from '../../domain/interfaces/DTO/IDataDTO';
import { DashboardEntity } from '../../domain/entities/DashboardEntity';
import { IDashboard } from '../../domain/interfaces/IDashboard';
import { useSelector } from 'react-redux';
import { State } from '../../store';

type DashboardHookType = IDataDTO & { entity: DashboardEntity | undefined, dashboards: IDashboard[] };

export const useDashboards = (ids?: string[]): DashboardHookType => {
	const { loading, error, data: entity  } = useSelector((state: State) => state.dataDashboards);
	const dashboards = entity?.get() ?? [];
	if (!ids) return { loading, error, entity, dashboards };
	return { loading, error, entity, dashboards: dashboards.filter(d => ids.includes(d.id)) };
};