import { IDataDTO } from '../../domain/interfaces/DTO/IDataDTO';
import { UserDashboardEntity } from '../../domain/entities/UserDashboardEntity';
import { IUserDashboard } from '../../domain/interfaces/IUserDashboard';
import { useSelector } from 'react-redux';
import { State } from '../../store';

type UsersDashboardHookType = IDataDTO & { entity: UserDashboardEntity | undefined, usersDashboards: IUserDashboard[] };

export const useUsersDashboard = (ids?: string[]): UsersDashboardHookType => {
	const { loading, error, data: entity } = useSelector((state: State) => state.dataUserDashboards);
	const usersDashboards = entity?.get() ?? [];
	if (!ids) return { loading, error, entity, usersDashboards };
	return { loading, error, entity, usersDashboards: usersDashboards.filter(d => ids.includes(d.id)) };
};