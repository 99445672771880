import { gql } from '@apollo/client';

export const QUERY_DASHBOARD_USERS_CAN_ACCESS = gql`
	query DashboardUsersCanAccess (
		$dashboardId: String!
	) {
		dashboardUsersCanAccess(
			dashboardId: $dashboardId
		) {
      		id
      		email
    	}
  	}
`;