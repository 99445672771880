import { gql } from '@apollo/client';

export const MUTATE_EDIT_USER_DASHBOARD = gql`
    mutation MutateEditUserDashboard (
        $id: String!
        $name: String!
        $description: String!
        $delete: Boolean!
    ) {
        mutateEditUserDashboard (
            id: $id
            name: $name
            description: $description
            delete: $delete
        ) {
            status
            userDashboard {
                id
                name
                description
                userId
                cards {
                    id
                    name
                    type
                    dashboard {
                        id
                    }
                    data
                    order
                }
                sharedUsers {
                    id
                    email
                }
            }
        }
    }
`;
