import React, { useEffect, useState } from 'react';
import { CORP_COLORS } from '../../config';
import { translate } from '../../infrastructure/translations/translate';
import { resultValue } from './utils/resultValue';
import { ResultCardError } from '../error/ResultCardError/ResultCardError';
import { useTransformedResultTableData } from '../../utils/useTransformedResultTableData';
import { IResultTableProps, ResultTable } from './ResultTable';
import Spinner from '../utils/Spinner/Spinner';

export type ResultQualityBasedResultType = { data: {
	quality: number; equipment: string, equipment_specification?: string, block: string, index: number, value: number, quality_weight: number, percentage: number 
}[] }
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isResultQualityBasedResult = (o: any): o is ResultQualityBasedResultType => {
	if (typeof o === 'object') return 'schema' in o && 'data' in o;
	return false;
};

// dictionnary of quality from 1 to 3
const qualities = [
	translate('results.quality.low'),
	translate('results.quality.medium'),
	translate('results.quality.high')
];

export const ResultQualityBasedResult = ({ data }: { data: string }): JSX.Element => {
	if (!isResultQualityBasedResult(data)) return (<ResultCardError/>);
	// transform data from id to name
	const transformedData = useTransformedResultTableData(data.data);
	const [dataTable, setDataTable] = useState<IResultTableProps | null>(null);

	// old results has summed quality that goes above 3
	// we need keep the old component elements for old results to avoid incoherent data
	const goodQualityStyle = (quality: number) => {
		if (quality === 3) return { color: CORP_COLORS.gray };
	};
	useEffect(() => {
		if (!transformedData || transformedData instanceof Error) return;
		const oldResults = transformedData?.some((d) =>  d.quality as number > 3);
		// set the table for the resultTable component
		setDataTable({
			columns: [
				{ name: translate('results.equipment') as string },
				{ name: translate('results.block') as string },
				{ name: !oldResults && translate('results.quality') as string },
				{ name: oldResults && translate('results.valuePBCI') as string },
				{ name: oldResults && translate('results.qualityWeight') as string },
				{ name: translate('results.percentage') as string },
			],
			values: transformedData.sort((a, b) => (b.percentage as number) > (a.percentage as number) ? 1 : -1).map((d) => [
				{ value: <>
					<div>{d.equipment?.name}</div>
					<div style={{ fontSize: '.8em' }}><em>{d.equipment?.specification}</em></div>
				</>, valueType: 'left', style: goodQualityStyle(d.quality as number) },
				{ value: d.block, valueType: 'left', style: goodQualityStyle(d.quality as number) },
				{ value: !oldResults && qualities[d.quality as number - 1], valueType: 'left', style: goodQualityStyle(d.quality as number) },
				{ value: oldResults && resultValue(d.value as number), valueType: 'right' },
				{ value: oldResults && resultValue(d.quality_weight as number), valueType: 'right' },
				{ value: resultValue(d.percentage as number), valueType: 'right', style: goodQualityStyle(d.quality as number) },
			])
		});
	}, [transformedData]);
	
	if (transformedData instanceof Error) return (<ResultCardError/>);
	if (!dataTable) return <Spinner/>;
	return <ResultTable dataTable={dataTable} />;
};
