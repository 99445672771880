import { gql } from '@apollo/client';

export const QUERY_LCA_TEMPLATE = gql`
    query QueryLcaTemplate{
        lcaModels {
            templates {
                id
                name
                description
                functionalUnit
                inventory
                maturity
                resultList
                indicators
                lifeCycleSteps
            }
        }
    }
`;
