import React from 'react';
import './MultiChoiceDropdown.scss';

export interface MultiChoiceDropdownOption {
	id: string;
	element: React.ReactElement | string;
	dropChildren: MultiChoiceDropdownOption[];
	selectable: boolean;
	disabled?: boolean;
	onOpen?: (id: string) => void;
	onFocus?: (e: React.FocusEvent<HTMLButtonElement>) => void;
	onBlur?: (e: React.FocusEvent<HTMLButtonElement>) => void;
	onMouseEnter?: (e: React.MouseEvent<HTMLButtonElement>) => void;
	onMouseLeave?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface MultiChoiceDropdownProps {
	list: MultiChoiceDropdownOption[],
	values: string[],
	onChange: (values: string[]) => void
}

const MultiChoiceDropdownElement = ({
	id,
	element,
	dropChildren,
	selectable,
	selected,
	onSelect,
	values,
	disabled,
	onOpen,
	onFocus,
	onBlur,
	onMouseEnter,
	onMouseLeave,
}: MultiChoiceDropdownOption & {
	values: string[],
	selected: boolean,
	onSelect: (value: string) => void
}): JSX.Element => {
	const [open, setOpen] = React.useState(false);
	const click = () => {
		if (disabled) return;
		if (selectable) {
			onSelect(id);
			return;
		}
		if (!open) {
			onOpen?.(id);
		}
		setOpen(!open);
	};
	return <div className="multi_choice_dropdown_element">
		<button
			type={'button'}
			onClick={click}
			className="multi_choice_dropdown_button"
			onFocus={onFocus}
			onBlur={onBlur}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<div className="multi_choice_dropdown_button_action">
				{disabled && <i className={'fa-solid fa-xmark'} />}
				{!disabled && <>
					{!selectable && <i className={`fa-solid ${open ? 'fa-square-chevron-down' : 'fa-square-chevron-up'}`}/>}
					{selectable && <i className={`fa-regular ${selected ? 'fa-check-square' : 'fa-square'}`}/>}
				</>}
			</div>
			{element}
		</button>
		<div className="multi_choice_dropdown_children">
			{open && dropChildren && <div className="multi_choice_dropdown_child">
				{dropChildren.map((child) => <MultiChoiceDropdownElement
					key={child.id}
					{...child}
					selected={values.includes(child.id)}
					onSelect={onSelect}
					values={values}
				/>)}
			</div>}
		</div>
	</div>;
};

export const MultiChoiceDropdown = ({
	list,
	values,
	onChange
}: MultiChoiceDropdownProps): JSX.Element => {
	const onSelect = (value: string) => {
		if (values.includes(value)) {
			onChange(values.filter((v) => v !== value));
		} else {
			onChange([...values, value]);
		}
	};
	return <div className="multi_choice_dropdown">
		{list.map((l) => <MultiChoiceDropdownElement
			key={l.id}
			{...l}
			selected={values.includes(l.id)}
			onSelect={onSelect}
			values={values}
		/>)}
	</div>;
};
