import { gql } from '@apollo/client';

export const QUERY_USER_DASHBOARD = gql`
    query QueryUserDashboard (
        $id: String!
    ) {
        userDashboard (
            id: $id
        ) {
            id
            name
            description
            cards {
                id
                name
                type
                dashboard {
                    id
                }
                data
                order
            }
            userId
            sharedUsers {
                id
                email
            }
        }
    }
`;
