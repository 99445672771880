import React, { useEffect, useRef } from 'react';
import { translate } from '../../infrastructure/translations/translate';
import { AlertError } from '../Alerts';
import ButtonLoading from '../button/ButtonLoading/ButtonLoading';


// If data are fetched within the Modal, don't forget to add a loading state and to show the Loading component
const Modal = ({
	children,
	style,
	onClose
}: { children: React.ReactElement | React.ReactElement[] | string, style?: React.CSSProperties, onClose?: () => void }): JSX.Element => {
	let lastElement: null | HTMLElement = null;
	const ref = useRef<HTMLDivElement>(null);
	let downOutside = false;
	let tabForward = false;
	const isElemIn = (e: HTMLElement) => e === ref.current || ref.current?.contains(e);

	const focus = (last?: boolean) => {
		const elems = ref.current?.querySelectorAll<HTMLInputElement | HTMLButtonElement>('input, button, a, textarea, select, [tabindex]');
		if (!elems) return;
		const elem = elems[last ? elems.length - 1 : 0];
		if (elem) elem.focus();
	};

	const handleClickDown = (e: MouseEvent) => {
		const elem = e.target as HTMLElement;
		if (elem === lastElement || lastElement?.contains(elem)) return;
		downOutside = !isElemIn(elem);
	};

	const handleClickUp = (e: MouseEvent) => {
		const elem = e.target as HTMLElement;
		if (elem === lastElement || lastElement?.contains(elem)) return;
		if (downOutside && !isElemIn(elem)) onClose?.();
	};

	const handleKeyDown = (e: KeyboardEvent) => {
		if (e.key === 'Escape') onClose?.();
		if (e.key === 'Tab') tabForward = !e.shiftKey;
	};

	const handleFocusIn = (e: FocusEvent) => {
		const elem = e.target as HTMLElement;
		if (!isElemIn(elem)) focus(!tabForward);
	};

	useEffect(() => {
		lastElement = document.activeElement as HTMLElement;
		document.addEventListener('keydown', handleKeyDown);
		document.addEventListener('mousedown', handleClickDown);
		document.addEventListener('mouseup', handleClickUp);
		document.addEventListener('focusin', handleFocusIn);
		focus();
		return () => {
			document.removeEventListener('keydown', handleKeyDown);
			document.removeEventListener('mousedown', handleClickDown);
			document.removeEventListener('mouseup', handleClickUp);
			document.removeEventListener('focusin', handleFocusIn);
			lastElement?.focus();
		};
	}, []);
	return (
		<div className={'modal'}>
			<div style={style} ref={ref} role={'dialog'} aria-modal={true}>
				{children}
			</div>
		</div>
	);
};

export const ModalFooter = ({
	onClose,
	handleFunction,
	buttonTitle,
	buttonDisabled,
	loading,
	error,
	errorMessage,
}: { onClose: () => void,
	handleFunction: () => void,
	buttonTitle: string,
	buttonDisabled:
	boolean,
	loading: boolean,
	error: boolean
	errorMessage: string
	}): JSX.Element => {

	return (
		<div className={'modal_footer dataset_management_footer'}>
			{error && <AlertError status={errorMessage}/>}
			<button type={'button'} onClick={onClose}>{translate('cancel')}</button>
			<ButtonLoading
				disable={buttonDisabled}
				classname={buttonDisabled ? '' : 'button_primary'}
				title={buttonTitle}
				loading={loading}
				onClick={handleFunction}
			/>
		</div>
	);
};

export default Modal;
