import React from 'react';

export const addMailto = (text: string): JSX.Element => {
	// Regular expression to find email addresses
	const emailRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g;

	// Check if the message contains an email address
	if (emailRegex.test(text)) {
		// Split the message and wrap emails in mailto links
		const parts = text.split(emailRegex);
		return (
			<span className="mailto_text_group">
				{parts.map((part, index) => 
					emailRegex.test(part) ? <a key={index} href={`mailto:${part}`}>{part}</a> : part
				)}
			</span>
		);
	} else {
		return <>{text}</>;
	}
};