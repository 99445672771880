import { ApolloClientProvider } from '../ApolloClient/ApolloClientProvider';
import { ResponseDataSettings } from '../../domain/data/ResponseDataSettings';
import { IAPIProps } from '../../domain/interfaces/IAPIProps';
import { ResponseMutateLineSettingsSave } from '../../domain/data/ResponseDatas';
import { DataMutate } from '../../domain/core/Entity';
import { Setting } from '../../domain/data/entries/Setting';
import { IQuerySettingTypesOutput } from '../../domain/interfaces/IQuerySettingTypes';

export class ApolloClientQuerySettingTypes extends ApolloClientProvider<IQuerySettingTypesOutput> {}
export class ApolloClientQueryDataSettings extends ApolloClientProvider<ResponseDataSettings, { datasetId: string }> {
	exec({ token, lang, adapter }: IAPIProps, data: { datasetId: string }): Promise<ResponseDataSettings | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateSettingsLine extends ApolloClientProvider<ResponseMutateLineSettingsSave, DataMutate<Setting>> {
	exec({ token, lang, adapter }: IAPIProps, data: DataMutate<Setting>): Promise<ResponseMutateLineSettingsSave | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}