import {
	ApolloClient,
	ApolloLink,
	ApolloQueryResult,
	createHttpLink,
	DocumentNode,
	FetchResult,
	from,
	InMemoryCache,
	NormalizedCacheObject,
	OperationVariables
} from '@apollo/client';
import { BASE_API_URL } from '../../config';
import { setContext } from '@apollo/client/link/context';
import { AdapterContract } from '../../domain/core/AdapterContract';
import { onError } from '@apollo/client/link/error';
import { EConnectionStatus } from '../../domain/interfaces/EConnectionStatus';

const inMemoryCache = new InMemoryCache({ addTypename: false });

export class ApolloClientContext {
	private client: ApolloClient<NormalizedCacheObject>;
	private break = false;
	constructor(
		private token: string | null | undefined,
		private lang: string = 'en',
		private adapter: AdapterContract
	) {
		this.adapter.storeEvent.on('user:status', this.breaking.bind(this));
		const errorLink = onError(({  networkError }) => {
			if (networkError) {
				if (networkError.message.includes('Token expired')) {
					this.adapter.storeError?.(new Error('You need to be logged to perform this action')).then();
				}
			}
		});
		const refreshTokenLink = new ApolloLink((operation, forward) => {
			return forward(operation).map((response) => {
				if (!this.break) {
					const context = operation.getContext();
					const { response: { headers } } = context;
					if (headers) {
						const token = headers.get('authorization');
						if (token) {
							this.adapter.setToken?.(token).then();
						}
					}
				}
				this.adapter.storeEvent.off('user:status', this.breaking.bind(this));
				return response;
			});
		});
		const httpLink = createHttpLink({ uri: BASE_API_URL });
		const authLink = setContext((_, { headers }) => {
			return {
				headers: {
					...headers,
					authorization: this.token ? `JWT ${this.token}` : '',
					lang: this.lang
				}
			};
		});

		this.client = new ApolloClient({
			link: from([errorLink, authLink, refreshTokenLink, httpLink]),
			cache: inMemoryCache,
			credentials: 'same-origin',
		});
	}
	query<T> (query: DocumentNode, variables: OperationVariables | undefined = undefined): Promise<ApolloQueryResult<T>> {
		return this.client.query({ query, fetchPolicy: 'no-cache', variables });
	}
	mutation<T> (mutation: DocumentNode, variables: OperationVariables): Promise<FetchResult<T>> {
		return this.client.mutate({ mutation, variables });
	}

	breaking (status: EConnectionStatus) {
		if (status === EConnectionStatus.DISCONNECTED) this.break = true;
	}
}
