/**
 * Data provider interface.
 *
 * Abstracts the data provider from the infrastructure layer.
 * Structure of getter and setter for requests.
 *
 * @author Maximilien Valenzano
 */
export abstract class DataProvider<T> {
	abstract get(props: { token: string, lang: string }, data?: unknown | undefined): Promise<T | null | undefined | Error>
	abstract set(props: { token: string, lang: string }, data: unknown): Promise<T | null | undefined | Error>
}
