import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { linkGenerator } from '../../../router/linkGenerator';
import ChoiceSelector from '../choiceSelector/choiceSelector';
import './datasetSelector.css';
import { translate } from '../../../infrastructure/translations/translate';
import { useDatasets } from '../../hooks/useDatasets';
import { useStudies } from '../../hooks/useStudies';
import { Study } from '../../../domain/data/entries/Study';
import { StudyDataset } from '../../../domain/data/entries/StudyDataset';


const DatasetSelector = (): JSX.Element => {
	const navigate = useNavigate();
	const { studies } = useStudies();
	const {
		action,
		study: studyId,
		dataset: datasetId,
		domain
	} = useParams<{ action: string, study: string, dataset: string, domain: string }>();

	const currentStudy = studies?.find(s => s.id === studyId);
	const { datasets } = useDatasets(currentStudy?.datasetsId ?? []);

	if (!studies || !studyId || !currentStudy) return <></>;
	const listCompany: { value: string, name: string }[] = [];
	for (const study of studies) {
		if (!study.companyFk) continue;
		const v = { value: study.companyFk.id, name: study.companyFk.name };
		if (!listCompany.find(l => l.value === v.value)) listCompany.push(v);
	}

	const [company, setCompany] = useState(currentStudy.companyFk.id || listCompany[0].value);
	const listStudiesComp = studies.filter(s => s.companyFk.id === company);

	// Filter studies with same name by comparing index and last index of the study's name in the list
	const studiesWithSameName = listStudiesComp
		.filter((s) => (
			listStudiesComp.map(s => s.name.trim())).indexOf(s.name.trim()) !==
			listStudiesComp.map(s => s.name.trim()).lastIndexOf(s.name.trim()));

	// TODO: We can do this on the filter -> always show the year
	const listStudies = listStudiesComp.map(s => (studiesWithSameName.includes(s)) ? ({
		value: s.id,
		name: s.name.concat(' ' + s.year.toString())
	}) : ({ value: s.id, name: s.name }));

	const foundStudy = studies.filter(s => s.companyFk.id === company).find(s => s.id === studyId);
	const handleChoiceCompany = (value: string) => {
		setCompany(value);
	};

	/**
	 * This function is used to handle the action when the user select a study
	 * dry code
	 * @param {string} selectedStudyId
	 * @param {string} a
	 * @param {string} selectedDatasetId
	 * @param {string} domain
	 * @param {StudyDataset} currentDataset
	 * @returns {void}
	 * @memberof DatasetSelector
	 * @Author Maximilien Valenzano
	 */
	const handleActionValidInDataset = (
		selectedStudyId: string,
		a: string | undefined,
		selectedDatasetId: string | undefined,
		domain: string | undefined,
		currentDataset?: StudyDataset
	): void => {

		const findStudyAndDataset = (studyId: string, datasetName?: string): { study: Study | undefined, dataset: StudyDataset | undefined } => {
			const study = studies.find(s => s.id === studyId);
			const dataset = datasetName
				? datasets?.filter(d => d.studyId === study?.id).find(d => d.name.trim() === datasetName)
				: datasets?.find(d => d.id === selectedDatasetId);
			return { study, dataset };
		};

		const checkDataset = () => {
			const { study, dataset } = findStudyAndDataset(selectedStudyId, currentDataset?.name.trim());
			if (!dataset) return false;
			navigate(linkGenerator(study?.id, a, dataset.id, domain));
			return true;
		};

		if (checkDataset()) return;

		const { study, dataset } = findStudyAndDataset(selectedStudyId);
		if (!study) return;
		if (selectedDatasetId === 'all') return navigate(linkGenerator(study.id, a, 'all'));

		if (dataset) {
			const navigateToDefaultOverview = () => navigate(linkGenerator(selectedStudyId, 'overview', selectedDatasetId));
			if (dataset.status !== undefined) {
				switch (a) {
					case 'inventory':
						if (!dataset.status.inventory) return navigateToDefaultOverview();
						break;
					case 'maturity':
						if (!dataset.status.maturity || dataset.status.maturity === 'none') return navigateToDefaultOverview();
						break;
					case 'validation':
						if (!dataset.permissions.validate) return navigateToDefaultOverview();
						break;
					case 'results':
						if (dataset.status.results !== 'ready') return navigateToDefaultOverview();
						break;
				}
			} else return navigateToDefaultOverview();
		}
		return navigate(linkGenerator(selectedStudyId, a, selectedDatasetId, domain));
	};

	if (!foundStudy || !datasetId) {
		if (!foundStudy) {
			const study = studies.find(s => s.companyFk.id === company);
			if (!study) return <></>;
			handleActionValidInDataset(study.id, action, study.datasetsId[0], domain);
		}
		if (currentStudy && !action) {
			const handleChoiceOnlyStudy = (value: string) => {
				navigate(linkGenerator(value));
			};
			return (
				<div className="datasetSelector">
					{listCompany.length > 1 &&
						<ChoiceSelector
							selectorName={'companySelector'}
							name={translate('datasetSelector.company') as string}
							selected={company}
							list={listCompany.sort(
								(a, b) =>
									a.name.toLowerCase().localeCompare(b.name.toLowerCase())
							)}
							handle={handleChoiceCompany}/>
					}
					<ChoiceSelector name={translate('datasetSelector.study') as string} selectorName={'studySelector'}
						selected={studyId} list={listStudies} handle={handleChoiceOnlyStudy}/>
				</div>
			);
		}
		return <></>;
	}

	const listDatasets = datasets?.filter(d => d.studyId === foundStudy.id).map(d => ({ value: d.id, name: d.name })) ?? [];
	if (action === 'results') listDatasets?.unshift({ value: 'all', name: translate('datasetSelector.all') as string });


	const handleChoiceStudy = (selectedStudyId: string) => {
		// get the information of the current study and dataset
		const currentDataset = datasets?.find(d => d.id === datasetId);
		handleActionValidInDataset(selectedStudyId, action, studies.find(s => s.id === selectedStudyId)?.datasetsId[0], domain, currentDataset);
	};
	const handleChoiceDataset = (selectedDatasetId: string) => {
		handleActionValidInDataset(foundStudy.id, action, selectedDatasetId, domain);
	};
	return (
		<div className="datasetSelector">
			{listCompany.length > 1 &&
				<ChoiceSelector selectorName={'companySelector'} name={translate('datasetSelector.company') as string}
					selected={company} list={listCompany.sort(
						(a, b) =>
							a.name.toLowerCase().localeCompare(b.name.toLowerCase())
					)} handle={handleChoiceCompany}/>}
			{listCompany.length == 1 && <div className={'choice_selector ai-center flex'}>
				<label htmlFor={'companySelector'}>{translate('datasetSelector.company') as string}</label>
				{listCompany[0].name}  </div>}

			{listStudies.length > 1 &&
				<ChoiceSelector name={translate('datasetSelector.study') as string} selectorName={'studySelector'}
					selected={studyId} list={listStudies.sort(
						(a, b) =>
							a.name.toLowerCase().localeCompare(b.name.toLowerCase())
					)} handle={handleChoiceStudy}/>}
			{listStudies.length == 1 && <div className={'choice_selector ai-center flex'}>
				<label htmlFor={'studySelector'}>{translate('datasetSelector.study') as string}</label>
				{listStudies[0].name}  </div>}

			{listDatasets.length > 1 &&
				<ChoiceSelector name={translate('datasetSelector.perimeter') as string} selectorName={'datasetSelector'}
					selected={datasetId} list={listDatasets.sort(
						(a, b) =>
							a.name.toLowerCase().localeCompare(b.name.toLowerCase())
					)} handle={handleChoiceDataset}/>}
			{listDatasets.length == 1 && <div className={'choice_selector ai-center flex'}>
				<label htmlFor={'datasetSelector'}>{translate('datasetSelector.perimeter') as string}</label>
				{listDatasets[0].name}  </div>}
		</div>
	);
};

export default DatasetSelector;
