import { gql } from '@apollo/client';

export const MUTATE_INVENTORY_EXTRA_EDIT = gql`
    mutation MutateInventoryExtraEdit(
        $datasetId: String!
        $referenceFlux: Float
        $indicators: [String!]
        $lifeCycleSteps: [String!]
        $blockEnergyMixes: [BlockEnergyMixLink]
        $factorSets: [String]
        $energyMixFactorSets: [String]
        $sniffing: Boolean
    ){
        mutateInventoryExtraEdit(
            datasetId: $datasetId
            referenceFlux: $referenceFlux
            indicators: $indicators
            lifeCycleSteps: $lifeCycleSteps
            blockEnergyMixes: $blockEnergyMixes
            factorSets: $factorSets
            energyMixFactorSets: $energyMixFactorSets
            sniffing: $sniffing
        ){
            status
        }
    }
`;
