import { IDataDTO } from '../../domain/interfaces/DTO/IDataDTO';
import { ManageEquipmentEntity } from '../../domain/entities/ManageEquipmentEntity';
import { useSelector } from 'react-redux';
import { State } from '../../store';

type ManageEquipmentHookType = IDataDTO & { entity: ManageEquipmentEntity | undefined };

export const useManageEquipment = (): ManageEquipmentHookType => {
	const { loading, error, data } = useSelector((state: State) => state.dataEquipmentStatus);
	return { loading, error, entity: data };
};