import React, { useState } from 'react';
import './Tip.css';
import { Alert } from '../../Alerts';
import { translate } from '../../../infrastructure/translations/translate';

export const Tip = ({
	children,
	style,
	type,
	icon,
	border
}: { children: string | React.ReactElement, icon?: string, style?: React.CSSProperties, type?: 'danger' | 'success' | 'warning' | 'info' | 'normal', border?: string }): JSX.Element => {
	const [show, setShow] = useState(true);
	if (!show) return <></>;
	border = border ? `solid 2px ${border}` : 'none';
	return (
		<Alert variant={type ?? 'info'} style={{ ...style, border }}>
			<div className={'alert_content'}>
				{icon === 'archive' ? <i className="icon_tip fa-solid fa-box-archive"/> : <></>}
				<pre className='tip_text' style={{ paddingTop: '5px', whiteSpace: 'break-spaces', fontFamily: 'inherit' }}>{children}</pre>
			</div>
			<div>
				<button aria-label={translate('close') as string} type={'button'} className={'button_outline'}
					onClick={() => setShow(false)}>x
				</button>
			</div>
		</Alert>
	);
};