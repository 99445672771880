import React from 'react';
import { Bar, ChartProps } from 'react-chartjs-2';
import { getColors, GRAPH_COLORS } from '../../../config';
import { ChartData } from 'chart.js';
import merge from 'lodash.merge';
import { cutLabels } from '../utils/cutLabels';

export interface BarData {
	label: string;
	value: number;
	scope: string;
	color?: string;
}

/**
 *
 * @param barData { label: string, value: number, scope: string }[]
 * @param datasetRef string | null
 * @param maxY number
 * @param legend string
 * @param options
 * @param thin
 * @param percentage
 * @param categoryPercentage
 * @param borderRadius
 * @param borderWidth
 * @param heigth
 * @param maxBarWidth
 * @param labelLength {number} - Fix the max length of label line, so it will be cut in multiple lines if it's too long. This DO NOT constraint the number of characters.
 *
 * @returns JSX.Element
 * @description A bar chart componant that displays data in a bar chart
 * @author Yacine Bentayeb
 */
export const BarChart = ({ barData, datasetRef, maxY, minY, legend, options, plugins, thin, percentage, categoryPercentage, borderRadius, borderWidth, height, maxBarWidth, labelLength }:{
	barData: (BarData | null)[],
	datasetRef?: string | null,
	maxY?: number,
	minY?: number,
	legend: string,
	options?: ChartProps<'bar'>['options'],
	plugins?: ChartProps<'bar'>['plugins'],
	thin?: number,
	percentage?: number,
	categoryPercentage?: number,
	borderRadius?: number;
	borderWidth?: number;
	height?: number;
	maxBarWidth?: number;
	labelLength?: number;
}): JSX.Element => {
	const [chartKey, setChartKey] = React.useState<number>(0);
	const [timeOutKey, setTimeOutKey] = React.useState<ReturnType<typeof setTimeout>>();
	const color = barData.map(d => {
		if (!d) return '';
		if (!d.color) return getColors(1)[0];
		if (d.color in GRAPH_COLORS) return GRAPH_COLORS[d.color as keyof typeof GRAPH_COLORS];
		return /^#[abcdef0-9]{3,6}/.test(d.color) ? d.color : getColors(1)[0];
	});
	const labels = barData.map(d => d && cutLabels(d.label, labelLength));
	const values = barData.map(d => d && d.value);

	const data: ChartData<'bar'> = {
		labels: labels,
		datasets: [
			{
				label: legend,
				data: values,
				backgroundColor: color,
				borderColor: 'transparent',
			},
		]
	};

	if (thin !== undefined) data.datasets.forEach(d => {
		d.barThickness = thin;
	});
	if (percentage !== undefined) data.datasets.forEach(d => {
		d.barPercentage = percentage;
	});
	if (categoryPercentage !== undefined) data.datasets.forEach(d => {
		d.categoryPercentage = categoryPercentage;
	});
	if (borderRadius !== undefined) data.datasets.forEach(d => {
		d.borderRadius = borderRadius;
	});
	if (borderWidth !== undefined) data.datasets.forEach(d => {
		d.borderWidth = borderWidth;
	});
	if (maxBarWidth !== undefined) data.datasets.forEach(d => {
		d.maxBarThickness = maxBarWidth;
	});

	options = merge({
		responsive: true,
		scales: {
			y: {
				beginAtZero: minY === undefined,
				max: maxY,
				min: minY ?? 0,
			},
			x: {
				beginAtZero: true,
				ticks: {
					autoSkip: false,
					maxRotation: 90,
				},
			},
		},
		datasetRef: datasetRef,
	}, options ?? {});

	const resize = () => {
		clearTimeout(timeOutKey);
		const t = setTimeout(() => {
			setChartKey(n => n + 1);
		}, 100);
		setTimeOutKey(t);
	};

	React.useEffect(() => {
		window.addEventListener('resize', resize);
		return () => {
			window.removeEventListener('resize', resize);
		};
	}, [timeOutKey]);

	return (
		<Bar
			className='dynamic_chart'
			data={data}
			options={options}
			plugins={plugins}
			height={height}
			key={chartKey}
		/>
	);
};