import { ApolloClientProvider } from '../ApolloClient/ApolloClientProvider';
import {
	IMutateValidateCmdbLineInput,
	IMutateValidateCmdbLineOutput
} from '../../domain/interfaces/IMutateValidateCmdbLine';
import { IAPIProps } from '../../domain/interfaces/IAPIProps';
import { ILaunchCompute, ILaunchComputeResponse } from '../../domain/interfaces/ILaunchCompute';
import { ResponseDataValidation } from '../../domain/data/ResponseDataValidation';
import { ResponseMutateLineValidationSave } from '../../domain/data/ResponseDatas';
import { DataMutate } from '../../domain/core/Entity';
import { Validation } from '../../domain/data/entries/Validation';

export class ApolloClientMutateValidateCmdbLine extends ApolloClientProvider<IMutateValidateCmdbLineOutput, IMutateValidateCmdbLineInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateValidateCmdbLineInput): Promise<IMutateValidateCmdbLineOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateValidatedLaunchCompute extends ApolloClientProvider<ILaunchComputeResponse, ILaunchCompute> {
	exec({ token, lang, adapter }: IAPIProps, data: ILaunchCompute): Promise<ILaunchComputeResponse | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateValidationLine extends ApolloClientProvider<ResponseMutateLineValidationSave, DataMutate<Validation>> {
	exec({ token, lang, adapter }: IAPIProps, data: DataMutate<Validation>): Promise<ResponseMutateLineValidationSave | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientQueryDataValidation extends ApolloClientProvider<ResponseDataValidation, { datasetId: string }> {}