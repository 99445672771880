import { gql } from '@apollo/client';

export const QUERY_RESILIO_DB_SUGGESTIONS = gql`
    query QueryResilioDbSuggestions (
		$searchValue: String!
		$type: String!
    ) {
    	resilioDbSuggestions (
			searchValue: $searchValue
			type: $type
    	)
    }
`;
