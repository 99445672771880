import { ResultCardError } from '../error/ResultCardError/ResultCardError';
import React, { useEffect, useState } from 'react';
import { useTransformedResultTableData } from '../../utils/useTransformedResultTableData';
import { IResultTableDataOutput } from '../../utils/getResultTableData';
import { resultValue } from './utils/resultValue';

export type ResultFootprintEquivalenceType = { schema: { fields: { indicator: string, equivalence: string, value: number }[], primaryKey: string[], pandas_version: string }, data: { indicator: string, equivalence: string, value: number, mean?: number, min?: number, max?: number, std?: number }[] }
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isResultFootprintEquivalence = (o: any): o is ResultFootprintEquivalenceType => {
	if (o && typeof o === 'object') return 'schema' in o && 'data' in o;
	return false;
};

export const ResultFootprintEquivalence = ({ data }: { data: string }): JSX.Element => {
	if (!isResultFootprintEquivalence(data)) return (<ResultCardError/>);

	// transform the data from id to name
	const transformedData = useTransformedResultTableData(data.data);
	const [equivalences, setEquivalences] = useState<IResultTableDataOutput[]>([]);

	useEffect(() => {
		if (!transformedData || transformedData instanceof Error ) return;
		setEquivalences(transformedData);
	}, [transformedData]);

	return (<div className={'equivalence_wrapper'}>
		{equivalences.length > 0 && equivalences.map((d, i) => {
			return (<div className={'equivalence'} key={i}>
				{d.icon && <div className={'icon_container'}>
					<div className={'icon'} dangerouslySetInnerHTML={{ __html: d.icon }}/>
					<p>{d.indicator}</p>
				</div>}
				<p className={'value'}>{resultValue(d.value)}</p>
				<p className={'name'}>{d.equivalence}</p>
				{d.equivalenceHypothesis && <p className={'unit'}>({d.equivalenceHypothesis})</p>}
			</div>);
		})}
	</div>);
};