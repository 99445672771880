import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from '../Modal';
import { Alert } from '../../Alerts';
import { translate } from '../../../infrastructure/translations/translate';
import { useUser } from '../../hooks/useUser';

export type ErrorModalPropsType = { errorText: string; alert: boolean | string; text: string; backHome: boolean; secondButton: () => void }
const ErrorModal = ({ errorText, alert, text, backHome, secondButton }: ErrorModalPropsType): JSX.Element  => {
	const { entity: userEntity } = useUser();
	const [show, setShow] = useState(true);
	const variant = typeof alert === 'string' ? alert : '';
	const navigate = useNavigate();
	const hideHandler = () => {
		if (secondButton) secondButton();
		setShow(false);
	};
	const logout = () => {
		userEntity?.queryUserLogout();
		navigate('/login');
	};
	return (
		<Modal style={{ zIndex: 3000, display: show ? 'inherit' : 'none' }} onClose={hideHandler}>
			<div className="modal_content">
				<div className={'modal_header'}>
					<div className={'modal_title'}>
						{errorText || translate('errorModal.title')}
					</div>
				</div>
				<div className={'modal_body'}>
					{alert ? <Alert variant={variant}>{text}</Alert> : <p>{text}</p>}
					<p>
						{translate('errorModal.refresh')}<br/>
						<strong>{translate('errorModal.contact')}</strong>
					</p>
				</div>
				<div className={'modal_footer'}>
					{backHome && <button className={'button_secondary'} onClick={logout}>{translate('errorModal.logout')}</button>}
					{secondButton && <button className={'button_warning'} onClick={secondButton}>{backHome ? translate('errorModal.backHome') : translate('errorModal.logout')}</button>}
				</div>
			</div>
		</Modal>
	);
};

export default ErrorModal;
