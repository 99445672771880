import { gql } from '@apollo/client';

export const QUERY_IS_QUALITY_EMPTY = gql`
    query QueryIsQualityEmpty (
    	$datasetIds: [String]!
    ){
    	isQualityEmpty(
    		datasetIds: $datasetIds
		)
	}
`;
