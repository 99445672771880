import React, { useId } from 'react';
import '../../DashboardCard.scss';
import 'chart.js/auto';
import { ChartProps } from 'react-chartjs-2';
import { StackedBarChart, StackedBarChartData } from '../../../../viewComponents/graphs/bars/StackedBarChart';
import {
	DashboardComparisonPluginGraphBarRefDiff,
	DashboardPluginGraphBarLegend
} from '../../DashboardPluginGraphBarLegend';
import { translate } from '../../../../infrastructure/translations/translate';
import { dashboardCompareToReference } from '../DashboardComparison';
import { DashboardComparisonData } from '../../../../domain/interfaces/DashboardComparisonData';
import {
	createDashboardComparisonBenchmarkModal,
	DashboardComparisonBenchmark
} from '../CreateDashboardComparisonBenchmarkModal';
import { resultValue } from '../../../../viewComponents/results/utils/resultValue';
import { valueFixedDigits } from '../../../../utils/numberToStringSeparator';
import { DashboardPinButton } from '../../DashboardPin';
import { parseByFootprintTypeDashboard } from '../ParseByFootprintTypeDashboard';
import { getImpactByLifeCycleStep } from '../../GetImpactByLifeCycleStep';
import { getImpactByIndicator } from '../../GetImpactByIndicator';
import { IBlock } from '../../../../domain/interfaces/IBlock';
import { useIndicators } from '../../../../viewComponents/hooks/useIndicators';
import { useLifeCycleSteps } from '../../../../viewComponents/hooks/useLifeCycleSteps';
import { useBlocks } from '../../../../viewComponents/hooks/useBlocks';
import { useModal } from '../../../../viewComponents/modal/useModal';
import { dashboardLabelName } from '../../DashboardLabelName';
import { TooltipItem } from 'chart.js/auto';
import { DashboardSelectReference } from '../../DashboardSelectReference';

const options: ChartProps<'bar'>['options'] = {
	layout: {
		padding: 10
	},
	maintainAspectRatio: false,
	aspectRatio: 3,
	plugins: {
		legend: {
			display: false
		},
		tooltip: {
			callbacks: {
				title: (items: TooltipItem<'bar'>[]) => {
					return items[0].label.split(',').join(' ');
				}
			}
		}
	},
	scales: {
		x: {
			grid: {
				display: false
			}
		}
	},
};

export interface DashboardDomainComparisonGraphData {
	graph: (StackedBarChartData | null)[];
	domains: string[];
	indicators: string[];
	lifeCycleSteps: string[];
	footprintType: number;
	indicator: string;
	lifeCycleStep: string;
	datasetRef: string | null;
}
export const DashboardDomainComparisonGraph = ({ showTable, data }: { showTable: boolean, data: DashboardDomainComparisonGraphData }) => {
	const { graph: graphData, indicator, datasetRef } = data;
	const { selectedIndicators: indicators } = useIndicators(data.indicators);

	const id = useId();
	const unit = indicators?.find(i => i.id === indicator)?.unit ?? translate('results.valuePBCI') as string;

	return (<div className="dashboard_graph">
		{!showTable && <>
			<div className={'dashboard_chart_container'}>
				<div className={'chart_indicator_unit'}>{unit}</div>
				<StackedBarChart
					data={graphData}
					datasetRef={datasetRef}
					options={options}
					thin={30}
					plugins={[DashboardPluginGraphBarLegend(id), DashboardComparisonPluginGraphBarRefDiff()]}
					percentage={0.5}
					borderRadius={3}
				/>
			</div>
			<div id={id} className="dashboard_graph_legend" />
		</>}
		{showTable && <table className="dashboard_table">
			<thead>
				<tr>
					<th>{translate('dataset')}</th>
					<th>{translate('unit')}</th>
					{graphData.filter((e) => e !== null)[0]?.values.map((e, i) => (
						<th key={i} className={'align-right'}>{e.scope}</th>
					))}
					<th className={'align-right'}>{translate('total')}</th>
				</tr>
			</thead>
			<tbody>
				{graphData.filter((e) => e !== null).map((e, i) => (
					<tr style={{ fontWeight: datasetRef === e?.label ? 'bold' : 'normal' }} key={i}>
						<td>{e?.label}</td>
						<td>{unit}</td>
						{e?.values.map((data, i) => (
							<td key={i} className={'value'}>
								<span className={'number value'}>
									{resultValue(data.value)}
									{datasetRef && datasetRef !== e?.label && <span className="number ref">
										{dashboardCompareToReference(graphData, datasetRef, data.value ?? -1)}
									</span>}
								</span>
							</td>
						))}
						<td className={'value'}>
							<span className={'number value'}>
								{resultValue(e?.values.reduce((acc, v) => acc + v.value, 0))}
							</span>
						</td>
					</tr>
				))}
			</tbody>
		</table>}
	</div>);
};

const DashboardDomainComparison = ({ data }: { data: DashboardComparisonData }): JSX.Element => {
	const { selectedIndicators: indicators } = useIndicators(data.indicators);
	const { lcs: lifeCycleSteps } = useLifeCycleSteps(data.lifeCycleSteps);
	const { blocks: domains } = useBlocks(data.domains);
	const [selectFootprintType, setSelectFootprintType] = React.useState<number>(0);
	const [selectIndicator, setSelectIndicator] = React.useState<string>('normalized');
	const [selectLifeCycleStep, setSelectLifeCycleStep] = React.useState<string>('all');
	const [benchmark, setBenchmark] = React.useState<DashboardComparisonBenchmark | undefined>();
	const [showTable, setShowTable] = React.useState<boolean>(false);
	const [openBenchmarkModal, setOpenBenchmarkModal] = React.useState<boolean>(false);
	const [datasetRef, setDatasetRef] = React.useState<string | null>(null);

	useModal(openBenchmarkModal, createDashboardComparisonBenchmarkModal(
		openBenchmarkModal,
		setOpenBenchmarkModal,
		setBenchmark
	));

	const defineBenchmark = () => {
		if (benchmark) {
			setBenchmark(undefined);
		} else {
			setOpenBenchmarkModal(true);
		}
	};

	const parseDataset = (dataset: DashboardComparisonData['datasets'][number]) => {
		if (!domains) return null;
		const values = domains.map((domain: IBlock) => {
			const value = dataset.equipments.reduce((acc, equipment) => {
				if (equipment.domain !== domain.id) return acc;
				return acc + equipment.impacts.reduce((acc, impact) => {
					return acc + getImpactByIndicator(getImpactByLifeCycleStep(impact, selectLifeCycleStep), selectIndicator, indicators).value;
				}, 0);
			}, 0);
			return {
				scope: domain.shortName,
				value: valueFixedDigits(parseByFootprintTypeDashboard(selectFootprintType, dataset, value))
			};
		});
		return {
			label: dashboardLabelName({ id: dataset.id, data }),
			values
		};
	};

	const graphData: (StackedBarChartData | null)[] = data.datasets.map(parseDataset);
	if (benchmark) {
		const b = benchmark.data.datasets
			.map(d => ({ ...d, referenceFlux: benchmark.data.datasets.reduce((acc, v) => acc + v.referenceFlux, 0) }))
			.map(parseDataset);
		graphData.push({
			label: benchmark.sampleName,
			values: domains.map((domain) => ({
				scope: domain.shortName,
				value: valueFixedDigits(b.reduce<
					StackedBarChartData['values'][number]['value']
				>((acc, v) => {
					return acc + (v?.values.find(v => v.scope === domain.shortName)?.value || 0);
				}, 0))
			}))
		});
	}
	if (graphData.length < 4) {
		graphData.unshift(null);
		graphData.push(null);
	}
	const saveData = {
		graph: graphData,
		domains: data.domains,
		indicators: data.indicators,
		lifeCycleSteps: data.lifeCycleSteps,
		footprintType: selectFootprintType,
		indicator: selectIndicator,
		lifeCycleStep: selectLifeCycleStep,
		datasetRef,
	};
	return <div className={'dashboard_card dashboard_card_color_blue'}>
		<h3>{translate('dashboard.title.domainsComparison')}</h3>
		<div className="dashboard_card_content">
			<select
				value={selectFootprintType}
				onChange={(e) => setSelectFootprintType(parseInt(e.target.value))}
			>
				<option value={0}>{translate('dashboard.select.globalFootprint')}</option>
				<option value={1}>{translate('dashboard.select.footprintPerUser')}</option>
			</select>
			<select
				value={selectIndicator}
				onChange={(e) => setSelectIndicator(e.target.value)}
			>
				<option value={'normalized'}>{translate('dashboard.select.indicator.normalized')} - {translate('results.valuePBCI')}</option>
				{indicators?.map((indicator) => (
					<option key={indicator.id} value={indicator.id}>{indicator.name} ({indicator.shortName}) - {indicator.unit}</option>
				))}
			</select>
			<select
				value={selectLifeCycleStep}
				onChange={(e) => setSelectLifeCycleStep(e.target.value)}
			>
				<option value={'all'}>{translate('dashboard.select.allLifeCycleSteps')}</option>
				{lifeCycleSteps?.map((lifeCycleStep) => (
					<option key={lifeCycleStep.id} value={lifeCycleStep.id}>{lifeCycleStep.name}</option>
				))}
			</select>
			<DashboardSelectReference data={data} reference={datasetRef} setReference={setDatasetRef} />
		</div>
		<div className="dashboard_card_content">
			<div className="dashboard_card_content_actions">
				<button type="button" className="button_blank dashboard_action" onClick={defineBenchmark}>
					<div className="dashboard_icon">
						<i className="fa-solid fa-scale-balanced" />
					</div>
					<div className="dashboard_text">
						{benchmark && translate('dashboard.action.benchmark.remove')}
						{!benchmark && translate('dashboard.action.benchmark.add')}
					</div>
				</button>
				<button
					type="button"
					className="button_blank dashboard_action"
					onClick={() => setShowTable(!showTable)}
				>
					<div className="dashboard_icon">
						{showTable && <i className="fa-solid fa-chart-column" />}
						{!showTable && <i className="fa-solid fa-table" />}
					</div>
					<div className="dashboard_text">
						{showTable && translate('dashboard.action.graph')}
						{!showTable && translate('dashboard.action.table')}
					</div>
				</button>
				<DashboardPinButton data={saveData} type={'DashboardDomainComparison'} />
			</div>
			<DashboardDomainComparisonGraph showTable={showTable} data={saveData} />
		</div>
	</div>;
};

export default DashboardDomainComparison;