import React, { useEffect, useState } from 'react';
import { translate } from '../../infrastructure/translations/translate';
import { ResultArrowComparison, ResultComparison } from './ResultArrowComparison';
import { resultValue } from './utils/resultValue';
import { ResultCardError } from '../error/ResultCardError/ResultCardError';
import { IResultTableProps, ResultTable } from './ResultTable';
import { useTransformedResultTableData } from '../../utils/useTransformedResultTableData';
import Spinner from '../utils/Spinner/Spinner';
import { IndicatorTable } from './utils/IndicatorTable/IndicatorTable';
import { RelativeDoughnutChart } from '../graphs/doughnuts/RelativeDoughnutChart';
import { groupSmallValues } from '../../utils/GraphsUtils';
import { DoughnutPluginWritePercentage } from '../graphs/plugins/DoughnutPluginWritePercentage';

export type ResultNormalizedFootprintPerIndicatorType = { schema: { fields: { name: string, type: string }[], primaryKey: string[], pandas_version: string }, data: { indicator: string, value: number, mean?: number, min?: number, max?: number, std?: number }[] }
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isResultNormalizedFootprintPerIndicator = (o: any): o is ResultNormalizedFootprintPerIndicatorType => {
	if (typeof o === 'object') return 'schema' in o && 'data' in o;
	return false;
};
export const ResultNormalizedFootprintPerIndicator = ({ data, isRelative }: { data: string, isRelative: boolean }): JSX.Element => {
	if (!isResultNormalizedFootprintPerIndicator(data)) return (<ResultCardError/>);

	// transform data from id to name
	const transformedData = useTransformedResultTableData(data.data, true);
	const [tableData, setTableData] = useState<IResultTableProps | null>(null);

	useEffect(() => {
		if (!transformedData || transformedData instanceof Error) return;
		// set the table for the ResultTable component
		setTableData({
			columns: [
				{ name: translate('results.indicator') as string },
				{ name: translate('results.unit') as string, style: { textAlign: 'left' } },
				{ name: translate('results.value') as string },
			],
			values: transformedData.map(d => [
				{ value: <IndicatorTable indicator={d.indicator ?? ''} icon={d.icon}/>, valueType: 'left' },
				{ value: isRelative ? '%' : translate('results.valuePBCI') as string, valueType: 'left',style: { textAlign: 'left' } },
				{ value: <>
					{resultValue(d.value as number)}
					<ResultArrowComparison arrow={ResultComparison({ value: d.value as number, mean: d.mean,min: d.min,max: d.max })}/>
				</>, valueType: 'right' },
			]),
		});

	}, [transformedData]);

	if (transformedData instanceof Error) return <ResultCardError/>;
	if (!tableData) return <Spinner/>;
	return <ResultTable dataTable={tableData} />;
};

export const NormalizedFootprintPerIndicators = ({ data }: { data: string }): JSX.Element => {
	if (!isResultNormalizedFootprintPerIndicator(data)) return (<ResultCardError/>);
	const transformedData = useTransformedResultTableData(data.data, true);
	if (transformedData instanceof Error || !transformedData) return <ResultCardError/>;

	const filteredValues = groupSmallValues(transformedData, 'indicator', 1);
	const dataset = filteredValues.map(d => ({ labels: String(d.indicator), values: Number(d.value), color: d.indicatorColor }));

	return <RelativeDoughnutChart
		datasets={dataset}
		plugins={[DoughnutPluginWritePercentage()]}
	/>;
};