import React, { useRef, useEffect } from 'react';
import Modal from '../../../viewComponents/modal/Modal';
import completeCgu from '../../../media/cgu/completeCgu.pdf';
import './CguModal.scss';

export const CguModal = ({ setOpenModal }: { setOpenModal: (value: boolean) => void }): JSX.Element => {
	const modalRef = useRef<HTMLDivElement | null>(null);

	// Close modal if click happens outside of modal content
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setOpenModal(false);
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [setOpenModal]);

	return (
		<Modal onClose={() => setOpenModal(false)}>
			<div ref={modalRef}>
				<iframe src={completeCgu} title="cgu-document" aria-label="cgu-document" />
			</div>
		</Modal>
	);
};
