import React from 'react';
import { translate } from '../../infrastructure/translations/translate';
import Template from '../../viewComponents/template/template/template';

/**
 * Forbidden view
 * @constructor
 * @return JSX.Element
 *
 * @author
 */
const ForbiddenView = (): JSX.Element => {
	const core = (
		<div>
			<h1>{translate('forbidden.title')}</h1>
			<p>{translate('forbidden.description')}</p>
		</div>
	);
	return (<Template core={core}/>);
};

export default ForbiddenView;