import React, { useState } from 'react';
import { translate } from '../../../../infrastructure/translations/translate';
import { DashboardComparisonData } from '../../../../domain/interfaces/DashboardComparisonData';
import { resultValueMoreDecimal } from '../../../../viewComponents/results/utils/resultValue';
import { Alert } from '../../../../viewComponents/Alerts';
import { DashboardPinButton } from '../../DashboardPin';
import { useBlocks } from '../../../../viewComponents/hooks/useBlocks';
import { unique } from '../../../../viewComponents/results/utils/Unique';
import { useTooltip } from '../../../../viewComponents/tip/useTooltip';
import { reduceEquipments } from '../../ReduceEquipments';

interface DashboardEquipmentEvolutionData {
	equipment: string;
	shortName: string[];
	specification: string;
	category: string;
	deprecated: boolean;
	unit: string;
	data: { year: string; value: number[], totalQuantity: number }[];
}

interface DashboardEquipmentEvolutionGraphProps {
	data: {
		dataset: string;
		yearRef: string;
		selectType: string;
		selectTypeData: string;
		selectRelativeToUser: boolean;
		graph: DashboardEquipmentEvolutionData[]
	};
}

export const DashboardEquipmentEvolutionGraph = ({ data }: DashboardEquipmentEvolutionGraphProps): JSX.Element => {
	const [toolTip, setToolTip] = useState<string | React.ReactElement>();

	useTooltip(toolTip !== undefined, toolTip ?? '');
	const { graph: graphData, selectType, selectTypeData, yearRef } = data;
	const years = data.graph.map((d) => d.data.map((d) => d.year)).flat().filter(unique)
		.sort((a, b) => parseInt(a) - parseInt(b));

	//TODO : add this function as a single function in a utils file and test it
	//TODO: it can be used for comparison, evolution and simulation
	//TODO: there is an issue for that: #3254
	const comparison = (e: number, i: number): React.ReactElement => {
		const index = graphData[e].data.findIndex((d) => d.year === yearRef);
		if (index == undefined || index < 0) return <></>;
		if (graphData[e].data.at(index) === undefined) return <></>;
		if (index === i) return <></>;
		const a = graphData[e].data[i].value.reduce((acc, d) => acc + d, 0) / (
			selectTypeData === 'lifetime' ? graphData[e].data[i].totalQuantity : 1
		);
		const b = graphData[e].data[index].value.reduce((acc, d) => acc + d, 0) / (
			selectTypeData === 'lifetime' ? graphData[e].data[index].totalQuantity : 1
		);
		if (a === 0 || b === 0) return <></>;
		const diff = a - b;
		const percentage = diff / b * 100;
		const text = `${diff < 0 ? '' : '+'}${percentage.toFixed(0)}%`;
		const color = Math.round(percentage) === 0 ? '#808080' :
			(selectTypeData === 'lifetime' && diff < 0) || (selectTypeData !== 'lifetime' && diff > 0) ?
				'#f00' : '#00ad00';
		return <span style={{ color: color }}>{text}</span>;
	};

	return (<div className="dashboard_graph">
		<table className="dashboard_graph_list" cellSpacing={0}>
			<thead>
				<tr>
					<th>{translate('results.equipment')}</th>
					{years.map((year) => {
						return (<th key={year}>
							<div style={{ fontWeight: yearRef === year ? 'bold' : 'normal' }}>
								<div>{year}</div>
							</div>
							{yearRef === year && <><br/><span
								className="ref">{translate('dashboard.select.for.ref')}</span></>}
						</th>);
					})}
				</tr>
			</thead>
			<tbody>
				{graphData.map((d, e) => <tr key={`equipment_${e}`}>
					<td>
						{selectType === 'category' && <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
							<h3>{d.category}</h3>
						</div>}
						{selectType === 'equipmentType' && <>
							<h3>{d.equipment}&nbsp;
								{d.unit.length > 0 && <span style={{ fontSize: '12px' }}>({d.unit})</span>}&nbsp;
								{d.deprecated && <i className="fa-regular fa-info-circle"
									onMouseEnter={() => setToolTip(translate('equipment.deprecated.tooltip') as string)}
									onMouseLeave={() => setToolTip(undefined)}
								/>}
							</h3>
							{d.specification.length > 0 && <span style={{ color: 'black', fontSize: '13px' }}>
								{d.specification}
							</span>}
							{d.shortName.length > 0 && d.shortName.filter(name => name.length > 0).map((name, i) => <h4 key={`shortName_${i}`}>- {name}</h4>)}
						</>}
					</td>
					{d.data.map((d, i) => <>
						<td key={`value_${e}_${i}`}>
							<span className="number value" style={{ fontWeight: yearRef === d.year ? 'bold' : 'normal' }}>
								{d.value.length <= 0 ? '-' : resultValueMoreDecimal(
									d.value.reduce((acc, d) => acc + d, 0) / (
										selectTypeData === 'lifetime' ? d.totalQuantity : 1
									)
								)}
								{yearRef && yearRef !== d.year && <span className="number ref">{comparison(e, i)}</span>}
							</span>
						</td>
					</>)}
					<td>
						<span></span>
					</td>
				</tr>)}
			</tbody>
		</table>
	</div>);
};

const DashboardEquipmentEvolution = ({
	data
}: {
	data: DashboardComparisonData;
}): JSX.Element => {
	const { blocks: domains } = useBlocks(data.domains);
	const [selectTypeData, setSelectTypeData] = React.useState<string>('quantity');
	const [selectRelativeToUser, setSelectRelativeToUser] = React.useState<boolean>(false);
	const [selectType, setSelectType] = React.useState<string>('category');
	const [selectDomain, setSelectDomain] = React.useState<string>('all');

	const DifferentStudyNames = data.datasets.map(d => d.study).filter(unique).length > 1;
	const DifferentCompanyNames = data.datasets.map(d => d.company).filter(unique).length > 1;
	const getName = (d: {
		name?: string,
		study?: string,
		company?: string
	}): string => {
		let name = '';
		if (DifferentCompanyNames) name = ` - ${d.company}`;
		if (DifferentStudyNames) name = ` - ${d.study}` + name;
		return d.name + name;
	};
	const datasetNames = data.datasets.map(d => getName(d)).filter(unique);
	const [selectDataset, setSelectDataset] = React.useState<string>(datasetNames[0]);
	const datasets = data.datasets.filter(d => getName(d) === selectDataset)
		.sort((a, b) => parseInt(a.year) - parseInt(b.year));
	const years = datasets.map(d => d.year.toString()).filter(unique)
		.sort((a, b) => parseInt(a) - parseInt(b));
	const [yearRef, setYearRef] = React.useState<string>(years[0]);

	// Sorting equipments by category and type : if the category is the same, sort by type
	const listEquipments = datasets.map(d => d.equipments.map(e => ({
		name: e.name,
		shortName: e.shortName,
		specification: e.specification,
		category: e.category,
		domain: e.domain,
		categoryOrder: e.categoryOrder,
		typeOrder: e.typeOrder,
		deprecated: e.deprecated,
		unit: e.unit
	}))).flat().filter((e, i, a) =>
		a.findIndex((eq) => eq.name === e.name && eq.specification === e.specification && eq.domain === e.domain && eq.shortName === e.shortName) === i
	).sort((a, b) => a.categoryOrder - b.categoryOrder || a.typeOrder - b.typeOrder) ?? [];

	/**
	 * Return the value of the equipment depending on the type of data
	 * If the data is -1, return -1 because it's considered as unknown -> equipment not found in the dataset
	 *
	 * @param d
	 * @param quantity
	 * @param referenceFlux
	 */
	const valueMean = (d: number, quantity: number, referenceFlux: number) => {
		if (d === -1) return -1;

		// If lifetime, we want lifetime * quantity
		if (selectTypeData === 'lifetime') {
			const lifetime = d * quantity;
			if (isNaN(lifetime)) return -1;
			return lifetime;
		}

		// If quantity and relative to one single user, we want quantity / referenceFlux
		if (selectRelativeToUser) return d / referenceFlux;

		return d;
	};

	const reduceDataset = (datasets: DashboardComparisonData['datasets']) => {
		return datasets.reduce<DashboardEquipmentEvolutionData[]>((acc, dataset) => {
			const year = dataset.year.toString();

			listEquipments.forEach(e => {
				const equipment = reduceEquipments(dataset.equipments, e);
				const key = selectTypeData as keyof typeof equipment;
				if (selectDomain !== 'all' && e.domain !== selectDomain) return;

				// Equipment not found in the list, we send -1 to the valueMean function, it will return -1 and we will display '-' in the table because p will be empty
				const value = valueMean(equipment?.[key] ?? -1, equipment?.quantity ?? 1, dataset.referenceFlux);
				const p = value !== -1 ? [value] : [];

				let index = -1;
				if (selectType === 'equipmentType') index = acc.findIndex((d) => d.equipment === e.name && d.specification === e.specification);
				if (selectType === 'category') index = acc.findIndex((d) => d.category === e.category);

				if (index === -1) {
					acc.push({
						equipment: e.name,
						shortName: selectType === 'equipmentType' ? [equipment?.shortName ?? ''] : [],
						specification: e.specification,
						category: e.category,
						deprecated: e.deprecated,
						unit: e.unit,
						data: [{ year, value: p, totalQuantity: equipment?.quantity ?? 0 }]
					});
				} else {
					// If the equipment is already in the list, we want to add the shortName to the list of shortName if the current dataset is the reference dataset
					if (selectType === 'equipmentType') acc[index].shortName.push(equipment?.shortName ?? '');

					const da = acc[index].data.find((d) => d.year === year);
					if (!da) {
						acc[index].data.push({ year, value: p, totalQuantity: equipment?.quantity ?? 0 });
					} else {
						da.value.push(...p);
						da.totalQuantity += equipment?.quantity ?? 0;
					}
				}
			});
			return acc;
		}, []);
	};

	const graphData: DashboardEquipmentEvolutionData[] = reduceDataset(datasets);

	const saveData = {
		graph: graphData,
		domain: selectDomain,
		dataset: selectDataset,
		yearRef,
		selectType,
		selectTypeData,
		selectRelativeToUser
	};

	return <div className={'dashboard_card dashboard_card_color_yellow'}>
		<h3>{translate('dashboard.title.equipmentsEvolution')}</h3>
		<div className="dashboard_card_content">
			<select
				value={selectDataset}
				onChange={(e) => setSelectDataset(e.target.value)}
			>
				{datasetNames.map((dataset) => <option key={dataset} value={dataset}>
					{dataset}
				</option>)}
			</select>
			<select
				value={selectTypeData}
				onChange={(e) => setSelectTypeData(e.target.value)}
			>
				<option value={'quantity'}>{translate('dashboard.select.quantity')}</option>
				<option value={'lifetime'}>{translate('dashboard.select.lifetime')}</option>
			</select>
			{selectTypeData === 'quantity' && <select
				value={selectRelativeToUser ? 'true' : 'false'}
				onChange={(e) => setSelectRelativeToUser(e.target.value === 'true')}
			>
				<option value={'false'}>{translate('dashboard.select.noRelative')}</option>
				<option value={'true'}>{translate('dashboard.select.userRelative')}</option>
			</select>}
			<select
				value={selectType}
				onChange={(e) => setSelectType(e.target.value)}
			>
				<option value={'category'}>{translate('dashboard.select.category')}</option>
				<option value={'equipmentType'}>{translate('dashboard.select.equipmentType')}</option>
			</select>
			<select
				value={selectDomain}
				onChange={(e) => setSelectDomain(e.target.value)}
			>
				<option value={'all'}>{translate('dashboard.select.allDomains')}</option>
				{domains?.map((domain) => (
					<option key={domain.id} value={domain.id}>{domain.name}</option>
				))}
			</select>
			<div className="dashboard_card_content_select_box">
				<label htmlFor="selectAReference">{translate('dashboard.equipment.select.a.reference')}</label>
				<select
					id="selectAReference"
					value={yearRef ?? ''}
					onChange={(e) => setYearRef(e.target.value)}
				>
					{years.map((year) => <option key={year} value={year}>
						{year}
					</option>)}
				</select>
			</div>
		</div>
		<div className="dashboard_card_content">
			<div className="dashboard_card_content_actions">
				<DashboardPinButton data={saveData} type={'DashboardEquipmentEvolution'}/>
			</div>
			<Alert variant={'info'}>
				{translate('dashboard.equipments.alert')}
				{selectType === 'category' && <>
					<br/><br/>
					{translate('dashboard.equipments.alert.category')}
				</>}
			</Alert>
			<DashboardEquipmentEvolutionGraph data={saveData}/>
		</div>
	</div>;
};

export default DashboardEquipmentEvolution;