import { IUserDashboardCard } from '../../../domain/interfaces/IUserDashboard';
import { IModal } from '../../../domain/interfaces/IModal';
import { translate } from '../../../infrastructure/translations/translate';
import React from 'react';
import Spinner from '../../../viewComponents/utils/Spinner/Spinner';
import { useUsersDashboard } from '../../../viewComponents/hooks/useUsersDashboard';
import { useModal } from '../../../viewComponents/modal/useModal';

export const createCardModalDelete = (open: boolean, setOpen: (open: boolean) => void, card: IUserDashboardCard): IModal | undefined => {
	const { entity: userDashboardEntity } = useUsersDashboard();
	const [step, setStep] = React.useState<number>(0);
	if (!open) return;

	const clear = () => {
		useModal(false, { body: undefined, header: undefined, visible: false });
		setStep(0);
		setOpen(false);
	};

	const sendCard = () => {
		setStep(1);
		userDashboardEntity?.mutateEditUserDashboardCard({
			id: card.id,
			name: card.name,
			type: card.type,
			dashboardId: card.dashboard.id,
			data: card.data,
			delete: true,
		}).then(() => {
			clear();
		});
	};

	const body = (<div className={'modal_edit_dashboard'}>
		{step == 1 && <div className="dashboard_pin_modal_success">
			<Spinner />
		</div>}
	</div>);

	return {
		body,
		header: translate('dashboard.overview.table.card.deleteModal.header'),
		visible: true,
		footer: step == 0 ? (<div className={'modal_edit_dashboard_footer'}>
			<button
				type={'button'}
				className={'button_danger'}
				onClick={sendCard}
			>
				{translate('delete')}
			</button>
		</div>) : <></>,
		onClose: () => clear(),
	};
};