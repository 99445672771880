import { useSelector } from 'react-redux';
import { State } from '../../store';
import { useUser } from '../../viewComponents/hooks/useUser';

export const useUserDashboardOwning = (id: string): boolean => {
	const { data: user } = useUser();
	const loggedId = user?.id;
	const dashboards = useSelector((state: State) => state.dataUserDashboards.data?.data);
	const dashboard = dashboards?.find(dashboard => dashboard.id === id);
	if (!dashboard) return false;
	return dashboard.userId === loggedId;
};