import { gql } from '@apollo/client';

export const QUERY_DATA_STUDIES = gql`
    query {
        myStudies {
            companyFk {
                id
                name
                emailDomains
            },
            year
            name
            id
            datasetsId
            checklist
            functionalUnit {
            	id
            	name
            	private
            	checklist
            }
        }
    }
`;
