import { IUserDashboardCard } from '../../../../../domain/interfaces/IUserDashboard';
import React from 'react';
import { translate } from '../../../../../infrastructure/translations/translate';
import { DashboardCardModifyButton } from '../../DashboardCardModifyButton';
import { DashboardCardMoveButton } from '../../DashboardCardMoveButton';
import { DashboardCardDeleteButton } from '../../DashboardCardDeleteButton';
import { getResultComponent, ResultHasGraphs } from '../../../../ResultsView/ResultsView';
import { useIndicators } from '../../../../../viewComponents/hooks/useIndicators';

export const DashboardCardACVResult = ({ card }: { card: IUserDashboardCard }): JSX.Element => {
	const [showTable, setShowTable] = React.useState<boolean>(false);
	const { selectedIndicators } = useIndicators();

	const block = JSON.parse(card.data);
	const hasGraph = ResultHasGraphs.includes(block.type);
	const currentElement = !hasGraph ? 'children' : showTable ? 'children' : 'graph';
	return (<div className={'dashboard_card dashboard_card_color_green'}>
		<div className={'buttons'}>
			<button
				type={'button'}
				className={'button_blank'}
				onClick={() => setShowTable(!showTable)}
			>
				<div className="dashboard_icon"><i className="fa-solid fa-chart-column"/></div>
				<div className="text">
					{showTable && translate('dashboard.action.graph')}
					{!showTable && translate('dashboard.action.table')}
				</div>
			</button>
			<DashboardCardModifyButton card={card} />
			<DashboardCardMoveButton card={card} />
			<DashboardCardDeleteButton card={card} />
		</div>
		<h3>{card.name}</h3>
		<div className="dashboard_card_content">
			<div className="dashboard_card_acv_result result_card_content">
				{getResultComponent(block, currentElement, selectedIndicators)}
			</div>
		</div>
	</div>);
};