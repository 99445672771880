import { useSelector } from 'react-redux';
import { State } from '../../store';
import { IDataDTO } from '../../domain/interfaces/DTO/IDataDTO';
import { Result } from '../../domain/data/entries/Result';
import { ResultsEntity } from '../../domain/entities/ResultsEntity';

type ResultHookType = IDataDTO & { entity: ResultsEntity | undefined, results: Result[] };

export const useResults = (rl_id?: string, resultsIds?: string[]): ResultHookType => {
	const { loading, error, data: entity } = useSelector((state: State) => state.dataResultsStatus);
	const results = entity?.get(rl_id, resultsIds) ?? [];
	return { loading, error, entity: entity, results };
};