import React from 'react';
import ImageScoreA from './ratingScoreSVG/Rating_A.svg';
import ImageScoreB from './ratingScoreSVG/Rating_B.svg';
import ImageScoreC from './ratingScoreSVG/Rating_C.svg';
import ImageScoreD from './ratingScoreSVG/Rating_D.svg';
import ImageScoreE from './ratingScoreSVG/Rating_E.svg';

export const ResultLetterScore = ({ value }: { value: string }): JSX.Element => {
	let letter: JSX.Element = (<img src={ImageScoreE} alt={'Score E'}/>);
	switch (value) {
		case 'A':
			letter = (<img src={ImageScoreA} alt={'Score A'}/>);
			break;
		case 'B': 
			letter = (<img src={ImageScoreB} alt={'Score B'}/>);
			break;
		case 'C': 
			letter = (<img src={ImageScoreC} alt={'Score C'}/>);
			break;
		case 'D': 
			letter = (<img src={ImageScoreD} alt={'Score D'}/>);
			break;
		case 'E': 
			letter = (<img src={ImageScoreE} alt={'Score E'}/>);
			break;
	}
	return letter;
};

 