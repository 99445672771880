import { IResultTableDataOutput } from './getResultTableData';
import { getColors } from '../config';
import { translate } from '../infrastructure/translations/translate';


/**
 * @description Group all values that are bellow 1% of the total to "other"
 * @param data - An array of IResultTableData objects.
 * @param key - A string that represents the key (e.g., life_cycle_step, block) from the IResultTableData object.
 * @returns An array of IResultTableData objects.
 */
export const groupSmallValues = (data: IResultTableDataOutput[], key: 'life_cycle_step' | 'block' | 'indicator', threshold: number): IResultTableDataOutput[] => {
	// group all values that are bellow 1% of the total to "other"
	const total = data.reduce((a, b) => a + Number(b.value), 0);
	const smallValues = data.filter(v => Number(v.value) < (threshold * 0.01) * total);
	const otherValues = smallValues.reduce((a, b) => a + Number(b.value), 0);
	const min = total * (threshold * 0.01);
	const filteredValues = data.filter(v => Number(v.value) >= min);
	if (smallValues.length > 1) {
		filteredValues.push({ [key]: translate('other') as string, value: otherValues });
	}
	return filteredValues;
};

/**
 * @description Get the next available color from the default colors array
 * @param colors
 * @param defaultColors
 */
export const getNextAvailableColor = (colors: string[], defaultColors: string[]) => {
	let nextIndex = 0;
	while (colors.includes(defaultColors[nextIndex])) {
		nextIndex = (nextIndex + 1) % defaultColors.length;
	}
	return defaultColors[nextIndex];
};

/**
 * @description Prepare the data to be "stacked" compatible, it returns the list of indicators
 * and lists of either life_cycle_step or block
 */
export const prepareStackedData = (
	data: IResultTableDataOutput[],
	key: 'block' | 'life_cycle_step',
	maxBarWidth?: number
) => {
	const indicators = [...new Set(data.map((item) => item.indicator))];
	const fields = [...new Set(data.map((item) => item[key]))];
	const indicatorShortNames = [...new Set(data.map((item) => item.indicatorShort ?? item.indicator ?? ''))];
	const defaultColors = getColors(fields.length);

	const colors: string[] = [];
	fields.forEach((field) => {
		const item = data.find((item) => item[key] === field);
		let color = key === 'block' ? item?.blockColor : item?.life_cycle_stepColor;
		if (color === undefined) color = getNextAvailableColor(colors, defaultColors);
		colors.push(color);
	});

	const datasets = fields.map((field, index) => ({
		label: field,
		data: indicators.map((indicator) => {
			const items = data.filter((item) => item.indicator === indicator && item[key] === field);
			const total = items.reduce((sum, item) => sum + Number(item.value), 0);
			const indicatorTotal = data.filter((item) => item.indicator === indicator).reduce((sum, item) => sum + Number(item.value), 0);
			return (total / indicatorTotal) * 100;  // calculate percentage
		}),
		backgroundColor: colors[index],
		maxBarThickness: maxBarWidth,
	}));
	return { labels: indicatorShortNames, datasets };
};