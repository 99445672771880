import { Plugin } from 'chart.js';
/**
 *
 * @param backgroundColor string
 * @description Returns the color of the text that should be displayed on the given background color
 * if the background color is dark, the text color will be white, otherwise it will be black
 */
function getTextColor(backgroundColor: string): string {
	const color = parseInt(backgroundColor.replace('#', ''), 16);   // convert rrggbb to decimal
	const r = (color >> 16) & 255;  // extract red
	const g = (color >> 8) & 255;   // extract green
	const b = color & 255;          // extract blue
	const brightness = (r * 299 + g * 587 + b * 114) / 1000;
	return brightness > 128 ? '#000000' : '#ffffff';
}

/**
 * Plugin to display the percentage of each doughnut slice on the chart, the percentage will be drawn on the ArcElement
 */
export const DoughnutPluginWritePercentage = (): Plugin<'doughnut'> => ({
	id: 'writePercentage',
	afterDatasetDraw: function(chart) {
		const ctx = chart.ctx;
		if (!chart.legend) return;

		// get the legends of the chart, it might be ordered differently than the data
		const legends = chart.legend.legendItems;
		// get the legends ordered by value
		const legendsOrderedByValue = chart.data.labels;

		if (!legends || !legendsOrderedByValue) return;

		ctx.font = 'bold 18px Roboto, sans-serif';
		ctx.textAlign = 'center';
		ctx.textBaseline = 'bottom';
		const datasets = chart.data.datasets;
		datasets.forEach((dataset, i) => {
			const meta = chart.getDatasetMeta(i);
			if (!meta.hidden) {
				// Calculate new total by removing indexes with hidden legends - allowing later to calculate the percentage
				const newTotal = dataset.data.reduce((a, b, index) => {
					if (typeof a !== 'number' || typeof b !== 'number') return a;
					if (legends[index].hidden) return a;
					return a + b;
				}, 0);
				if (!newTotal || typeof newTotal !== 'number') return;
				meta.data.forEach((element, index) => {
					// Get the legend text of current element (based on datasets order)
					const legend = legendsOrderedByValue[index];
					// Get the index in the ordered legends
					const legendIndex = legends.findIndex((l: { text: string; }) => l.text === legend);
					// if the legend is hidden (collapsed), don't display the percentage
					if (legends[legendIndex].hidden) return;

					const data = dataset.data[index];
					if (!data) return;
					// Ignore values less than 3%
					if (data < 3) return;
					// Calculate the percentage of the current value based on new total
					const percentage = (data as number) / newTotal * 100;
					// round the value to 0 decimal
					const dataString = percentage.toFixed(0) + '%';
					const position = element.tooltipPosition(false);
					const bgColor = element.options.backgroundColor;
					// set the color of the text, white if the background is dark, black otherwise
					ctx.fillStyle = getTextColor(bgColor);
					ctx.fillText(dataString, position.x, position.y + 10);
				});
			}
		});
	}
});