/**
 * Converts a string to a number, or returns 0 if the string is not a number
 * @param {string} str - the string to convert
 * @returns {number} - the converted number
 *
 * @author Maximilien Valenzano
 */
export const stringToNumOrZero = (str: string): number => {
	if (str.length <= 0) return 0;
	const num = Number(str);
	return isNaN(num) ? 0 : num;
};