import React from 'react';
import { Bar } from 'react-chartjs-2';
import { IResultTableDataOutput } from '../../../utils/getResultTableData';
import { prepareStackedData } from '../../../utils/GraphsUtils';

/**
 * @param field 'block' | 'life_cycle_step',
 * @param data IResultTableDataOutput[]
 * @param maxBarWidth number | undefined
 * @returns JSX.Element
 * @description A stacked bar chart componant that displays relatives data of a list of values
 * @author Yacine Bentayeb
 */
export const FootprintStackedBarChart = ({ data, field, maxBarWidth }:{
	data: IResultTableDataOutput[],
	field: 'block' | 'life_cycle_step',
	maxBarWidth?: number;
}): JSX.Element => {
	const stackedData = prepareStackedData(data, field, maxBarWidth ?? 60);
	const labels = stackedData.datasets.map(d => d.label);
	const options = {
		maintainAspectRatio: false,
		plugins: {
			legend: {
				labels: {
					boxWidth: 40 / Math.ceil(labels.length / 5),
				},
			},
		},
		scales: {
			y: {
				beginAtZero: true,
				stacked: true,
				max: 100,
				grid: {
					drawOnChartArea: false
				}
			},
			x: {
				stacked: true,
				grid: {
					drawOnChartArea: false
				},
				ticks: {
					font: {
						size: 14,
						weight: 'bold'
					},
				},
			}
		}
	};
	return (
		<Bar
			className='dynamic_chart'
			data={stackedData}
			options={options}
		/>
	);
};