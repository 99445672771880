import { gql } from '@apollo/client';

export const QUERY_SETTING_TYPES = gql`
    query QuerySettingTypes{
        settingTypes {
            id
            name
            desc
            source
        }
    }
`;
