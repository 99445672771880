import {
	LambdaVerifyLinkLineAdds,
	ResponseLambdaMutateLine,
	ResponseLambdaVerifyLink
} from '../data/ResponseLambdaSuccess';
import { RelatedDatasetEntity, Entity, injectInputSetter, InputProperty, ValueEntity, ObjectKey } from '../core/Entity';
import { Core } from '../core/Core';
import { Equipment } from '../data/entries/Equipment';
import { numNullToString } from '../../utils/numNullToString';
import {
	ApolloClientMutateLambdaEquipmentLine,
	ApolloClientMutateLambdaUserCreate,
	ApolloClientMutateNotifyAssignees,
	ApolloClientQueryLambdaRequestCode,
	ApolloClientQueryLambdaVerifyCode,
	ApolloClientQueryLambdaVerifyLink, ApolloClientQueryLambdaVerifyToken
} from '../../infrastructure/ApolloClass/LambdaInventoryClass';
import { MUTATE_LAMBDA_USER_CREATION } from '../../infrastructure/ApolloClient/requests/MUTATE_LAMBDA_USER_CREATION';
import { MUTATE_NOTIFY_ASSIGNEES } from '../../infrastructure/ApolloClient/requests/MUTATE_NOTIFY_ASSIGNEES';
import { MUTATE_LAMBDA_EQUIPMENT_LINE } from '../../infrastructure/ApolloClient/requests/MUTATE_LAMBDA_EQUIPMENT_LINE';
import { QUERY_LAMBDA_REQUEST_CODE } from '../../infrastructure/ApolloClient/requests/QUERY_LAMBDA_REQUEST_CODE';
import { QUERY_LAMBDA_VERIFY_CODE } from '../../infrastructure/ApolloClient/requests/QUERY_LAMBDA_VERIFY_CODE';
import { QUERY_LAMBDA_VERIFY_LINK } from '../../infrastructure/ApolloClient/requests/QUERY_LAMBDA_VERIFY_LINK';
import { QUERY_LAMBDA_VERIFY_TOKEN } from '../../infrastructure/ApolloClient/requests/QUERY_LAMBDA_VERIFY_TOKEN';
import { ILambdaUserCreation } from '../interfaces/ILambdaUserCreation';
import { IMutateNotifyAssigneesInput } from '../interfaces/IMutateNotifyAssignees';
import { ILambdaMutateLine } from '../interfaces/ILambdaMutateLine';
import { stringToNumOrZero } from '../../utils/StringToNumOrZero';

type BlockList = { id: string, name: string, lines: ResponseLambdaVerifyLink['lambdaVerifyLink']['lines'][number][] }[]
type DatasetList<T> = { id: string, name: string, blocks: T }[];
export type StudyList<T = BlockList> = { id: string, name: string, datasets: DatasetList<T> }[]
type LambdaEquipment = Omit<Equipment, 'flag'>

export class LambdaInventoryEntity extends Entity {
	public data: undefined | StudyList<LambdaInventoryBlocksEntity[]>;
	public lambdaToken = '';
	private _isQuerying = false;

	initialization() {
		this.app.adapter.mutateLambdaUserCreation ??= this.app.installer(ApolloClientMutateLambdaUserCreate, MUTATE_LAMBDA_USER_CREATION);
		this.app.adapter.mutateNotifyAssignees ??= this.app.installer(ApolloClientMutateNotifyAssignees, MUTATE_NOTIFY_ASSIGNEES);
		this.app.adapter.mutateLambdaEquipmentLine ??= this.app.installer(ApolloClientMutateLambdaEquipmentLine, MUTATE_LAMBDA_EQUIPMENT_LINE);
		this.app.adapter.queryLambdaRequestCode ??= this.app.installer(ApolloClientQueryLambdaRequestCode, QUERY_LAMBDA_REQUEST_CODE);
		this.app.adapter.queryLambdaVerifyCode ??= this.app.installer(ApolloClientQueryLambdaVerifyCode, QUERY_LAMBDA_VERIFY_CODE);
		this.app.adapter.queryLambdaVerifyLink ??= this.app.installer(ApolloClientQueryLambdaVerifyLink, QUERY_LAMBDA_VERIFY_LINK);
		this.app.adapter.queryLambdaVerifyToken ??= this.app.installer(ApolloClientQueryLambdaVerifyToken, QUERY_LAMBDA_VERIFY_TOKEN);
		this.app.adapter.storeLambdaInventory?.({ loading: false, error: null, data: this });
	}

	get(token: string): StudyList<LambdaInventoryBlocksEntity[]> | undefined {
		if (!this.data) {
			if (this._isQuerying) return;
			this.queryLambdaVerifyLink(token);
		}
		return this.data;
	}

	set(obj: ResponseLambdaVerifyLink['lambdaVerifyLink']['lines'] | undefined): void {
		const studies: StudyList = [];
		for (const line of obj || []) {
			const study = studies.find(b => b.id === line.studyId);
			if (!study) studies.push({
				id: line.studyId,
				name: line.studyName,
				datasets: [{
					id: line.datasetId,
					name: line.datasetName,
					blocks: [{
						id: line.blockId,
						name: line.blockName,
						lines: [line]
					}]
				}]
			});
			else {
				const dataset = study.datasets.find(b => b.id === line.datasetId);
				if (!dataset) study.datasets.push({
					id: line.datasetId,
					name: line.datasetName,
					blocks: [{
						id: line.blockId,
						name: line.blockName,
						lines: [line]
					}]
				});
				else {
					const block = dataset.blocks.find(b => b.id === line.blockId);
					if (!block) dataset.blocks.push({
						id: line.blockId,
						name: line.blockName,
						lines: [line]
					});
					else block.lines.push(line);
				}
			}
		}
		this.data = studies.map(s => ({
			...s,
			datasets: s.datasets.map(d => ({
				...d,
				blocks: d.blocks.map(b => new LambdaInventoryBlocksEntity(this.app, this, b))
			}))
		}));
		this.change();
	}

	change(): Promise<void> | undefined {
		return this.app.adapter.storeLambdaInventory?.({ loading: false, error: null, data: this });
	}

	update(line: ResponseLambdaMutateLine['mutateLambdaLine']['line']): void {
		const equipment = this.data?.reduce<LambdaEquipmentEntity | undefined>(
			(sp, sc) => sp ?? sc.datasets.reduce<LambdaEquipmentEntity | undefined>(
				(dp, dc) => dp ?? dc.blocks.reduce<LambdaEquipmentEntity | undefined>(
					(bp, bc) => bp ?? bc.data.lines.reduce<LambdaEquipmentEntity | undefined>(
						(lp, lc) => lp ?? lc.data.id == line.id ? lc : undefined, undefined
					), undefined
				), undefined
			), undefined
		);
		if (!equipment) return;
		equipment.define(line);
		this.change();
	}

	private _storeError(error: Error) {
		this.app.adapter.storeLambdaInventory?.({
			loading: false,
			error: error,
			data: this
		});
	}

	/***************************************************
	 * 					API CALLS					   *
	 ***************************************************/
	async queryLambdaVerifyLink(token: string): Promise<boolean> {
		this._isQuerying = true;
		const data = await this.callApi(this.app.adapter.queryLambdaVerifyLink, { token }).catch(() => {
			this._isQuerying = false;
			return undefined;
		});
		this._isQuerying = false;
		if (!data || !data.lambdaVerifyLink.success) {
			return false;
		}
		this.set(data.lambdaVerifyLink.lines);
		return true;
	}

	async queryLambdaRequestCode(token: string): Promise<boolean> {
		const data = await this.callApi(this.app.adapter.queryLambdaRequestCode, { token });
		if (!data || !data.lambdaRequestCode.success) {
			this._storeError(new Error('Authentication Fail'));
			return false;
		}
		this.lambdaToken = token;
		return true;
	}

	async queryLambdaVerifyCode(code: string, token: string): Promise<boolean> {
		const data = await this.callApi(this.app.adapter.queryLambdaVerifyCode, { code, token });
		return !(!data || !data.lambdaVerifyCode.success);
	}

	async queryLambdaVerifyToken(token: string): Promise<boolean> {
		const data = await this.callApi(this.app.adapter.queryLambdaVerifyToken, { token });
		return !(!data || !data.lambdaVerifyToken.success);
	}

	async mutateLambdaUserCreation(input: ILambdaUserCreation): Promise<boolean> {
		const data = await this.callApi(this.app.adapter.mutateLambdaUserCreation, input).catch(() => undefined);
		if (!data || data.mutateLambdaUserCreation.status !== 200) {
			return false;
		}
		this.app.entities.company.addLambdaUser(data.mutateLambdaUserCreation.user);
		for (const line of data.mutateLambdaUserCreation.equipments) {
			this.app.entities.inventory.update(line);
		}
		this.app.adapter.storeUserNotification?.({
			title: 'tableAssign.notification.title',
			message: 'tableAssign.notification.emailSent',
			footer: true
		});
		return true;
	}

	async mutateNotifyAssignees(input: IMutateNotifyAssigneesInput): Promise<boolean> {
		const data = await this.callApi(this.app.adapter.mutateNotifyAssignees, input);
		if (!data || data.mutateSendNotificationToTemporaryAssignees.status !== 200) {
			return false;
		}
		// Clear list of temporary assigned users
		this.app.adapter.storeTemporaryAssignedUsersEmails?.([]);
		return true;
	}

	async mutateLambdaEquipmentLine(input: ILambdaMutateLine): Promise<boolean> {
		const data = await this.callApi(this.app.adapter.mutateLambdaEquipmentLine, input).catch(() => undefined);
		if (!data) return false;
		this.update(data.mutateLambdaLine.line);
		return true;
	}
}

export class LambdaInventoryBlocksEntity extends RelatedDatasetEntity {
	public data: { id: string, name: string, lines: LambdaEquipmentEntity[] };
	public entity: LambdaInventoryEntity;

	constructor(app: Core, parent: LambdaInventoryEntity, obj: { id: string, name: string, lines: ResponseLambdaVerifyLink['lambdaVerifyLink']['lines'][number][] }) {
		super(app, parent);
		this.entity = parent;
		this.data = {
			...obj,
			lines: obj.lines.map(e => new LambdaEquipmentEntity(app, this, e))
		};
	}
}

export class LambdaEquipmentEntity extends ValueEntity {
	public data: InputProperty<LambdaEquipment> & LambdaVerifyLinkLineAdds;
	private entity: LambdaInventoryEntity;

	constructor(app: Core, parent: LambdaInventoryBlocksEntity, obj: LambdaEquipment & LambdaVerifyLinkLineAdds) {
		super(app, parent);
		this.entity = parent.entity;
		this.data = this.define(obj);
	}

	define(obj: LambdaEquipment & LambdaVerifyLinkLineAdds): InputProperty<LambdaEquipment> & LambdaVerifyLinkLineAdds {
		const update = {
			...obj,
			allocationFactor: injectInputSetter('allocationFactor', numNullToString(obj.allocationFactor), this),
			quantity: injectInputSetter('quantity', numNullToString(obj.quantity), this),
			unknown: injectInputSetter('unknown', obj.unknown ? 'true' : 'false', this),
			quality: injectInputSetter('quality', numNullToString(obj.quality), this),
			lifetime: injectInputSetter('lifetime', numNullToString(obj.lifetime), this),
			internalLifetime: injectInputSetter('internalLifetime', numNullToString(obj.internalLifetime), this),
			reusePart: injectInputSetter('reusePart', numNullToString(obj.reusePart), this),
			reuseLifetime: injectInputSetter('reuseLifetime', numNullToString(obj.reuseLifetime), this),
			customElectricityConsumption: injectInputSetter('customElectricityConsumption', numNullToString(obj.customElectricityConsumption), this),
			comment: injectInputSetter('comment', obj.comment ?? '', this),
		};
		if (this.isChanged.includes('allocationFactor')) update.allocationFactor.set(this.data.allocationFactor.get());
		if (this.isChanged.includes('quantity')) update.quantity.set(this.data.quantity.get());
		if (this.isChanged.includes('unknown')) update.unknown.set(this.data.unknown.get());
		if (this.isChanged.includes('quality')) update.quality.set(this.data.quality.get());
		if (this.isChanged.includes('lifetime')) update.lifetime.set(this.data.lifetime.get());
		if (this.isChanged.includes('internalLifetime')) update.internalLifetime.set(this.data.internalLifetime.get());
		if (this.isChanged.includes('reusePart')) update.reusePart.set(this.data.reusePart.get());
		if (this.isChanged.includes('reuseLifetime')) update.reuseLifetime.set(this.data.reuseLifetime.get());
		if (this.isChanged.includes('customElectricityConsumption')) update.customElectricityConsumption.set(this.data.customElectricityConsumption.get());
		if (this.isChanged.includes('comment')) update.comment.set(this.data.comment.get());
		this.data = update;
		return this.data;
	}

	change(name: ObjectKey, change: boolean) {
		super.change(name, change);

		// Calculate lifetime only if one of the following fields is changed
		if (![ 'internalLifetime', 'reusePart', 'reuseLifetime' ].includes(name as string)) return;
		let lifetime = this.data.lifetime.get().length ? parseFloat(this.data.lifetime.get()) : null;
		// Update lifetime if internalLifetime, reusePart or reuseLifetime is changed - Only in frontend for now
		if (
			this.isChanged.includes('internalLifetime')
			|| this.isChanged.includes('reusePart')
			|| this.isChanged.includes('reuseLifetime')
		) {
			lifetime = this.calculateLifetime();
			this.data.lifetime.set(lifetime ? lifetime.toString() : '');
		}
	}

	calculateLifetime(): number | null {
		const ilt = stringToNumOrZero(this.data.internalLifetime.get());
		const rp = stringToNumOrZero(this.data.reusePart.get());
		const rlt = stringToNumOrZero(this.data.reuseLifetime.get());
		const div = rp === 0 ? 0 : rp / 100;
		const add = div === 0 || rlt === 0 ? 0 : div * rlt;
		let lifetime: number | null = ilt + add;
		if (lifetime === 0) {
			lifetime = null;
		}
		return lifetime;
	}

	save(): void {
		this.app.entities.lambdaInventory.mutateLambdaEquipmentLine({
			token: this.entity.lambdaToken,
			lineId: this.data.id,
			values: {
				allocationFactor: this.data.allocationFactor.get().length ? parseFloat(this.data.allocationFactor.get()) : 1,
				quantity: this.data.quantity.get().length ? parseFloat(this.data.quantity.get()) : null,
				quality: this.data.quality.get().length ? parseFloat(this.data.quality.get()) : null,
				lifetime: this.data.lifetime.get().length ? parseFloat(this.data.lifetime.get()) : null,
				internalLifetime: this.data.internalLifetime.get().length ? parseFloat(this.data.internalLifetime.get()) : null,
				reusePart: this.data.reusePart.get().length ? parseFloat(this.data.reusePart.get()) : null,
				reuseLifetime: this.data.reuseLifetime.get().length ? parseFloat(this.data.reuseLifetime.get()) : null,
				customElectricityConsumption: this.data.customElectricityConsumption.get().length ? parseFloat(this.data.customElectricityConsumption.get()) : null,
				comment: this.data.comment.get(),
			}
		}).then((success) => {
			this.event.emit('change', success);
		});
	}
}
