import React from 'react';


export const CheckBoxCustom = ({ checked, onChange, checkedIcon, unCheckedIcon }: {
	checked: boolean,
	onChange: (checked: boolean) => void,
	checkedIcon?: string,
	unCheckedIcon?: string,
	size?: string,
}) => {
	return (
		<i
			role='checkbox'
			className={`check_icon fa-lg ${checked ? checkedIcon ?? 'fa-solid fa-check-square' : unCheckedIcon ?? 'fa-regular fa-square'}`}
			onClick={() => onChange(!checked)}
		/>
	);
};

export const CheckBoxText = ({ text, className, checked,checkedIcon, unCheckedIcon, textIsRight, onChange, checkBoxSize }: {
	text: string,
	className: string,
	checked: boolean,
	checkedIcon?: string,
	unCheckedIcon?: string,
	textIsRight?: boolean,
	onChange: (checked: boolean) => void,
	checkBoxSize?: string,
}) => {
	return (
		<div className={className}>
			{!textIsRight && <label>{text}</label>}
			<CheckBoxCustom
				checked={checked}
				onChange={onChange}
				checkedIcon={checkedIcon}
				unCheckedIcon={unCheckedIcon}
				size={checkBoxSize}
			/>
			{textIsRight && <label>{text}</label>}
		</div>
	);
};