import React, { useState } from 'react';
import { IModal } from '../../../domain/interfaces/IModal';
import { translate } from '../../../infrastructure/translations/translate';
import './DashboardModalACV.scss';
import ChoiceSelector from '../../../viewComponents/head/choiceSelector/choiceSelector';
import { Study } from '../../../domain/data/entries/Study';
import { useNavigate } from 'react-router-dom';
import { linkGenerator } from '../../../router/linkGenerator';
import { ICompany } from '../../../domain/interfaces/ResponseUserData';
import { useModal } from '../../../viewComponents/modal/useModal';
import { useStudies } from '../../../viewComponents/hooks/useStudies';
import { useDatasets } from '../../../viewComponents/hooks/useDatasets';
import { useUser } from '../../../viewComponents/hooks/useUser';

export const createDashboardModalACV = (open: boolean, setOpen: (value: boolean) => void): IModal | undefined => {
	const navigate = useNavigate();
	const { studies } = useStudies();
	const { data: logged } = useUser();

	const manageCompanies = (logged?.companiesToManage.length ?? 0) > 1;

	const [selectedCompanyId, setSelectedCompanyId] = useState('');
	const [selectedStudyId, setSelectedStudyId] = useState('');
	const [selectedDatasetId, setSelectedDatasetId] = useState('');
	const [selectedCompany, setSelectedCompany] = useState<ICompany | null>(null);
	const [selectedStudy, setSelectedStudy] = useState<Study | null>(null);
	const { datasets } = useDatasets(selectedStudy?.datasetsId ?? []);

	const clear = () => {
		setSelectedCompanyId('');
		setSelectedStudyId('');
		setSelectedDatasetId('');
		setSelectedCompany(null);
		setSelectedStudy(null);
		useModal(false, { visible: false, body: undefined, header: '' });
	};

	if (!open) return;

	const handleSelectCompany = () => {
		const company = studies?.find(s => s.companyFk.id === selectedCompanyId)?.companyFk;
		setSelectedCompany(company ?? null);
	};
	const handleSelectStudy = () => {
		const study = studies?.find(s => s.id === selectedStudyId);
		setSelectedStudy(study ?? null);
	};
	const handleSelectDataset = () => {
		navigate(linkGenerator(selectedStudyId, 'results', selectedDatasetId));
		useModal(false, { visible: false, body: <></>, header: '' });
	};
	let action;
	let body;
	let header;
	if (!selectedCompany && manageCompanies) {
		action = handleSelectCompany;
		const list = studies?.filter(
			(s, idx) => studies.findIndex(
				f => f.companyFk.id == s.companyFk.id
			) === idx
		).map(s => ({
			value: s.companyFk.id,
			name: s.companyFk.name
		})) ?? [];
		body = <div className="dashboard_modal">
			<span>{translate('company')} ({translate('required')})</span>
			<ChoiceSelector
				key="company"
				selectorName={translate('company') as string}
				selected={selectedCompanyId}
				list={list}
				handle={setSelectedCompanyId}
			/>
		</div>;
		header = translate('dashboard.modal.acv.choose.a.company');
	} else if (!selectedStudy) {
		action = handleSelectStudy;
		body = <div className="dashboard_modal">
			<span>{translate('study')} ({translate('required')})</span>
			<ChoiceSelector
				key="study"
				selectorName={translate('study') as string}
				selected={selectedStudyId}
				list={studies?.filter(
					s => !manageCompanies || s.companyFk.id === selectedCompanyId
				).map(s => ({
					value: s.id,
					name: `${s.name} - ${s.year}`
				})) ?? []}
				handle={setSelectedStudyId}
			/>
		</div>;
		header = translate('dashboard.modal.acv.choose.a.study');
	} else {
		action = handleSelectDataset;
		body = <div className="dashboard_modal">
			<h2>{selectedStudy.name}</h2>
			<span>{translate('dataset')} ({translate('required')})</span>
			<ChoiceSelector
				key="dataset"
				selectorName={translate('dataset') as string}
				selected={selectedDatasetId}
				list={datasets ? [ ...datasets.filter(ds => ds.studyId === selectedStudyId).map(d => ({
					value: d.id,
					name: d.name,
					disabled: d.status.results !== 'ready' ? translate('dashboard.modal.dataset.tooltip.noResults') : false
				})), {
					value: 'all',
					name: translate('all.study') as string,
					disabled: datasets.filter(d => d.studyId === selectedStudyId).some(d => d.status.results !== 'ready') ? translate('dashboard.modal.dataset.tooltip.noResults') : false
				}] : []}
				handle={setSelectedDatasetId}
			/>
		</div>;
		header = translate('dashboard.modal.acv.choose.a.dataset');
	}
	return {
		action,
		actionText: 'validate',
		body,
		header,
		onClose(): void {
			clear();
			setOpen(false);
		},
		visible: true
	};
};