import React from 'react';
import { Alert } from '../../viewComponents/Alerts';

interface DashboardCardAlertProps {
    variant: 'success' | 'danger' | 'warning' | 'info';
    className?: string;
    children: React.ReactNode;
}

export const DashboardCardAlert = ({ variant, className, children }: DashboardCardAlertProps) => {
	return <div className={`dashboard_card ${className}`}>
		<Alert variant={variant}>
			{children}
		</Alert>
	</div>;
};