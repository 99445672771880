import React from 'react';
import { translate } from '../../../infrastructure/translations/translate';
import Spinner from '../../utils/Spinner/Spinner';

type Props = {
	title: string;
	classname: string;
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
	loading: boolean;
	width?: string;
	height?: string;
	type?: 'button' | 'submit' | 'reset' | undefined;
	disable?: boolean;
};

/**
 * Button with loading spinner
 * @param {string} title - title of the button
 * @param {boolean} loading - true if loading
 * @param {string} classname - classname of the button
 * @param {React.MouseEventHandler<HTMLButtonElement>} onClick - function to call on click
 * @param {string} width - width of the button
 * @param {string} height - height of the button
 * @param {boolean} disable - state of the button
 * @param {string} type - type of the button (button by default)
 * @constructor
 * @author Maxime Joly
 */
const ButtonLoading = ({ title, classname, onClick, loading, width, height, disable, type }: Props): JSX.Element => {
	const loadingStyle : React.CSSProperties = {
		backgroundColor: loading ? 'white' : '',
		color: loading ? 'grey' : '',
		pointerEvents: loading ? 'none' : 'auto',
		width: width ?? 'auto',
		height: height ?? 'auto',
	};

	return (
		<button aria-label={'loading-button'} title={translate(title) as string} type={type ?? 'button'} onClick={onClick} className={classname} style={loadingStyle} disabled={disable ? disable : loading}>
			{loading ? <Spinner style={{ width: '20px', height: '20px', border: 'none' }}/> : translate(title)}
		</button>
	);
};

export default ButtonLoading;
