import { gql } from '@apollo/client';

export const QUERY_COMPUTING_HISTORY = gql`
    query QueryComputingHistory (
        $datasets: [String]!,
        $sample_id: String
    )
    {
        computingHistory (
            datasets: $datasets,
            sampleId: $sample_id
        )
        {
            id
            name
            private
            computedDatasets {
                id
                name
            }
            archived
            definitive
            inventoryExtraSnapshot
        }
    }
`;
