import { ResultLaunchersEntity } from '../../domain/entities/ResultLaunchersEntity';
import { IDataDTO } from '../../domain/interfaces/DTO/IDataDTO';
import { IResultLauncher } from '../../domain/data/entries/IResultLauncher';
import { useSelector } from 'react-redux';
import { State } from '../../store';

type ResultLaunchersHookType = IDataDTO & { entity: ResultLaunchersEntity | undefined, resultLaunchers: IResultLauncher[] };

export const useResultLaunchers = (datasetsIds?: string[], sampleId?: string): ResultLaunchersHookType => {
	const { loading, error, data: entity } = useSelector((state: State) => state.dataResultLaunchersStatus);
	const resultLaunchers = entity?.get(datasetsIds, sampleId) ?? [];
	return { loading, error, entity, resultLaunchers };
};