import React, { useEffect, useState } from 'react';
import { translate } from '../../infrastructure/translations/translate';
import { IModal } from '../../domain/interfaces/IModal';
import { IUserDashboard } from '../../domain/interfaces/IUserDashboard';
import { IDashboard } from '../../domain/interfaces/IDashboard';
import { useDashboards } from '../../viewComponents/hooks/useDashboards';
import { useUsersDashboard } from '../../viewComponents/hooks/useUsersDashboard';
import { useUser } from '../../viewComponents/hooks/useUser';
import { useModal } from '../../viewComponents/modal/useModal';

export const DashboardShareButton = ({ userDashboard, dashboard, sharedUsers }: { userDashboard?: IUserDashboard, dashboard?: IDashboard, sharedUsers: { id: string, email: string }[] }): JSX.Element => {
	const [openModal, setOpenModal] = useState(false);

	useModal(openModal, createDashboardShareModal(openModal, setOpenModal, sharedUsers, userDashboard, dashboard));

	return (<button
		type={'button'}
		className={'button_blank dashboard_share_button'}
		onClick={() => setOpenModal(true)}
	>
		<div className="dashboard_icon"><i className="fa-solid fa-user" /></div>
		<div className="text">{translate('dashboard.overview.table.share')}</div>
	</button>);
};

export const createDashboardShareModal = (open: boolean, setOpen: (value: boolean) => void, sharedUsers: { id: string, email: string }[], userDashboard?: IUserDashboard, dashboard?: IDashboard): IModal | undefined => {
	const { data: logged } = useUser();
	const { entity: dashboardEntity } = useDashboards();
	const { entity: userDashboardEntity } = useUsersDashboard();

	const [filter, setFilter] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);
	const [sharedUserChanges, setSharedUserChanges] = useState<{ id: string, email: string, selected: boolean }[]>([]);
	const [userList, setUserList] = useState<{ id: string, email: string }[]>([]);

	useEffect(() => {
		// We check for open for two reasons:
		// 1. Avoid fetching user list if the user do not open dashboard sharing
		// 2. If there is a card from a dataset A, and this is the only card from it, user lists will contain users related to this dataset.
		// So if we remove the card and then share the dashboard, we don't want to have those users anymore
		if (!open) return;
		if (dashboard) {
			dashboardEntity?.queryDashboardUsersCanAccess(dashboard.id).then(list => setUserList(list));
		} else if (userDashboard) {
			userDashboardEntity?.queryUserDashboardUsersCanAccess(userDashboard.id).then(list => setUserList(list));
		}
	}, [open, dashboard, userDashboard]);

	if (!open) return;

	const clear = () => {
		setFilter('');
		setSharedUserChanges([]);
		setLoading(false);
		useModal(false, { body: undefined, header: undefined, visible: false });
		setOpen(false);
	};

	const shared = (user: { id: string, email: string }) => (
		sharedUsers.some(sharedUser => sharedUser.id === user.id) &&
		!sharedUserChanges.some(change => change.id === user.id && !change.selected)
	) || (
		!sharedUsers.some(sharedUser => sharedUser.id === user.id) &&
		sharedUserChanges.some(change => change.id === user.id && change.selected)
	);

	const share = () => {
		const cUserIds = sharedUserChanges.filter(change => change.selected).map(change => change.id);
		const sUserIds = sharedUsers.filter(user => !sharedUserChanges.some(change => change.id === user.id)).map(user => user.id);
		const userIds = [...cUserIds, ...sUserIds];
		setLoading(true);
		if (userDashboard)	userDashboardEntity?.mutateShareUserDashboard({ id: userDashboard.id, userIds }).then(() => {
			clear();
		});
		if (dashboard) dashboardEntity?.mutateShareDashboard({ id: dashboard.id, userIds }).then(() => {
			clear();
		});
	};

	const changeSharedUser = (user: { id: string, email: string }) => {
		const change = sharedUserChanges.find(change => change.id === user.id);
		if (!change) setSharedUserChanges(
			[...sharedUserChanges, {
				id: user.id,
				email: user.email,
				selected: !sharedUsers.some(sharedUser => sharedUser.id === user.id)
			}]
		);
		else setSharedUserChanges(c => c.filter(change => change.id !== user.id));
	};

	const userListFiltered = userList?.filter(user => user.email.includes(filter)).filter(user => user.id !== logged?.id);

	const body = (<div className={'modal_dashboard_share'}>
		<div className="modal_dashboard_share_filter_input">
			<label htmlFor="filter">{translate('filter')}</label>
			<input type="text" id="filter" value={filter} onChange={e => setFilter(e.target.value)} />
		</div>
		<div className="modal_dashboard_share_list">
			{userListFiltered?.map(user => <button
				type={'button'}
				className={`modal_dashboard_share_user button_blank ${shared(user) ? 'selected' : ''}`}
				key={user.id}
				onClick={() => changeSharedUser(user)}
			>
				<div className="modal_dashboard_share_assigned_user_email">{user.email}</div>
				<div className="modal_dashboard_share_assigned_user_remove">
					{shared(user) && <i className="fa-solid fa-times"/>}
					{!shared(user) && <i className="fa-solid fa-plus"/>}
				</div>
			</button>)}
		</div>
	</div>);

	return {
		action: share,
		actionText: 'share',
		body,
		header: translate('dashboard.overview.table.share'),
		onClose: clear,
		load: loading,
		visible: true
	};
};