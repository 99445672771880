import { gql } from '@apollo/client';

export const MUTATE_EDIT_LCA_MATURITY = gql`
    mutation MutateEditLcaMaturity(
        $id: String!
        $name: String!
        $description: String!
        $goodPractices: [String!]!
        $delete: Boolean
    ){
        mutateEditLcaMaturity(
            id: $id
            name: $name
            description: $description
            goodPractices: $goodPractices
            delete: $delete
        ){
            maturity {
                id
                name
                description
                goodPractices
            }
        }
    }
`;
