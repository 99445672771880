import React from 'react';
import { ChartProps, Doughnut } from 'react-chartjs-2';
import { getColors } from '../../../config';
import './doughnutChart.css';
import { labelSize } from '../utils/labelSize';
import { getNextAvailableColor } from '../../../utils/GraphsUtils';
import { translate } from '../../../infrastructure/translations/translate';
import { Chart } from 'chart.js';

/**
 * A doughnut chart component that displays relatives data of a list of values
 *
 * @param datasets {{labels: string, values: number, color: string | undefined}[]} - The data to display
 * @param plugins {ChartProps<'doughnut'>['plugins']} - Optional plugins used to customize chart
 * @param sortDataset {boolean = true} - If true, sort the dataset based on value
 * @param sortClockwise {boolean = true} - If true, sort the dataset clockwise
 *
 * @returns JSX.Element
 * @author Yacine Bentayeb
 */
export const RelativeDoughnutChart = ({
	datasets,
	plugins,
	sortDataset = true,
	sortClockwise = true,
}: {
	datasets: {labels: string, values: number, color?: string }[],
	plugins?: ChartProps<'doughnut'>['plugins'],
	sortDataset?: boolean,
	sortClockwise?: boolean,
}): JSX.Element => {
	// Calculate total of all block values
	const total = datasets.reduce((a, b) => a + b.values, 0);
	// Calculate relative values
	datasets.forEach(d => d.values = d.values / total * 100);
	datasets.forEach(d => d.values = Math.round(d.values * 100) / 100);

	// Sort by values - 'other' always at the end
	if (sortDataset) {
		datasets = [...datasets].sort((a, b) => {
			if (a.labels.toLowerCase() === (translate('other') as string).toLocaleLowerCase()) return 1;
			if (b.labels.toLowerCase() === (translate('other') as string).toLocaleLowerCase()) return -1;

			return sortClockwise ? b.values - a.values : a.values - b.values;
		});
	}

	const values = datasets.map(pair => pair.values);
	const labels = datasets.map(pair => labelSize(pair.labels));

	const defaultColors = getColors(labels.length);
	const colors: string[] = [];
	datasets.forEach(d => {
		if (d.color) colors.push(d.color);
		else colors.push(getNextAvailableColor(colors, defaultColors));
	});

	const doughnutData = {
		labels: labels,
		datasets: [{
			data: values,
			backgroundColor: colors,
		}],
	};

	return (
		<Doughnut
			className='dynamic_chart doughnut_relative_chart'
			plugins={plugins}
			data={doughnutData}
			options={{
				plugins: {
					legend: {
						position: 'right',
						labels: {
							font: {
								size: 14,
								weight: 'bold',
							}
						},
					},
					tooltip: {
						callbacks: {
							label: (context: { chart: Chart, dataset: { data: number[] }, dataIndex: number }) => {
								const legends = context.chart.legend?.legendItems;
								if (!legends) return '';
								const newTotal = context.dataset.data.reduce((a, b, index) => {
									if (legends[index].hidden) return a;
									return a + b;
								}, 0);
								const percentage = context.dataset.data[context.dataIndex] / newTotal * 100;
								const label = percentage.toFixed(2) || '';
								return `${label}%`;
							},
						},
					},
				},
				responsive: true,
				maintainAspectRatio: false,
			}}
		/>
	);
};
