import { gql } from '@apollo/client';

export const MUTATE_LAMBDA_EQUIPMENT_LINE = gql`
    mutation lambdaSendLine(
        $token: String!,
        $lineId: String!,
        $values: LambdaMutateLineInput!
    ) {
        mutateLambdaLine(
            token: $token,
            lineId: $lineId,
            values: $values
        ) {
            success
            line {
                id,
                blockId,
                blockName,
                studyId,
                studyName,
                datasetId,
                datasetName,
                collectOpen,
                name,
                help,
                unit,
                priority,
                specification,
                quality,
                quantity,
                unknown,
                quality,
                lifetime,
                internalLifetime,
                reusePart,
                reuseLifetime,
                customElectricityConsumption,
                comment,
                category
            }
        }
    }
`;
