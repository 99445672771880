import React from 'react';

export interface DashboardButtonType {
	size?: 'long' | 'short';
	color: 'green_table' | 'green_light' | 'green' | 'yellow' | 'red' | 'blue';
	description: string;
	icon?: string;
	disabled?: boolean;
}

const truncateText = (text: string, maxLength: number) => {
	if (text.length > maxLength) {
		return text.substring(0, maxLength) + '...';
	}
	return text;
};
  
const DashboardButton = (props: DashboardButtonType & Omit<React.HTMLProps<HTMLButtonElement>, keyof DashboardButtonType>): JSX.Element => {
	const size = props.size ?? 'short';
	const buttonChildren = typeof props.children === 'string' ? truncateText(props.children, 20) : props.children;
	const buttonDescription = truncateText(props.description, 50);

	return <button
		{...props}
		className={`dashboard_create_table ${size} ${props.color} ${props.disabled ? 'disabled' : ''}`}
		type={'button'}
		disabled={false}
		tabIndex={props.disabled ? -1 : 0}
		onClick={(e) => props.disabled ? e.preventDefault() : props.onClick?.(e)}
	>
		{props.icon && <img src={props.icon} alt={props.title}/>}
		<h3>{buttonChildren}</h3>
		<p>{buttonDescription}</p>
	</button>;
};

export default DashboardButton;
