import React from 'react';
import { translate } from '../../../infrastructure/translations/translate';
import { ResultArrowComparison } from '../ResultArrowComparison';
import { unique } from './Unique';

export interface IInfo {
	id: string,
	name: string
}

export const InfoSamplesInfo = ({ info }: { info?: IInfo[] }): JSX.Element => {
	if (!info) return (<h4 style={{ margin: '5px 0' }}>{translate('info.block.noSample')}</h4>);
	return (
		<div style={{ fontSize: '.8em' }}>
			<h4 style={{ margin: '5px 0' }}>{translate('info.block.preSample') + info.map(s => s.name).filter(unique).join(', ')}</h4>
			<p><ResultArrowComparison arrow={'max'} color={'grey'}/> {translate('info.block.indicatorMax')}</p>
			<p><ResultArrowComparison arrow={'up'} color={'grey'}/> {translate('info.block.indicatorUp')}</p>
			<p><ResultArrowComparison arrow={'down'} color={'grey'}/> {translate('info.block.indicatorDown')}</p>
			<p><ResultArrowComparison arrow={'min'} color={'grey'}/> {translate('info.block.indicatorMin')}</p>
		</div>
	);
};
