import React, { useEffect, useState } from 'react';
import { translate } from '../../infrastructure/translations/translate';
import { ResultArrowComparison } from './ResultArrowComparison';
import { resultValue } from './utils/resultValue';
import { ResultCardError } from '../error/ResultCardError/ResultCardError';
import { prepareDataTableResult } from '../../utils/getResultTableData';
import { IResultTableProps, ResultTable } from './ResultTable';
import { useTransformedResultTableData } from '../../utils/useTransformedResultTableData';
import Spinner from '../utils/Spinner/Spinner';
import { IndicatorTable } from './utils/IndicatorTable/IndicatorTable';
import { getMedian } from '../../utils/getMedian';
import { FootprintStackedBarChart } from '../graphs/bars/FootprintStackedBarChart';

export type ResultImpactPerBlockType = { schema: { fields: { name: string, type: string }[], primaryKey: string[], pandas_version: string }, data: { block: string, indicator: string, unit: string, value: number, mean?: number, min?: number, max?: number, std?: number }[] }
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isResultImpactPerBlock = (o: any): o is ResultImpactPerBlockType => {
	if (typeof o === 'object') return 'schema' in o && 'data' in o;
	return false;
};

export const ResultFootprintPerBlock = ({ data, isRelative }: { data: string, isRelative: boolean }): JSX.Element => {
	if (!isResultImpactPerBlock(data)) return (<ResultCardError/>);
	const [dataTable, setDataTable] = useState<IResultTableProps | null>(null);
	// transform the data from id to name
	const transformedData = useTransformedResultTableData(data.data);

	useEffect(() => {
		if (!transformedData || transformedData instanceof Error ) return;
		// Prepare the data for the table
		const table = prepareDataTableResult(transformedData, (data) => data.block ?? '');
		if (table.length > 0) {
			// set the table for the ResultTable component
			setDataTable({
				columns: [
					{ name: translate('results.indicator') as string },
					{ name: translate('results.unit') as string, style: { textAlign: 'left' } },
					...table[0].values.map((v) => ({ name: v.key })),
				],
				values: table.map((t) => {
					// Extract the values from t.values
					const values = t.values.map(v => v.value);
					// Calculate median and new units
					const { values: medianValues, units: newUnits } = getMedian(values, t.unit ?? '');
					return [
						{ value: <IndicatorTable indicator={t.indicator} icon={t.icon}/>, valueType: 'left' },
						{ value: isRelative ? '%' : newUnits, valueType: 'left', style: { textAlign: 'left' } },
						...medianValues.map((value, index) => ({
							value: (
								<>
									{resultValue(value)}
									<ResultArrowComparison arrow={t.values[index].arrow} />
								</>
							),
							valueType: 'right',
						})),
					];
				}),
			});
		}
	}, [transformedData]);

	if (transformedData instanceof Error) return <ResultCardError/>;
	if (!dataTable) return <Spinner/>;
	return <ResultTable dataTable={dataTable} />;
};



export const FootprintPerBlockGraph = ({ data }: { data: string }): JSX.Element => {
	if (!isResultImpactPerBlock(data)) return (<ResultCardError/>);
	const transformedData = useTransformedResultTableData(data.data);
	if (transformedData instanceof Error || !transformedData) return <ResultCardError/>;

	return <FootprintStackedBarChart data={transformedData} field='block'/>;
};