import { gql } from '@apollo/client';

export const MUTATE_ARCHIVE_RESULTS = gql`
    mutation MutateArchiveResults(
        $rlId: String!
    ){
        mutateArchiveResults(
            rlId: $rlId
        ) {
            status
        }
    }
`;
