import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Plugin } from 'chart.js';
import './doughnutChart.css';

export const ProgressDoughnutChart = ({ labels, values, displayScore, progressColor }:{
	labels: string[],
	values: number[],
	displayScore: boolean
	description?: string
	progressColor?: string
}): JSX.Element => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const plugins: Plugin<'doughnut', any>[] = [{
		id: 'Center description',
		beforeDraw: function(chart) {
			if (!displayScore) return;
			const { ctx, width, height } = chart;
			const center = { w: width / 2, h: height / 2 + 5 };
			const score = values[0].toLocaleString(navigator.language, { maximumFractionDigits: 1 }) + '%';
			const fontSize = 40;
			const fontStyle = 'normal';
			const fontFamily = 'Roboto, sans-serif';
			ctx.textAlign = 'center';
			ctx.textBaseline = 'middle';
			ctx.font = `${fontStyle} ${fontSize}px ${fontFamily}`;
			ctx.fillText(score, center.w, center.h);
			ctx.save();
		}
	}];
	const doughnutData = {
		labels: labels,
		datasets: [{
			data: values,
			backgroundColor: [progressColor, '#d0d0d0'],
		}],
	};	
	return(
		<Doughnut
			className='dynamic_chart doughnut_progress_chart'
			data={doughnutData}
			options={{
				responsive: true,
				maintainAspectRatio: false,
				cutout: '70%',
				plugins:{
					legend: { display: false }		
				},	
			}}
			plugins={plugins}
		/>
	);
};
