import { Core } from '../core/Core';
import { StaticPage } from '../data/entries/StaticPage';
import { ResponseStaticPage } from '../data/ResponseStaticPage';
import { Entity } from '../core/Entity';
import { QUERY_STATIC_PAGE_BY_ID } from '../../infrastructure/ApolloClient/requests/QUERY_STATIC_PAGE_BY_ID';
import { QUERY_STATIC_PAGES } from '../../infrastructure/ApolloClient/requests/QUERY_STATIC_PAGES';
import {
	ApolloClientQueryStaticPage,
	ApolloClientQueryStaticPages
} from '../../infrastructure/ApolloClass/StaticPageClass';

export type StaticPagesEntityDataType = StaticPage[];

export class StaticPagesEntity extends Entity {
	data: StaticPage[] | undefined;
	private _isQuerying = false;

	constructor(app: Core) {
		super(app);
	}

	initialization() {
		this.app.adapter.queryStaticPage ??= this.app.installer(ApolloClientQueryStaticPage, QUERY_STATIC_PAGE_BY_ID);
		this.app.adapter.queryStaticPages ??= this.app.installer(ApolloClientQueryStaticPages, QUERY_STATIC_PAGES);
		this.app.adapter.storeStaticPage?.({ loading: false, data: this, error: null });
	}

	set(obj: StaticPagesEntityDataType): void {
		this.data = obj;
		this.change();
	}

	change(): Promise<void> | undefined {
		return this.app.adapter.storeStaticPage?.({ loading: false, data: this, error: null });
	}

	update(): void {
		return;
	}

	/**
	 * Add a static page to the entity.
	 *
	 * @param page The static page to add.
	 */
	add(page: ResponseStaticPage): void {
		if (!page.pageById) return;
		if (!this.data) this.data = [];
		const p = this.data.find(p => p.id === page.pageById?.id);
		if (!p) {
			this.data.push(page.pageById);
			return;
		}
		p.content = page.pageById.content;
		this.change();
	}

	/**
	 * Get all static pages.
	 *
	 * @returns Return all static pages.
	 */
	get(): StaticPage[] | undefined {
		if (!this.data) {
			if (this._isQuerying) return;
			this.queryStaticPages();
		}
		return this.data;
	}

	getById(id: string): StaticPage | undefined {
		if (!this.data) return;
		else if (!this.data.find(p => p.id === id)) {
			this.queryStaticPage(id);
		}
		return this.data.find(p => p.id === id);
	}

	private _storeError(err: Error) {
		this.app.adapter.storeStaticPage?.({
			loading: false,
			error: err,
			data: this,
		});
	}

	async queryStaticPage(id: string) {
		this.app.adapter.storeStaticPage?.({
			loading: true,
			error: null,
			data: this,
		});
		const data = await this.callApi(this.app.adapter.queryStaticPage, { id }).catch((err) => {
			this._storeError(err);
			return undefined;
		});
		if (!data) return;
		this.add(data);
	}

	async queryStaticPages() {
		if (this._isQuerying) return;
		this._isQuerying = true;
		document.documentElement.lang = this.app.adapter.getLang?.() ?? 'en';
		const data = await this.callApi(this.app.adapter.queryStaticPages).catch((err) => {
			this._storeError(err);
			this._isQuerying = false;
			return undefined;
		});
		this._isQuerying = false;
		if (!data) return;
		this.set(data.staticPages);
	}
}