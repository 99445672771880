import React, { useEffect, useId } from 'react';
import { DashboardComparisonData } from '../../../../domain/interfaces/DashboardComparisonData';
import { translate } from '../../../../infrastructure/translations/translate';
import { useIndicators } from '../../../../viewComponents/hooks/useIndicators';
import { useLifeCycleSteps } from '../../../../viewComponents/hooks/useLifeCycleSteps';
import { useBlocks } from '../../../../viewComponents/hooks/useBlocks';
import { DashboardPinButton } from '../../DashboardPin';
import { useModal } from '../../../../viewComponents/modal/useModal';
import {
	createDashboardComparisonBenchmarkModal,
	DashboardComparisonBenchmark
} from '../../Comparison/CreateDashboardComparisonBenchmarkModal';
import { unique } from '../../../../viewComponents/results/utils/Unique';
import { getImpactByIndicator } from '../../GetImpactByIndicator';
import { getImpactByLifeCycleStep } from '../../GetImpactByLifeCycleStep';
import { valueFixedDigits } from '../../../../utils/numberToStringSeparator';
import { parseByFootprintTypeDashboard } from '../../Comparison/ParseByFootprintTypeDashboard';
import { getColors } from '../../../../config';
import { LineChart, LineChartData } from '../../../../viewComponents/graphs/bars/LineChart';
import { ChartProps } from 'react-chartjs-2';
import {
	DashboardEvolutionPluginGraphBarRefDiff,
	DashboardPluginGraphBarLegend
} from '../../DashboardPluginGraphBarLegend';
import { GroupedBarChart, GroupedBarChartData } from '../../../../viewComponents/graphs/bars/GroupedBarChart';
import { IIndicator } from '../../../../domain/interfaces/IIndicator';
import DashboardIndicatorsSelector from '../../DashboardIndicatorsSelector';
import { TooltipItem } from 'chart.js/auto';
import { DashboardSelectReference } from '../../DashboardSelectReference';
import { resultValue } from '../../../../viewComponents/results/utils/resultValue';
import { compareEvolutionValueToRefValue } from '../DashboardEvolution';
import { createDashboardModalEvolutionBenchmarkNotUsed } from '../CreateDashboardModalEvolutionBenchmarkNotUsed';

type DashboardIndicatorsEvolutionGraphDataLine = {
	label: string;
	color?: string;
	dashed?: boolean;
	values: {
		scope: string;
		value: number;
	}[];
}

export interface DashboardIndicatorsEvolutionGraphData {
	indicators: string[];
	lifeCycleSteps: string[];
	footprintType: number;
	indicator: string;
	domain: string;
	lifeCycleStep: string;
	graph: {
		indicator: IIndicator;
		graph: (DashboardIndicatorsEvolutionGraphDataLine | null)[]
	}[];
	reference: string | null;
}

const options: ChartProps<'line' | 'bar'>['options'] = {
	maintainAspectRatio: false,
	aspectRatio: 3,
	layout: {
		padding: 10
	},
	plugins: {
		legend: {
			display: false
		},
		tooltip: {
			callbacks: {
				title: (items: TooltipItem<'line' | 'bar'>[]) => {
					return items[0].label.split(',').join(' ');
				}
			}
		}
	},
	scales: {
		x: {
			grid: {
				display: false
			}
		}
	}
};

export const DashboardIndicatorsEvolutionGraph = ({ data, showTable, histogram }: { data: DashboardIndicatorsEvolutionGraphData, showTable: boolean, histogram?: boolean }): JSX.Element => {
	const { graph: graphData, reference } = data;
	const id = useId();
	const years = graphData[0].graph
		.filter(e => e)
		.map(e => e?.values.map(v => v.scope))
		.flat()
		.filter(unique)
		.sort((a, b) => a && b ? parseInt(a) - parseInt(b) : 0);
	const yearColors = getColors(years.length, true).map((c, i) => ({ year: years[i], color: c }));

	const getGraphLineByIndicator = (indicator: string): (LineChartData | null)[] => [
		...(years.length < 4 ? [null, null] : [null]),
		...years.map((year) => ({
			label: year ?? '',
			values: graphData
				.find(d => d.indicator.id === indicator)?.graph
				.map(d => {
					const find = d?.values.find(v => v.scope === year);
					return {
						scope: d?.label ?? '',
						value: find?.value ?? null,
						color: d?.color ?? 'black',
						dashed: d?.dashed ?? false
					};
				}) ?? []
		})),
		...(years.length < 4 ? [null, null] : [null]),
	];

	const getGraphBarByIndicator = (indicator: string): (GroupedBarChartData | null)[] => graphData
		.find(d => d.indicator.id === indicator)?.graph
		.map(d => d ? ({
			label: d.label,
			values: years
				.map((y) => ({
					scope: y ?? '',
					value: d.values.find(v => v.scope === y)?.value ?? 0,
					color: yearColors.find(c => c.year === y)?.color ?? 'black',
				}))
		}) : null) ?? [];

	return (<>
		{graphData.map(g => (
			<div className="dashboard_graph_multiple" key={`indicator_${g.indicator.shortName}`}>
				<div className="dashboard_graph_multiple_head">
					<h4>{g.indicator.shortName}</h4>
					<div>{g.indicator.name}</div>
					<div>{g.indicator.unit}</div>
				</div>
				<div className='dashboard_graph_multiple_graph'>
					{!showTable && <div className="dashboard_graph_multiple_graph_container">
						<div className="dashboard_chart_container">
							{!histogram && <LineChart
								data={getGraphLineByIndicator(g.indicator.id)}
								options={options}
								plugins={[DashboardPluginGraphBarLegend(`${id}_indicator_${g.indicator.id}`)]}
							/>}
							{histogram && <GroupedBarChart
								data={getGraphBarByIndicator(g.indicator.id)}
								datasetRef={reference}
								options={options}
								plugins={[DashboardEvolutionPluginGraphBarRefDiff()]}
								thin={15}
								colors={yearColors.map(c => c.color)}
								percentage={1}
								categoryPercentage={.5}
								borderRadius={3}
							/>}
						</div> 
						{!histogram && <div id={`${id}_indicator_${g.indicator.id}`} className="dashboard_graph_legend"/>}
					</div>}
					{showTable && <table className="dashboard_table">
						<thead>
							<tr>
								<th>{translate('dataset')}</th>
								{years.map((year, i) => (
									<th key={i}>{year}</th>
								))}
							</tr>
						</thead>
						<tbody>
							{g.graph
								.filter(d => d)
								.map((d, i) => (<tr key={i}>
									<td>{d?.label}</td>
									{years.map((year, j) => {
										const find = d?.values.find(d => d.scope === year);
										const refValue = d?.values.find(v => v.scope === reference)?.value;
										return (
											<td style={{ fontWeight: reference === year ? 'bold' : 'normal' }} key={j}>
												<span className={'number value'}>
													{resultValue(find?.value)}
													{reference && year !== reference &&
														<span className="number ref">{compareEvolutionValueToRefValue(refValue, find?.value)}</span>}</span>
											</td>
										);
									})}
								</tr>))}
						</tbody>
					</table>}
				</div>
				<div className="dashboard_graph_tips">
					<DashboardPinButton data={{ ...data, graph: [g], indicator: g.indicator.id }} type={'DashboardIndicatorsComparison'} text={false} />
				</div>
			</div>
		))}
	</>);
};

type ParsedDataset = {
	dataset: DashboardComparisonData['datasets'][number],
	value: number
}
type GroupedByDataset = {
	name: string,
	study: string,
	company: string,
	values: {
		year: string,
		value: number[]
	}[]
}

const DashboardIndicatorsEvolution = ({ data }: { data: DashboardComparisonData }): JSX.Element => {
	const { selectedIndicators: indicators } = useIndicators(data.indicators);
	const { lcs: lifeCycleSteps } = useLifeCycleSteps(data.lifeCycleSteps);
	const { blocks: domains } = useBlocks(data.domains);

	const [selectFootprintType, setSelectFootprintType] = React.useState<number>(0);
	const [selectDomain, setSelectDomain] = React.useState<string>('all');
	const [selectLifeCycleStep, setSelectLifeCycleStep] = React.useState<string>('all');
	const [selectedIndicators, setSelectedIndicators] = React.useState<string[]>(indicators?.map(i => i.id) ?? []);
	const [reference, setReference] = React.useState<string | null>(null);

	const [benchmark, setBenchmark] = React.useState<DashboardComparisonBenchmark | undefined>();
	const [showTable, setShowTable] = React.useState<boolean>(false);
	const [histogram, setShowHistogram] = React.useState<boolean>(false);
	const isLineMode = !histogram && !showTable;

	const [openBenchmarkModal, setOpenBenchmarkModal] = React.useState<boolean>(false);
	const [openModalBenchYearsNotCorresponding, setOpenModalBenchYearsNotCorresponding] = React.useState<boolean>(false);

	const defineBenchmark = () => {
		if (benchmark) {
			setBenchmark(undefined);
		} else {
			setOpenBenchmarkModal(true);
		}
	};

	useModal(openBenchmarkModal, createDashboardComparisonBenchmarkModal(
		openBenchmarkModal,
		setOpenBenchmarkModal,
		setBenchmark
	));

	useModal(openModalBenchYearsNotCorresponding, createDashboardModalEvolutionBenchmarkNotUsed(
		openModalBenchYearsNotCorresponding,
		setOpenModalBenchYearsNotCorresponding
	));

	const DifferentStudyNames = data.datasets.map(d => d.study).filter(unique).length > 1;
	const DifferentCompanyNames = data.datasets.map(d => d.company).filter(unique).length > 1;
	const getName = (d: {
		name?: string,
		study?: string,
		company?: string
	}): string => {
		let name = '';
		if (DifferentCompanyNames) name = ` - ${d.company}`;
		if (DifferentStudyNames) name = ` - ${d.study}` + name;
		return d.name + name;
	};
	const datasetNames = data.datasets.map(d => getName(d)).filter(unique);
	const colors = getColors(datasetNames.length, true);
	const datasetColors = datasetNames.map((name, i) => ({
		name,
		color: colors[i]
	}));

	const getDataIndicator = (filterIndicator: string): GroupedByDataset[] => {
		const parseDataset = (dataset: DashboardComparisonData['datasets'][number]): ParsedDataset => {
			const value = dataset.equipments.reduce((accEquipment, equipment) => {
				if (selectDomain !== 'all' && equipment.domain !== selectDomain) return accEquipment;
				return accEquipment + equipment.impacts.reduce((accImpact, impact) => {
					return accImpact + getImpactByIndicator(getImpactByLifeCycleStep(impact, selectLifeCycleStep), filterIndicator, indicators).value;
				}, 0);
			}, 0);
			return {
				dataset,
				value: valueFixedDigits(parseByFootprintTypeDashboard(selectFootprintType, dataset, value))
			};
		};

		const reduceToGroupedDataset = (acc: GroupedByDataset[], dataset: ParsedDataset) => {
			const datasetName = dataset.dataset.name;
			const studyName = dataset.dataset.study;
			const studyYear = dataset.dataset.year;
			const companyName = dataset.dataset.company;

			const find = acc.find(d => d.name === datasetName && d.study === studyName && d.company === companyName);
			if (find) {
				const findDataset = find.values.find(a => a.year === studyYear);
				if (findDataset) {
					findDataset.value.push(dataset.value);
				} else {
					find.values.push({
						year: studyYear,
						value: [dataset.value]
					});
				}
			} else {
				acc.push({
					name: datasetName,
					study: studyName,
					company: companyName,
					values: [{
						year: studyYear,
						value: [dataset.value]
					}]
				});
			}
			return acc;
		};

		const valuesByDataset = data.datasets.map(parseDataset);

		const tempData =  valuesByDataset.reduce(reduceToGroupedDataset, []);

		if (benchmark) {
			const benchmarkDatasets = benchmark.data.datasets
				.map(d => ({
					...d,
					referenceFlux: benchmark.data.datasets
						.filter(c => c.year === d.year)
						.reduce((acc, c) => acc + c.referenceFlux, 0)
				})).map(parseDataset);
			const benchmarkGroupedDatasets = benchmarkDatasets.reduce(reduceToGroupedDataset, []);
			tempData.push(...benchmarkGroupedDatasets);
		}

		return tempData;
	};

	const graphData: {
		indicator: IIndicator,
		graph: (DashboardIndicatorsEvolutionGraphDataLine | null)[]
	}[] = indicators?.filter(
		indicator => selectedIndicators.includes(indicator.id) || selectedIndicators.includes('all')
	).map(indicator => ({
		indicator,
		graph: getDataIndicator(indicator.id).map((e) => ({
			label: getName(e),
			color: datasetColors.find(d => d.name === getName(e))?.color ?? 'black',
			dashed: !datasetColors.find(d => d.name === getName(e)),
			values: e.values.map((v) => ({
				scope: v.year,
				value: v.value.reduce((a, c) => a + c, 0) / v.value.length
			}))
		}))
	})) ?? [];

	const years = graphData
		.filter(e => e.graph)
		.map(e => e.graph.map(g => g?.values.map(v => v.scope)))
		.flat()
		.flat()
		.filter(unique)
		.sort((a, b) => a && b ? parseInt(a) - parseInt(b) : 0);

	let benchYearsCorresponding = true;
	if (benchmark) {
		benchYearsCorresponding = benchmark.data.datasets.some(d => years.includes(d.year));
	}

	useEffect(() => {
		if (!benchYearsCorresponding) {
			setOpenModalBenchYearsNotCorresponding(true);
			setBenchmark(undefined);
		}
	}, [benchYearsCorresponding]);

	const saveData = {
		indicators: data.indicators,
		lifeCycleSteps: data.lifeCycleSteps,
		footprintType: selectFootprintType,
		indicator: '',
		domain: selectDomain,
		lifeCycleStep: selectLifeCycleStep,
		graph: graphData,
		reference
	};

	return <div className="dashboard_card dashboard_card_color_yellow">
		<h3>{translate('dashboard.title.perimetersEvolution')}</h3>
		<div className="dashboard_card_content">
			<select
				value={selectFootprintType}
				onChange={e => setSelectFootprintType(parseInt(e.target.value))}
			>
				<option value={0}>{translate('dashboard.select.globalFootprint')}</option>
				<option value={1}>{translate('dashboard.select.footprintPerUser')}</option>
			</select>
			<DashboardIndicatorsSelector indicators={indicators} selectedIndicators={selectedIndicators} setSelectedIndicators={setSelectedIndicators} />
			<select
				value={selectDomain}
				onChange={(e) => setSelectDomain(e.target.value)}
			>
				<option value={'all'}>{translate('dashboard.select.allDomains')}</option>
				{domains?.map((domain) => (
					<option key={domain.id} value={domain.id}>{domain.name}</option>
				))}
			</select>
			<select
				value={selectLifeCycleStep}
				onChange={(e) => setSelectLifeCycleStep(e.target.value)}
			>
				<option value={'all'}>{translate('dashboard.select.allLifeCycleSteps')}</option>
				{lifeCycleSteps?.map((lifeCycleStep) => (
					<option key={lifeCycleStep.id} value={lifeCycleStep.id}>{lifeCycleStep.name}</option>
				))}
			</select>
			{!isLineMode && <DashboardSelectReference benchmark={benchmark} data={data} reference={reference} setReference={setReference} isEvolution={true}/>}
		</div>
		<div className="dashboard_card_content">
			<div className="dashboard_card_content_actions">
				<button type="button" className="button_blank dashboard_action" onClick={defineBenchmark}>
					<div className="dashboard_icon">
						<i className="fa-solid fa-scale-balanced"/>
					</div>
					<div className="dashboard_text">
						{benchmark && translate('dashboard.action.benchmark.remove')}
						{!benchmark && translate('dashboard.action.benchmark.add')}
					</div>
				</button>
				<button
					type="button"
					className="button_blank dashboard_action"
					onClick={() => setShowHistogram(!histogram)}
				>
					<div className="dashboard_icon">
						{histogram && <i className="fa-solid fa-chart-column"/>}
						{!histogram && <i className="fa-solid fa-table"/>}
					</div>
					<div className="dashboard_text">
						{histogram && translate('dashboard.action.histogram.hide')}
						{!histogram && translate('dashboard.action.histogram.show')}
					</div>
				</button>
				<button
					type="button"
					className="button_blank dashboard_action"
					onClick={() => setShowTable(!showTable)}
				>
					<div className="dashboard_icon">
						{showTable && <i className="fa-solid fa-chart-column"/>}
						{!showTable && <i className="fa-solid fa-table"/>}
					</div>
					<div className="dashboard_text">
						{showTable && translate('dashboard.action.graph')}
						{!showTable && translate('dashboard.action.table')}
					</div>
				</button>
				<DashboardPinButton data={saveData} type={'DashboardIndicatorsEvolution'}/>
			</div>
			<DashboardIndicatorsEvolutionGraph data={saveData} showTable={showTable} histogram={histogram} />
		</div>
	</div>;
};

export default DashboardIndicatorsEvolution;