import { translate } from '../../infrastructure/translations/translate';
import React from 'react';
import { IModal } from '../../domain/interfaces/IModal';
import { useNavigate } from 'react-router-dom';
import { useModal } from '../../viewComponents/modal/useModal';
import { useDashboards } from '../../viewComponents/hooks/useDashboards';

export const createDashboardSaveDataModal = (open: boolean, setOpen: (value: boolean) => void, type: string, data: unknown): IModal | undefined => {
	const { entity } = useDashboards();
	const [name, setName] = React.useState<string>('');
	const [loading, setLoading] = React.useState<boolean>(false);

	if (!open) return;

	const clear = () => {
		setName('');
		setLoading(false);
		useModal(false, { body: undefined, header: undefined, visible: false });
		setOpen(false);
	};

	const save = () => {
		setLoading(true);
		entity?.mutateEditDashboard({
			id: 'new',
			name,
			type,
			data: JSON.stringify(data),
		}).then(() => {
			setTimeout(() => {
				clear();
			}, 200);
		});
	};

	const body = (<>
		<div className="dashboard_save_modal">
			<label htmlFor="name">
				{translate('dashboard.name.of.view')}
			</label>
			<input
				id="name"
				type="text"
				value={name}
				onChange={(e) => setName(e.target.value)}
			/>
		</div>
	</>);

	return {
		action: save,
		actionText: 'save',
		body,
		header: undefined,
		load: loading,
		onClose(): void {
			clear();
		},
		visible: true
	};
};

export const DashboardSaveDataButton = ({ type, data }: { type: string, data: unknown }): JSX.Element => {
	const [openModal, setOpenModal] = React.useState<boolean>(false);

	useModal(openModal, createDashboardSaveDataModal(openModal, setOpenModal, type, data));

	return (<button
		type={'button'}
		className={'button_primary'}
		onClick={() => setOpenModal(true)}
		title={translate('dashboard.comparison.saveButton') as string}
	>
		<i className={'fa-solid fa-save'}/>
	</button>);
};

export const createDeleteDashboardModal = (open: boolean, setOpen: (value: boolean) => void, dashboardId?: string): IModal | undefined  => {
	const navigate = useNavigate();
	const { entity: dashboardEntity } = useDashboards();

	if (!open || !dashboardId) return;

	const clear = () => {
		useModal(false, { body: undefined, header: undefined, visible: false });
		setOpen(false);
	};

	const deleteDashboard = () => {
		dashboardEntity?.mutateEditDashboard({
			id: dashboardId,
			name: '',
			type: '',
			data: '{}',
			delete: true
		}).then(() => {
			clear();
			navigate('/dashboard/overview');
		});
	};

	const body = (<>
		<div className="dashboard_save_modal">
			{translate('dashboard.comparison.deleteModal')}
		</div>
	</>);

	return {
		action: deleteDashboard,
		actionText: 'delete',
		body,
		header: undefined,
		load: false,
		onClose(): void {
			clear();
		},
		visible: true
	};
};

export const DeleteDashboardButton = ({ dashboardId }: { dashboardId?: string }): JSX.Element => {
	const [openModal, setOpenModal] = React.useState<boolean>(false);

	useModal(openModal, createDeleteDashboardModal(openModal, setOpenModal, dashboardId));

	return (<button
		type={'button'}
		className={'button_danger'}
		onClick={() => setOpenModal(true)}
		aria-label={translate('dashboard.comparison.deleteButton') as string}
		title={translate('dashboard.comparison.deleteButton') as string}
	>
		<i className={'fa-solid fa-trash'}/>
	</button>);
};