import { gql } from '@apollo/client';

export const MUTATE_EQUIPMENT_CATEGORIES_ORDER = gql`
    mutation MutateEquipmentCategoriesOrder(
        $categoriesList: [String!]!
    ){
        mutateEquipmentCategoriesOrder(
            categoriesList: $categoriesList
        ){
            status
        }
    }
`;
