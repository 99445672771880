import { gql } from '@apollo/client';

export const QUERY_COUNTRIES_OF_USE = gql`
	query countriesOfUse {
		countriesOfUse {
			code
			name
		}
	}
`;
