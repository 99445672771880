import { useSelector } from 'react-redux';
import { State } from '../../store';
import { ILifeCycleStep } from '../../domain/interfaces/IQueryLifeCycleAssessmentParameters';
import { LifeCycleStepEntity } from '../../domain/entities/LifeCycleStepEntity';

export const useLifeCycleSteps = (selected?: string[]): { entity: LifeCycleStepEntity | undefined, lcs: ILifeCycleStep[] } => {
	const { data: entity } = useSelector((state: State) => state.dataLifeCycleStatus);
	const lcs = entity?.get() ?? [];
	if (!selected) return { entity, lcs };
	return { entity, lcs: lcs.filter((l: ILifeCycleStep) => selected.includes(l.id)) };
};