import { gql } from '@apollo/client';

export const QUERY_STUDY = gql`
    query QueryStudy(
        $studyId: String!
    ){
        study(studyId: $studyId) {
            companyFk {
                id,
                name,
                emailDomains,
            },
            year,
            name,
            id,
            archived,
            datasetsId,
            checklist,
            functionalUnit {
                id,
                name,
                private,
                checklist
            }
        }
    }
`;
