import { gql } from '@apollo/client';

export const QUERY_LCA_INVENTORY = gql`
    query QueryLcaInventory{
        lcaModels {
            inventories {
                id
                name
                description
                organizedEquipments {
                    id
                    equipmentType
                    block
                }
                organizedSettings {
                    id
                    settingType
                    block
                }
            }
        }
    }
`;
