import { ApolloClientProvider } from '../ApolloClient/ApolloClientProvider';
import { IQueryDashboardInput, IQueryDashboardOutput } from '../../domain/interfaces/IQueryDashboard';
import {
	IQueryDashboardComparisonBenchmarkInput,
	IQueryDashboardComparisonBenchmarkOutput
} from '../../domain/interfaces/IQueryDashboardComparisonBenchmark';
import {
	IQueryDashboardComparisonDataInput,
	IQueryDashboardComparisonDataOutput
} from '../../domain/interfaces/IQueryDashboardComparisonData';
import { IQueryDashboardListOutput } from '../../domain/interfaces/IQueryDashboardList';
import { IAPIProps } from '../../domain/interfaces/IAPIProps';
import { IMutateShareDashboardInput, IMutateShareDashboardOutput } from '../../domain/interfaces/IMutateShareDashboard';
import { IMutateEditDashboardInput, IMutateEditDashboardOutput } from '../../domain/interfaces/IMutateEditDashboard';
import {
	IQueryUsersCanAccessDashboardInput,
	IQueryUsersCanAccessDashboardOutput
} from '../../domain/interfaces/IQueryDashboardUsersCanAccess';

export class ApolloClientMutateShareDashboard extends ApolloClientProvider<IMutateShareDashboardOutput, IMutateShareDashboardInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateShareDashboardInput): Promise<IMutateShareDashboardOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}
export class ApolloClientQueryDashboard extends ApolloClientProvider<IQueryDashboardOutput, IQueryDashboardInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IQueryDashboardInput): Promise<IQueryDashboardOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateEditDashboard extends ApolloClientProvider<IMutateEditDashboardOutput, IMutateEditDashboardInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateEditDashboardInput): Promise<IMutateEditDashboardOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}
export class ApolloClientQueryDashboardComparisonBenchmark extends ApolloClientProvider<IQueryDashboardComparisonBenchmarkOutput, IQueryDashboardComparisonBenchmarkInput> {}
export class ApolloClientQueryDashboardComparisonData extends ApolloClientProvider<IQueryDashboardComparisonDataOutput, IQueryDashboardComparisonDataInput> {}
export class ApolloClientQueryDashboardList extends ApolloClientProvider<IQueryDashboardListOutput> {}
export class ApolloClientQueryUsersCanAccessDashboard extends ApolloClientProvider<IQueryUsersCanAccessDashboardOutput, IQueryUsersCanAccessDashboardInput> {}
