import React from 'react';
import './IndicatorTable.css';

export const IndicatorTable = ({ indicator, icon }: { indicator: string, icon?: string }): JSX.Element => {
	return (
		<div className={'indicator_table'} >
			{icon && <div className={'icon_indicator_table'} dangerouslySetInnerHTML={{ __html: icon }}/>}
			<div className={'indicator_table_text'}>{indicator}</div>
		</div>
	);
};
