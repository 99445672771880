import React from 'react';
import { IModal } from '../../../domain/interfaces/IModal';
import { translate } from '../../../infrastructure/translations/translate';
import { useModal } from '../../../viewComponents/modal/useModal';

export const createDashboardComparisonObjectiveModal = (
	open: boolean,
	setOpen: (value: boolean) => void,
	setObjective: (value: { percent: number, year: number }) => void
): IModal | undefined => {
	const newYear = new Date().getFullYear() + 1;
	const [percent, setPercent] = React.useState<number>(50);
	const [year, setYear] = React.useState<number>(newYear);

	if (!open) return;

	const clear = () => {
		setPercent(50);
		setYear(newYear);
		useModal(false, { body: undefined, header: '', visible: false });
	};

	const update = () => {
		setObjective({
			percent,
			year
		});
		setOpen(false);
		clear();
	};

	const body = <div className="dashboard_modal">
		<span>{translate('dashboard.objective.percent')} ({translate('required')})</span>
		<input
			type="number"
			min={0}
			max={100}
			value={percent}
			onChange={(e) => setPercent(parseInt(e.target.value))}
		/>
		<span>{translate('dashboard.objective.year')} ({translate('required')})</span>
		<input 
			type="number"
			min={1990}
			step={1}
			value={year}
			onChange={(e) => setYear(parseInt(e.target.value))}
		/>
	</div>;

	return {
		action: update,
		actionText: 'validate',
		body,
		header: translate('dashboard.modal.objective.header'),
		onClose(): void {
			setOpen(false);
			clear();
		},
		visible: true
	};
};