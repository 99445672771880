import { gql } from '@apollo/client';

export const QUERY_COLORS = gql`
    query QueryColors(
    	$studyId: String!
    	) {
    		colors (
				studyId: $studyId
			) {
				indicators {
					itemId
					color
				}
				blocks {
					itemId
					color
				}
				lifeCycleSteps {
					itemId
					color
				}
			}
    	}
`;
