import React, { useEffect, useRef } from 'react';
import './ToolTipLight.css';

export type ToolTipLightProps = {
	text?: string,
	children?: React.ReactNode,
	left?: number,
	top?: number,
	style?: React.CSSProperties,
	pos?: position
}

/**
 * @param text - text to display
 * @param children - children to display
 * @param style - style to apply to the text
 * @param pos - position of the tooltip
 * - if children is defined, text is ignored
 * - if style is defined, it is applied to the text
 * @author Yacine Bentayeb
 */
type position = 'fixed' | 'absolute' | 'relative' | 'sticky' | 'initial' | 'inherit';

export const ToolTipLight = ({ text, children, left, top, style, pos }: ToolTipLightProps) => {
	const ref = useRef<HTMLDivElement>(null);
	const [styleState, setStyleState] = React.useState<{ left: number, top: number }>();
	const updatePosition = (e?: MouseEvent) => {
		if (!ref.current) return;
		const posX = e?.clientX ?? left ?? 0;
		const posY = e?.clientY ?? top ?? 0;
		const { width, height } = ref.current?.getClientRects()[0] ?? { width: 0, height: 0 };
		const x = posX ?? left ?? 0;
		const y = posY ?? top ?? 0;
		if (x === 0 && y === 0) return;
		const marge = 5;
		const l = x + width + marge > window.innerWidth ? x - width - marge : x + marge;
		const t = y + height + marge > window.innerHeight ? y - height - marge : y + marge;

		setStyleState({ left: l, top: t });
	};
	useEffect(() => {
		updatePosition();
		document.addEventListener('mousemove', updatePosition);
		return () => {
			document.removeEventListener('mousemove', updatePosition);
		};
	},[]);
	return (
		<div ref={ref} style={{ ...styleState, position: pos ?? 'fixed', zIndex: 1000 }}>
			{styleState && <div className="tooltip_text" style={{ ...style }}>{children ?? text}</div>}
		</div>
	);
};