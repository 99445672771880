import { gql } from '@apollo/client';

export const QUERY_DASHBOARD_LIST = gql`
    query QueryDashboardList{
        dashboardList {
            id
            name
            date
            type
            userId
            sharedUsers {
                id
                email
            }
        }
    }
`;
