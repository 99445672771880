import { translate } from '../../infrastructure/translations/translate';
import { MultiChoiceDropdown, MultiChoiceDropdownProps } from '../../viewComponents/selector/MultiChoiceDropdown/MultiChoiceDropdown';
import React, { useEffect, useRef } from 'react';
import { IIndicator } from '../../domain/interfaces/IIndicator';

interface DashboardIndicatorsSelectorProps {
	indicators: IIndicator[];
	selectedIndicators: string[];
	setSelectedIndicators: (value: string[]) => void;
	allGrouped?: boolean;
}
const DashboardIndicatorsSelector = ({ indicators, selectedIndicators, setSelectedIndicators, allGrouped }: DashboardIndicatorsSelectorProps) => {
	const [isIndicatorSelectionOpen, setIsIndicatorSelectionOpen] = React.useState<boolean>(false);
	const refButton = useRef<HTMLButtonElement>(null);
	const listElem = useRef<HTMLDivElement>(null);

	const dropdownPosition = () => {
		if (!refButton.current || !listElem.current) return;
		const button = refButton.current;
		const list = listElem.current;
		const rect = button.getBoundingClientRect();
		const top = rect.top + rect.height + 5;
		const left = rect.left;
		list.style.top = `${top}px`;
		list.style.left = `${left}px`;
		list.style.maxHeight = `calc(100vh - ${top}px - 10px)`;
		list.style.maxWidth = `calc(100vw - ${left}px - 10px)`;
	};
	dropdownPosition();

	useEffect(() => {
		window.addEventListener('resize', dropdownPosition);
		window.addEventListener('scroll', dropdownPosition);
		document.addEventListener('scroll', dropdownPosition, true);
		window.addEventListener('orientationchange', dropdownPosition);
		return () => {
			window.removeEventListener('resize', dropdownPosition);
			window.removeEventListener('scroll', dropdownPosition);
			document.removeEventListener('scroll', dropdownPosition, true);
			window.removeEventListener('orientationchange', dropdownPosition);
		};
	}, []);

	const setIndicatorElement = (name: string) => {
		return <div className="dashboard_modal_dataset">
			<div className="dashboard_modal_dataset_name">{name}</div>
		</div>;
	};

	const setIndicatorObj = (indicator: IIndicator) => ({
		id: indicator.id,
		element: setIndicatorElement(indicator.name),
		selectable: true,
		dropChildren: [],
		disabled: false,
	});

	const getIndicators = (): MultiChoiceDropdownProps['list'] => {
		const list: MultiChoiceDropdownProps['list'] = [];
		indicators.forEach((indicator) => {
			list.push(setIndicatorObj(indicator));
		});
		if (allGrouped) list.unshift({
			id: 'allGrouped',
			element: setIndicatorElement(translate('dashboard.select.allIndicatorsGrouped') as string),
			selectable: true,
			dropChildren: [],
			disabled: false,
		});
		return list;
	};

	return (
		<div className={'dashboard_custom_select'}>
			<button className={'dashboard_custom_select_button'} onClick={() => setIsIndicatorSelectionOpen(!isIndicatorSelectionOpen)} ref={refButton}>
				{translate('dashboard.select.custom.indicators')}
				<i className={`fa-solid ${isIndicatorSelectionOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`}/>
			</button>
			<div className={`choice_selector_wrapper ${isIndicatorSelectionOpen ? 'open' : ''}`} ref={listElem}>
				<MultiChoiceDropdown list={getIndicators()} values={selectedIndicators} onChange={setSelectedIndicators}/>
			</div>
		</div>
	);
};

export default DashboardIndicatorsSelector;