import { gql } from '@apollo/client';

export const QUERY_USER_DASHBOARD_LIST = gql`
    query QueryUserDashboardList{
        userDashboardList{
            id
            name
            description
            userId
            sharedUsers {
                id
                email
            }
        }
    }
`;
