import { DocumentNode, OperationVariables } from '@apollo/client';
import { DataProvider } from '../DataProvider';
import { ApolloClientContext } from './ApolloClientContext';
import { IAPIProps } from '../../domain/interfaces/IAPIProps';

export class ApolloClientProvider<T extends object, S extends OperationVariables | undefined = undefined> extends DataProvider<T> {
	constructor(public query: DocumentNode, public context: typeof ApolloClientContext = ApolloClientContext) {
		super();
	}

	get({ token, lang, adapter }: IAPIProps, data: S | undefined = undefined): Promise<T | null |  undefined> {
		return new Promise((resolve, reject) => {
			(new this.context(token, lang, adapter)).query<T>(this.query, data)
				.then(r => r.data).then(resolve)
				.catch(reject);
		});
	}

	set({ token, lang, adapter }: IAPIProps, data: S): Promise<T | null | undefined> {
		return new Promise((resolve, reject) => {
			(new this.context(token, lang, adapter)).mutation<T>(this.query, data as OperationVariables)
				.then(r => r.data).then(resolve)
				.catch(reject);
		});
	}

	exec({ token, lang, adapter }: IAPIProps, data: S | undefined = undefined): Promise<T | null |  undefined> {
		return this.get({ token, lang, adapter }, data);
	}
}
