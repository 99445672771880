import { IBlock } from '../domain/interfaces/IBlock';
import { IIndicator } from '../domain/interfaces/IIndicator';
import { IResultTableDataInput } from './getResultTableData';

export const getBlockName = (blockIds: string, blocks: IBlock[]): string | null => {
	return blocks?.find((block) => block.id === blockIds)?.shortName || blocks?.find((block) => block.id === blockIds)?.name || null;
};

export const getIndicatorName = (indicatorId: string, indicators: IIndicator[]): string | null => {
	const findIndicator = indicators?.find((indicator) => indicator.id === indicatorId);
	if (!findIndicator) return null;
	let short = '';
	if (findIndicator.shortName) short = ` (${findIndicator.shortName})`;
	return `${findIndicator.name}${short}`;
};

export const getUnitByIndicatorId = (indicatorId: string, indicators: IIndicator[]): string | null => {
	return indicators?.find((indicator) => indicator.id === indicatorId)?.unit || null;
};

export const getIconByIndicatorId = (indicatorId: string, indicators: IIndicator[]): string | null => {
	return indicators?.find((indicator) => indicator.id === indicatorId)?.icon || null;
};

export const getIndicShortNames = (data: IResultTableDataInput[], indicators: IIndicator[] | undefined) : (string | undefined)[] => {
	return [... new Set(data.map((item) => {
		return indicators?.find((indic: IIndicator | undefined) => indic?.id == item.indicator)?.shortName;
	}))];
};
  