import { ApolloClientProvider } from '../ApolloClient/ApolloClientProvider';
import { IMutateEditLcaInventoryInput, IMutateEditLcaInventoryOutput } from '../../domain/interfaces/IMutateEditLcaInventory';
import { IAPIProps } from '../../domain/interfaces/IAPIProps';
import {
	IMutateEditLcaMaturityInput,
	IMutateEditLcaMaturityOutput
} from '../../domain/interfaces/IMutateEditLcaMaturity';
import {
	IMutateEditLcaTemplateInput,
	IMutateEditLcaTemplateOutput
} from '../../domain/interfaces/IMutateEditLcaTemplate';
import { IQueryLcaInventoryOutput } from '../../domain/interfaces/IQueryLcaInventory';
import { IQueryLcaMaturityOutput } from '../../domain/interfaces/IQueryLcaMaturity';
import { IQueryLcaTemplateOutput } from '../../domain/interfaces/IQueryLcaTemplate';
import { IQueryLifeCycleAssessmentParametersOutput } from '../../domain/interfaces/IQueryLifeCycleAssessmentParameters';

export class ApolloClientMutateEditLcaInventory extends ApolloClientProvider<IMutateEditLcaInventoryOutput, IMutateEditLcaInventoryInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateEditLcaInventoryInput): Promise<IMutateEditLcaInventoryOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateEditLcaMaturity extends ApolloClientProvider<IMutateEditLcaMaturityOutput, IMutateEditLcaMaturityInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateEditLcaMaturityInput): Promise<IMutateEditLcaMaturityOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateEditLcaTemplate extends ApolloClientProvider<IMutateEditLcaTemplateOutput, IMutateEditLcaTemplateInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateEditLcaTemplateInput): Promise<IMutateEditLcaTemplateOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientQueryLcaInventory extends ApolloClientProvider<IQueryLcaInventoryOutput> {}
export class ApolloClientQueryLcaMaturity extends ApolloClientProvider<IQueryLcaMaturityOutput> {}
export class ApolloClientQueryLcaTemplate extends ApolloClientProvider<IQueryLcaTemplateOutput> {}
export class ApolloClientQueryLifeCycleAssessmentParameters extends ApolloClientProvider<IQueryLifeCycleAssessmentParametersOutput> {}