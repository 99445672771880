import { gql } from '@apollo/client';

export const QUERY_DATA_SETTINGS = gql`
query requestSettings(
  $datasetId: String!
)
{
  datasetSettings (id: $datasetId) {
    id,
    name,
    settings {
      id,
      name,
      help,
      priority,
      unit,
      value,
      quality,
      comment
    }
  }
}`;
