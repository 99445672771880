import React from 'react';
import './spinner.css';

const Spinner = ({ style }: { style?: React.CSSProperties }): JSX.Element => {
	return (
		<div className={'spinner'} style={style ?? {}}>

		</div>
	);
};

export default Spinner;
