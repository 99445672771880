import { IUserDashboardCard } from '../../../../../domain/interfaces/IUserDashboard';
import React from 'react';
import { translate } from '../../../../../infrastructure/translations/translate';
import { DashboardDomainComparisonGraph } from '../../../Comparison/Graphs/DashboardDomainComparison';
import { DashboardCardMoveButton } from '../../DashboardCardMoveButton';
import { DashboardCardDeleteButton } from '../../DashboardCardDeleteButton';
import { DashboardCardModifyButton } from '../../DashboardCardModifyButton';
import { useIndicators } from '../../../../../viewComponents/hooks/useIndicators';
import { useLifeCycleSteps } from '../../../../../viewComponents/hooks/useLifeCycleSteps';

export const DashboardCardDomainComparison = ({ card }: { card: IUserDashboardCard }): JSX.Element => {
	const { indicators } = useIndicators();
	const { lcs: lifeCycleSteps } = useLifeCycleSteps();
	const [showTable, setShowTable] = React.useState<boolean>(false);

	const data = JSON.parse(card.data);
	const indicator = indicators?.find((indicator) => indicator.id === data.indicator);
	const lifeCycleStep = lifeCycleSteps?.find((lifeCycleStep) => lifeCycleStep.id === data.lifeCycleStep);
	return (<div className={'dashboard_card dashboard_card_color_blue'}>
		<div className={'buttons'}>
			<button
				type={'button'}
				className={'button_blank'}
				onClick={() => setShowTable(!showTable)}
			>
				<div className="dashboard_icon"><i className="fa-solid fa-chart-column"/></div>
				<div className="text">
					{showTable && translate('dashboard.action.graph')}
					{!showTable && translate('dashboard.action.table')}
				</div>
			</button>
			<DashboardCardModifyButton card={card} />
			<DashboardCardMoveButton card={card} />
			<DashboardCardDeleteButton card={card} />
		</div>
		<h3>{card.name}</h3>
		<div className="dashboard_card_content">
			<div className="dashboard_card_selected"><span
				className="bold">{translate('dashboard.card.selected.footprintType')}</span> : {translate(data.footprintType === 1 ? 'dashboard.select.footprintPerUser' : 'dashboard.select.globalFootprint')}
			</div>
			<div className="dashboard_card_selected"><span
				className="bold">{translate('dashboard.card.selected.indicator')}</span> : {data.indicator === 'normalized' ? `${translate('dashboard.select.indicator.normalized')} - ${translate('results.valuePBCI')}` : indicator && `${indicator.name} (${indicator.shortName}) - ${indicator.unit}`}
			</div>
			<div className="dashboard_card_selected"><span
				className="bold">{translate('dashboard.card.selected.lifeCycleStep')}</span> : {data.lifeCycleStep === 'all' ? translate('dashboard.select.allLifeCycleSteps') : lifeCycleStep && lifeCycleStep.name}
			</div>
		</div>
		<div className="dashboard_card_content">
			<DashboardDomainComparisonGraph showTable={showTable} data={data}/>
		</div>
	</div>);
};