import { ApolloClientProvider } from '../ApolloClient/ApolloClientProvider';
import { IQueryIndicatorsOutput } from '../../domain/interfaces/IQueryIndicators';
import { IMutateIndicatorOrderManagementInput, IMutateIndicatorOrderManagementOutput } from '../../domain/interfaces/IMutateIndicatorOrderManagement';
import { IAPIProps } from '../../domain/interfaces/IAPIProps';
import { IQueryEquivalencesOutput } from '../../domain/interfaces/IQueryEquivalences';

export class ApolloClientQueryIndicators extends ApolloClientProvider<IQueryIndicatorsOutput> {}
export class ApolloClientQueryEquivalences extends ApolloClientProvider<IQueryEquivalencesOutput> {}
export class ApolloClientMutateIndicatorOrderManagement extends ApolloClientProvider<IMutateIndicatorOrderManagementOutput, IMutateIndicatorOrderManagementInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateIndicatorOrderManagementInput): Promise<IMutateIndicatorOrderManagementOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}
