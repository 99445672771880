import React, { Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import {
	linkDashboardEnsemble,
	linkDashboardId,
	linkDatasetDomain,
	linkDatasetElem,
	linkKanban,
	linkSampleDatasetResults,
	linkSampleResults,
	linkStaticPage,
	linkStudy,
	linkWorkflow,
	linkContact,
	linkContactStudy,
} from './linkGenerator';
import Template from '../viewComponents/template/template/template';
import ForbiddenView from '../views/ForbiddenView/ForbiddenView';
import AriaNavigation from '../viewComponents/head/ariaNavigation/ariaNavigation';
import DashboardView from '../views/DashboardView/DashboardView';
import ContactView from '../views/ContactView/ContactView';
import { useUser } from '../viewComponents/hooks/useUser';

const HomeView = React.lazy(() => import('../views/HomeView/HomeView'));
const StudyWorkflowView = React.lazy(() => import('../views/Study/StudyWorkflowView/StudyWorkflowView'));
const StudyKanbanView = React.lazy(() => import('../views/Study/StudyKanbanView/StudyKanbanView'));
const Page = React.lazy(() => import('../viewComponents/Page'));
const StaticPageView = React.lazy(() => import('../views/StaticPageView/StaticPageView'));
const UserSettingsView = React.lazy(() => import('../views/User/UserSettingsView/UserSettingsView'));
const UserManageView = React.lazy(() => import('../views/User/UserManageView/UserManageView'));
const SampleResultsView = React.lazy(() => import('../views/SampleResultsView/SampleResultsView'));

const RouterInternal = (): JSX.Element => {
	const { data: user } = useUser();
	const canManage = user?.permissions.includes('login.can_assign_user_to_equipment');
	const canHaveDashboard = user?.permissions.includes('login.can_have_dashboard');
	return (
		<BrowserRouter>
			<AriaNavigation/>
			<Routes>
				<Route index element={
					<Suspense fallback={<Template loading={true}/>}>
						<HomeView/>
					</Suspense>
				}/>
				<Route path={linkWorkflow} element={
					<Suspense fallback={<Template loading={true}/>}>
						<StudyWorkflowView/>
					</Suspense>
				}/>
				<Route path={linkKanban} element={
					<Suspense fallback={<Template loading={true}/>}>
						<StudyKanbanView/>
					</Suspense>
				}/>
				<Route path={linkStudy} element={
					<Suspense fallback={<Template loading={true}/>}>
						<StudyKanbanView/>
					</Suspense>
				}/>
				<Route path={linkDatasetElem} element={
					<Suspense fallback={<Template loading={true}/>}>
						<Page/>
					</Suspense>
				}/>
				<Route path={linkDatasetDomain} element={
					<Suspense fallback={<Template loading={true}/>}>
						<Page/>
					</Suspense>
				}/>
				<Route path={linkStaticPage} element={
					<Suspense fallback={<Template loading={true}/>}>
						<StaticPageView/>
					</Suspense>
				}/>
				<Route path={'/user/settings/:page'} element={
					<Suspense fallback={<Template loading={true}/>}>
						<UserSettingsView/>
					</Suspense>
				}/>
				<Route path={'/user/settings'} element={<Navigate to={'/user/settings/password'}/>}/>
				{canManage && <Route path={'/manage/:page'} element={
					<Suspense fallback={<Template loading={true}/>}>
						<UserManageView/>
					</Suspense>
				}/>}
				{canManage && <Route path={linkSampleResults} element={
					<Suspense fallback={<Template loading={true}/>}>
						<SampleResultsView/>
					</Suspense>
				}/>}
				{canManage && <Route path={linkSampleDatasetResults} element={
					<Suspense fallback={<Template loading={true}/>}>
						<SampleResultsView/>
					</Suspense>
				}/>}
				<Route path={linkContact} element={
					<Suspense fallback={<Template loading={true}/>}>
						<ContactView/>
					</Suspense>
				}/>
				<Route path={linkContactStudy} element={
					<Suspense fallback={<Template loading={true}/>}>
						<ContactView/>
					</Suspense>
				}/>
				{canHaveDashboard && <>
					<Route path={linkDashboardEnsemble} element={
						<Suspense fallback={<Template loading={true}/>}>
							<DashboardView/>
						</Suspense>
					} />
					<Route path={linkDashboardId} element={
						<Suspense fallback={<Template loading={true}/>}>
							<DashboardView/>
						</Suspense>
					} />
				</>}
				<Route path={'/forbidden'} element={<ForbiddenView/>}/>
				<Route path={'*'} element={<Navigate to={'/'}/>}/>
			</Routes>
		</BrowserRouter>
	);
};

export default RouterInternal;
