/**
 * Convert number to string with format separator
 * 1000.1 => 1,000.1 in english
 * @param {number} number - number to convert
 * @returns {string} - number with separator
 *
 * @author Maximilien Valenzano
 */
export function numberToStringSeparator(number: number): string {
	let maximumFractionDigits;

	if (number > 100) {
		maximumFractionDigits = 0;
	} else if (number >= 1) {
		maximumFractionDigits = 1;
	}
	return Intl.NumberFormat(navigator.language,
		{ maximumFractionDigits: maximumFractionDigits, minimumFractionDigits: 0 })
		.format(number);
}

/**
 * Fixe digit for number to a count
 * @param {number} value - the number to convert
 * @param {number} digits - the number of digits to show
 * @returns {number} - the converted number
 */
export function valueFixedDigits(value: number | undefined): number {
	if (value === undefined) return 0;
	else return Number(value >= 100 ? value.toFixed(0) : value >= 1 ? value.toFixed(1) : value);
}