import { Arrow } from '../../../viewComponents/template/arrow/arrow';
import logo from '../../../media/logo.svg';
import React from 'react';
import { translate } from '../../../infrastructure/translations/translate';
import '../LoginView.css';
import './RedirectHandlerView.css';
import { useSelector } from 'react-redux';
import { State } from '../../../store';

const RedirectHandlerView = (): JSX.Element => {
	const version = useSelector((state: State) => state.version);
	return (
		<section>
			<div className={'login_container'}>
				<Arrow color={'#2f9d68'} size={'large'}/>
				<div className={'login'}>
					<div className={'login_header'}>
						<img src={logo} height='50px' alt={'resilio logo'}/>
						<b>resilio</b>
					</div>

					<div className={'login_redirect_body'}>
						<h3 className={'connect'}>{translate('login.sso.redirect')}</h3>
					</div>

					<div className={'login_footer'}>
						<p className={'app_version'}>{version}</p>
					</div>
				</div>
			</div>
		</section>
	);
};

export default RedirectHandlerView;