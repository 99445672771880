import { IUserDashboardCard } from '../../../../../domain/interfaces/IUserDashboard';
import React from 'react';
import { translate } from '../../../../../infrastructure/translations/translate';
import { DashboardMaturityDomainComparisonGraph } from '../../../Comparison/Graphs/DashboardMaturityDomainComparison';
import { DashboardCardMoveButton } from '../../DashboardCardMoveButton';
import { DashboardCardDeleteButton } from '../../DashboardCardDeleteButton';
import { DashboardCardModifyButton } from '../../DashboardCardModifyButton';

export const DashboardCardMaturityDomainComparison = ({ card }: { card: IUserDashboardCard }) => {
	const [showTable, setShowTable] = React.useState<boolean>(false);

	const data = JSON.parse(card.data);
	return (<div className={'dashboard_card dashboard_card_color_blue'}>
		<div className={'buttons'}>
			<button
				type={'button'}
				className={'button_blank'}
				onClick={() => setShowTable(!showTable)}
			>
				<div className="dashboard_icon"><i className="fa-solid fa-chart-column"/></div>
				<div className="text">
					{showTable && translate('dashboard.action.graph')}
					{!showTable && translate('dashboard.action.table')}
				</div>
			</button>
			<DashboardCardModifyButton card={card} />
			<DashboardCardMoveButton card={card} />
			<DashboardCardDeleteButton card={card} />
		</div>
		<h3>{card.name}</h3>
		<div className="dashboard_card_content">
			<div className="dashboard_card_selected"><span className="bold">{translate('dashboard.card.selected.domain')}</span> : {data.domain === 'all' ? translate('dashboard.select.allDomains') : data.domain}</div>
		</div>
		<div className="dashboard_card_content">
			<DashboardMaturityDomainComparisonGraph showTable={showTable} data={data}/>
		</div>
	</div>);
};