import { gql } from '@apollo/client';

export const QUERY_LIFE_CYCLE_ASSESSMENT_PARAMETERS_VERSIONS = gql`
    query QueryLifeCycleAssessmentParametersVersions (
        $resultLauncherId: String!
    ) {
        launcherLcaParametersVersions(
            resultLauncherId: $resultLauncherId
        )
    }
`;
