import { gql } from '@apollo/client';

export const QUERY_MATURITY_DOMAINS = gql`
    query QueryMaturityDomains{
        maturityDomains{
            id,
            name,
            scope,
        }
    }
`;