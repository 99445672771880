import { Entity } from '../core/Entity';
import { IDashboard } from '../interfaces/IDashboard';
import {
	ApolloClientMutateEditDashboard,
	ApolloClientMutateShareDashboard,
	ApolloClientQueryDashboard,
	ApolloClientQueryDashboardComparisonBenchmark,
	ApolloClientQueryDashboardComparisonData,
	ApolloClientQueryDashboardList,
	ApolloClientQueryUsersCanAccessDashboard
} from '../../infrastructure/ApolloClass/DashboardClass';
import { IQueryDashboardComparisonBenchmarkInput, IQueryDashboardComparisonBenchmarkOutput } from '../interfaces/IQueryDashboardComparisonBenchmark';
import { IQueryDashboardComparisonDataInput, IQueryDashboardComparisonDataOutput } from '../interfaces/IQueryDashboardComparisonData';
import { QUERY_DASHBOARD } from '../../infrastructure/ApolloClient/requests/QUERY_DASHBOARD';
import { QUERY_DASHBOARD_COMPARISON_BENCHMARK } from '../../infrastructure/ApolloClient/requests/QUERY_DASHBOARD_COMPARISON_BENCHMARK';
import { QUERY_DASHBOARD_COMPARISON_DATA } from '../../infrastructure/ApolloClient/requests/QUERY_DASHBOARD_COMPARISON_DATA';
import { QUERY_DASHBOARD_LIST } from '../../infrastructure/ApolloClient/requests/QUERY_DASHBOARD_LIST';
import { QUERY_DASHBOARD_USERS_CAN_ACCESS } from '../../infrastructure/ApolloClient/requests/QUERY_DASHBOARD_USERS_CAN_ACCESS';
import { MUTATE_SHARE_DASHBOARD } from '../../infrastructure/ApolloClient/requests/MUTATE_SHARE_DASHBOARD';
import { MUTATE_EDIT_DASHBOARD } from '../../infrastructure/ApolloClient/requests/MUTATE_EDIT_DASHBOARD';
import { IMutateShareDashboardInput } from '../interfaces/IMutateShareDashboard';
import { IMutateEditDashboardInput } from '../interfaces/IMutateEditDashboard';
import { IUser } from '../interfaces/ResponseCompanyUsers';

export class DashboardEntity extends Entity {
	public data: IDashboard[] | undefined;
	private _isQuerying = false;

	initialization() {
		this.app.adapter.mutateShareDashboard ??= this.app.installer(ApolloClientMutateShareDashboard, MUTATE_SHARE_DASHBOARD);
		this.app.adapter.mutateEditDashboard ??= this.app.installer(ApolloClientMutateEditDashboard, MUTATE_EDIT_DASHBOARD);
		this.app.adapter.queryDashboard ??= this.app.installer(ApolloClientQueryDashboard, QUERY_DASHBOARD);
		this.app.adapter.queryDashboardList ??= this.app.installer(ApolloClientQueryDashboardList, QUERY_DASHBOARD_LIST);
		this.app.adapter.queryDashboardComparisonBenchmark ??= this.app.installer(ApolloClientQueryDashboardComparisonBenchmark, QUERY_DASHBOARD_COMPARISON_BENCHMARK);
		this.app.adapter.queryDashboardComparisonData ??= this.app.installer(ApolloClientQueryDashboardComparisonData, QUERY_DASHBOARD_COMPARISON_DATA);
		this.app.adapter.queryDashboardUsersCanAccess ??= this.app.installer(ApolloClientQueryUsersCanAccessDashboard, QUERY_DASHBOARD_USERS_CAN_ACCESS);
		this.app.adapter.storeDashboards?.({ loading: false, error: null, data: this });
	}

	get(): IDashboard[] | undefined {
		if (!this.data) {
			if (this._isQuerying) return;
			this.queryDashboardList();
		}
		return this.data;
	}

	change(): Promise<void> | undefined {
		if (this.data) this.data = [...this.data];
		return this.app.adapter.storeDashboards?.({ loading: false, error: null, data: this });
	}

	set(obj: IDashboard[] | undefined): void {
		if (this.data === undefined || obj === undefined) {
			this.data = obj;
		} else {
			obj.forEach((line: IDashboard) => {
				if (this.data === undefined) this.data = [];
				const dashboardIndex = this.data.findIndex(d => d.id === line.id);
				if (dashboardIndex === undefined || dashboardIndex === -1) {
					this.data.push(line);
				} else {
					this.data[dashboardIndex] = { ...this.data[dashboardIndex], ...line };
				}
			});
		}
		this.change();
	}

	update(line?: IDashboard): void {
		if (!line) return;
		if (this.data === undefined) this.data = [];
		const dashboardIndex = this.data?.findIndex(d => d.id === line.id);
		if (dashboardIndex === undefined || dashboardIndex === -1) {
			this.data.push(line);
		} else {
			this.data[dashboardIndex] = { ...this.data[dashboardIndex], ...line };
		}
		this.change();
	}

	delete(id?: string): void {
		if (!id) return;
		if (this.data === undefined) this.data = [];
		const dashboardIndex = this.data?.findIndex(d => d.id === id);
		if (dashboardIndex === undefined || dashboardIndex === -1) return;
		this.data.splice(dashboardIndex, 1);
		this.change();
	}

	private _storeError(error: Error) {
		this.app.adapter.storeDashboards?.({ loading: false, error, data: this });
	}

	/***************************************************
	 * 					API CALLS					   *
	 ***************************************************/

	async queryDashboardList(): Promise<void> {
		this._isQuerying = true;
		const data = await this.callApi(this.app.adapter.queryDashboardList).catch((error) => {
			this._storeError(error);
			this._isQuerying = false;
			return undefined;
		});
		this._isQuerying = false;
		if (!data) return;
		this.set(data.dashboardList);
	}

	async queryDashboard(id: string): Promise<void> {
		const data = await this.callApi(this.app.adapter.queryDashboard, { id });
		this.update(data.dashboard);
	}

	async queryDashboardComparisonBenchmark(input: IQueryDashboardComparisonBenchmarkInput): Promise<IQueryDashboardComparisonBenchmarkOutput['dashboardComparisonDataBenchmark']['data']> {
		const data = await this.callApi(this.app.adapter.queryDashboardComparisonBenchmark, input);
		return data.dashboardComparisonDataBenchmark.data;
	}

	async queryDashboardComparisonData(input: IQueryDashboardComparisonDataInput): Promise<IQueryDashboardComparisonDataOutput['dashboardComparisonData']['data']> {
		const data = await this.callApi(this.app.adapter.queryDashboardComparisonData, input);
		return data.dashboardComparisonData.data;
	}

	async queryDashboardUsersCanAccess(dashboardId: string): Promise<IUser[]> {
		const data = await this.callApi(this.app.adapter.queryDashboardUsersCanAccess, { dashboardId });
		return data.dashboardUsersCanAccess;
	}

	async mutateShareDashboard(input: IMutateShareDashboardInput): Promise<boolean> {
		const data = await this.callApi(this.app.adapter.mutateShareDashboard, input);
		this.update(data.mutateShareDashboard.dashboard);
		return true;
	}

	async mutateEditDashboard(input: IMutateEditDashboardInput) {
		const data = await this.callApi(this.app.adapter.mutateEditDashboard, input);
		if (!data || data.mutateEditDashboard.status !== 200) {
			return false;
		}
		if (input.delete) this.delete(input.id);
		else this.update(data.mutateEditDashboard.dashboard);
		return true;

	}
}