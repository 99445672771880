import { gql } from '@apollo/client';

export const MUTATE_EDIT_LCA_TEMPLATE = gql`
    mutation MutateEditLcaTemplate(
        $id: String!,
        $name: String!,
        $description: String!,
        $functionalUnit: String!,
        $inventory: String!,
        $maturity: String!,
        $resultList: String!,
        $indicators: [String]!,
        $lifeCycleSteps: [String]!,
        $delete: Boolean
    ){
        mutateEditLcaTemplate(
            id: $id,
            name: $name,
            description: $description,
            functionalUnit: $functionalUnit,
            inventory: $inventory,
            maturity: $maturity,
            resultList: $resultList,
            indicators: $indicators,
            lifeCycleSteps: $lifeCycleSteps,
            delete: $delete
        ){
            template {
                id
                name
                description
                functionalUnit
                inventory
                maturity
                resultList
                indicators
                lifeCycleSteps
            }
        }
    }
`;
