export function labelSize (value: string, n = 40): string | string[] {
	const words = value.split(' ');
	const results: string[] = [];
	for (const word of words) {
		if (results.length === 0) {
			results.push(word);
			continue;
		}
		const l = results[results.length - 1];
		if (`${l} ${word}`.length > n) {
			results.push(word);
			continue;
		}
		results[results.length - 1] = `${l} ${word}`;
	}
	if (results.length > 1)
		return results;
	return results[0];
}