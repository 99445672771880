import { gql } from '@apollo/client';

export const QUERY_IMPACT_FACTORS = gql`
	  query queryImpactFactors(
		$equipmentTypeId: String!
	  ) {
		  impactFactors (
			equipmentTypeId: $equipmentTypeId
		  ){
			  id
			  indicator {
				id
				name
			  }
			  lifeCycleStep {
				id
				name
			  }
			  value
          }
      }
`;
