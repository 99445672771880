import { gql } from '@apollo/client';

export const MUTATE_EDIT_USER_DASHBOARD_CARDS_ORDER = gql`
    mutation MutateEditUserDashboardCardsOrder(
        $dashboardId: String!,
        $cardIds: [String!]!
    ){
        mutateEditUserDashboardCardsOrder(
            dashboardId: $dashboardId,
            cardIds: $cardIds
        ){
            status
            userDashboard{
                id
                name
                cards{
                    id
                    name
                    dashboard {
                        id
                    }
                    type
                    data
                    order
                }
                userId
                sharedUsers {
                    id
                    email
                }
            }
        }
    }
`;
