import { Plugin } from 'chart.js';
import { GRAPH_COLORS } from '../../../config';

/**
 * Plugin to draw a circle on the polar chart, based on the limit value
 *
 * @param limit {number} - The limit value to draw the circle at
 * @param maxValue {number} - The maximum value of the chart
 */
export const PolarPluginDrawCircle = (limit: number | undefined, maxValue: number): Plugin<'polarArea'> => ({
	id: 'drawCircle',
	afterDatasetDraw(chart) {
		if (!limit) return;
		const ctx = chart.ctx;
		const chartArea = chart.chartArea;
		const centerX = (chartArea.left + chartArea.right) / 2;
		const centerY = (chartArea.top + chartArea.bottom) / 2;
		const chartRadius = (chartArea.width < chartArea.height ? chartArea.width : chartArea.height) / 2;

		const limitRadius = (limit / maxValue) * chartRadius;
		ctx.beginPath();
		ctx.arc(centerX, centerY, limitRadius, 0, 2 * Math.PI);
		ctx.strokeStyle = GRAPH_COLORS.RED;
		ctx.lineWidth = 2;
		ctx.setLineDash([5, 5]);
		ctx.stroke();
	}
});