import { ApolloClientProvider } from '../ApolloClient/ApolloClientProvider';
import { IMutateBlockOrderManagementInput, IMutateBlockOrderManagementOutput } from '../../domain/interfaces/IMutateBlockOrderManagement';
import { IAPIProps } from '../../domain/interfaces/IAPIProps';
import { IQueryBlocksResponse } from '../../domain/interfaces/IBlock';

export class ApolloClientMutateBlockOrderManagement extends ApolloClientProvider<IMutateBlockOrderManagementOutput, IMutateBlockOrderManagementInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateBlockOrderManagementInput): Promise<IMutateBlockOrderManagementOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientQueryBlocks extends ApolloClientProvider<IQueryBlocksResponse> {}