import { IUserDashboardCard } from '../../../../../domain/interfaces/IUserDashboard';
import React from 'react';
import { translate } from '../../../../../infrastructure/translations/translate';
import { DashboardEquipmentComparisonGraph } from '../../../Comparison/Graphs/DashboardEquipmentComparison';
import { DashboardCardMoveButton } from '../../DashboardCardMoveButton';
import { DashboardCardDeleteButton } from '../../DashboardCardDeleteButton';
import { DashboardCardModifyButton } from '../../DashboardCardModifyButton';

export const DashboardCardEquipmentComparisonGraph = ({ card }: { card: IUserDashboardCard }) => {
	const data = JSON.parse(card.data);
	return (<div className={'dashboard_card dashboard_card_color_blue'}>
		<div className={'buttons'}>
			<DashboardCardModifyButton card={card} />
			<DashboardCardMoveButton card={card} />
			<DashboardCardDeleteButton card={card} />
		</div>
		<h3>{card.name}</h3>
		<div className="dashboard_card_content">
			<div className="dashboard_card_selected"><span
				className="bold">{translate('dashboard.card.selected.equipment.valueType')}</span> : {translate(data.selectTypeData === 'quantity' ? 'dashboard.select.quantity' : 'dashboard.select.lifetime')}
			</div>
			<div className="dashboard_card_selected"><span
				className="bold">{translate('dashboard.card.selected.equipment.showAs')}</span> : {translate(data.selectType === 'category' ? 'dashboard.select.category' : 'dashboard.select.equipmentType')}
			</div>
			<div className="dashboard_card_selected"><span
				className="bold">{translate('dashboard.card.selected.equipment.relativity')}</span> : {translate(data.selectRelativeToUser ? 'dashboard.select.userRelative' : 'dashboard.select.noRelative')}
			</div>
			<div className="dashboard_card_selected"><span
				className="bold">{translate('dashboard.card.selected.domain')}</span> : {data.domain === 'all' ? translate('dashboard.select.allDomains') : data.domain}
			</div>
		</div>
		<div className="dashboard_card_content">
			<DashboardEquipmentComparisonGraph data={data}/>
		</div>
	</div>);
};