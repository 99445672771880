import React from 'react';
import ReactMarkdown from 'react-markdown';
import { IModal } from '../../../domain/interfaces/IModal';
import './UpdateModal.scss';
import { translate } from '../../../infrastructure/translations/translate';
import { useUser } from '../../hooks/useUser';

export const createUpdateModal = (
	open: boolean,
	setOpen: (value: boolean) => void,
	content: string
): IModal | undefined => {
	const { entity } = useUser();

	if (!open) {
		return;
	}

	const body = (<div className="update_modal_body">
		<ReactMarkdown>{content}</ReactMarkdown>
	</div>
	);

	return {
		header: translate('whatsNew') as string,
		body: body,
		onClose: () => {
			entity?.mutateUserHasSeenUpdate();
			setOpen(false);
		},
		visible: true,
		footer: <></>
	};
};