import { gql } from '@apollo/client';

export const MUTATE_STUDY_MANAGEMENT = gql`
	mutation MutateStudyManagement(
		$id: String!,
		$name: String!,
		$year: Int!,
		$company: String!,
        $functionalUnit: String!,
		$remove: Boolean!,
		$checklist: String!,
		$archived: Boolean,
        $consultants: [String],
	) {
		mutateStudyManagement(
			id: $id,
			name: $name,
			year: $year,
			company: $company,
            functionalUnit: $functionalUnit,
			remove: $remove,
			checklist: $checklist,
			archived: $archived,
            consultants: $consultants,
		) {
			status
			study {
                companyFk {
                    id
                    name
                    emailDomains
                },
                year,
                name,
                id,
                archived,
                datasetsId,
                checklist,
                consultants{
                    id,
                    email,
                    phoneNumber,
                    groups{
                        id,
                        name
                    }
                },
                functionalUnit{
                    id,
                    name,
                    checklist
                }
			}
        }
    }
`;
