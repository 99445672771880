import React from 'react';
import { Study } from '../../../domain/data/entries/Study';
import { unique } from '../../../viewComponents/results/utils/Unique';
import { Alert } from '../../../viewComponents/Alerts';
import { translate } from '../../../infrastructure/translations/translate';
import {
	formatDatasetsIds,
	MultiChoiceDropdownDatasetResultReady
} from '../../../viewComponents/utils/MultiChoiceDropdownDatasetResultReady/MultiChoiceDropdownDatasetResultReady';
import { IModal } from '../../../domain/interfaces/IModal';
import { useModal } from '../../../viewComponents/modal/useModal';
import { useDatasets } from '../../../viewComponents/hooks/useDatasets';

export const createDashboardModalSimulation = (
	open: boolean,
	setOpen: (value: boolean) => void,
	setSimulationDatasets: (value: string[]) => void,
	studies: Study[] | undefined
): IModal | undefined => {
	const { datasets } = useDatasets([]);
	const [refDatasetId, setRefDatasetId] = React.useState<string>('');
	const [selectDatasetsIds, setSelectDatasetsIds] = React.useState<string[]>([]);
	const [functionalUnit, setFunctionalUnit] = React.useState<string>('');
	const [selection, setSelection] = React.useState<'ref' | 'simu' | null>(null);

	if (!open) return;

	const clear = () => {
		setRefDatasetId('');
		setSelectDatasetsIds([]);
		setFunctionalUnit('');
		useModal(false, { visible: false, body: undefined, header: '' });
	};

	const action = () => {
		if (selection) {
			setSelection(null);
			return;
		}
		setSimulationDatasets([refDatasetId, ...selectDatasetsIds]);
		clear();
		setOpen(false);
	};

	const functionalUnits = studies?.map(s => s.functionalUnit.name).filter(unique);
	const DatasetInfoList = studies?.map(s =>
		datasets?.filter(d => d.studyId === s.id).map(d => ({
			id: d.id,
			element: <>
				<div>{s.companyFk.name}</div>
				<div>{s.name} ({s.year})</div>
				<div>{d.name}</div>
			</>
		})) ?? []
	).flat().filter(unique) ?? [];
	const refStudy: Study | undefined  = refDatasetId.length
		? studies?.find(st => formatDatasetsIds(st.datasetsId) === refDatasetId)
		: undefined;

	const DatasetInfoRefSelected = refStudy ? {
		id: formatDatasetsIds(refStudy.datasetsId) ?? '',
		element: <>
			<div>{refStudy.companyFk.name}</div>
			<div>{refStudy.name} ({refStudy.year})</div>
			<div>{translate('all.study')}</div>
		</>
	} : DatasetInfoList.find(d => d.id === refDatasetId);

	const DatasetInfoListSelected: typeof DatasetInfoList = [];
	selectDatasetsIds.forEach(s => {
		const find = DatasetInfoList.find(d => d.id === s);
		if (find) return DatasetInfoListSelected.push(find);
		const studyFind = studies?.find(st => formatDatasetsIds(st.datasetsId) === s);
		if (studyFind) return DatasetInfoListSelected.push({
			id: formatDatasetsIds(studyFind.datasetsId),
			element: <>
				<div>{studyFind.companyFk.name}</div>
				<div>{studyFind.name} ({studyFind.year})</div>
				<div>{translate('all.study')}</div>
			</>
		});
	});

	const handleFunctionalUnitChange = (value: string) => {
		setFunctionalUnit(value);
		setRefDatasetId('');
		setSelectDatasetsIds([]);
	};

	const UpdateSelectDatasetsIds = (datasetsIds: string[]) => {
		if (selection !== 'ref') {
			setSelectDatasetsIds([...datasetsIds].filter(d => d != refDatasetId).filter(unique));
		} else {
			setRefDatasetId(datasetsIds.filter(i => i != '')[0]);
			setSelection(null);
		}
	};

	const body = <div className="dashboard_modal">
		<Alert variant={'info'}>
			{translate('dashboard.alert.usedIndicatorsAndLifeCycleSteps')}
			<br/><br/>
			{translate('dashboard.alert.functionalUnit')}
		</Alert>
		<select
			id={'functionalUnit'}
			className={'dashboard_modal__select_functional_unit'}
			onChange={(e) => handleFunctionalUnitChange(e.target.value)}
			value={functionalUnit ?? undefined}
			aria-label={translate('dashboard.modal.choose.functionalUnit') as string}
		>
			<option value={''}>{translate('dashboard.modal.choose.functionalUnit')}</option>
			{functionalUnits?.map((fu, index) => <option value={fu} key={index}>{fu}</option>)}
		</select>
		{functionalUnit.length > 0 && <div className="dashboard_modal__studies">
			{!selection && <>
				<div className="dashboard_modal__selected_datasets">
					<button
						type={'button'}
						className="button_blank dashboard_modal__selected_dataset"
						onClick={!DatasetInfoRefSelected ? () => setSelection('ref') : () => setRefDatasetId('')}
					>
						{DatasetInfoRefSelected && <>
							<div
								className={'dashboard_modal__selected_dataset__title'}>{translate('dashboard.modal.reference.dataset')}</div>
							{DatasetInfoRefSelected.element}
							<i className="icon small fa-regular fa-times-circle"/>
						</>}
						{!DatasetInfoRefSelected && <>
							<div className={'dashboard_modal__selected_dataset__title'}>{translate('dashboard.modal.choose.a.reference.dataset')}</div>
							<i className="icon fa-regular fa-circle-plus" />
						</>}
					</button>
				</div>
				<div className="dashboard_modal__selected_datasets">
					{DatasetInfoListSelected.map(d => <button
						type={'button'}
						key={d.id}
						className="button_blank dashboard_modal__selected_dataset"
						onClick={() => setSelectDatasetsIds(ds => ds.filter(id => id !== d.id))}
					>
						<div className={'dashboard_modal__selected_dataset__title'}>{translate('dashboard.simulation.title')}</div>
						{d.element}
						<i className="icon small fa-regular fa-times-circle" />
					</button>)}
					<button
						type={'button'}
						className="button_blank dashboard_modal__selected_dataset"
						onClick={() => setSelection('simu')}
					>
						<div className="dashboard_modal__selected_dataset__title">{translate('dashboard.modal.simulation.add.a.perimeter')}</div>
						<i className="icon fa-regular fa-circle-plus" />
					</button>
				</div>
			</>}
			{selection && <>
				<div>{translate('dashboard.simulation.title')}</div>
				<div>
					<button
						type={'button'}
						className="mt3 mb3 button_primary"
						onClick={() => setSelection(null)}
					>
						{translate('dashboard.modal.back')}
					</button>
				</div>
				<MultiChoiceDropdownDatasetResultReady
					selectDatasetsIds={selection == 'ref' ? [refDatasetId] : selectDatasetsIds}
					setSelectDatasetsIds={UpdateSelectDatasetsIds}
					functionalUnit={functionalUnit}
					constraint={refDatasetId.length ? refDatasetId : selectDatasetsIds.length ? selectDatasetsIds[0] : undefined}
				/>
			</>}
		</div>}
	</div>;

	return {
		action,
		body,
		header: translate('dashboard.modal.choose.a.study.and.a.dataset') as string,
		visible: true,
		isButtonDisabled: !refDatasetId || selectDatasetsIds.length === 0,
		onClose(): void {
			clear();
			setOpen(false);
		}
	};
};
