import { gql } from '@apollo/client';

export const MUTATE_SAMPLES_MANAGEMENT = gql`
    mutation MUTATE_SAMPLES_MANAGEMENT(
        $id: String!,
        $name: String!,
        $desc: String!,
        $datasetsIds: [String!]!,
        $useForValidation: Boolean!,
        $private: Boolean!,
        $delete: Boolean!,
    ) {
        mutateSamplesManagement(
            id: $id,
            name: $name,
            desc: $desc,
            datasetsIds: $datasetsIds,
            useForValidation: $useForValidation,
            private: $private,
            delete: $delete,
        ) {
        	status
            samples {
            	id,
            	name,
            	desc,
            	datasets {
            	    id
            	}
            	datasetsCount
            	functionalUnit
            	private
            	useForValidation
            	indicatorIds
            }
        }
    }
`;