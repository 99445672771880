import { ApolloClientProvider } from '../ApolloClient/ApolloClientProvider';
import {
	IMutateSamplesManagementInput,
	IMutateSamplesManagementOutput
} from '../../domain/interfaces/IMutateSamplesManagement';
import { IAPIProps } from '../../domain/interfaces/IAPIProps';
import { IQuerySamplesOutput } from '../../domain/interfaces/IQuerySamples';
import { IQuerySampleListInput, IQuerySampleListOutput } from '../../domain/interfaces/IQuerySampleList';
import {
	IMutateSampleUnifyDatasetsCheckInput,
	IMutateSampleUnifyDatasetsCheckOutput
} from '../../domain/interfaces/IMutateSampleUnifyDatasetsCheck';
import {
	IMutateSampleUnifyDatasetsProceedInput,
	IMutateSampleUnifyDatasetsProceedOutput
} from '../../domain/interfaces/IMutateSampleUnifyDatasetsProceed';

export class ApolloClientMutateSamplesManagement extends ApolloClientProvider<IMutateSamplesManagementOutput, IMutateSamplesManagementInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateSamplesManagementInput): Promise<IMutateSamplesManagementOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}
export class ApolloClientMutateSampleUnifyDatasetsCheck extends ApolloClientProvider<IMutateSampleUnifyDatasetsCheckOutput, IMutateSampleUnifyDatasetsCheckInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateSampleUnifyDatasetsCheckInput): Promise<IMutateSampleUnifyDatasetsCheckOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}
export class ApolloClientMutateSampleUnifyDatasetsProceed extends ApolloClientProvider<IMutateSampleUnifyDatasetsProceedOutput, IMutateSampleUnifyDatasetsProceedInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateSampleUnifyDatasetsProceedInput): Promise<IMutateSampleUnifyDatasetsProceedOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientQuerySamples extends ApolloClientProvider<IQuerySamplesOutput> {}
export class ApolloClientQuerySampleList extends ApolloClientProvider<IQuerySampleListOutput, IQuerySampleListInput> {}