import { Equipment } from '../data/entries/Equipment';
import {
	RelatedDatasetEntity,
	Entity,
	injectInputSetter,
	InputProperty,
	ValueEntity,
	DataMutate, ObjectKey
} from '../core/Entity';
import { numNullToString } from '../../utils/numNullToString';
import { Core } from '../core/Core';
import { ResponseDataInventory } from '../data/ResponseDataInventory';
import { ResponseMutateLineInventorySave } from '../data/ResponseDatas';
import { stringToNumOrZero } from '../../utils/StringToNumOrZero';
import { ICmdbInfos } from '../interfaces/ICmdbInfos';
import {
	ApolloClientMutateAddEquipmentExplore,
	ApolloClientMutateAddEquipmentFromCmdb,
	ApolloClientMutateAddEquipmentResilioDb,
	ApolloClientMutateEquipmentEdit,
	ApolloClientMutateExportInventory,
	ApolloClientMutateInventoryLine,
	ApolloClientMutateManageCmdb,
	ApolloClientQueryCheckResilioDbStatus,
	ApolloClientQueryCmdbEquipments,
	ApolloClientQueryCmdbTemplate,
	ApolloClientQueryDataInventory
} from '../../infrastructure/ApolloClass/InventoryClass';
import { QUERY_DATA_INVENTORY } from '../../infrastructure/ApolloClient/requests/QUERY_DATA_INVENTORY';
import { MUTATE_ADD_EQUIPMENT_EXPLORE } from '../../infrastructure/ApolloClient/requests/MUTATE_ADD_EQUIPMENT_EXPLORE';
import { MUTATE_ADD_EQUIPMENT_FROM_CMDB } from '../../infrastructure/ApolloClient/requests/MUTATE_ADD_EQUIPMENT_FROM_CMDB';
import { MUTATE_MANAGE_CMDB } from '../../infrastructure/ApolloClient/requests/MUTATE_MANAGE_CMDB';
import { MUTATE_EQUIPMENT_EDIT } from '../../infrastructure/ApolloClient/requests/MUTATE_EQUIPMENT_EDIT';
import { MUTATE_ADD_EQUIPMENT_RESILIO_DB } from '../../infrastructure/ApolloClient/requests/MUTATE_ADD_EQUIPMENT_RESILIO_DB';
import { QUERY_CMDB_EQUIPMENTS } from '../../infrastructure/ApolloClient/requests/QUERY_CMDB_EQUIPMENTS';
import { QUERY_CMDB_TEMPLATE } from '../../infrastructure/ApolloClient/requests/QUERY_CMDB_TEMPLATE';
import { QUERY_CHECK_RESILIO_DB_STATUS } from '../../infrastructure/ApolloClient/requests/QUERY_CHECK_RESILIO_DB_STATUS';
import { MUTATE_INVENTORY_LINE } from '../../infrastructure/ApolloClient/requests/MUTATE_INVENTORY_LINE';
import { MUTATE_EXPORT_INVENTORY } from '../../infrastructure/ApolloClient/requests/MUTATE_EXPORT_INVENTORY';
import { store } from '../../store';
import { IQueryCheckResilioDbStatusOutput } from '../interfaces/IQueryCheckResilioDbStatus';
import { IQueryCmdbTemplateInput } from '../interfaces/IQueryCmdbTemplate';
import { IQueryCmdbEquipmentsInput } from '../interfaces/IQueryCmdbEquipments';
import { IMutateAddEquipmentExploreInput } from '../interfaces/IMutateAddEquipmentExplore';
import { IMutateAddEquipmentResilioDbInput } from '../interfaces/IMutateAddEquipmentResilioDb';
import { IMutateAddEquipmentFromCmdbInput } from '../interfaces/IMutateAddEquipmentFromCmdb';
import { IMutateEquipmentEditInput } from '../interfaces/IMutateEquipmentEdit';
import { IMutateManageCmdbInput } from '../interfaces/IMutateManageCmdb';
import { IMutateExportInventoryInput } from '../interfaces/IMutateExportInventory';
import { getNotificationErrorLevel, trimErrorLevel } from '../../utils/notificationUtils';
import { calculateLifetime } from '../../utils/tableUtils';

export class InventoryDataEntity extends Entity {
	public data: undefined | InventoryDatasetEntity[];
	private _isQuerying = false;

	initialization() {
		this.app.adapter.mutateDataInventory ??= this.app.installer(ApolloClientMutateInventoryLine, MUTATE_INVENTORY_LINE);
		this.app.adapter.mutateAddEquipmentExplore ??= this.app.installer(ApolloClientMutateAddEquipmentExplore, MUTATE_ADD_EQUIPMENT_EXPLORE);
		this.app.adapter.mutateAddEquipmentResilioDb ??= this.app.installer(ApolloClientMutateAddEquipmentResilioDb, MUTATE_ADD_EQUIPMENT_RESILIO_DB);
		this.app.adapter.mutateAddEquipmentFromCmdb ??= this.app.installer(ApolloClientMutateAddEquipmentFromCmdb, MUTATE_ADD_EQUIPMENT_FROM_CMDB);
		this.app.adapter.mutateEquipmentEdit ??= this.app.installer(ApolloClientMutateEquipmentEdit, MUTATE_EQUIPMENT_EDIT);
		this.app.adapter.mutateManageCmdb ??= this.app.installer(ApolloClientMutateManageCmdb, MUTATE_MANAGE_CMDB);
		this.app.adapter.mutateExportInventory ??= this.app.installer(ApolloClientMutateExportInventory, MUTATE_EXPORT_INVENTORY);
		this.app.adapter.queryCmdbEquipments ??= this.app.installer(ApolloClientQueryCmdbEquipments, QUERY_CMDB_EQUIPMENTS);
		this.app.adapter.queryCmdbTemplate ??= this.app.installer(ApolloClientQueryCmdbTemplate, QUERY_CMDB_TEMPLATE);
		this.app.adapter.queryCheckResilioDbStatus ??= this.app.installer(ApolloClientQueryCheckResilioDbStatus, QUERY_CHECK_RESILIO_DB_STATUS);
		this.app.adapter.queryDataInventory ??= this.app.installer(ApolloClientQueryDataInventory, QUERY_DATA_INVENTORY);
		this.app.adapter.storeDataInventory?.({ loading: false, error: null, data: this });
	}

	get(): InventoryDatasetEntity[] | undefined {
		if (!this.data) {
			if (this._isQuerying) return;
			this.queryDataInventory();
		}
		return this.data;
	}

	set(obj: ResponseDataInventory['dataset'] | undefined): void {
		this.data = obj?.map(i => new InventoryDatasetEntity(this.app, this, i));
		this.change();
	}

	addEquipments(obj: Equipment[], blockName: string): void {
		const dataset = this.data?.find(d => d.data.name === blockName);
		if (!dataset) return;
		obj.forEach((equipment) => {
			if (dataset.data.equipments.find(e => e.data.id === equipment.id)) return;
			dataset.data.equipments.push(new EquipmentEntity(this.app, dataset, equipment));
		});
		this.change();
	}

	change(): Promise<void> | undefined {
		return this.app.adapter.storeDataInventory?.({ loading: false, error: null, data: this });
	}

	update(line: ResponseMutateLineInventorySave['mutateSaveEquipmentLine']['equipment']): void {
		const equipment = this.data?.reduce<EquipmentEntity | undefined>(
			(vp, vc) => vp ?? vc.data.equipments.reduce<EquipmentEntity | undefined>(
				(ep, ec) => ep ?? (ec.data.id === line.id ? ec : undefined),
				undefined),
			undefined);
		if (!equipment) return;
		equipment.define(line);
		this.change();
	}

	updateMetadataById(blocks: ResponseMutateLineInventorySave['mutateSaveEquipmentLine']['blocks']): void {
		blocks.forEach((block) => {
			const dataset = this.data?.find(d => d.data.id === block.id);
			if (dataset) dataset.data.metadata = block.metadata;
		});
		this.change();
	}

	remove(id: string): void {
		const entity = this.data?.find(d => d.data.equipments.find(e => e.data.id === id));
		if (!entity) return;
		entity.remove(id);
		this.change();
	}

	removeCmdb(id: string): void {
		const entity = this.data?.find(d => d.data.cmdb.find(c => c.id === id));
		if (!entity) return;
		entity.removeCmdb(id);
		this.change();
	}

	private _storeError(error: Error) {
		this.app.adapter.storeDataInventory?.({
			loading: false,
			error: error ?? new Error('Authentication Fail'),
			data: this
		});
	}

	/***************************************************
	 * 					API CALLS					   *
	 ***************************************************/
	async queryDataInventory() {
		this._isQuerying = true;
		await this.app.adapter.storeDataInventory?.({ loading: true, error: null, data: undefined });
		const data = await this.callApi(this.app.adapter.queryDataInventory, { datasetId: store.getState().datasetID || '' }).catch(err => {
			this._storeError(err);
			this._isQuerying = false;
			return undefined;
		});
		this._isQuerying = false;
		if (!data) return;
		this.set(data.dataset);
	}

	async queryCheckResilioDbStatus(): Promise<IQueryCheckResilioDbStatusOutput> {
		return this.callApi(this.app.adapter.queryCheckResilioDbStatus);
	}

	async queryCmdbTemplate(input: IQueryCmdbTemplateInput) {
		return this.callApi(this.app.adapter.queryCmdbTemplate, input);
	}

	async queryCmdbEquipments(input: IQueryCmdbEquipmentsInput) {
		const data = await this.callApi(this.app.adapter.queryCmdbEquipments, input);
		this.addEquipments(data.cmdbEquipments, input.blockName);
		return data;
	}

	async mutateInventoryLine(input: DataMutate<Equipment>) {
		const data = await this.callApi(this.app.adapter.mutateDataInventory, input).catch((err) => {
			this._storeError(new Error(err));
			return undefined;
		});
		if (!data || data.mutateSaveEquipmentLine.status !== 200) {
			return false;
		}
		this.update(data.mutateSaveEquipmentLine.equipment);
		this.updateMetadataById(data.mutateSaveEquipmentLine.blocks);
		return data.mutateSaveEquipmentLine.status === 200;
	}

	async mutateAddEquipmentExplore(input: IMutateAddEquipmentExploreInput) {
		const data = await this.callApi(this.app.adapter.mutateAddEquipmentExplore, input);
		this.set(data.mutateAddEquipment.data);
	}

	async mutateExportInventory(input: IMutateExportInventoryInput) {
		const data = await this.callApi(this.app.adapter.mutateExportInventory, input);
		return data.mutateExportInventory.base64;
	}

	async mutateAddEquipmentResilioDb(input: IMutateAddEquipmentResilioDbInput) {
		const data = await this.callApi(this.app.adapter.mutateAddEquipmentResilioDb, input);
		if (!data || data.mutateAddEquipmentResilioDb.status !== 200) {
			if (data && data.mutateAddEquipmentResilioDb.errors.length > 0) {
				let errorMessage = '';
				data.mutateAddEquipmentResilioDb.errors.forEach((error: string) => {
					errorMessage += error;
				});
				throw new Error(errorMessage);
			}
			else throw new Error(this.errorMessage ?? 'Something went wrong');
		}
		this.set(data.mutateAddEquipmentResilioDb.data);
	}

	async mutateAddEquipmentFromCmdb(input: IMutateAddEquipmentFromCmdbInput, blockId: string | null) {
		const data = await this.callApi(this.app.adapter.mutateAddEquipmentFromCmdb, input);
		if (!data || data.mutateAddEquipmentFromCmdb.status !== 200) {
			const modalData = store.getState().dataModalStatus.data;
			const rejectedRows = data.mutateAddEquipmentFromCmdb.rejectedRows;
			const error = data.mutateAddEquipmentFromCmdb.error;
			if (!error) throw new Error(this.errorMessage ?? 'Something went wrong');
			if (!modalData?.visible || (modalData?.visible && modalData?.identifier !== 'addEquipmentModal')) {
				this.app.adapter.storeUserNotification?.({
					title: 'inventory.addEquipment.modal.cmdb.importError.title',
					message: error?.length > 0 ? trimErrorLevel(error) : 'inventory.addEquipment.modal.cmdb.importError.message',
					footer: false,
					level: getNotificationErrorLevel(error),
					listToDisplay: rejectedRows ?? undefined,
					titleListToDisplay: 'inventory.cmdb.notification.partiallyImportedRows',
				});
			}
			// We still add the equipments that have been imported successfully
			if (data.mutateAddEquipmentFromCmdb.data && data.mutateAddEquipmentFromCmdb.data.length > 0)
				this.set(data.mutateAddEquipmentFromCmdb.data);
			if (error?.length > 0) throw new Error(error);
		}
		let redirectLink: string;
		if (blockId && input.datasetId) {
			redirectLink = `/inventory/study/${input.studyId}/dataset/${input.datasetId}/domain/${blockId}`;
		} else if (input.datasetId) {
			redirectLink = `/overview/study/${input.studyId}/dataset/${input.datasetId}`;
		} else {
			redirectLink = `/study/${input.studyId}`;
		}
		this.app.adapter.storeUserNotification?.({
			title: 'inventory.addEquipment.modal.cmdb.importSuccess.title',
			message: 'inventory.addEquipment.modal.cmdb.importSuccess.message',
			redirect: redirectLink,
			footer: true
		});
		this.set(data.mutateAddEquipmentFromCmdb.data);
	}

	async mutateEquipmentEdit(input: IMutateEquipmentEditInput) {
		const data = await this.callApi(this.app.adapter.mutateEquipmentEdit, input);
		if (!data || data.mutateEquipmentEdit.status !== 200) {
			throw new Error(this.errorMessage ?? 'Something went wrong');
		}
		if (data.mutateEquipmentEdit.equipment) this.update(data.mutateEquipmentEdit.equipment);
		else this.remove(input.id);
	}

	async mutateManageCmdb(input: IMutateManageCmdbInput) {
		const data = await this.callApi(this.app.adapter.mutateManageCmdb, input);
		if (!data || data.mutateManageCmdb.status !== 200) {
			if (data && data.mutateManageCmdb.errors.length > 0) {
				let errorMessage = '';
				data.mutateManageCmdb.errors.forEach((error: string) => {
					errorMessage += error;
				});
				throw new Error(errorMessage);
			}
			else throw new Error(this.errorMessage ?? 'Something went wrong');
		}
		if (input.delete) {
			// Remove all equipments related to the cmdb
			const cmdbs = this.data?.map(d => d.data.cmdb.find(c => c.id === input.cmdbId));
			if (!cmdbs) return;
			cmdbs.forEach((cmdb) => cmdb && this.removeCmdb(cmdb.id));
			return;
		}
		const inventoryDataset = this.data?.find(d => d.data.cmdb.find(c => c.id === input.cmdbId));
		if (!inventoryDataset) return;

		// Update cmdb infos
		inventoryDataset.data.cmdb = data.mutateManageCmdb.data.find(d => d.cmdb.find(c => c.id === input.cmdbId))?.cmdb ?? [];
		this.change();

		const equipmentsModified = data.mutateManageCmdb.data.find(d => d.cmdb.find(c => c.id === input.cmdbId))?.equipments;
		if (!equipmentsModified || !equipmentsModified.length) return;
		equipmentsModified.forEach((equipment) => {
			this.update(equipment);
		});

	}
}

export class InventoryDatasetEntity extends RelatedDatasetEntity {
	public data: { id: string, name: string, shortName: string, equipments: EquipmentEntity[], cmdb: ICmdbInfos[], priority: boolean, metadata: { equipmentRate: number | null } };

	constructor(app: Core, parent: InventoryDataEntity, obj: ResponseDataInventory['dataset'][number]) {
		super(app, parent);
		this.data = {
			...obj,
			equipments: obj.equipments.map(e => new EquipmentEntity(app, this, e))
		};
	}

	remove(id: string): void {
		this.data.equipments = this.data.equipments.filter(e => e.data.id !== id);
	}

	removeCmdb(id: string): void {
		this.data.cmdb = this.data.cmdb.filter(c => c.id !== id);
	}

	/*paste(equipment: EquipmentEntity, name: keyof Equipment, value: string): void {
		let idx = this.data.equipments.indexOf(equipment) - 1;
		const propIdx = this.data.equipments[idx].tablePasteOrder.indexOf(name);
		const block = value.trim();
		let n;
		for (const line of block.split('\r\n')) {
			idx++;
			n = propIdx - 1;
			if (!this.data.equipments[idx]) continue;
			for (const entry of line.split('\t')) {
				n++;
				const prop = this.data.equipments[idx].tablePasteOrder[n];
				if (!prop) continue;
				const input = this.data.equipments[idx].data[prop];
				if (typeof input === 'object') input.set(entry);
			}
		}
	}*/
}

export class EquipmentEntity extends ValueEntity {
	public data: InputProperty<Equipment>;
	public readonly tablePasteOrder: (keyof InputProperty<Equipment>)[] = ['quantity', 'internalLifetime', 'reusePart', 'reuseLifetime', 'lifetime', 'quality'];

	constructor(app: Core, parent: InventoryDatasetEntity, obj: Equipment) {
		super(app, parent);
		this.data = this.define(obj);
	}

	define(obj: Equipment): InputProperty<Equipment> {
		const update = {
			...obj,
			allocationFactor: injectInputSetter('allocationFactor', numNullToString(obj.allocationFactor), this),
			quantity: injectInputSetter('quantity', numNullToString(obj.quantity), this),
			unknown: injectInputSetter('unknown', obj.unknown ? 'true' : 'false', this),
			quality: injectInputSetter('quality', numNullToString(obj.quality), this),
			lifetime: injectInputSetter('lifetime', numNullToString(obj.lifetime), this),
			internalLifetime: injectInputSetter('internalLifetime', numNullToString(obj.internalLifetime), this),
			reusePart: injectInputSetter('reusePart', numNullToString(obj.reusePart), this),
			reuseLifetime: injectInputSetter('reuseLifetime', numNullToString(obj.reuseLifetime), this),
			customElectricityConsumption: injectInputSetter('customElectricityConsumption', numNullToString(obj.customElectricityConsumption), this),
			comment: injectInputSetter('comment', obj.comment ?? '', this),
			flag: injectInputSetter('flag', obj.flag ? 'true' : 'false', this),
		};
		if (this.isChanged.includes('allocationFactor')) update.allocationFactor.set(this.data.allocationFactor.get());
		if (this.isChanged.includes('quantity')) update.quantity.set(this.data.quantity.get());
		if (this.isChanged.includes('unknown')) update.unknown.set(this.data.unknown.get());
		if (this.isChanged.includes('quality')) update.quality.set(this.data.quality.get());
		if (this.isChanged.includes('lifetime')) update.lifetime.set(this.data.lifetime.get());
		if (this.isChanged.includes('internalLifetime')) update.internalLifetime.set(this.data.internalLifetime.get());
		if (this.isChanged.includes('reusePart')) update.reusePart.set(this.data.reusePart.get());
		if (this.isChanged.includes('reuseLifetime')) update.reuseLifetime.set(this.data.reuseLifetime.get());
		if (this.isChanged.includes('customElectricityConsumption')) update.customElectricityConsumption.set(this.data.customElectricityConsumption.get());
		if (this.isChanged.includes('comment')) update.comment.set(this.data.comment.get());
		if (this.isChanged.includes('flag')) update.flag.set(this.data.flag.get());
		this.data = update;
		return this.data;
	}

	change(name: ObjectKey, change: boolean) {
		super.change(name, change);

		// Calculate lifetime only if one of the following fields is changed
		if (![ 'internalLifetime', 'reusePart', 'reuseLifetime' ].includes(name as string)) return;
		const actualLifetime = this.data.lifetime.get().length ? parseFloat(this.data.lifetime.get()) : null;
		// Update lifetime if a change is detected
		const lifetime = this._calculateLifetime();
		if (actualLifetime !== lifetime) this.data.lifetime.set(numNullToString(lifetime));
	}

	private _calculateLifetime(): number | null {
		const ilt = stringToNumOrZero(this.data.internalLifetime.get());
		const rp = stringToNumOrZero(this.data.reusePart.get());
		const rlt = stringToNumOrZero(this.data.reuseLifetime.get());
		const lifetime = calculateLifetime(ilt, rp, rlt);
		return lifetime == 0 ? null : lifetime;
	}

	save(): void {
		this.app.entities.inventory.mutateInventoryLine({
			id: this.data.id,
			values: {
				allocationFactor: this.data.allocationFactor.get().length ? parseFloat(this.data.allocationFactor.get()) : 1,
				quantity: this.data.quantity.get().length ? parseFloat(this.data.quantity.get()) : null,
				unknown: this.data.unknown.get() === 'true',
				quality: this.data.quality.get().length ? parseFloat(this.data.quality.get()) : null,
				lifetime: this.data.lifetime.get().length ? parseFloat(this.data.lifetime.get()) : null,
				internalLifetime: this.data.internalLifetime.get().length ? parseFloat(this.data.internalLifetime.get()) : null,
				reusePart: this.data.reusePart.get().length ? parseFloat(this.data.reusePart.get()) : null,
				reuseLifetime: this.data.reuseLifetime.get().length ? parseFloat(this.data.reuseLifetime.get()) : null,
				customElectricityConsumption: this.data.customElectricityConsumption.get().length ? parseFloat(this.data.customElectricityConsumption.get()) : null,
				comment: this.data.comment.get(),
				flag: this.data.flag.get() === 'true',
			}
		}).then((hasChanged) => {
			this.event.emit('change', hasChanged);
		});
	}
}
