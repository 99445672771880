import { ApolloClientProvider } from '../ApolloClient/ApolloClientProvider';
import { IQueryUserDashboardInput, IQueryUserDashboardOutput } from '../../domain/interfaces/IQueryUserDashboard';
import { IAPIProps } from '../../domain/interfaces/IAPIProps';
import { IQueryUserDashboardListOutput } from '../../domain/interfaces/IQueryUserDashboardList';
import {
	IMutateEditUserDashboardCardInput,
	IMutateEditUserDashboardCardOutput
} from '../../domain/interfaces/IMutateEditUserDashboardCard';
import {
	IMutateEditUserDashboardCardOrderInput,
	IMutateEditUserDashboardCardOrderOutput
} from '../../domain/interfaces/IMutateEditUserDashboardCardOrder';
import {
	IMutateEditUserDashboardInput,
	IMutateEditUserDashboardOutput
} from '../../domain/interfaces/IMutateEditUserDashboard';
import {
	IMutateShareUserDashboardInput,
	IMutateShareUserDashboardOutput
} from '../../domain/interfaces/IMutateShareUserDashboard';
import {
	IQueryUsersCanAccessUserDashboardInput,
	IQueryUsersCanAccessUserDashboardOutput
} from '../../domain/interfaces/IQueryUserDashboardUsersCanAccess';

export class ApolloClientQueryUserDashboard extends ApolloClientProvider<IQueryUserDashboardOutput, IQueryUserDashboardInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IQueryUserDashboardInput): Promise<IQueryUserDashboardOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateEditUserDashboardCard extends ApolloClientProvider<IMutateEditUserDashboardCardOutput, IMutateEditUserDashboardCardInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateEditUserDashboardCardInput): Promise<IMutateEditUserDashboardCardOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateEditUserDashboardCardOrder extends ApolloClientProvider<IMutateEditUserDashboardCardOrderOutput, IMutateEditUserDashboardCardOrderInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateEditUserDashboardCardOrderInput): Promise<IMutateEditUserDashboardCardOrderOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateEditUserDashboard extends ApolloClientProvider<IMutateEditUserDashboardOutput, IMutateEditUserDashboardInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateEditUserDashboardInput): Promise<IMutateEditUserDashboardOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateShareUserDashboard extends ApolloClientProvider<IMutateShareUserDashboardOutput, IMutateShareUserDashboardInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateShareUserDashboardInput): Promise<IMutateShareUserDashboardOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientQueryUserDashboardList extends ApolloClientProvider<IQueryUserDashboardListOutput> {}
export class ApolloClientQueryUsersCanAccessUserDashboard extends ApolloClientProvider<IQueryUsersCanAccessUserDashboardOutput, IQueryUsersCanAccessUserDashboardInput> {}