import { gql } from '@apollo/client';

export const MUTATE_SEND_SUGGESTIONS = gql(`
	mutation SendSuggestionMutation(
		$suggestion: String!,
		$topic: String!,
		$channel: String
	) {
		mutateSendSuggestion(
			suggestion: $suggestion,
			topic: $topic,
			channel: $channel
		) {
			status
		}
	}
`);