import { Entity } from '../core/Entity';
import { IIndicator } from '../interfaces/IIndicator';
import { IQueryIndicatorsOutput } from '../interfaces/IQueryIndicators';
import { IMutateIndicatorOrderManagementInput, IMutateIndicatorOrderManagementOutput } from '../interfaces/IMutateIndicatorOrderManagement';
import { QUERY_INDICATORS } from '../../infrastructure/ApolloClient/requests/QUERY_INDICATORS';
import { QUERY_EQUIVALENCES } from '../../infrastructure/ApolloClient/requests/QUERY_EQUIVALENCES';
import { MUTATE_INDICATOR_ORDER_MANAGEMENT } from '../../infrastructure/ApolloClient/requests/MUTATE_INDICATOR_ORDER_MANAGEMENT';
import {
	ApolloClientMutateIndicatorOrderManagement,
	ApolloClientQueryEquivalences,
	ApolloClientQueryIndicators
} from '../../infrastructure/ApolloClass/IndicatorsClass';
import { IQueryEquivalencesOutput } from '../interfaces/IQueryEquivalences';

export class IndicatorEntity extends Entity {
	public data: IIndicator[] | undefined;
	public isQuerying = false;

	initialization() {
		this.app.adapter.queryIndicators ??= this.app.installer(ApolloClientQueryIndicators, QUERY_INDICATORS);
		this.app.adapter.queryEquivalences ??= this.app.installer(ApolloClientQueryEquivalences, QUERY_EQUIVALENCES);
		this.app.adapter.mutateIndicatorOrderManagement ??= this.app.installer(ApolloClientMutateIndicatorOrderManagement, MUTATE_INDICATOR_ORDER_MANAGEMENT);
		this.app.adapter.storeDataIndicators?.({ loading: false, data: this, error: null });
	}

	get(): IIndicator[] | undefined {
		if (!this.data) {
			if (this.isQuerying) return;
			this.queryIndicators();
		}
		return this.data;
	}

	change(): Promise<void> | undefined {
		if (!this.data) return Promise.resolve();
		this.data.sort((a, b) => {
			return a.order - b.order;
		});
		return this.app.adapter.storeDataIndicators?.({ loading: false, data: this, error: null });
	}

	set(obj: IIndicator[] | undefined): void {
		this.data = obj;
		this.change();
	}
	
	update(data: IIndicator[]): void {
		if (!data) return;
		data.forEach((item) => {
			if (this.data === undefined) this.data = [];
			const idx = this.data.findIndex((b) => b.id === item.id);
			if (idx === undefined || idx === -1) {
				this.data = [...this.data, item];
			} else {
				this.data[idx] = { ...item };
			}
		});
		this.change();
	}

	addColors(data: {itemId: string, color:string}[]) {
		if (!this.data) return;
		data.forEach((item) => {
			const indicator = this.data?.find((i) => i.id === item.itemId);
			if (indicator) indicator.color = item.color;
		});
		this.change();
	}

	clearColors() {
		if (!this.data) return;
		this.data.forEach((item) => {
			item.color = undefined;
		});
		this.change();
	}

	private _storeError(error: Error) {
		this.app.adapter.storeDataIndicators?.({ loading: false, data: this, error });
	}

	/***************************************************
	 * 					API CALLS					   *
	 ***************************************************/

	async queryIndicators(): Promise<void> {
		this.isQuerying = true;
		const data = await this.callApi<IQueryIndicatorsOutput>(this.app.adapter.queryIndicators).catch((error) => {
			this._storeError(error);
			this.isQuerying = false;
			return undefined;
		});
		this.isQuerying = false;
		if (!data) return;
		this.set(data.indicators);
	}

	async mutateIndicatorOrderManagement(indicators: IMutateIndicatorOrderManagementInput) {
		const data = await this.callApi<IMutateIndicatorOrderManagementOutput, IMutateIndicatorOrderManagementInput>(this.app.adapter.mutateIndicatorOrderManagement, indicators);
		this.update(data.mutateIndicatorOrderManagement.indicators);
	}

	async queryEquivalences() {
		const data = await this.callApi<IQueryEquivalencesOutput>(this.app.adapter.queryEquivalences);
		return data.equivalences;
	}
}
