import { IUserDashboardCard } from '../../../domain/interfaces/IUserDashboard';
import React from 'react';
import { createCardModalDelete } from './CreateCardModalDelete';
import { translate } from '../../../infrastructure/translations/translate';
import { useUserDashboardOwning } from '../UserDashboardOwning';
import { useModal } from '../../../viewComponents/modal/useModal';

export const DashboardCardDeleteButton = ({ card }: { card: IUserDashboardCard }) => {
	const owning = useUserDashboardOwning(card.dashboard.id);
	const [openModalDelete, setOpenModalDelete] = React.useState<boolean>(false);
	useModal(openModalDelete, createCardModalDelete(openModalDelete, setOpenModalDelete, card));
	return (owning ? <button
		type={'button'}
		className={'button_blank'}
		onClick={() => setOpenModalDelete(true)}
	>
		<div className="dashboard_icon"><i className="fa-solid fa-xmark"/></div>
		<div className="text">{translate('delete')}</div>
	</button> : null);
};