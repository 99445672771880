import React, { useEffect, useState } from 'react';
import { translate } from '../../infrastructure/translations/translate';
import 'chart.js/auto';
import { resultValue } from './utils/resultValue';
import { ResultCardError } from '../error/ResultCardError/ResultCardError';
import { ResultTable, IResultTableProps } from './ResultTable';
import { prepareDataTableResult } from '../../utils/getResultTableData';
import { useTransformedResultTableData } from '../../utils/useTransformedResultTableData';
import Spinner from '../utils/Spinner/Spinner';
import { IndicatorTable } from './utils/IndicatorTable/IndicatorTable';
import { getMedian } from '../../utils/getMedian';
import { isResultImpactPerBlock } from './ResultFootprintPerBlock';
import { ResultArrowComparison } from './ResultArrowComparison';
import { FootprintStackedBarChart } from '../graphs/bars/FootprintStackedBarChart';

export type ResultStepImpactType = { schema: { fields: { name: string, type: string }[], primaryKey: string[], pandas_version: string }, data: { block: string, indicator: string, value: number, life_cycle_step: string, mean?: number, min?: number, max?: number, std?: number }[] }
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isResultStepImpact = (o: any): o is ResultStepImpactType => {
	if (typeof o === 'object') return 'schema' in o && 'data' in o;
	return false;
};
export const ResultFootprintPerLCStep = ({ data, isRelative }: { data: string, isRelative: boolean }): JSX.Element => {
	if (!isResultStepImpact(data)) return (<ResultCardError/>);

	// transform the data from IDs to names
	const transformedData = useTransformedResultTableData(data.data);
	const [tableData, setTableData] = useState<IResultTableProps | null>(null);

	useEffect(() => {
		if (!transformedData || transformedData instanceof Error) return;
		// Prepare the data for the table
		const table = prepareDataTableResult(transformedData, (data) => data.life_cycle_step ?? '');

		// set the table for the ResultTable component
		setTableData({
			columns: [
				{ name: translate('results.indicator') as string },
				{ name: translate('results.unit') as string, style: { textAlign: 'right' } },
				...table[0].values.map(v => ({ name: translate(`results.lifeCycleStep.${v.key}`) as string })),
			],
			values: table.map((t) => {
				// Extract the values from t.values
				const values = t.values.map(v => v.value);
				// Calculate median and new units
				const { values: medianValues, units: newUnits } = getMedian(values, t.unit ?? '');
				return [
					{ value: <IndicatorTable indicator={t.indicator ?? ''} icon={t.icon}/>, valueType: 'left' },
					{ value: isRelative ? '%' : newUnits, valueType: 'right' },
					...medianValues.map((value, index) => ({
						value: (
							<>
								{resultValue(value)}
								<ResultArrowComparison arrow={t.values[index].arrow} />
							</>
						),
						valueType: 'right',
					}))
				];
			})
		});

	}, [transformedData]);

	if (transformedData instanceof Error) return <ResultCardError/>;
	if (!tableData) return <Spinner/>;
	return <ResultTable dataTable={tableData} />;
};

export const FootprintPerLCSGraph = ({ data }: { data: string }): JSX.Element => {
	if (!isResultImpactPerBlock(data)) return (<ResultCardError/>);
	const transformedData = useTransformedResultTableData(data.data);
	if (transformedData instanceof Error || !transformedData) return <ResultCardError/>;

	return <FootprintStackedBarChart data={transformedData} field='life_cycle_step'/>;
};