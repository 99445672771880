import { gql } from '@apollo/client';

export const QUERY_RESULT_TYPES = gql`
	query QueryResultTypes (
		$datasetId: String
	) {
		resultTypes(
			 datasetId: $datasetId
		) {
			identifier,
			name,
			shortName,
			desc,
			methodology,
			relatedToSample
		}
	}
`;

