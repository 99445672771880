import { gql } from '@apollo/client';

export const MUTATE_PASSWORD_SEND_RESET = gql`
    mutation SendPasswordResetEmail(
        $email: String!
    ) {
        mutateSendPasswordResetEmail(
            email: $email
        ) {
            status,
            errors
        }
    }
`;
