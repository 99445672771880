import React from 'react';

export interface IResultTableProps {
	columns: {
		name: string | false,
		valueType?: string,
		style?: React.CSSProperties,
	}[],
	values: {
		value: string | React.ReactNode | false,
		valueType: string,
		style?: React.CSSProperties,
	}[][],
	selector?: {
		selected: {name: string, value: string},
		options: {name: string, value: string}[],
		onSelect: (selected: {name: string, value: string}) => void,
	},
}

export const ResultTable = ({ dataTable }:{ dataTable: IResultTableProps}) : JSX.Element => {
	return (<>
		{dataTable.selector && (
			<div>
				<select
					name="indicators"
					id="indicators"
					value={dataTable.selector.selected.value}
					onChange={(e) => {
						const selectedOption = dataTable.selector?.options.find((option) => option.value === e.target.value);
						if (selectedOption)
							dataTable.selector?.onSelect(selectedOption);
					}}
				>
					{dataTable.selector.options.map((option, idx) => (
						<option key={idx} value={option.value}>{option.name}</option>
					))}
				</select>
			</div>
		)}
		<table className={'result_table'}>
			<thead>
				<tr>
					{dataTable.columns.map((c, idx) => c.name && (c.valueType === 'right' ?
						<th style={c.style} className={'result_value_right'} key={idx}>{c.name}</th> :
						<th style={c.style} key={idx}>{c.name}</th>))
					}
				</tr>
			</thead>
			<tbody>
				{dataTable.values.map((v, idx) => <tr key={idx}>
					{v.map((vv, idx) => 
						vv.value && (vv.valueType === 'right' ?
							<td style={vv.style} className={'result_value_right'} key={idx}>{vv.value}</td> :
							<td style={vv.style} key={idx}>{vv.value}</td>
						))}
				</tr>)}
			</tbody>
		</table>
	</>);
};