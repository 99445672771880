import { gql } from '@apollo/client';


export const QUERY_DATA_RESULTS = gql`
    query requestResults(
        $rl_id: String
        $resultsIds: [String]
    )
    {
        results (
            rlId: $rl_id
            resultsIds: $resultsIds
        ) {
            id,
            type,
            relatedToSample,
            lastUpdated,
            data,
            relativeData
            archived,
            sample {
                id,
                name
            }
        }
    }`;
