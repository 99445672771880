import { Entity } from '../core/Entity';
import { IUserDashboard, IUserDashboardCard } from '../interfaces/IUserDashboard';
import {
	ApolloClientMutateEditUserDashboard,
	ApolloClientMutateEditUserDashboardCard,
	ApolloClientMutateEditUserDashboardCardOrder, ApolloClientMutateShareUserDashboard,
	ApolloClientQueryUserDashboard,
	ApolloClientQueryUserDashboardList, ApolloClientQueryUsersCanAccessUserDashboard
} from '../../infrastructure/ApolloClass/UserDashboardClass';
import { IQueryUserDashboardInput } from '../interfaces/IQueryUserDashboard';
import { IMutateEditUserDashboardCardInput, } from '../interfaces/IMutateEditUserDashboardCard';
import { IMutateEditUserDashboardCardOrderInput } from '../interfaces/IMutateEditUserDashboardCardOrder';
import { IMutateEditUserDashboardInput } from '../interfaces/IMutateEditUserDashboard';
import { QUERY_USER_DASHBOARD_LIST } from '../../infrastructure/ApolloClient/requests/QUERY_USER_DASHBOARD_LIST';
import { QUERY_USER_DASHBOARD } from '../../infrastructure/ApolloClient/requests/QUERY_USER_DASHBOARD';
import { MUTATE_EDIT_USER_DASHBOARD_CARD } from '../../infrastructure/ApolloClient/requests/MUTATE_EDIT_USER_DASHBOARD_CARD';
import { MUTATE_EDIT_USER_DASHBOARD_CARDS_ORDER } from '../../infrastructure/ApolloClient/requests/MUTATE_EDIT_USER_DASHBOARD_CARDS_ORDER';
import { MUTATE_EDIT_USER_DASHBOARD } from '../../infrastructure/ApolloClient/requests/MUTATE_EDIT_USER_DASHBOARD';
import { MUTATE_SHARE_USER_DASHBOARD } from '../../infrastructure/ApolloClient/requests/MUTATE_SHARE_USER_DASHBOARD';
import { IMutateShareUserDashboardInput } from '../interfaces/IMutateShareUserDashboard';
import {
	QUERY_USER_DASHBOARD_USERS_CAN_ACCESS
} from '../../infrastructure/ApolloClient/requests/QUERY_USER_DASHBOARD_USERS_CAN_ACCESS';
import { IUser } from '../interfaces/ResponseCompanyUsers';

type UserDashboardPartialCards = Omit<IUserDashboard, 'cards'> & { cards?: IUserDashboardCard[] };

export class UserDashboardEntity extends Entity {
	public data: IUserDashboard[] | undefined;
	private _isQuerying = false;

	initialization() {
		this.app.adapter.queryUserDashboard ??= this.app.installer(ApolloClientQueryUserDashboard, QUERY_USER_DASHBOARD);
		this.app.adapter.queryUserDashboardList ??= this.app.installer(ApolloClientQueryUserDashboardList, QUERY_USER_DASHBOARD_LIST);
		this.app.adapter.queryUserDashboardUsersCanAccess ??= this.app.installer(ApolloClientQueryUsersCanAccessUserDashboard, QUERY_USER_DASHBOARD_USERS_CAN_ACCESS);
		this.app.adapter.mutateEditUserDashboardCard ??= this.app.installer(ApolloClientMutateEditUserDashboardCard, MUTATE_EDIT_USER_DASHBOARD_CARD);
		this.app.adapter.mutateEditUserDashboardCardOrder ??= this.app.installer(ApolloClientMutateEditUserDashboardCardOrder, MUTATE_EDIT_USER_DASHBOARD_CARDS_ORDER);
		this.app.adapter.mutateEditUserDashboard ??= this.app.installer(ApolloClientMutateEditUserDashboard, MUTATE_EDIT_USER_DASHBOARD);
		this.app.adapter.mutateShareUserDashboard ??= this.app.installer(ApolloClientMutateShareUserDashboard, MUTATE_SHARE_USER_DASHBOARD);
		this.app.adapter.storeUserDashboards?.({ loading: false, error: null, data: this });
	}

	get(): IUserDashboard[] | undefined {
		if (!this.data) {
			if (this._isQuerying) return;
			this.queryUserDashboardList();
		}
		return this.data;
	}

	change(): Promise<void> | undefined {
		return this.app.adapter.storeUserDashboards?.({ loading: false, error: null, data: this });
	}

	set(obj: UserDashboardPartialCards[] | undefined): void {
		this.data = obj?.map(d => ({ ...d, cards: d.cards ?? [] }));
		this.change();
	}

	update(line?: UserDashboardPartialCards): void {
		if (!line) return;
		if (this.data === undefined) this.data = [];
		const dashboardIndex = this.data?.findIndex(d => d.id === line.id);
		if (dashboardIndex === undefined || dashboardIndex === -1) {
			this.data.push({ ...line, cards: line.cards ?? [] });
		} else {
			this.data[dashboardIndex] = { ...line, cards: line.cards ?? this.data[dashboardIndex].cards };
		}
		this.change();
	}

	delete(id?: string): void {
		if (!id) return;
		if (this.data === undefined) this.data = [];
		const dashboardIndex = this.data?.findIndex(d => d.id === id);
		if (dashboardIndex === undefined || dashboardIndex === -1) return;
		this.data.splice(dashboardIndex, 1);
		this.change();
	}

	updateCard(card?: IUserDashboardCard): void {
		if (!card) return;
		if (this.data === undefined) this.data = [];
		const dashboardIndex = this.data.findIndex(d => d.id === card.dashboard.id);
		if (dashboardIndex === undefined || dashboardIndex === -1) return;
		const dashboard = this.data[dashboardIndex];
		const cardIndex = dashboard.cards.findIndex(c => c.id === card.id);
		if (cardIndex === undefined || cardIndex === -1) {
			if (card.order == 0){
				dashboard.cards.forEach(c => c.order++);
			}
			dashboard.cards.push(card);
		} else {
			dashboard.cards[cardIndex] = card;
		}
		this.change();
	}

	deleteCard(id?: string, dashboardId?: string): void {
		if (id === undefined || dashboardId === undefined) return;
		if (this.data === undefined) this.data = [];
		const dashboardIndex = this.data?.findIndex(d => d.id === dashboardId);
		if (dashboardIndex === undefined || dashboardIndex === -1) return;
		const dashboard = this.data[dashboardIndex];
		const cardIndex = dashboard.cards.findIndex(c => c.id === id);
		if (cardIndex === undefined || cardIndex === -1) return;
		const order = dashboard.cards[cardIndex].order;
		dashboard.cards.splice(cardIndex, 1);
		dashboard.cards.forEach(c => c.order >= order && c.order--);
		this.change();
	}

	private async _storeError(error: Error) {
		await this.app.adapter.storeUserDashboards?.({ loading: false, error, data: this });
	}

	/***************************************************
	 * 					API CALLS					   *
	 ***************************************************/

	async queryUserDashboardList() {
		this._isQuerying = true;
		const data = await this.callApi(this.app.adapter.queryUserDashboardList).catch((error) => {
			this._storeError(error);
			this._isQuerying = false;
			return undefined;
		});
		this._isQuerying = false;
		if (!data) return;
		this.set(data.userDashboardList);
	}

	async queryUserDashboard(input: IQueryUserDashboardInput) {
		const data = await this.callApi(this.app.adapter.queryUserDashboard, input);
		this.update(data.userDashboard);
	}

	async queryUserDashboardUsersCanAccess(userDashboardId: string): Promise<IUser[]> {
		const data = await this.callApi(this.app.adapter.queryUserDashboardUsersCanAccess, { userDashboardId });
		return data.userDashboardUsersCanAccess;
	}

	async mutateEditUserDashboardCard(input: IMutateEditUserDashboardCardInput): Promise<IUserDashboardCard> {
		const data = await this.callApi(this.app.adapter.mutateEditUserDashboardCard, input);
		if (input.delete) {
			this.deleteCard(input.id, input.dashboardId);
		} else {
			this.updateCard(data.mutateEditUserDashboardCard.userDashboardCard);
		}
		return data.mutateEditUserDashboardCard.userDashboardCard;
	}

	async mutateEditUserDashboardCardOrder(input: IMutateEditUserDashboardCardOrderInput): Promise<IUserDashboard> {
		const data = await this.callApi(this.app.adapter.mutateEditUserDashboardCardOrder, input);
		this.update(data.mutateEditUserDashboardCardsOrder.userDashboard);
		return data.mutateEditUserDashboardCardsOrder.userDashboard;
	}

	async mutateShareUserDashboard(input: IMutateShareUserDashboardInput): Promise<boolean> {
		const data = await this.callApi(this.app.adapter.mutateShareUserDashboard, input);
		this.update(data.mutateShareUserDashboard.userDashboard);
		return true;
	}

	async mutateEditUserDashboard(input: IMutateEditUserDashboardInput): Promise<IUserDashboard> {
		const data = await this.callApi(this.app.adapter.mutateEditUserDashboard, input);
		if (input.delete) {
			this.delete(input.id);
		} else {
			this.update(data.mutateEditUserDashboard.userDashboard);
		}
		return data.mutateEditUserDashboard.userDashboard;
	}
}