import React, { useEffect } from 'react';
import { translate } from '../../infrastructure/translations/translate';
import { resultValue } from './utils/resultValue';
import { ResultCardError } from '../error/ResultCardError/ResultCardError';
import { ResultTable, IResultTableProps } from './ResultTable';
import { IResultTableDataInput } from '../../utils/getResultTableData';
import { useTransformedResultTableData } from '../../utils/useTransformedResultTableData';
import Spinner from '../utils/Spinner/Spinner';
import { IndicatorTable } from './utils/IndicatorTable/IndicatorTable';
import { getMedian } from '../../utils/getMedian';


export type ResultFootprintPerFunctionalUnitType = { schema: { fields: { name: string, type: string }[], primaryKey: string[], pandas_version: string }, data: { indicator: string, unit: string, value: number, mean?: number, min?: number, max?: number, std?: number }[] }// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isResultFootprintPerFunctionalUnit = (o: any): o is ResultFootprintPerFunctionalUnitType => {
	if (typeof o === 'object') return 'schema' in o && 'data' in o;
	return false;
};
export const ResultFootprintPerFunctionalUnit = ({ data }: { data: string }): JSX.Element => {
	if (!isResultFootprintPerFunctionalUnit(data)) return (<ResultCardError/>);
	const [dataTable, setDataTable] = React.useState<IResultTableProps | null>(null);

	// translate the data ids
	const transformedData = useTransformedResultTableData(data.data);

	useEffect(() => {
		if (!transformedData || transformedData instanceof Error) return;
		// get the first object to check if there are optional columns (min, mean, max) to display
		const firstData = transformedData[0];
		const optionalColumns: [keyof IResultTableDataInput, string][] = [
			['min', translate('results.min') as string],
			['mean', translate('results.mean') as string],
			['max', translate('results.max') as string],
		];

		setDataTable({
			columns:
				[
					{ name: translate('results.indicator') as string },
					{ name: translate('results.unit') as string, style: { textAlign: 'left' } },
					{ name: translate('results.value') as string },
					...optionalColumns.filter(([key]) => firstData[key] !== undefined).map(([, name]) => ({ name })),
				],
			values:
				transformedData.map(d => {
					// If d.value is an array, calculate the median. If not, use d.value as is.
					const medianResult = Array.isArray(d.value)
						? getMedian(d.value, d.unit ?? '', [d.min ?? 0, d.mean ?? 0, d.max ?? 0])
						: getMedian([d.value ?? 0], d.unit ?? '', [d.min ?? 0, d.mean ?? 0, d.max ?? 0]);
					const medianValue = medianResult ? medianResult.values[0] : d.value;
					const newUnits = medianResult ? medianResult.units : d.unit;
					const minMeanMaxMedian = medianResult ? medianResult.valuesMeans : [d.min, d.mean, d.max];
					return [
						{ value: <IndicatorTable indicator={d.indicator ?? ''} icon={d.icon}/>, valueType: 'left' },
						{ value: newUnits, valueType: 'left', style: { textAlign: 'left' } },
						{ value: resultValue(medianValue as number), valueType: 'right' },
						...optionalColumns
							.filter(([key]) => d[key] !== undefined)
							.map(([name], index) => ({ name, value: resultValue(minMeanMaxMedian ? minMeanMaxMedian[index] : 0), valueType: 'right' })),
					];
				}),
		});
	}, [transformedData]);

	if (transformedData instanceof Error) return <ResultCardError/>;
	if (!dataTable) return <Spinner/>;
	return <ResultTable dataTable={dataTable} />;
};
