import { gql } from '@apollo/client';

export const MUTATE_ACCEPT_CGU = gql`
    mutation MutateAcceptCgu(
        $cguToken: String!,
        $cguAccepted: Boolean!,
    ) {
        mutateAcceptCgu(
            cguToken: $cguToken,
            cguAccepted: $cguAccepted,
        ) {
            status,
            error,
            authToken,
        }
    }
`;
