import { DashboardComparisonMaturity } from '../../domain/interfaces/DashboardComparisonData';

export function maturityCorrectlyFilled(maturity: DashboardComparisonMaturity): boolean {
	if (!maturity) return false;
	if (!maturity.scopes) return false;
	if (!maturity.scopes.length) return false;
	if (!maturity.domains) return false;
	if (!maturity.domains.length) return false;
	if (!maturity.datasets) return false;
	if (!maturity.datasets.length) return false;
	if (!maturity.datasets.every((dataset) => dataset.maturity && dataset.maturity.length)) return false;
	return maturity.datasets.every((dataset) => {
		return dataset.maturity.every((maturity) => {
			if (maturity.grade == null || isNaN(maturity.grade)) return false;
			return maturity.grade >= 0 && maturity.grade <= 5;
		});
	});
}