import React from 'react';
import Modal from '../../modal/Modal';
import empty_cart from '../../../media/error_svg_images/empty_cart.svg';
import access_denied from '../../../media/error_svg_images/access_denied.svg';
import bug_fixing_man from '../../../media/error_svg_images/bug_fixing_man.svg';
import bug_fixing_woman from '../../../media/error_svg_images/bug_fixing_woman.svg';
import page_not_found from '../../../media/error_svg_images/page_not_found.svg';
import server_down from '../../../media/error_svg_images/server_down.svg';
import { translate } from '../../../infrastructure/translations/translate';
import './ErrorImage.scss';


// add more images here and in the src/error_svg_images/ folder if needed
const img_map: Record<string, string> = {
	'empty_cart': empty_cart,
	'access_denied': access_denied,
	'bug_fixing_man': bug_fixing_man,
	'bug_fixing_woman': bug_fixing_woman,
	'page_not_found': page_not_found,
	'server_down': server_down,
};

/**
 * @description - Modal used in the ErrorImage component (see descrption bellow)
 * 
 * @param errorText - the text to be displayed
 * @param img - the image to be displayed (see img_map for available images)
 * @param onClose - the function to be called when the modal is closed
 * @returns {JSX.Element}
 * @author Yacine Bentayeb
 */

const ErrorImageModal = ({ errorText, img, onClose }: { errorText:string, img : string, onClose?: () => void }): JSX.Element =>{
	const image = img_map[img];

	return (
		<Modal onClose={onClose} style={{}}>
			<div className="modal_content" style={{ display: 'flex', justifyContent: 'center', maxWidth: '700px' }} >
				<div className={'modal_header'}>
					<h3>{translate('errorImage.header')}</h3>
				</div>
				<div className={'modal_body'} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', maxWidth: '700px' }}>
					<img src={image} alt="Error" style={{ width: '500px' }}/>
					<p id="error_message" style={{ fontSize: '20px', fontFamily: 'Lexend', textAlign: 'center' }}>{errorText}</p>
				</div>
				<div className={'modal_footer'}>
					<button type={'button'} className={'button_primary'}
						onClick={onClose}>{translate('close')}</button>
				</div>
			</div>
		</Modal>
	);
};


/**
 * @description - this component displays an error image with a text, it can be displayed in a modal or not
 * @description - the images are stored in the src/error_svg_images/ folder
 * @description - If not used with a modal, it's made to be displayed instead of the main content of a page
 * @param errorText - the text to be displayed
 * @param img - the image to be displayed (see img_map for available images)
 * @param modal - if true, the error image will be displayed in a modal
 * @param onClose - (optional) the function to be called when the modal is closed
 * @returns {JSX.Element}
 * @author Yacine Bentayeb
 * 
 */

const ErrorImage = ({ errorText, img, modal, onClose } : 
	{errorText: string, img: string, modal: boolean , onClose?: () => void}): JSX.Element =>{
	const image = img_map[img];
	
	return (
		<>
			{
				modal ? <ErrorImageModal errorText={errorText} img={img} onClose={onClose}/> :
					<div className='error_image_container'>
						<div className={'error_image_content'}>
							<img src={image} alt={img}/>
							<p id="error_message">{errorText}</p>
						</div>
					</div>
			}
		</>
	);
};

export default ErrorImage;
