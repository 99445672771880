import { gql } from '@apollo/client';

export const QUERY_RESULT_LISTS = gql`
	query {
		resultLists {
			id,
			name,
			desc,
			resultTypes {
				name
        identifier
			}
    }
	}
`;
