import { Study } from '../../domain/data/entries/Study';
import { StudiesEntity } from '../../domain/entities/StudiesEntity';
import { IDataDTO } from '../../domain/interfaces/DTO/IDataDTO';
import { State } from '../../store';
import { useSelector } from 'react-redux';

type UseStudiesHookType = IDataDTO & { entity: StudiesEntity | undefined, studies: Study[] | undefined };

export const useStudies = (): UseStudiesHookType => {
	const { loading, error, data: entity } = useSelector((state: State) => state.dataStudiesStatus);
	const studies = entity?.get();
	return { loading, error, entity, studies };
};