import { IDataDTO } from '../../domain/interfaces/DTO/IDataDTO';
import { DatasetsEntity } from '../../domain/entities/DatasetsEntity';
import { StudyDataset } from '../../domain/data/entries/StudyDataset';
import { useSelector } from 'react-redux';
import { State } from '../../store';

type UseDatasetsHookType = IDataDTO & { entity: DatasetsEntity | undefined, datasets: StudyDataset[], currentDataset: StudyDataset | undefined };

export const useDatasets = (ids: string[], filter?: boolean): UseDatasetsHookType => {
	const { loading, error, data: entity } = useSelector((state: State) => state.dataDatasetsStatus);
	const datasetId = useSelector((state: State) => state.datasetID);

	if (!ids.length) {
		// No ids have been provided but we still want to return the datasets filtered (Kanban View with no datasets for example)
		if (filter) return { loading, error, entity, datasets: [], currentDataset: undefined };
		return { loading, error, entity, datasets: entity?.data ?? [], currentDataset: undefined };
	}
	// Clear empty strings from ids
	ids = ids.filter(id => id !== '');

	// The dataset 'all' is used in the results view to display all dataset's results. But it's not a "real" dataset and cannot be queried
	const datasets = ids.some(id => id === 'all') ? [] : (entity?.get(ids) ?? []);
	const currentDataset = datasets.find((dataset: StudyDataset) => dataset.id === datasetId);
	if (filter) return { loading, error, entity, datasets: datasets.filter(d => ids.includes(d.id)), currentDataset };
	return { loading, error, entity, datasets, currentDataset };
};
