import { gql } from '@apollo/client';

export const MUTATE_EQUIPMENT_ORDER = gql`
	mutation EquipmentOrder(
		$typeList: [String!]!
	) {
		mutateEquipmentOrder(
			typeList: $typeList
		) {
			status
		}
	}
`;
