import { gql } from '@apollo/client';

export const MUTATE_EDIT_FUNCTIONAL_UNIT = gql`
    mutation MutateEditFunctionalUnit(
        $id: String!,
        $nameEn: String!,
        $nameFr: String!,
        $private: Boolean!,
        $defaultLcaTemplate: String,
        $delete: Boolean!
    ){
        mutateEditFunctionalUnit(
            id: $id,
            nameEn: $nameEn,
            nameFr: $nameFr,
            private: $private,
            defaultLcaTemplate: $defaultLcaTemplate,
            delete: $delete
        ){
            functionalUnit{
                id
                name
                nameFr
                nameEn
                private
                defaultLcaTemplate
            }
        }
    }
`;
