import { gql } from '@apollo/client';

export const QUERY_SEARCH_EQUIPMENT_TYPE = gql`
    query (
            $name: String!
            $sourcesNames: [String]
    ) {
        searchEquipmentType(
        	name: $name
        	sources: $sourcesNames
		) {
			id,
			name,
			shortName,
			specification,
			category,
			source
		}
    }
`;
