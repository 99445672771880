import { gql } from '@apollo/client';

export const QUERY_EQUIPMENT_TYPES = gql`
    query equipmentTypesInfo (
        $category: String
        $sourcesNames: [String]
        $idList: [String]
        $getOnlyPrivate: Boolean
        $datasetId: String
        $removeCustom: Boolean
        $offset: Int
        $limit: Int
    ) {
        equipmentTypesInfo (
            category: $category
            sources: $sourcesNames
            idList: $idList
            getOnlyPrivate: $getOnlyPrivate
            datasetId: $datasetId
            removeCustom: $removeCustom
            offset: $offset
            limit: $limit
        ) {
            equipmentTypes {
                id,
                name,
                shortName,
                shortNameEn,
                shortNameFr,
                specification,
                specificationEn,
                specificationFr,
                category,
                source,
                desc,
                descEn,
                descFr,
                metadata,
                defaultLifetime,
                electricityConsumption,
                affiliatedCompany,
                isService,
                deprecated
            }
            numberOfEquipmentByCategory
        }
    }
`;
