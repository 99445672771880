import { translate } from '../../infrastructure/translations/translate';

const getDatasetName = (name: string): string => name !== 'all' ? name : translate('all.study') as string;

export const dashboardLabelName = ({
	id,
	data,
	defaultName = '',
}: {
    id: string;
    data: {
        datasets: {
            id: string;
            name: string;
            study: string;
			year: string;
            company: string;
        }[];
    };
	defaultName?: string;
}): string => {
	const dataset = data.datasets.find(d => d.id === id);

	if (!dataset) return defaultName;

	const sameCompany = data.datasets.every(d => d.company == dataset.company);
	const sameStudy = data.datasets.every(d => d.study == dataset.study && d.year == dataset.year);

	const name = getDatasetName(dataset.name);

	if (sameCompany && sameStudy) return name;
	if (sameCompany) return `${name} - ${dataset.study} ${dataset.year}`;
	return `${name} - ${dataset.study} ${dataset.year} - ${dataset.company}`;
};