import { gql } from '@apollo/client';

export const QUERY_STATIC_PAGES = gql`
    query {
        staticPages {
            id,
            name,
            content,
            private
        }
    }
`;
