
import { gql } from '@apollo/client';

export const QUERY_UPDATE = gql`
    query QueryUpdate{
    	update {
    		content
    	}
    }
`;
