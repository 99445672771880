import { gql } from '@apollo/client';

export const QUERY_SAMPLES = gql`
    query {
        samples {
            id,
            name,
            desc,
            useForValidation,
            datasets {
                id
            }
            datasetsCount
            private
            functionalUnit
            indicatorIds
        }
    }
`;
