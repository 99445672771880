export const localStorageGetToken = (): Promise<string> => {
	const token = localStorage.getItem('token') || '';
	return Promise.resolve(token);
};

export const localStorageSetToken = (token: string): Promise<boolean> => {
	localStorage.setItem('token', token);
	return Promise.resolve(true);
};

export const localStorageGetLang = (): string => {
	const lang = localStorage.getItem('lang') || navigator.language || 'en';
	if (lang.length > 2) {
		return 'en';
	}
	return lang;
};

export const localStorageSetLang = (lang: string): Promise<boolean> => {
	localStorage.setItem('lang', lang);
	return Promise.resolve(true);
};
