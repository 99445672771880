import React from 'react';
import { ChartProps, Line } from 'react-chartjs-2';
import merge from 'lodash.merge';
import { cutLabels } from '../utils/cutLabels';

export interface LineChartData {
	label: string;
	values: {
		scope: string;
		value: number | null;
		color?: string;
		dashed?: boolean;
	}[];
}

/**
 * Stacked Bar Chart
 * @param data
 * @param options
 * @param plugins
 * @constructor
 *
 * @author Maximilien Valenzano
 */
export const LineChart = ({ data, options, plugins }: {
	data: (LineChartData | null)[];
	options?: ChartProps<'line'>['options'];
	plugins?: ChartProps<'line'>['plugins'];
}): JSX.Element => {
	const [chartKey, setChartKey] = React.useState<number>(0);
	const [timeOutKey, setTimeOutKey] = React.useState<ReturnType<typeof setTimeout>>();
	const labels = data.map(d => d && cutLabels(d.label));
	const scopes: string[] = Array.from(data.reduce<Set<string>>((p, c) => {
		if (!c) return p;
		c.values.forEach(v => p.add(v.scope));
		return p;
	}, new Set()));
	const datasets: ChartProps<'line'>['data']['datasets'] = scopes.map((scope) => ({
		label: scope,
		data: data.map(d => d?.values.find(v => v.scope === scope)?.value ?? null),
		backgroundColor: data.find(d => d?.values.find(v => v.scope === scope))?.values.find(v => v.scope === scope && v.color)?.color ?? 'black',
		borderColor: data.find(d => d?.values.find(v => v.scope === scope))?.values.find(v => v.scope === scope && v.color)?.color ?? 'black',
		borderWidth: 1,
		borderDash: data.find(d => d?.values.find(v => v.scope === scope))?.values.find(v => v.scope === scope && v.dashed)?.dashed ? [5, 5] : [],
	}));
	const barData: ChartProps<'line'>['data'] = {
		labels,
		datasets
	};

	options = merge({
		scales: {
			y: {
				beginAtZero: true,
			},
			x: {
				beginAtZero: true,
				ticks: {
					autoSkip: false,
					maxRotation: 0,
				}
			}
		}
	}, options ?? {});

	const resize = () => {
		clearTimeout(timeOutKey);
		const t = setTimeout(() => {
			setChartKey(n => n + 1);
		}, 100);
		setTimeOutKey(t);
	};

	React.useEffect(() => {
		window.addEventListener('resize', resize);
		return () => {
			window.removeEventListener('resize', resize);
		};
	}, [timeOutKey]);

	return (
		<Line
			className='dynamic_chart'
			data={barData}
			options={options}
			plugins={plugins}
			key={chartKey}
		/>
	);
};