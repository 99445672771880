import React from 'react';
import logo from '../../../media/logo.svg';
import Modal from '../../modal/Modal';
import { translate } from '../../../infrastructure/translations/translate';
import { useUser } from '../../hooks/useUser';

const ErrorBoundaryFallback = (): JSX.Element => {
	const { data: user } = useUser();
	const logoImg = user?.myCompany?.style ? user?.myCompany?.logo : null;
	return (
		<Modal>
			<div className={'card'}>
				{logoImg ?
					<div>
						<img alt="Resilio logo" src={logo} width={'50px'} />
						{' '}
						<img alt={'Company logo'} src={logoImg} width={'50px'}/>
					</div>
					:
					<img alt="Resilio logo" src={logo} />
				}
				<div className={'card_header text-center'}>{translate('errorBoundary.errorOccurs')}</div>
				<div className={'card_body text-center'}>{translate('errorBoundary.reloadPage')}</div>
			</div>
		</Modal>
	);
};

export default ErrorBoundaryFallback;
