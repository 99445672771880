import { gql } from '@apollo/client';

export const QUERY_DASHBOARD_COMPARISON_DATA = gql`
    query QueryDashboardComparisonData (
		$datasetsIds: [String!]!
    ) {
    	dashboardComparisonData (
			datasetsIds: $datasetsIds
		) {
			data
		}
    }
`;
