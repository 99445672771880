
import { gql } from '@apollo/client';

export const MUTATE_VALIDATE_CMDB_LINE = gql`
    mutation MutateValidateCmdbLine(
    	$datasetId: String!,
    	$cmdbName: String!,
    	$equipmentCategory: String!,
    	$blockName: String!,
    	$validate: Boolean!
    ){
    	mutateValidateCmdbLine(
    		datasetId: $datasetId,
    		cmdbName: $cmdbName,
    		equipmentCategory: $equipmentCategory,
    		blockName: $blockName,
    		validate: $validate
		){
			status,
			errors,
			data {
				id,
				name,
				dataset,
				block,
				equipmentsInfo {
					category,
					categoryId,
					quantity,
                	weightedQuantity,
					totalLifetime,
					equipmentLineCount,
					validatedQuantity,
					validatedLifetime,
					validated
				}
			}
		}
    }
`;
