import { gql } from '@apollo/client';

export const QUERY_DASHBOARD_COMPARISON_BENCHMARK = gql`
    query QueryDashboardComparisonBenchmark (
		$sampleId: String!
    ) {
    	dashboardComparisonDataBenchmark (
			sampleId: $sampleId
		) {
			data
		}
    }
`;
