import React from 'react';
import { hexToRgb } from '../../../utils/hexToRgb';
import { rgbToHex } from '../../../utils/rgbToHex';
import './arrow.css';
import AccountHeader from '../../head/accountHeader/accountHeader';

export const Arrow = ({ color, size }: { color: string; size?: 'large' | 'small' }): JSX.Element => {
	size = size || 'small';
	const rgb = hexToRgb(color);
	const darken = rgbToHex([rgb[0] + 31, rgb[1] + 41, rgb[2] + 33]);
	return (
		<div className={`resilio_arrow ${size}`} aria-hidden={'true'} style={{ pointerEvents: 'none' }}>
			<svg viewBox="0 0 131 261" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M131.038 261L0.515884 130.522L48.9296 82.1079L131.038 164.216V261Z" fill={`${color}`}/>
				<path d="M131.038 0V84.5829L42.8074 172.813L0.515884 130.522L131.038 0Z" fill={`${darken}`}/>
			</svg>
			<AccountHeader />
		</div>
	);
};
