
import { gql } from '@apollo/client';

export const QUERY_EQUIPMENT_BY_IDS = gql`
    query QueryEquipmentByIds(
        $ids: [String!]!
    )
    {
        equipmentByIds (
            ids: $ids
        ) {
            id,
            name,
            specification,
            category
        }
    }
`;
