import { EquipmentType } from '../../../../domain/data/entries/EquipmentType';
import { IResultTableDataOutput } from '../../../../utils/getResultTableData';


// Helper function to aggregate data by category
const aggregateByCategory = (data: IResultTableDataOutput[], block: string): IResultTableDataOutput[] => {
	const filteredData = block === 'all' ? data : data.filter((d) => d.block === block);
	const categoryMap = new Map<
		string,
		{ quantity: number; nb_users: number; weightedLifetime: number; totalQuantity: number }
	>();

	for (const row of filteredData) {
		const { category, quantity = 0, nb_users = 0, lifetime = 0 } = row;
		if (category) {
			const current = categoryMap.get(category) || { quantity: 0, nb_users, weightedLifetime: 0, totalQuantity: 0 };
			categoryMap.set(category, {
				quantity: current.quantity + quantity,
				nb_users: current.totalQuantity === 0 ? nb_users : current.nb_users,
				weightedLifetime: current.weightedLifetime + lifetime * quantity,
				totalQuantity: current.totalQuantity + quantity
			});
		}
	}

	return Array.from(categoryMap, ([category, data]) => ({
		category,
		quantity: data.quantity,
		nb_users: data.nb_users,
		lifetime: data.totalQuantity ? data.weightedLifetime / data.totalQuantity : 0,
		quantity_by_users: data.nb_users ? data.quantity / data.nb_users : 0
	})) as IResultTableDataOutput[];
};

// Helper function to aggregate data by equipment type
const aggregateByEquipmentType = (data: IResultTableDataOutput[], block: string): IResultTableDataOutput[] => {
	const filteredData = block === 'all' ? data : data.filter((d) => d.block === block);
	const equipmentMap = new Map<
		string,
		{ equipment_type: EquipmentType; quantity: number; nb_users: number; weightedLifetime: number; totalQuantity: number }
	>();

	for (const row of filteredData) {
		const { equipment_type, quantity = 0, nb_users = 0, lifetime = 0 } = row;
		if (equipment_type) {
			const equipmentId = equipment_type.id;
			const current = equipmentMap.get(equipmentId) || {
				equipment_type,
				quantity: 0,
				nb_users,
				weightedLifetime: 0,
				totalQuantity: 0
			};
			equipmentMap.set(equipmentId, {
				equipment_type,
				quantity: current.quantity + quantity,
				nb_users: current.totalQuantity === 0 ? nb_users : current.nb_users,
				weightedLifetime: current.weightedLifetime + lifetime * quantity,
				totalQuantity: current.totalQuantity + quantity
			});
		}
	}

	return Array.from(equipmentMap.values()).map((data) => ({
		equipment_type: data.equipment_type,
		quantity: data.quantity,
		nb_users: data.nb_users,
		lifetime: data.totalQuantity ? data.weightedLifetime / data.totalQuantity : 0,
		quantity_by_users: data.nb_users ? data.quantity / data.nb_users : 0
	})) as IResultTableDataOutput[];
};

// Main aggregation function
export const aggregateData = (
	data: IResultTableDataOutput[],
	scope: 'category' | 'equipment',
	block: string
): IResultTableDataOutput[] => {
	return scope === 'category'
		? aggregateByCategory(data, block)
		: aggregateByEquipmentType(data, block);
};
