import { gql } from '@apollo/client';

export const MUTATE_ERROR_REPORT = gql`
	mutation ErrorReportMutation(
		$errorName: String!,
		$errorMessage: String!,
		$errorStack : String!,
        $componentStack: String!
        $topic: String!,
		$channel: String
	) {
		mutateErrorReport(
			errorName: $errorName,
			errorMessage: $errorMessage,
			errorStack: $errorStack,
            componentStack: $componentStack
            topic: $topic,
			channel: $channel
		) {
			status
        }
    }
`;
