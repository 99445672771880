import { ApolloClientProvider } from '../ApolloClient/ApolloClientProvider';
import { IMutateCompanyManageResponse } from '../../domain/interfaces/IMutateCompanyManage';
import { ICompanyManage } from '../../domain/data/entries/ICompanyManage';
import { IAPIProps } from '../../domain/interfaces/IAPIProps';
import {
	IMutateCompanyToManageInput,
	IMutateCompanyToManageOutput
} from '../../domain/interfaces/IMutateCompanyToManage';
import { IQueryCompaniesManageResponse } from '../../domain/interfaces/IQueryCompaniesManage';
import { ResponseCompanyUsers } from '../../domain/interfaces/ResponseCompanyUsers';
import { IQuerySsoSettingsInput, IQuerySsoSettingsOutput } from '../../domain/interfaces/IQuerySsoSettings';

export class ApolloClientMutateCompanyManagement extends ApolloClientProvider<IMutateCompanyManageResponse, ICompanyManage> {
	exec({ token, lang, adapter }: IAPIProps, data: ICompanyManage): Promise<IMutateCompanyManageResponse | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateCompanyToManage extends ApolloClientProvider<IMutateCompanyToManageOutput, IMutateCompanyToManageInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateCompanyToManageInput): Promise<IMutateCompanyToManageOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientQuerySsoSettings extends ApolloClientProvider<IQuerySsoSettingsOutput, IQuerySsoSettingsInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IQuerySsoSettingsInput): Promise<IQuerySsoSettingsOutput | null | undefined> {
		return this.get({ token, lang, adapter }, data);
	}
}
export class ApolloClientQueryCompaniesManagement extends ApolloClientProvider<IQueryCompaniesManageResponse> {}
export class ApolloClientQueryCompanyUsers extends ApolloClientProvider<ResponseCompanyUsers> {}
