export const linkStudy = '/study/:study';
export const linkWorkflow = '/study/:study/workflow';
export const linkKanban = '/study/:study/kanban';
export const linkDatasetElem = '/:action/study/:study/dataset/:dataset';
export const linkDatasetDomain = '/:action/study/:study/dataset/:dataset/domain/:domain';
export const linkSampleResults = '/results/sample/:sampleId';
export const linkSampleDatasetResults = '/results/sample/:sampleId/dataset/:datasetId';
export const linkDashboardEnsemble = '/dashboard/:dashboard';
export const linkDashboardId = '/dashboard/:dashboard/:dashboardId';
export const linkStaticPage = '/static/:name';
export const linkContact = '/contact';
export const linkContactStudy = '/study/:study/contact';

export const linkGenerator = (study?: string | null, action?: string | null, dataset?: string | null, domain?: string | null, contact?: string | null): string => {
	if (study && contact) {return `/study/${study}/contact`;}
	if (contact) return '/contact';
	if (study && (!action || !dataset && !domain)) return `/study/${study}`;
	if (study && action === 'inventory' && dataset && !domain) return `/overview/study/${study}/dataset/${dataset}`;
	if (study && action && dataset && !domain) return `/${action}/study/${study}/dataset/${dataset}`;
	if (study && action && dataset && domain) return `/${action}/study/${study}/dataset/${dataset}/domain/${domain}`;
	return '/';
};

export const hashLinkGenerator = (study?: string | null, action?: string | null, dataset?: string | null, hash?: string | null): string => {
	return `/${action}/study/${study}/dataset/${dataset}${hash}`;
};

export const linkStaticPageById = (name: string): string => {
	return `/static/${name}`;
};
