import { SideBarContentItemList } from './template/sidebar/sidebar';
import { useParams } from 'react-router-dom';
import { translate } from '../infrastructure/translations/translate';
import { useUser } from './hooks/useUser';

const UserManageViewsSidebar = (): SideBarContentItemList => {
	const { page } = useParams();
	const { data: user } = useUser();
	const list = [];

	list.push({
		id: 'password',
		name: translate('userManageSettings.password') as string,
		current: page === 'password',
		link: '/user/settings/password'
	});

	if (user?.permissions.includes('login.can_manage_sso')) list.push({
		id: 'sso',
		name: translate('userManageSettings.sso') as string,
		current: page === 'sso',
		link: '/user/settings/sso'
	});

	return {
		content: {
			list
		}
	};
};

export default UserManageViewsSidebar;