import React, { useId } from 'react';
import '../../DashboardCard.scss';
import 'chart.js/auto';
import { ChartProps } from 'react-chartjs-2';
import { StackedBarChart, StackedBarChartData } from '../../../../viewComponents/graphs/bars/StackedBarChart';
import {
	DashboardPluginGraphBarLegend,
	DashboardPluginGraphBarPercentageOnTop
} from '../../DashboardPluginGraphBarLegend';
import { translate } from '../../../../infrastructure/translations/translate';
import { DashboardComparisonData } from '../../../../domain/interfaces/DashboardComparisonData';
import { resultValue } from '../../../../viewComponents/results/utils/resultValue';
import { valueFixedDigits } from '../../../../utils/numberToStringSeparator';
import { useIndicators } from '../../../../viewComponents/hooks/useIndicators';
import { useLifeCycleSteps } from '../../../../viewComponents/hooks/useLifeCycleSteps';
import { useBlocks } from '../../../../viewComponents/hooks/useBlocks';
import { DashboardPinButton } from '../../DashboardPin';
import { getImpactByIndicator } from '../../GetImpactByIndicator';
import { getImpactByLifeCycleStep } from '../../GetImpactByLifeCycleStep';
import { parseByFootprintTypeDashboard } from '../../Comparison/ParseByFootprintTypeDashboard';
import { dashboardLabelName } from '../../DashboardLabelName';
import { TooltipItem } from 'chart.js/auto';

const options: ChartProps<'bar'>['options'] = {
	layout: {
		padding: 10
	},
	maintainAspectRatio: false,
	aspectRatio: 3,
	plugins: {
		legend: {
			display: false
		},
		tooltip: {
			callbacks: {
				title: (items: TooltipItem<'bar'>[]) => {
					return items[0].label.split(',').join(' ');
				}
			}
		}
	},
	scales: {
		x: {
			grid: {
				display: false
			}
		}
	}
};

export interface DashboardDomainSimulationGraphData {
	graph: (StackedBarChartData | null)[];
	domains: string[];
	indicators: string[];
	lifeCycleSteps: string[];
	footprintType: number;
	indicator: string;
	lifeCycleStep: string;
	datasetRef: string | null;
}
export const DashboardDomainSimulationGraph = ({ showTable, data }: { showTable: boolean, data: DashboardDomainSimulationGraphData }) => {
	const { graph: graphData, indicator, datasetRef } = data;
	const { selectedIndicators: indicators } = useIndicators(data.indicators);
	const legendLen = Math.ceil(
		data.domains.reduce(
			(acc, domain) => acc > domain.length ? acc : domain.length, 0
		)
	) * 11;
	const id = useId();
	const unit = indicators?.find(i => i.id === indicator)?.unit ?? translate('results.valuePBCI') as string;
	return (<div className="dashboard_graph" style={{ paddingRight: showTable ? 0 : legendLen }}>
		{!showTable && <>
			<div className={'dashboard_chart_container'}>
				<div className="chart_indicator_unit">
					{unit}
				</div>
				<StackedBarChart
					data={graphData}
					options={options}
					thin={30}
					plugins={[DashboardPluginGraphBarLegend(id), DashboardPluginGraphBarPercentageOnTop()]}
					percentage={0.5}
					borderRadius={3}
					labelLength={30}
				/>
			</div>
			<div id={id} className="dashboard_graph_legend" />
		</>}
		{showTable && <table className="dashboard_table">
			<thead>
				<tr>
					<th>{translate('dataset')}</th>
					<th>{translate('unit')}</th>
					{graphData.filter((e) => e !== null)[0]?.values.map((e, i) => (
						<th key={i} className={'align-right'}>{e.scope}</th>
					))}
					<th className={'align-right'}>{translate('total')}</th>
				</tr>
			</thead>
			<tbody>
				{graphData.filter((e) => e !== null).map((e, i) => (
					<tr style={{ fontWeight: datasetRef === e?.label ? 'bold' : 'normal' }} key={i}>
						<td>{e?.label}</td>
						<td>{unit}</td>
						{e?.values.map((data, i) => (
							<td key={i} className={'value'}>
								<span className={'number value'}>
									{resultValue(data.value)}
								</span>
							</td>
						))}
						<td className={'value'}>
							<span className={'number value'}>
								{resultValue(e?.values.reduce((acc, cur) => acc + cur.value, 0))}
							</span>
						</td>
					</tr>
				))}
			</tbody>
		</table>}
	</div>);
};

const DashboardDomainSimulation = ({ data }: { data: DashboardComparisonData }): JSX.Element => {
	const { selectedIndicators: indicators } = useIndicators(data.indicators);
	const { lcs: lifeCycleSteps } = useLifeCycleSteps(data.lifeCycleSteps);
	const { blocks: domains } = useBlocks(data.domains);
	const [selectFootprintType, setSelectFootprintType] = React.useState<number>(0);
	const [selectIndicator, setSelectIndicator] = React.useState<string>('normalized');
	const [selectLifeCycleStep, setSelectLifeCycleStep] = React.useState<string>('all');
	const [showTable, setShowTable] = React.useState<boolean>(false);
	const datasetRef = data.datasets?.[0];

	const simus = data.datasets?.filter(d => d.id != datasetRef.id) ?? [];

	const parseDataset = (dataset: DashboardComparisonData['datasets'][number]) => {
		if (!domains) return null;
		const values = domains.map(domain => {
			const value = dataset.equipments.reduce((acc, equipment) => {
				if (equipment.domain !== domain.id) return acc;
				return acc + equipment.impacts.reduce((acc, impact) => {
					return acc + getImpactByIndicator(getImpactByLifeCycleStep(impact, selectLifeCycleStep), selectIndicator, indicators).value;
				}, 0);
			}, 0);
			return {
				scope: domain.shortName,
				value: valueFixedDigits(parseByFootprintTypeDashboard(selectFootprintType, dataset, value))
			};
		});
		return {
			label: dashboardLabelName({ id: dataset.id, data }),
			values
		};
	};

	const graphData: (StackedBarChartData | null)[] = [
		parseDataset(datasetRef),
		...simus.map(parseDataset)
	];
	if (graphData.length < 4) {
		graphData.unshift(null);
		graphData.push(null);
	}
	const saveData = {
		graph: graphData,
		domains: data.domains,
		indicators: data.indicators,
		lifeCycleSteps: data.lifeCycleSteps,
		footprintType: selectFootprintType,
		indicator: selectIndicator,
		lifeCycleStep: selectLifeCycleStep,
		datasetRef: graphData.filter(d => d != null)[0]?.label ?? ''
	};
	return <div className={'dashboard_card dashboard_card_color_red'}>
		<h3>{translate('dashboard.title.domainsSimulation')}</h3>
		<div className="dashboard_card_content">
			<select
				value={selectFootprintType}
				onChange={(e) => setSelectFootprintType(parseInt(e.target.value))}
			>
				<option value={0}>{translate('dashboard.select.globalFootprint')}</option>
				<option value={1}>{translate('dashboard.select.footprintPerUser')}</option>
			</select>
			<select
				value={selectIndicator}
				onChange={(e) => setSelectIndicator(e.target.value)}
			>
				<option value={'normalized'}>{translate('dashboard.select.indicator.normalized')} - {translate('results.valuePBCI')}</option>
				{indicators?.map((indicator) => (
					<option key={indicator.id} value={indicator.id}>{indicator.name} ({indicator.shortName}) - {indicator.unit}</option>
				))}
			</select>
			<select
				value={selectLifeCycleStep}
				onChange={(e) => setSelectLifeCycleStep(e.target.value)}
			>
				<option value={'all'}>{translate('dashboard.select.allLifeCycleSteps')}</option>
				{lifeCycleSteps?.map((lifeCycleStep) => (
					<option key={lifeCycleStep.id} value={lifeCycleStep.id}>{lifeCycleStep.name}</option>
				))}
			</select>
		</div>
		<div className="dashboard_card_content">
			<div className="dashboard_card_content_actions">
				<button
					type="button"
					className="button_blank dashboard_action"
					onClick={() => setShowTable(!showTable)}
				>
					<div className="dashboard_icon">
						{showTable && <i className="fa-solid fa-chart-column" />}
						{!showTable && <i className="fa-solid fa-table" />}
					</div>
					<div className="dashboard_text">
						{showTable && translate('dashboard.action.graph')}
						{!showTable && translate('dashboard.action.table')}
					</div>
				</button>
				<DashboardPinButton data={saveData} type={'DashboardDomainSimulation'} />
			</div>
			<DashboardDomainSimulationGraph showTable={showTable} data={saveData} />
		</div>
	</div>;
};

export default DashboardDomainSimulation;