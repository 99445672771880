import { gql } from '@apollo/client';

export const QUERY_GROUPS = gql`
	  query QUERY_GROUPS {
		  groups {
			  id
			  name
			  translate
			  permissions
			  grade
			  privileged
			  manageSameGrade
			  isStaff
          }
      }
`;
