import { EventGateway } from './EventGateway';
import { AdapterContract } from './AdapterContract';
import { PrimaryController } from './PrimaryController';
import { InventoryDataEntity } from '../entities/InventoryEntity';
import { UserEntity } from '../entities/UserEntity';
import { SettingsDataEntity } from '../entities/SettingsEntity';
import { MaturityDataEntity } from '../entities/MaturityEntity';
import { ValidationDataEntity } from '../entities/ValidationEntity';
import { ResultsEntity } from '../entities/ResultsEntity';
import { ResultLaunchersEntity } from '../entities/ResultLaunchersEntity';
import { StudiesEntity } from '../entities/StudiesEntity';
import { StaticPagesEntity } from '../entities/StaticPagesEntity';
import { CompanyEntity } from '../entities/CompanyEntity';
import { UserAssignmentsEntity } from '../entities/UserAssignmentsEntity';
import { LambdaInventoryEntity } from '../entities/LambdaInventoryEntity';
import { ApplicationService } from './ApplicationService';
import { UserDashboardEntity } from '../entities/UserDashboardEntity';
import { IndicatorEntity } from '../entities/IndicatorEntity';
import { LifeCycleStepEntity } from '../entities/LifeCycleStepEntity';
import { SampleEntity } from '../entities/SampleEntity';
import { DashboardEntity } from '../entities/DashboardEntity';
import { DatasetsEntity } from '../entities/DatasetsEntity';
import { BlockEntity } from '../entities/BlockEntity';
import { ApolloClientProvider } from '../../infrastructure/ApolloClient/ApolloClientProvider';
import { IAPIProps } from '../interfaces/IAPIProps';
import { DocumentNode, OperationVariables } from '@apollo/client';
import { LifeCycleAssessmentEntity } from '../entities/LifeCycleAssessmentEntity';
import { ManageEquipmentEntity } from '../entities/ManageEquipmentEntity';
import { CmdbEntity } from '../entities/CmdbEntity';

export type ServiceInstall = new (application: Core) => ApplicationService;

/**
 * Core
 *
 * Core of the domain application.
 * Used to install entities, services and adapters.
 *
 * - entities are data store.
 * - gateway is used to receive events.
 * - services are used to handle events and use adapters.
 * - adapters are used to communicate with infrastructure.
 *
 * @author Maximilien Valenzano
 */
export class Core {

	public static self: Core;

	constructor(gateway: EventGateway, adapter: AdapterContract) {
		this._adapter = adapter;
		this._gateway = gateway;
		if (!Core.self) Core.self = this;
		this._gateway.setPrimaryController(new PrimaryController(this));
		this._entities = {
			user: new UserEntity(this),
			company: new CompanyEntity(this),
			userAssignments: new UserAssignmentsEntity(this),
			studies: new StudiesEntity(this),
			datasets: new DatasetsEntity(this),
			settings: new SettingsDataEntity(this),
			inventory: new InventoryDataEntity(this),
			equipments: new ManageEquipmentEntity(this),
			maturity: new MaturityDataEntity(this),
			validation: new ValidationDataEntity(this),
			staticPage: new StaticPagesEntity(this),
			results: new ResultsEntity(this),
			resultLaunchers: new ResultLaunchersEntity(this),
			lambdaInventory: new LambdaInventoryEntity(this),
			userDashboard: new UserDashboardEntity(this),
			dashboard: new DashboardEntity(this),
			indicators: new IndicatorEntity(this),
			lifeCycleSteps: new LifeCycleStepEntity(this),
			lca: new LifeCycleAssessmentEntity(this),
			samples: new SampleEntity(this),
			blocks: new BlockEntity(this),
			cmdb: new CmdbEntity(this)
		};
	}

	private _gateway: EventGateway;

	get gateway(): EventGateway {
		return this._gateway;
	}

	private _adapter: AdapterContract;

	get adapter(): AdapterContract {
		return this._adapter;
	}

	private _entities : {
		user: UserEntity,
		company: CompanyEntity,
		userAssignments: UserAssignmentsEntity,
		studies: StudiesEntity,
		datasets: DatasetsEntity,
		settings: SettingsDataEntity,
		inventory: InventoryDataEntity,
		equipments: ManageEquipmentEntity,
		maturity: MaturityDataEntity,
		validation: ValidationDataEntity,
		staticPage: StaticPagesEntity,
		results: ResultsEntity,
		resultLaunchers: ResultLaunchersEntity,
		lambdaInventory: LambdaInventoryEntity,
		userDashboard: UserDashboardEntity,
		dashboard: DashboardEntity,
		indicators: IndicatorEntity,
		lifeCycleSteps: LifeCycleStepEntity,
		lca: LifeCycleAssessmentEntity,
		samples: SampleEntity,
		blocks: BlockEntity,
		cmdb: CmdbEntity
	};

	get entities(): Entities {
		return this._entities;
	}

	/**
	 * This function will replace adapterInstaller
	 * It is call on Entities, at each initialization() call
	 */
	public installer<O extends object, I extends OperationVariables | undefined> (apolloClass: new (query: DocumentNode) => ApolloClientProvider<O, I>, queryDocument: DocumentNode): (apiProps: IAPIProps, input?: I) => Promise<O> {
		const caller = new apolloClass(queryDocument);
		return async (apiProps: IAPIProps, input?: I): Promise<O> => {
			const data = await caller.exec(apiProps, input);
			if (!data) throw new Error('No data returned');
			return data;
		};
	}
}

export type Entities = Core['_entities'];
