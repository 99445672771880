import React from 'react';
import { translate } from '../../infrastructure/translations/translate';
import { resultValue } from './utils/resultValue';
import { ResultCardError } from '../error/ResultCardError/ResultCardError';
import { IIndicator } from '../../domain/interfaces/IIndicator';
import { useSelector } from 'react-redux';
import { State } from '../../store';
import { ResultArrowComparison, ResultComparison } from './ResultArrowComparison';

const getIndicatorStore = () : IIndicator[] | undefined => {
	const { error, data } = useSelector((state: State) => state.dataIndicatorsStatus);

	const indicators = data?.data;
	if (error || !indicators) return undefined;
	return indicators;
};

export type ResultEnvironmentalBudgetType = { schema: { fields: { name: string, type: string }[], primaryKey: string[], pandas_version: string }, data: { indicator: string, index: number, value: number, mean?: number, min?: number, max?: number, std?: number, name: string }[] }
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isResultEnvironmentalBudget = (o: any): o is ResultEnvironmentalBudgetType => {
	if (typeof o === 'object') return 'schema' in o && 'data' in o;
	return false;
};
export const ResultEnvironmentalBudget = ({ data }: { data: string }): JSX.Element => {
	if (!isResultEnvironmentalBudget(data)) return (<ResultCardError/>);

	// transform indicators ID to name
	const indicators = getIndicatorStore();
	if (!indicators) return (<ResultCardError/>);
	const indicatorObj = indicators.find(i => i.id === data.data[0].indicator);

	const value = data.data[0].value;
	const indicator = indicatorObj?.shortName;
	const name = indicatorObj?.name;
	return (
		<>
			<p className={'text-center'}>
				<span style={{ fontSize: '3em' }} className={'m2'}>{resultValue(value)}</span>
				<span style={{ fontSize: '2em' }}>{translate('results.eqEarth')}</span>
				<ResultArrowComparison arrow={ResultComparison({					value: value,
					mean: data.data[0].mean,
					min: data.data[0].min,
					max: data.data[0].max,
				})}/>
			</p>
			<p className={'text-center'} style={{ fontSize: '2em' }}>
				{name} ({indicator})
			</p>
		</>
	);
};
