import { gql } from '@apollo/client';

export const MUTATE_COLOR_MANAGEMENT = gql`
    mutation MUTATE_COLOR_MANAGEMENT(
        $studyId: String!,
        $colors: String!
    ){
        mutateColorManagement(
            studyId: $studyId,
            colors: $colors
        ){
            status
            colors {
                indicators {
                    itemId
                    color
                }
                blocks {
                    itemId
                    color
                }
                lifeCycleSteps {
                    itemId
                    color
                }
            }
        }
    }
`;
