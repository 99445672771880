import React, { useState } from 'react';
import { translate } from '../../infrastructure/translations/translate';
import { ResultCardError } from '../error/ResultCardError/ResultCardError';
import { useTransformedResultTableData } from '../../utils/useTransformedResultTableData';
import { IResultTableDataOutput } from '../../utils/getResultTableData';
import { resultValue } from './utils/resultValue';
import { aggregateData } from './utils/InventoryOverview/AggregateInventoryData';

export type ResultInventoryOverviewResultType = { data: {
	equipment_type: string; 
	category: string;
	block: string;
	quantity: number;
	nb_users: number;
	lifetime: number;
}[] }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isResultInventoryOverviewResult = (o: any): o is ResultInventoryOverviewResultType => {
	if (typeof o === 'object') return 'schema' in o && 'data' in o;
	return false;
};


export const ResultInventoryOverview = ({ data }: { data: IResultTableDataOutput[] }): JSX.Element => {
	// Initial data validation check
	if (!isResultInventoryOverviewResult(data)) return <ResultCardError />;

	const [block, setBlock] = useState<string>('all');
	const [scope, setScope] = useState<'category' | 'equipment'>('category');

	const transformedData = useTransformedResultTableData(data.data);
	if (!transformedData || transformedData instanceof Error) return <ResultCardError />;

	const aggregatedData = aggregateData(transformedData, scope, block);

	const uniqueBlocks = Array.from(new Set(transformedData.map((d) => d.block)));
	const blocks = ['all', ...uniqueBlocks];

	return (
		<>
			<div className='result_selectors'>
				<select
					name="scope"
					id="scope"
					value={scope}
					onChange={(e) => setScope(e.target.value as 'category' | 'equipment')}
				>
					<option value="category">{translate('results.category')}</option>
					<option value="equipment">{translate('equipment')}</option>
				</select>
				<select
					name="block"
					id="block"
					value={block}
					onChange={(e) => setBlock(e.target.value)}
				>
					{blocks.map((d) => (
						<option key={d} value={d}>
							{d === 'all' ? translate('results.allDomains') : d}
						</option>
					))}
				</select>
			</div>
			<table className='result_table'>
				<thead>
					<tr>
						<th>{scope === 'category' ? 'Category' : 'Equipment Type'}</th>
						<th>{scope === 'category' ? translate('results.averageQuantity') : translate('results.quantity')}</th>
						<th>{scope === 'category' ? translate('results.averageQuantityByUsers') : translate('results.quantityByUsers')}</th>
						<th>{scope === 'category' ? translate('results.averageLifetime') : translate('results.lifetime')}</th>
					</tr>
				</thead>
				<tbody>
					{aggregatedData.map((item, index) => (
						<tr key={index}>
							<td>{scope === 'category' ? item.category : item.equipment_type?.name}</td>
							<td>{resultValue(item.quantity)}</td>
							<td>{resultValue(item.quantity_by_users)}</td>
							<td>{resultValue(item.lifetime)}</td>
						</tr>
					))}
				</tbody>
			</table>
		</>
	);
};
