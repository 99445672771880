import { gql } from '@apollo/client';

export const QUERY_LIFE_CYCLE_ASSESSMENT_PARAMETERS = gql`
    query QueryLifeCycleAssessmentParameters{
        lifeCycleAssessmentParameters {
            indicators {
                id
                name
                unit
                shortName
                icon
                order
                planetaryBoundary
            }
            lifeCycleSteps {
                id
                name
                code
                order
            }
            energyMix {
                id
                name
                deprecated
            }
            energyMixFactorSets {
                id
                energyMixId
                version
                updatedAt
            }
        }
    }
`;
