import { gql } from '@apollo/client';

export const QUERY_SAMPLE_LIST = gql`
    query QuerySampleList (
        $functionalUnitId: String
    ){
        sampleList (
            functionalUnitId: $functionalUnitId
        ){
            id
            name
            desc
            functionalUnit
        }
    }
`;