import { Core } from './Core';
import { EventType } from './EventType';
import { ApplicationService } from './ApplicationService';

/**
 * Primary Controller
 *
 * Provide use cases for the application
 * It's a funnel to determine which service to use
 *
 * Implement use cases at runtime by infrastructure events
 *
 * @author Maximilien Valenzano
 */
export class PrimaryController {
	constructor(protected core: Core) {}

	private _useCases = new Map<EventType, ApplicationService>();

	addUseCase(eventType: EventType, useCase: ApplicationService) {
		this._useCases.set(eventType, useCase);
	}

	useCase(eventType: EventType): ApplicationService {
		const use = this._useCases.get(eventType);
		if (use) return use;
		throw new Error(`No use case provided for ${eventType}`);
	}
}
