import { gql } from '@apollo/client';

export const QUERY_MODAL_EQUIPMENTS_INFOS = gql`
	query ModalEquipmentsInfos (
		$datasetId: String!
	) {
		modalEquipmentsInfos (datasetId: $datasetId) {
			id
			name
			factorSet (datasetId: $datasetId) {
				equipmentTypeId,
				equipmentTypeName,
				equipmentTypeSource,
				equipmentTypeShortNameAndSpecification
				dataReference {
					reference,
					isHash
				}
				version,
				updatedAt
			}
		}
	}
`;