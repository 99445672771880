import { numberToStringSeparator } from '../../../utils/numberToStringSeparator';
import { roundValueOrScientific } from '../../../utils/roundValueOrScientific';

/**
 * Use converters to convert the value to a string for results
 * if the value is over 100, no decimal digits
 * if the value is between 1 and 100, show with 1 digit
 * if the value is between 0.01 and 1, show with 2 digits
 * if the value is under 0.01, show in scientific notation
 *
 * @param {number} value - the number to convert
 *
 * @returns {string} - the converted number
 *
 * @autor Maximilien Valenzano
 */

export function resultValue(value: number | undefined): string {
	if (value === undefined) return '';
	else if (value >= 1) return numberToStringSeparator(value);
	else return roundValueOrScientific(value);
}

export function resultValueMoreDecimal(value: number | undefined): string {
	if (value === undefined) return '';
	else if (value >= 1) return numberToStringSeparator(value);
	else {
		if (value >= 0.01) return value.toLocaleString(navigator.language, {
			minimumFractionDigits: 0,
			maximumFractionDigits: 3
		});
		else if (value === 0) return '0';
		return value.toExponential(1);
	}
}