import { DashboardComparisonData, DashboardComparisonMaturity } from '../../domain/interfaces/DashboardComparisonData';
import { DashboardComparisonBenchmark } from './Comparison/CreateDashboardComparisonBenchmarkModal';
import { translate } from '../../infrastructure/translations/translate';
import { dashboardLabelName } from './DashboardLabelName';
import React from 'react';
import { unique } from '../../viewComponents/results/utils/Unique';

/**
 * Component to select a reference for your graph
 * If the graph is evolution, we will take only year as reference
 *
 * @param data - Data used for dashboard
 * @param reference - Reference selected
 * @param setReference - Function to set the reference
 * @param isEvolution - If the graph is evolution mode
 * @param benchmark - Benchmark data
 * @constructor
 */
export const DashboardSelectReference = ({
	data,
	reference,
	setReference,
	isEvolution,
	benchmark
}: {
	data: DashboardComparisonData | DashboardComparisonMaturity,
	reference: string | null,
	setReference: (datasetRef: string) => void,
	isEvolution?: boolean,
	benchmark?: DashboardComparisonBenchmark,
}): JSX.Element => {
	const multipleDatasets = !!benchmark || data.datasets.length > 1;
	if (!multipleDatasets) return <></>;

	// Get all the years from the datasets and benchmark if it exists
	const years = data.datasets.map(dataset => dataset.year).filter(unique);
	if (benchmark) {
		const benchmarkYears = benchmark.data.datasets.map(dataset => dataset.year);
		benchmarkYears.forEach(year => {
			if (!years.includes(year)) years.push(year);
		});
	}
	years.sort();

	const renderList = () => {
		if (isEvolution) return years.map((year, key) => (
			<option
				key={`${year}-${key}`}
				value={year}
			>
				{year}
			</option>
		));

		return data.datasets.map((dataset, key) => (
			<option
				key={`${dashboardLabelName({ id: dataset.id, data })}-${key}`}
				value={`${dashboardLabelName({ id: dataset.id, data })}`}
			>
				{`${dashboardLabelName({ id: dataset.id, data })}`}
			</option>
		));
	};

	return (<div className="dashboard_card_content_select_box">
		<label htmlFor="selectAReference">{translate('dashboard.equipment.select.a.reference')}</label>
		<select
			id="selectAReference"
			value={reference ?? ''}
			onChange={(e) => setReference(e.target.value ?? null)}
		>
			<option value={''}>{translate('dashboard.select.noDataset')}</option>
			{renderList()}
			{benchmark && !isEvolution && <option value={benchmark.sampleName}>{benchmark.sampleName}</option>}
		</select>
	</div>);
};