// ContactView.tsx
import React from 'react';
import Template from '../../viewComponents/template/template/template';
import { Study } from '../../domain/data/entries/Study';
import './ContactView.scss';
import { translate } from '../../infrastructure/translations/translate';
import { useParams } from 'react-router-dom';
import { useStudies } from '../../viewComponents/hooks/useStudies';


/**
 * @description This component is used to display the contact page
 * @description It displays the list of consultants for each study
 * @author Yacine Bentayeb
 * @see Template
 * @returns JSX.Element
 */

const ContactView = (): JSX.Element => {
	const { loading, error, studies } = useStudies();

	const studyId = useParams<{ study: string }>().study;
	const displayedStudies = studyId ? studies?.filter((study: Study) => study.id === studyId) : studies;

	if (loading) return (<Template loading={loading}/>);
	if (error) return (<Template error={error}/>);
	if (studies && !studies.length) return (<Template core={<section><h3>{translate('home.noStudyYet')}</h3></section>} side={{
		content: {
			list: []
		}
	}}/>);

	const core = (
		<section className='contact_view'>
			{displayedStudies?.sort((a: Study, b: Study) => a.name.localeCompare(b.name)).map((study) => (
				<div key={study.id} className='contact_study'>
					<h2 className='contact_study_title'>
						{study.name} - ({study.companyFk.name})
					</h2>
					<table>
						<thead>
							<tr>
								<th>{translate('contact.consultantName')}</th>
								<th>
									<div className='contact_column_title'>
										<i className='fas fa-envelope'/>
										{translate('contact.email')}
									</div>
								</th>
								<th>
									<div className='contact_column_title'>
										<i className='fas fa-phone'/>
										{translate('contact.phoneNo')}
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							{study.consultants?.map((consultant) => (
								<tr key={consultant.id} className='contact_consultant'>
									{consultant.firstName && consultant.lastName
										? <td>{consultant.firstName} {consultant.lastName}</td>
										: <td>{consultant.username}</td>
									}
									<td><a href={`mailto:${consultant.email}`}>{consultant.email}</a></td>
									<td><a href={`tel:${consultant.phoneNumber}`}>{consultant.phoneNumber}</a></td>
								</tr>
							))}	
						</tbody>
					</table>
				</div>
			))}
		</section>
	);
	return (<Template core={core}/>);
};

export default ContactView;