import { gql } from '@apollo/client';

export const QUERY_GOOD_PRACTICES = gql`
    query QueryGoodPractices{
        goodPractices {
            id
            name
            desc
            priority
            difficulty
        }
    }
`;
