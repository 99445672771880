import { gql } from '@apollo/client';

export const QUERY_EQUIVALENCES = gql`
    query QueryEquivalences{
    	equivalences{
    		id
    		name
    		value
    		indicator
    		magnitude
			unit
			icon
			hypothesis
		}
    }
`;
