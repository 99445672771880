import { IUserAssignment, IUserAssignmentMutate } from '../interfaces/IUserAssignment';
import { Entity } from '../core/Entity';
import {
	ApolloClientMutateUserAssignments,
	ApolloClientQueryUserAssignments
} from '../../infrastructure/ApolloClass/UserClass';
import { QUERY_USER_ASSIGNMENTS } from '../../infrastructure/ApolloClient/requests/QUERY_USER_ASSIGNMENTS';
import { MUTATE_USER_ASSIGNMENTS } from '../../infrastructure/ApolloClient/requests/MUTATE_USER_ASSIGNMENTS';

export class UserAssignmentsEntity extends Entity {
	public data: IUserAssignment[] | undefined = [];

	initialization() {
		this.app.adapter.queryUserAssignments ??= this.app.installer(ApolloClientQueryUserAssignments, QUERY_USER_ASSIGNMENTS);
		this.app.adapter.mutateUserAssignments ??= this.app.installer(ApolloClientMutateUserAssignments, MUTATE_USER_ASSIGNMENTS);
		this.app.adapter.storeUserAssignments?.(this);
	}

	change(): Promise<void> | undefined {
		return this.app.adapter.storeUserAssignments?.(this);
	}
	set(obj: IUserAssignment[] | undefined): void {
		this.data = obj;
		this.change();
	}
	update(lines: IUserAssignment[]): void {
		lines.forEach(line => {
			if (!this.data) this.data = [];
			if (!this.data.find(l => l.lineId === line.lineId && l.email === line.email))
				this.data.push(line);
		});
		this.change();
	}

	/***************************************************
	 * 					API CALLS					   *
	 ***************************************************/

	async queryUserAssignments(lineId: string): Promise<IUserAssignment[]> {
		const data = await this.callApi(this.app.adapter.queryUserAssignments, { lineId });
		this.update(data.assignments);
		return data.assignments;
	}

	async mutateUserAssignments(input: IUserAssignmentMutate[]) {
		const data = await this.callApi(this.app.adapter.mutateUserAssignments, { userAssignments: input });
		if (!data || data.mutateUserAssignments.status !== 200) return false;
		for (const line of data.mutateUserAssignments.equipments) {
			this.app.entities.inventory.update(line);
		}
		this.app.adapter.storeTemporaryAssignedUsersEmails?.(data.mutateUserAssignments.tempsMailList);
		return true;
	}
}
