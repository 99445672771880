import { ApolloClientProvider } from '../ApolloClient/ApolloClientProvider';
import { IMutateCreateDatasetFromTemplateInput, IMutateCreateDatasetFromTemplateOutput } from '../../domain/interfaces/IMutateCreateDatasetFromTemplate';
import { IAPIProps } from '../../domain/interfaces/IAPIProps';
import { IMutateCheckComputeStatusOutput } from '../../domain/interfaces/IMutateCheckComputeStatus';
import { ILaunchCompute } from '../../domain/interfaces/ILaunchCompute';
import { IMutateDatasetManagementInput, IMutateDatasetManagementResponse } from '../../domain/interfaces/IMutateDatasetManagement';
import { IMutateInventoryExtraEditInput, IMutateInventoryExtraEditOutput } from '../../domain/interfaces/IMutateInventoryExtraEdit';
import { ILockDataset, ResponseLockDataset } from '../../domain/interfaces/ILockDataset';
import {
	IMutateRequestCalculationInput,
	IMutateRequestCalculationOutput
} from '../../domain/interfaces/IMutateRequestCalculation';
import { IQueryDatasetsInput, IQueryDatasetsOutput } from '../../domain/interfaces/IQueryDatasets';
import { IQueryIsQualityEmptyInput, IQueryIsQualityEmptyOutput } from '../../domain/interfaces/IQueryIsQualityEmpty';
import { IQueryInventoryExtraInput, IQueryInventoryExtraOutput } from '../../domain/interfaces/IQueryInventoryExtra';
import { ICopyDataset, ResponseCopyDataset } from '../../domain/interfaces/ICopyDataset';
import { IMutateFilterIndicatorsInput, IMutateFilterIndicatorsResponse } from '../../domain/interfaces/IMutateFilterIndicators';
import {
	IQueryLifeCycleAssessmentParametersVersionsInput,
	IQueryLifeCycleAssessmentParametersVersionsOutput
} from '../../domain/interfaces/IQueryLifeCycleAssessmentParametersVersions';

export class ApolloClientMutateCreateDatasetFromTemplate extends ApolloClientProvider<IMutateCreateDatasetFromTemplateOutput, IMutateCreateDatasetFromTemplateInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateCreateDatasetFromTemplateInput): Promise<IMutateCreateDatasetFromTemplateOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateCopyDataset extends ApolloClientProvider<ResponseCopyDataset, ICopyDataset> {
	exec({ token, lang, adapter }: IAPIProps, data: ICopyDataset): Promise<ResponseCopyDataset | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateCheckComputeStatus extends ApolloClientProvider<IMutateCheckComputeStatusOutput, ILaunchCompute> {
	exec({ token, lang, adapter }: IAPIProps, data: ILaunchCompute): Promise<IMutateCheckComputeStatusOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateDatasetManagement extends ApolloClientProvider<IMutateDatasetManagementResponse, IMutateDatasetManagementInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateDatasetManagementInput): Promise<IMutateDatasetManagementResponse | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateFilterIndicators extends ApolloClientProvider<IMutateFilterIndicatorsResponse, IMutateFilterIndicatorsInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateFilterIndicatorsInput): Promise<IMutateFilterIndicatorsResponse | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateEditInventoryExtra extends ApolloClientProvider<IMutateInventoryExtraEditOutput, IMutateInventoryExtraEditInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateInventoryExtraEditInput): Promise<IMutateInventoryExtraEditOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateLockDataset extends ApolloClientProvider<ResponseLockDataset, ILockDataset> {
	exec({ token, lang, adapter }: IAPIProps, data: ILockDataset): Promise<ResponseLockDataset | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateRequestCalculation extends ApolloClientProvider<IMutateRequestCalculationOutput, IMutateRequestCalculationInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateRequestCalculationInput): Promise<IMutateRequestCalculationOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientQueryDatasets extends ApolloClientProvider<IQueryDatasetsOutput, IQueryDatasetsInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IQueryDatasetsInput): Promise<IQueryDatasetsOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientQueryIsQualityEmpty extends ApolloClientProvider<IQueryIsQualityEmptyOutput, IQueryIsQualityEmptyInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IQueryIsQualityEmptyInput): Promise<IQueryIsQualityEmptyOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientQueryInventoryExtra extends ApolloClientProvider<IQueryInventoryExtraOutput, IQueryInventoryExtraInput>{}
export class ApolloClientQueryLifeCycleAssessmentParametersVersions extends ApolloClientProvider<IQueryLifeCycleAssessmentParametersVersionsOutput, IQueryLifeCycleAssessmentParametersVersionsInput>{}