import { IIndicator } from '../../domain/interfaces/IIndicator';

/**
 * Return the impact of the indicator depending on the selected indicator
 * If normalized, return the impact normalized by the planetary boundary (all indicators on the same scale)
 * An indicator can not be normalized if it doesn't have a planetary boundary
 *
 * @param impact
 * @param selectIndicator
 * @param indicators
 */
export const getImpactByIndicator = (impact: {
    indicator: string,
    lifeCycleStep: string,
    value: number
}, selectIndicator: string, indicators?: IIndicator[]) => {
	if (selectIndicator === 'normalized') {
		const indicator = indicators?.find(i => i.id === impact.indicator);
		if (!indicator?.planetaryBoundary) return { ...impact, value: 0 };
		return { ...impact, value: impact.value / indicator.planetaryBoundary };
	} else if (impact.indicator === selectIndicator) {
		return impact;
	}
	return { ...impact, value: 0 };
};