import React from 'react';
import { IModal } from '../../../domain/interfaces/IModal';
import { translate } from '../../../infrastructure/translations/translate';
import { Study } from '../../../domain/data/entries/Study';
import { Alert } from '../../../viewComponents/Alerts';
import { unique } from '../../../viewComponents/results/utils/Unique';
import {
	MultiChoiceDropdownDatasetResultReady
} from '../../../viewComponents/utils/MultiChoiceDropdownDatasetResultReady/MultiChoiceDropdownDatasetResultReady';
import { useModal } from '../../../viewComponents/modal/useModal';

export const createDashboardModalComparison = (
	open: boolean,
	setOpen: (value: boolean) => void,
	setComparisonDatasets: (value: string[]) => void,
	studies: Study[] | undefined
): IModal | undefined => {
	const [selectDatasetsIds, setSelectDatasetsIds] = React.useState<string[]>([]);
	const [functionalUnit, setFunctionalUnit] = React.useState<string>('');

	if (!open) return;

	const clear = () => {
		setSelectDatasetsIds([]);
		setFunctionalUnit('');
		useModal(false, { visible: false, body: undefined, header: '' });
	};
	const action = () => {
		setComparisonDatasets(selectDatasetsIds);
		clear();
		setOpen(false);
	};

	const functionalUnits = studies?.map(s => s.functionalUnit.name).filter(unique);

	const handleFunctionalUnitChange = (value: string) => {
		setFunctionalUnit(value);
		setSelectDatasetsIds([]);
	};

	const body = <div className="dashboard_modal">
		<Alert variant={'info'}>
			{translate('dashboard.alert.usedIndicatorsAndLifeCycleSteps')}
			<br/><br/>
			{translate('dashboard.alert.functionalUnit')}
		</Alert>
		<select
			id={'functionalUnit'}
			className={'dashboard_modal__select_functional_unit'}
			onChange={(e) => handleFunctionalUnitChange(e.target.value)}
			value={functionalUnit ?? undefined}
			aria-label={translate('dashboard.modal.choose.functionalUnit') as string}
		>
			<option value={''}>{translate('dashboard.modal.choose.functionalUnit')}</option>
			{functionalUnits?.map((fu, index) => <option value={fu} key={index}>{fu}</option>)}
		</select>
		{functionalUnit.length > 0 && <div className="dashboard_modal__studies">
			<MultiChoiceDropdownDatasetResultReady
				selectDatasetsIds={selectDatasetsIds}
				setSelectDatasetsIds={setSelectDatasetsIds}
				functionalUnit={functionalUnit}
			/>
		</div>}
	</div>;

	return {
		action,
		actionText: 'validate',
		body,
		header: translate('dashboard.modal.choose.a.study.and.a.dataset'),
		onClose(): void {
			clear();
			setOpen(false);
		},
		visible: true
	};
};

