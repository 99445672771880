import React from 'react';
import './DashboardView.scss';
import Template from '../../viewComponents/template/template/template';
import { SideBarContentItemList } from '../../viewComponents/template/sidebar/sidebar';
import { translate } from '../../infrastructure/translations/translate';
import { useNavigate, useParams } from 'react-router-dom';
import DashboardButton from './DashboardButton';
import DashboardTableIcon from '../../media/dashboard/table.svg';
import DashboardACVIcon from '../../media/dashboard/acv.svg';
import DashboardComparisonIcon from '../../media/dashboard/comparison.svg';
import DashboardEvolutionIcon from '../../media/dashboard/evolution.svg';
import DashboardSimulationIcon from '../../media/dashboard/simulation.svg';
import { createDashboardModalACV } from './ACV/DashboardModalACV';
import { createDashboardModalComparison } from './Comparison/CreateDashboardModalComparison';
import { changeDashboard } from '../../store/dispatchers';
import DashboardComparison from './Comparison/DashboardComparison';
import { createDashboardTableCreationModal } from './Table/DashboardTableCreationModal';
import { DashboardTable } from './Table/DashboardTable';
import { createDashboardModalSimulation } from './Simulation/CreateDashboardModalSimulation';
import DashboardSimulation from './Simulation/DashboardSimulation';
import { useDashboards } from '../../viewComponents/hooks/useDashboards';
import { useUsersDashboard } from '../../viewComponents/hooks/useUsersDashboard';
import { useUser } from '../../viewComponents/hooks/useUser';
import { useModal } from '../../viewComponents/modal/useModal';
import { useStudies } from '../../viewComponents/hooks/useStudies';
import { createDashboardModalEvolution } from './Evolution/CreateDashboardModalEvolution';
import DashboardEvolution from './Evolution/DashboardEvolution';

const DashboardOverviewView = (): JSX.Element => {
	const navigate = useNavigate();
	const { dashboards } = useDashboards();
	const { data: user } = useUser();
	const { studies } = useStudies();
	const { usersDashboards: userDashboardData } = useUsersDashboard();

	const [openACVModal, setOpenACVModal] = React.useState<boolean>(false);
	const [openComparisonModal, setOpenComparisonModal] = React.useState<boolean>(false);
	const [openSimulationModal, setOpenSimulationModal] = React.useState<boolean>(false);
	const [openEvolutionModal, setOpenEvolutionModal] = React.useState<boolean>(false);
	const [openTableCreationModal, setOpenTableCreationModal] = React.useState<boolean>(false);

	const loggedId = user?.id;
	const userDashboardList = userDashboardData.filter(d => d.userId == loggedId) ?? [];
	const sharedUserDashboardList = userDashboardData.filter(d => d.userId != loggedId) ?? [];

	useModal(openACVModal, createDashboardModalACV(openACVModal, setOpenACVModal));

	const setComparisonData = (datasetsIds: string[]) => {
		changeDashboard({ type: 'comparison', data: datasetsIds });
		navigate('/dashboard/comparison/new');
	};
	useModal(false, createDashboardModalComparison(
		openComparisonModal,
		setOpenComparisonModal,
		setComparisonData,
		studies
	));

	const setSimulationData = (datasetsIds: string[]) => {
		changeDashboard({ type: 'simulation', data: datasetsIds });
		navigate('/dashboard/simulation/new');
	};
	useModal(openSimulationModal, createDashboardModalSimulation(
		openSimulationModal,
		setOpenSimulationModal,
		setSimulationData,
		studies
	));

	useModal(openTableCreationModal, createDashboardTableCreationModal(openTableCreationModal, setOpenTableCreationModal));

	const setEvolutionData = (datasetsIds: string[]) => {
		changeDashboard({ type: 'evolution', data: datasetsIds });
		navigate('/dashboard/evolution/new');
	};

	useModal(openEvolutionModal, createDashboardModalEvolution(
		openEvolutionModal,
		setOpenEvolutionModal,
		setEvolutionData,
		studies
	));

	const color = (type: string) => {
		switch (type) {
			case 'comparison':
				return 'blue';
			case 'evolution':
				return 'yellow';
			case 'simulation':
				return 'red';
			default:
				return 'green';
		}
	};

	const icon = (type: string) => {
		switch (type) {
			case 'comparison':
				return DashboardComparisonIcon;
			case 'evolution':
				return DashboardEvolutionIcon;
			case 'simulation':
				return DashboardSimulationIcon;
			default:
				return DashboardTableIcon;
		}
	};

	const side: SideBarContentItemList = {
		content: {
			list: [
				{
					id: 'overview',
					name: translate('dashboard.overview.title') as string,
					current: true,
					link: '/dashboard/overview',
				},
			]
		}
	};
	const core = <section>
		<DashboardButton
			size={'long'}
			color={'green_light'}
			description={translate('dashboard.create.table.button.desc') as string}
			icon={DashboardTableIcon}
			onClick={() => setOpenTableCreationModal(true)}
		>
			{translate('dashboard.create.table.button.title')}
		</DashboardButton>
		<div className="dashboard_results">
			<h4>{translate('dashboard.results.title')}</h4>
			<div className="buttons">
				<DashboardButton
					color={'green'}
					description={translate('dashboard.acv.button.desc') as string}
					icon={DashboardACVIcon}
					onClick={() => setOpenACVModal(true)}
				>
					{translate('dashboard.acv.button.title')}
				</DashboardButton>
				<DashboardButton
					color={'yellow'}
					description={translate('dashboard.evolution.button.desc') as string}
					icon={DashboardEvolutionIcon}
					onClick={() => setOpenEvolutionModal(true)}
				>
					{translate('dashboard.evolution.button.title')}
				</DashboardButton>
				<DashboardButton
					color={'blue'}
					description={translate('dashboard.comparison.button.desc') as string}
					icon={DashboardComparisonIcon}
					onClick={() => setOpenComparisonModal(true)}
				>
					{translate('dashboard.comparison.button.title')}
				</DashboardButton>
				<DashboardButton
					color={'red'}
					description={translate('dashboard.simulation.button.desc') as string}
					icon={DashboardSimulationIcon}
					onClick={() => setOpenSimulationModal(true)}
				>
					{translate('dashboard.simulation.button.title')}
				</DashboardButton>
			</div>
		</div>
		{userDashboardList.length > 0 && <div className="dashboard_list">
			<h4>{translate('dashboard.myDashboardList.title')}</h4>
			<div className="buttons">
				{userDashboardList.map((dashboard) => (
					<DashboardButton
						key={dashboard.id}
						size={'short'}
						color={'green_table'}
						icon={DashboardTableIcon}
						description={dashboard.description}
						onClick={() => navigate(`/dashboard/overview/${dashboard.id}`)}
					>
						{dashboard.name}
					</DashboardButton>
				))}
			</div>
		</div>}
		{sharedUserDashboardList.length > 0 && <div className="dashboard_list">
			<h4>{translate('dashboard.sharedDashboardList.title')}</h4>
			<div className="buttons">
				{sharedUserDashboardList.map((dashboard) => (
					<DashboardButton
						key={dashboard.id}
						size={'short'}
						color={'green_table'}
						icon={DashboardTableIcon}
						description={dashboard.description}
						onClick={() => navigate(`/dashboard/overview/${dashboard.id}`)}
					>
						{dashboard.name}
					</DashboardButton>
				))}
			</div>
		</div>}
		{dashboards && dashboards.length > 0 && <div className="dashboard_list">
			<h4>{translate('dashboard.dashboard.saved.title')}</h4>
			<div className="buttons">
				{dashboards.map((dashboard) => (
					<DashboardButton
						key={dashboard.id}
						size={'short'}
						color={color(dashboard.type)}
						icon={icon(dashboard.type)}
						description={translate(`dashboard.${dashboard.type}`) as string}
						onClick={() => navigate(`/dashboard/${dashboard.type}/${dashboard.id}`)}
					>
						{dashboard.name}
					</DashboardButton>
				))}
			</div>
		</div>}
	</section>;
	return <Template core={core} side={side} />;
};

const DashboardView = (): JSX.Element => {
	const { dashboard, dashboardId } = useParams<{ dashboard: string, dashboardId: string }>();

	const core = <section>
		<h2>{translate('dashboard.feature.to.be.implemented')}</h2>
	</section>;
	if (dashboard === 'comparison' && dashboardId) return (<DashboardComparison />);
	if (dashboard === 'simulation' && dashboardId) return (<DashboardSimulation />);
	if (dashboard === 'evolution' && dashboardId) return (<DashboardEvolution />);
	if (dashboard === 'overview' && dashboardId) return (<DashboardTable />);
	if (dashboard !== 'overview') return (<Template core={core} />);

	return <DashboardOverviewView />;
};

export default DashboardView;