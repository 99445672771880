/**
 * Convert rgb to hex
 * @param {[number,number,number]} rgb
 * @returns {string}
 *
 * @author Maximilien Valenzano
 */
export const rgbToHex = (rgb: [number, number, number]): string => {
	return rgb.reduce((p, c) => {
		c = c < 0 ? 0 : c;
		c = c > 255 ? 255 : c;
		const h = c.toString(16);
		return p + (h.length === 1 ? '0' + h : h);
	}, '#');
};
