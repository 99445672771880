/**
 * Converts a number to a string by rounding it to 2 digit after the decimal point
 * if the number is lower than 1 and greater or equal to 0.01, it's rounded with 2 digits
 * if the number is lower than 0.01, to scientific notation
 * @param {number} x - the number to convert
 * @param {number} maxFractionDigits - the number of digits after the decimal point
 * @returns {string} - the converted number
 *
 * @author Maximilien Valenzano
 */
export const roundValueOrScientific = (x: number, maxFractionDigits = 2): string => {
	if (x >= 0.01) return x.toLocaleString(navigator.language, {
		minimumFractionDigits: 0,
		maximumFractionDigits: maxFractionDigits
	});
	else if (x === 0) return '0';
	return x.toExponential(1);
};