
import { gql } from '@apollo/client';

export const QUERY_SSO_SETTINGS = gql`
    query QuerySsoSettings (
        $emailDomain: String!
        ) {
            ssoSettings (
            	emailDomain: $emailDomain
            	) {
					ssoId,
          			ssoAuthorizationUrl,
          			ssoProvider,
          			ssoScope
        	}
    }
`;
