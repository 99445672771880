export const cutLabels = (value: string | number, length = 18) => {
	const words = value.toString().split(' ');
	const elements: string[] = [];
	words.forEach(word => {
		if (elements.length === 0) return elements.push(word);
		if (`${elements[elements.length - 1]} ${word}`.length < length) {
			elements[elements.length - 1] = `${elements[elements.length - 1]} ${word}`;
		} else {
			elements.push(word);
		}
	});
	return elements;
};