import { IUserDashboardCard } from '../../../domain/interfaces/IUserDashboard';
import React from 'react';
import { translate } from '../../../infrastructure/translations/translate';
import { useUserDashboardOwning } from '../UserDashboardOwning';
import { useUsersDashboard } from '../../../viewComponents/hooks/useUsersDashboard';

export const DashboardCardMoveButton = ({ card }: { card: IUserDashboardCard }) => {
	const owning = useUserDashboardOwning(card.dashboard.id);
	const { entity: userDashboardEntity } = useUsersDashboard();
	const dashboard = userDashboardEntity?.data?.find(d => d.id === card.dashboard.id);
	if (!dashboard) return (<></>);

	const isTop = card.order === 0;
	const isBottom = card.order === (dashboard?.cards.length ?? 1) - 1;

	const moveUp = () => {
		if (!dashboard) return;
		if (isTop) return;
		const cardAbove = dashboard.cards.find(c => c.order === card.order - 1);
		if (!card || !cardAbove) return;
		card.order--;
		cardAbove.order++;
		userDashboardEntity?.mutateEditUserDashboardCardOrder({
			dashboardId: card.dashboard.id,
			cardIds: dashboard.cards.sort((a, b) => a.order - b.order).map(c => c.id)
		});
	};
	const movedown = () => {
		if (!dashboard) return;
		if (isBottom) return;
		const cardBefore = dashboard.cards.find(c => c.order === card.order + 1);
		if (!card || !cardBefore) return;
		card.order++;
		cardBefore.order--;
		userDashboardEntity?.mutateEditUserDashboardCardOrder({
			dashboardId: card.dashboard.id,
			cardIds: dashboard.cards.sort((a, b) => a.order - b.order).map(c => c.id)
		});
	};

	return (owning ? <>
		{!isTop &&<button
			type={'button'}
			className={'button_blank'}
			onClick={moveUp}
		>
			<div className="dashboard_icon"><i className="fa-solid fa-chevron-up"/></div>
			<div className="text">{translate('dashboard.card.move.up')}</div>
		</button>}
		{!isBottom && <button
			type={'button'}
			className={'button_blank'}
			onClick={movedown}
		>
			<div className="dashboard_icon"><i className="fa-solid fa-chevron-down"/></div>
			<div className="text">{translate('dashboard.card.move.down')}</div>
		</button>}
	</> : null);
};