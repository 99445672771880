import { ApolloClientProvider } from '../ApolloClient/ApolloClientProvider';
import { IMutateStudyManagementInput, IMutateStudyManagementResponse } from '../../domain/interfaces/IMutateStudyManagement';
import { IAPIProps } from '../../domain/interfaces/IAPIProps';
import { ResponseDataStudies } from '../../domain/data/ResponseDataStudies';
import { IQueryStudyInput, IQueryStudyOutput } from '../../domain/interfaces/IQueryStudy';
import { IMutateEditFunctionalUnitInput, IMutateEditFunctionalUnitOutput } from '../../domain/interfaces/IMutateEditFunctionalUnit';
import { IQueryFunctionalUnitsOutput } from '../../domain/interfaces/IQueryFunctionalUnits';
import { IMutateColorManagementInput, IMutateColorManagementOutput } from '../../domain/interfaces/IMutateColorManagement';
import { IQueryColorsInput, IQueryColorsOutput } from '../../domain/interfaces/IQueryColors';

export class ApolloClientMutateStudyManagement extends ApolloClientProvider<IMutateStudyManagementResponse, IMutateStudyManagementInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateStudyManagementInput): Promise<IMutateStudyManagementResponse | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}
export class ApolloClientQueryStudy extends ApolloClientProvider<IQueryStudyOutput, IQueryStudyInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IQueryStudyInput): Promise<IQueryStudyOutput | null | undefined> {
		return this.get({ token, lang, adapter }, data);
	}
}
export class ApolloClientMutateEditFunctionalUnit extends ApolloClientProvider<IMutateEditFunctionalUnitOutput, IMutateEditFunctionalUnitInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateEditFunctionalUnitInput): Promise<IMutateEditFunctionalUnitOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateColorManagement extends ApolloClientProvider<IMutateColorManagementOutput, IMutateColorManagementInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateColorManagementInput): Promise<IMutateColorManagementOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientQueryColors extends ApolloClientProvider<IQueryColorsOutput, IQueryColorsInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IQueryColorsInput): Promise<IQueryColorsOutput | null | undefined> {
		return this.get({ token, lang, adapter }, data);
	}
}

export class ApolloClientQueryMyStudies extends ApolloClientProvider<ResponseDataStudies> {}
export class ApolloClientQueryDataStudies extends ApolloClientProvider<ResponseDataStudies> {}
export class ApolloClientQueryFunctionalUnits extends ApolloClientProvider<IQueryFunctionalUnitsOutput> {}