export const getNotificationErrorLevel = (error: string): 'warning' | 'error' => {
	if (error.includes('warning')) return 'warning';
	if (error.includes('error')) return 'error';
	else return 'error';
};

export const trimErrorLevel = (error: string): string => {
	if (error.includes('warning:')) return error.replace('warning:', '');
	if (error.includes('error:')) return error.replace('error:', '');
	else return error;
};
