import React from 'react';
import './infrastructure';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Application from './Application';
import { store } from './store';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!);
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<Application/>
		</Provider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
