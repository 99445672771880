import { gql } from '@apollo/client';

export const MUTATE_EDIT_DASHBOARD = gql`
    mutation MutateEditDashboard(
        $id: String!
        $name: String!
        $type: String!
        $data: JSONString!
        $delete: Boolean
    ){
        mutateEditDashboard(
            id: $id
            name: $name
            type: $type
            data: $data
            delete: $delete
        ){
            status
            dashboard {
                id
                name
                date
                type
                data
                userId
                sharedUsers {
                    id
                    email
                }
            }    
        }
    }
`;
