import { DashboardComparisonData } from '../../../domain/interfaces/DashboardComparisonData';

/**
 * Return the value of the impact depending on the footprint type
 * If by user, return the value divided by the reference flux
 * If global, return the value
 *
 * @param type 0 if global, 1 if by user
 * @param dataset
 * @param value
 */
export const parseByFootprintTypeDashboard = (
	type: number,
	dataset: DashboardComparisonData['datasets'][number],
	value: number
) => {
	if (type === 0) return value;
	return value / dataset.referenceFlux;
};