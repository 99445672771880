import React from 'react';
import '../../DashboardCard.scss';
import 'chart.js/auto';
import { ChartProps } from 'react-chartjs-2';
import { translate } from '../../../../infrastructure/translations/translate';
import { resultValue } from '../../../../viewComponents/results/utils/resultValue';
import { DashboardComparisonData } from '../../../../domain/interfaces/DashboardComparisonData';
import { valueFixedDigits } from '../../../../utils/numberToStringSeparator';
import { useIndicators } from '../../../../viewComponents/hooks/useIndicators';
import { useLifeCycleSteps } from '../../../../viewComponents/hooks/useLifeCycleSteps';
import { DashboardPinButton } from '../../DashboardPin';
import { getImpactByIndicator } from '../../GetImpactByIndicator';
import { getImpactByLifeCycleStep } from '../../GetImpactByLifeCycleStep';
import { parseByFootprintTypeDashboard } from '../../Comparison/ParseByFootprintTypeDashboard';
import { mergeDeep } from '@apollo/client/utilities';
import {
	ReducedBarChart,
	ReducedBarChartData
} from '../../../../viewComponents/graphs/bars/ReducedBarChart';
import { createDashboardComparisonObjectiveModal } from '../../Comparison/CreateDashboardComparisonObjectiveModal';
import { dashboardCompareToReference } from '../../Comparison/DashboardComparison';
import { IModal } from '../../../../domain/interfaces/IModal';
import { useTooltip } from '../../../../viewComponents/tip/useTooltip';
import { useModal } from '../../../../viewComponents/modal/useModal';
import { useBlocks } from '../../../../viewComponents/hooks/useBlocks';
import { dashboardLabelName } from '../../DashboardLabelName';
import { TooltipItem } from 'chart.js/auto';

const options: ChartProps<'bar'>['options'] = {
	layout: {
		padding: 10
	},
	maintainAspectRatio: false,
	aspectRatio: 3,
	plugins: {
		legend: {
			display: false
		},
		tooltip: {
			callbacks: {
				title: (items: TooltipItem<'bar'>[]) => {
					return items[0].label.split(',').join(' ');
				}
			}
		}
	},
	scales: {
		x: {
			grid: {
				display: false
			}
		}
	}
};

const DashboardGlobalSimulationGraphTooltip = (): JSX.Element => {
	const [showTooltip, setShowTooltip] = React.useState<boolean>(false);

	useTooltip(showTooltip, translate('dashboard.select.cumulativeToBefore.table.description'));

	return <button
		className={'button_blank'}
		onMouseEnter={() => setShowTooltip(true)}
		onMouseLeave={() => setShowTooltip(false)}
	>
		<i className="fa-regular fa-question-circle"/>
	</button>;
};

export interface DashboardGlobalSimulationGraphData {
	graph: (ReducedBarChartData | null)[];
	indicators: string[];
	lifeCycleSteps: string[];
	indicator: string;
	lifeCycleStep: string;
	datasetRef: string;
	relative?: boolean;
}
export const DashboardGlobalSimulationGraph = ({ showTable, data }:{ showTable: boolean, data: DashboardGlobalSimulationGraphData }) => {
	const { selectedIndicators: indicators } = useIndicators(data.indicators);
	const { graph: graphData, indicator: selectIndicator, datasetRef } = data;
	const unit = indicators?.find(i => i.id === selectIndicator)?.unit ?? translate('results.valuePBCI') as string;
	const refValue = graphData.filter(d => d != null)[0]?.values[0].value ?? 0;
	const maxValue =  graphData.reduce((p, c) => {
		if (c === null) return p;
		return Math.max(p, c.values[0].value + (c.values[1]?.value ?? 0));
	}, 0);
	const graphOptions = mergeDeep({
		scales: {
			y: {
				max: maxValue,
			},
		}
	}, options);
	return (
		<div className="dashboard_graph">
			{!showTable &&
				<div className="dashboard_chart_container">
					<div className="chart_indicator_unit">
						{unit}
					</div>
					<ReducedBarChart
						data={graphData}
						options={graphOptions}
						thin={40}
						percentage={.8}
						categoryPercentage={.3}
						borderRadius={3}
					/>
				</div>
			}
			{showTable && <table className="dashboard_table">
				<thead>
					<tr>
						<th>{translate('dataset')}</th>
						<th>{translate('unit')}</th>
						<th className={'align-right'}>{translate('value')} <DashboardGlobalSimulationGraphTooltip/></th>
					</tr>
				</thead>
				<tbody>
					{graphData.filter((e) => e !== null).map((e, i) => (
						<tr key={i}>
							<td>{e?.label}</td>
							<td>{unit}</td>
							<td>
								<span className={'number value'}>
									{resultValue(
										(e?.values[0].value ?? 0) + (e?.values[1]?.value ?? 0) > refValue ? (e?.values[0].value ?? 0) + (e?.values[1]?.value ?? 0) : (e?.values[0].value ?? 0),
									)}
									{datasetRef && datasetRef !== e?.label && <span className="number ref">
										{dashboardCompareToReference(graphData, datasetRef, (e?.values[0].value ?? 0) + (e?.values[1]?.value ?? 0) > refValue ? (e?.values[0].value ?? 0) + (e?.values[1]?.value ?? 0) : (e?.values[0].value ?? 0))}
									</span>}
								</span>
							</td>
						</tr>
					))}
				</tbody>
			</table>}
		</div>);
};

const createDashboardSimulationCumulativeModal = (
	open: boolean,
	setOpen: (value: boolean) => void,
	setCumulative: (value: boolean) => void
): IModal | undefined => {

	const clear = () => {
		setOpen(false);
		useModal(false, { body: undefined, header: undefined, visible: false });
	};

	if (!open) return;

	const body = <div>
		{translate('dashboard.select.cumulativeToBefore.warning')}
	</div>;

	return {
		action () {
			setCumulative(true);
			clear();
		},
		actionText: 'continue',
		body,
		header: translate('dashboard.select.cumulativeToBefore'),
		visible: true,
		onClose: clear,
	};
};

const ButtonDashboardGlobalSimulationCumulative = ({
	cumulative,
	setCumulative,
}: {
	cumulative: boolean,
	setCumulative: (value: boolean) => void
}): JSX.Element => {
	const [openModalCumulative, setOpenModalCumulative] = React.useState<boolean>(false);
	const [showCumulativeTooltip, setShowCumulativeTooltip] = React.useState<boolean>(false);

	useTooltip(showCumulativeTooltip, translate('dashboard.select.cumulativeToBefore.description'));

	useModal(openModalCumulative, createDashboardSimulationCumulativeModal(
		openModalCumulative,
		setOpenModalCumulative,
		setCumulative
	));

	const defineCumulative = () => {
		if (cumulative) {
			setCumulative(false);
		} else {
			setOpenModalCumulative(true);
		}
	};
	return <button
		type="button"
		className="button_blank dashboard_action"
		onClick={defineCumulative}
		onMouseEnter={() => !cumulative && setShowCumulativeTooltip(true)}
		onMouseLeave={() => setShowCumulativeTooltip(false)}
	>
		<div className="dashboard_icon">
			<i className="fa-solid fa-arrow-down-short-wide"/>
		</div>
		<div className="dashboard_text">
			{cumulative && translate('dashboard.select.cumulativeNone')}
			{!cumulative && <>
				{translate('dashboard.select.cumulativeToBefore')}
				{' '}<i className="fa-regular fa-question-circle"/>
			</>}
		</div>
	</button>;
};

const DashboardGlobalSimulation = ({ data }: { data: DashboardComparisonData }): JSX.Element => {
	const { selectedIndicators: indicators } = useIndicators(data.indicators);
	const { lcs: lifeCycleSteps } = useLifeCycleSteps(data.lifeCycleSteps);
	const [selectfootprintType, setSelectfootprintType] = React.useState<number>(0);
	const [selectIndicator, setSelectIndicator] = React.useState<string>('normalized');
	const [selectDomain, setSelectDomain] = React.useState<string>('all');
	const [selectLifeCycleStep, setSelectLifeCycleStep] = React.useState<string>('all');
	const [objective, setObjective] = React.useState<{ percent: number, year: number } | undefined>();
	const [openObjectiveModal, setOpenObjectiveModal] = React.useState<boolean>(false);
	const [showTable, setShowTable] = React.useState<boolean>(false);
	const { blocks: domains } = useBlocks(data.domains);
	const [cumulative, setCumulative] = React.useState<boolean>(false);

	useModal(openObjectiveModal, createDashboardComparisonObjectiveModal(
		openObjectiveModal,
		setOpenObjectiveModal,
		setObjective
	));

	const defineObjective = () => {
		if (objective) {
			setObjective(undefined);
		} else {
			setOpenObjectiveModal(true);
		}
	};

	const getValueForDataset = (dataset: DashboardComparisonData['datasets'][number]) => {
		return dataset.equipments.reduce((acc, equipment) => {
			if (selectDomain !== 'all' && equipment.domain !== selectDomain) return acc;
			return acc + equipment.impacts.reduce((acc, impact) => {
				return acc + getImpactByIndicator(getImpactByLifeCycleStep(impact, selectLifeCycleStep), selectIndicator, indicators).value;
			}, 0);
		}, 0);
	};

	const ref = data.datasets[0];
	const simus = data.datasets.filter(d => d != ref);
	let refValue = 0;
	let lastValue = 0;
	let refScope = '';
	const parseDataset = (dataset: DashboardComparisonData['datasets'][number], index: number): ReducedBarChartData | null => {
		const value = getValueForDataset(dataset);
		const indicator = indicators?.find(i => i.id === selectIndicator);
		const scope = indicator?.unit ?? translate('results.valuePBCI') as string;
		const val = valueFixedDigits(parseByFootprintTypeDashboard(selectfootprintType,dataset,value));
		let value1 = {
			value: val,
			scope,
			color: dataset != ref ? 'transparent' : '#484949'
		};
		let value2 = null;
		if (dataset != ref) {
			if (val > refValue) {
				value1 = {
					value: lastValue,
					scope,
					color: dataset != ref ? 'transparent' : '#484949'
				};
				value2 = {
					value: val - refValue,
					scope,
					color: index == simus.length - 1 ? '#e77171' : '#787979'
				};
			} else {
				if (cumulative) {
					value1 = {
						value: lastValue - (refValue - val),
						scope,
						color: dataset != ref ? 'transparent' : '#484949'
					};
				}
				value2 = {
					value: refValue - val,
					scope,
					color: index == simus.length - 1 ? '#e77171' : '#787979'
				};
			}
		} else {
			refValue = val;
			lastValue = val;
			refScope = scope;
		}
		if (cumulative && dataset != ref) {
			lastValue = value1.value;
		}
		return {
			label: dashboardLabelName({ id: dataset.id, data }), values: [value1, value2]
		};
	};

	const graphData: (ReducedBarChartData | null)[] = [
		parseDataset(ref, -1),
		...simus.map(parseDataset)
	];
	if (objective) {
		graphData.push({
			label: `${translate('dashboard.objective')} ${objective.year}`,
			values: [
				{ value: valueFixedDigits(refValue * (100 - objective.percent) / 100), scope: refScope, color: '#46825a' },
				null,
			]
		});
	}
	if (graphData.length < 6) {
		graphData.unshift(null);
		graphData.push(null);
	}
	const saveData = {
		footprintType: selectfootprintType,
		indicators: data.indicators,
		lifeCycleSteps: data.lifeCycleSteps,
		indicator: selectIndicator,
		lifeCycleStep: selectLifeCycleStep,
		domain: selectDomain,
		graph: graphData,
		datasetRef: graphData.filter(d => d != null)[0]?.label ?? '',
		cumulative
	};

	return <div className={'dashboard_card dashboard_card_color_red'}>
		<h3>{translate('dashboard.title.globalSimulation')}</h3>
		<div className="dashboard_card_content">
			<select
				value={selectfootprintType}
				onChange={(e) => setSelectfootprintType(parseInt(e.target.value))}
			>
				<option value={0}>{translate('dashboard.select.globalFootprint')}</option>
				<option value={1}>{translate('dashboard.select.footprintPerUser')}</option>
			</select>
			<select
				value={selectIndicator}
				onChange={(e) => setSelectIndicator(e.target.value)}
			>
				<option value={'normalized'}>{translate('dashboard.select.indicator.normalized')} - {translate('results.valuePBCI')}</option>
				{indicators?.map((indicator) => (
					<option key={indicator.id} value={indicator.id}>{indicator.name} ({indicator.shortName}) - {indicator.unit}</option>
				))}
			</select>
			<select
				value={selectDomain}
				onChange={(e) => setSelectDomain(e.target.value)}
			>
				<option value={'all'}>{translate('dashboard.select.allDomains')}</option>
				{domains?.map((domain) => (
					<option key={domain.id} value={domain.id}>{domain.shortName}</option>
				))}
			</select>
			<select
				value={selectLifeCycleStep}
				onChange={(e) => setSelectLifeCycleStep(e.target.value)}
			>
				<option value={'all'}>{translate('dashboard.select.allLifeCycleSteps')}</option>
				{lifeCycleSteps?.map((lifeCycleStep) => (
					<option key={lifeCycleStep.id} value={lifeCycleStep.id}>{lifeCycleStep.name}</option>
				))}
			</select>
		</div>
		<div className="dashboard_card_content">
			<div className="dashboard_card_content_actions">
				<ButtonDashboardGlobalSimulationCumulative cumulative={cumulative} setCumulative={setCumulative} />
				<button type="button" className="button_blank dashboard_action" onClick={defineObjective}>
					<div className="dashboard_icon">
						<i className="fa-regular fa-bullseye-pointer" />
					</div>
					<div className="dashboard_text">
						{objective && translate('dashboard.action.objective.remove')}
						{!objective && translate('dashboard.action.objective.add')}
					</div>
				</button>
				<button
					type="button"
					className="button_blank dashboard_action"
					onClick={() => setShowTable(!showTable)}
				>
					<div className="dashboard_icon">
						{showTable && <i className="fa-solid fa-chart-column" />}
						{!showTable && <i className="fa-solid fa-table" />}
					</div>
					<div className="dashboard_text">
						{showTable && translate('dashboard.action.graph')}
						{!showTable && translate('dashboard.action.table')}
					</div>
				</button>
				<DashboardPinButton data={saveData} type={'DashboardGlobalSimulation'} />
			</div>
			<DashboardGlobalSimulationGraph showTable={showTable} data={saveData} />
		</div>
	</div>;
};

export default DashboardGlobalSimulation;