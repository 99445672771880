import { Core } from '../domain/core/Core';
import { EventGateway } from '../domain/core/EventGateway';
import { Adapter } from './Adapter';

/**
 * The index file for the infrastructure layer.
 * This file is responsible for creating the event gateway, the adapter and the core.
 *
 * @author Maximilien Valenzano
 */

export const adapter = new Adapter();
export const gateway = new EventGateway();
export const app = new Core(gateway, adapter);
