import { gql } from '@apollo/client';

export const QUERY_STATIC_PAGE_BY_ID = gql`
    query pageById(
        $id: String!
    ) {
        pageById(
            id: $id
        ) {
            id,
            name,
            content,
            private
        }
    }
`;
