export type CmdbStep = keyof typeof DefaultCmdbStepOrder;
export type CmdbSubStep<T extends CmdbStep> = typeof DefaultCmdbStepOrder[T][number];
export type CmdbStepState<T extends CmdbStep = CmdbStep> = {
	step: T;
	subStep: CmdbSubStep<T>;
	locked: boolean;
};

export type ValueSelectionSubStep = typeof valueSelectionSubSteps[number];
export const valueSelectionSubSteps = ['type', 'block', 'ssdTechno', 'screenTechno', 'perimeter', 'country'];

// Don't forget to order the steps in the order you want them to be displayed
export const DefaultCmdbStepOrder = {
	sheet: [undefined] as const,
	column: ['mandatory', 'optional', 'validation'] as const,
	value: ['info', ...valueSelectionSubSteps, 'numericalValue', 'finished'] as const,
} as const;