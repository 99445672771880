import React from 'react';
import logo from '../../../media/logo.svg';
import { useUser } from '../../hooks/useUser';

const SidebarLogo = (): JSX.Element => {
	const { data: user } = useUser();
	const logoImg = user?.myCompany?.style ? user?.myCompany?.logo : null;
	return (<>
		<div className="sidebar_logo">
			{logoImg ?
				<>
					<img alt="Resilio logo" src={logo} />
					<img alt={'Company logo'} src={logoImg}/>
				</>
				:
				<>
					<img alt="Resilio logo" src={logo} />
					<span className="sidebar_logo_name">resilio</span>
				</>
			}
		</div>
	</>);
};

export default SidebarLogo;
