import { gql } from '@apollo/client';

export const QUERY_INDICATORS = gql`
    query QueryIndicators {
        indicators {
					id
					name
					unit
					shortName
					icon
					planetaryBoundary
					order
				}
    }
`;
