import React from 'react';
import { IUserDashboardCard } from '../../../../../domain/interfaces/IUserDashboard';
import { translate } from '../../../../../infrastructure/translations/translate';
import { DashboardCardModifyButton } from '../../DashboardCardModifyButton';
import { DashboardCardMoveButton } from '../../DashboardCardMoveButton';
import { DashboardCardDeleteButton } from '../../DashboardCardDeleteButton';
import { DashboardMaturityDomainEvolutionGraph } from '../../../Evolution/Graphs/DashboardMaturityDomainEvolution';

export const DashboardCardMaturityDomainEvolutionGraph = ({ card }: { card: IUserDashboardCard }) => {
	const [showTable, setShowTable] = React.useState<boolean>(false);
	const [showHistogram, setShowHistogram] = React.useState<boolean>(false);

	const data = JSON.parse(card.data);

	return <div className="dashboard_card dashboard_card_color_yellow">
		<div className="buttons">
			<button
				type="button"
				className="button_blank dashboard_action"
				onClick={() => setShowHistogram(!showHistogram)}
			>
				<div className="dashboard_icon">
					{showHistogram && <i className="fa-solid fa-chart-column"/>}
					{!showHistogram && <i className="fa-solid fa-table"/>}
				</div>
				<div className="dashboard_text">
					{showHistogram && translate('dashboard.action.histogram.hide')}
					{!showHistogram && translate('dashboard.action.histogram.show')}
				</div>
			</button>
			<button
				type={'button'}
				className={'button_blank'}
				onClick={() => setShowTable(!showTable)}
			>
				<div className="dashboard_icon"><i className="fa-solid fa-chart-column"/></div>
				<div className="text">
					{showTable && translate('dashboard.action.graph')}
					{!showTable && translate('dashboard.action.table')}
				</div>
			</button>
			<DashboardCardModifyButton card={card}/>
			<DashboardCardMoveButton card={card}/>
			<DashboardCardDeleteButton card={card}/>
		</div>
		<h3>{card.name}</h3>
		<div className="dashboard_card_content">
			<div className="dashboard_card_selected"><span
				className="bold">{translate('dashboard.card.selected.dataset')}</span> : {data.dataset}
			</div>
			<div className="dashboard_card_selected"><span
				className="bold">{translate('dashboard.card.selected.domain')}</span> : {data.domain === 'all' ? translate('dashboard.select.allDomains') : data.domain}
			</div>
			{data.domain === 'all' && <div className="dashboard_card_selected"><span
				className="bold">{translate('dashboard.card.selected.scope')}</span> : {data.scope === 'all' ? translate('dashboard.select.allScopes') : data.scope}
			</div>}
		</div>
		<div className="dashboard_card_content">
			<DashboardMaturityDomainEvolutionGraph data={data} showTable={showTable} histogram={showHistogram} />
		</div>
	</div>;
};