import React, { useEffect, useRef, useState } from 'react';
import './accountHeader.css';
import { Link } from 'react-router-dom';
import { translate } from '../../../infrastructure/translations/translate';
import UserManageViewsSidebar from '../../UserManageViewsSidebar';
import { useUser } from '../../hooks/useUser';

const AccountHeader = (): JSX.Element => {
	const { entity: userEntity, data: user } = useUser();
	if (!user) return (<></>);
	const canManage = user.permissions.includes('login.can_access_manage');
	const [open, setOpen] = useState(false);
	const outRef = useRef<HTMLDivElement>(null);
	const redirectLinkManage = UserManageViewsSidebar().content.list[0].link;

	const handleClick = (event: MouseEvent) => {
		if (event.target && outRef.current && !outRef.current.contains(event.target as Node)) return setOpen(false);
	};

	useEffect(() => {
		document.addEventListener('mouseup', handleClick);
		return () => {
			document.removeEventListener('mouseup', handleClick);
		};
	}, []);

	return (
		<div className="account" id="account" ref={outRef} style={{ pointerEvents: 'all' }}>
			<button type="button" className="capsule" onClick={() => setOpen(!open)}
				aria-label={translate('accountHeader.accountBadge') as string} aria-expanded={open}>
				<i className="fas fa-user" style={{ color: 'grey' }}/>
			</button>
			<div className={`card ${open ? 'open' : ''}`}>
				<div className="email text-center">
					{user.email}
				</div>
				<div className="links">
					{canManage && <Link to={redirectLinkManage}>{translate('accountHeader.manage')}</Link>}
					<Link to={'/user/settings'}>{translate('accountHeader.settings')}</Link>
				</div>
				<div className="logout">
					<button type="button" onClick={() => userEntity?.queryUserLogout()}>{translate('accountHeader.logout')}</button>
				</div>
			</div>
		</div>
	);
};

export default AccountHeader;
