import { gql } from '@apollo/client';

export const QUERY_USER_ASSIGNMENTS = gql`
	query (
		$lineId: String!
	) {
		assignments (id: $lineId) {
			lineId,
			userId,
			email
		}
	}
`;
