import { gql } from '@apollo/client';

export const MUTATE_MATURITY_DOMAINS_ORDER = gql`
    mutation MutateMaturityDomains($maturityIds:[String]!)
    {
        mutateMaturityDomainsOrder(maturityIds: $maturityIds){
            status,
            domains{
                id,
                name,
                scope,
                order
            }
        }
    }
`;