import React, { useEffect, useState } from 'react';
import { ResultCardError } from '../error/ResultCardError/ResultCardError';
import { useTransformedResultTableData } from '../../utils/useTransformedResultTableData';
import { IResultTableProps, ResultTable } from './ResultTable';
import { translate } from '../../infrastructure/translations/translate';
import Spinner from '../utils/Spinner/Spinner';
import { resultValue } from './utils/resultValue';
import { getColorByScore } from '../../config';
import { ProgressDoughnutChart } from '../graphs/doughnuts/ProgressDoughnutChart';

export type ResultQualityScoreBasedResultType = { data: { block: string, quality: number | undefined }[] }
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isResultQualityScoreBasedResult = (o: any): o is ResultQualityScoreBasedResultType => {
	if (typeof o === 'object') return 'schema' in o && 'data' in o;
	return false;
};
export const ResultQualityScoreBasedResult = ({ data, isRelative }: { data: string, isRelative: boolean }): JSX.Element => {
	if (!isResultQualityScoreBasedResult(data)) return (<ResultCardError/>);
	const transformedData = useTransformedResultTableData(data.data);
	const [dataTable, setDataTable] = useState<IResultTableProps | null>(null);

	useEffect(() => {
		if (!transformedData || transformedData instanceof Error) return;
		// In the dataFrame, there is 2 rows that are used for the graph, we don't want to display them in the table
		const filteredData = transformedData.filter(d => d.block !== 'number_lines' && d.block !== 'number_removed_lines');
		setDataTable({
			columns: [
				{ name: translate('results.block') as string },
				{ name: translate('results.qualityScore') as string, style: { textAlign: 'left' } },
			],
			values: filteredData.map(d => [
				{ value: d.block, valueType: 'left', style: { fontWeight: d.block === 'Total' ? 'bold' : '' } },
				{ value: `${d.quality === 0 ? 0 : resultValue(d.quality)} ${isRelative ? '%' : '/ 3'}`,
					valueType: 'left',style: { textAlign: 'left' } },
			]),
		});
	}, [transformedData]);

	if (transformedData instanceof Error) return <ResultCardError/>;
	if (!dataTable) return <Spinner/>;
	return <ResultTable dataTable={dataTable} />;
};

export const QualityScoreGraph = ({ data } : { data: string}) : JSX.Element => {
	if (!isResultQualityScoreBasedResult(data)) return (<ResultCardError/>);
	const transformedData = useTransformedResultTableData(data.data);
	const [scoreRelative, setScoreRelative] = useState<number | undefined>(undefined);
	const [numberLines, setNumberLines] = useState<number | undefined>(undefined);
	const [numberRemovedLines, setNumberRemovedLines] = useState<number | undefined>(undefined);
	const [color, setColor] = useState<string | undefined>(undefined);


	if (transformedData instanceof Error) return (<ResultCardError/>);

	useEffect(() => {
		if (!transformedData || transformedData instanceof Error) return;
		const total = transformedData.find(d => d.block === 'Total')?.quality;
		if (!total) return;
		const totalRelative = Math.round((total - 1) / (3 - 1) * 100);
		// the score is between 1 = min and 3 = max, we want it between 0 and 100
		setScoreRelative(totalRelative);
		setColor(getColorByScore(Math.floor(totalRelative / 25)));
		setNumberLines(transformedData.find(d => d.block === 'number_lines')?.quality);
		setNumberRemovedLines(transformedData.find(d => d.block === 'number_removed_lines')?.quality);
	}, [transformedData]);

	return (
		<>
			{scoreRelative && color &&
				<ProgressDoughnutChart
					labels={[translate('results.qualityScore') as string, translate('results.qualityScore.toImprove') as string]}
					values={[Number(scoreRelative), 100 - Number(scoreRelative)]}
					displayScore={true}
					progressColor={color}
				/>
			}
			<p className='doughnut_progress_chart_description'>
				{translate('results.qualityScore.evaluated') as string} {numberLines} / {Number(numberRemovedLines) + Number(numberLines)}
			</p>
		</>
	);
};
