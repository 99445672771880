import React from 'react';
import { translate } from '../../../infrastructure/translations/translate';
import './ariaNavigation.css';

/**
 * 
 * @returns JSX.Element
 * @constructor
 * @author Yacine Bentayeb
 * @description This component is used to provide a navigation for screen readers
 * @documentation https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/navigation_role#description
 */


const AriaNavigation = (): JSX.Element => {
	return (
		<div className="access-keys" role="navigation" style={
			{ position: 'fixed', zIndex: 1000, top: 0, backgroundColor: 'white', left: '-100%' }
		}>
			<ul>
				<li>
					<a href="src/viewComponents#nav" id="nav-link" className='nav_short_cut' tabIndex={0}>
						{translate('ariaNavigation.menu')}
					</a>
				</li>
				<li>
					<a href="src/viewComponents#main" id="main-link" className='nav_short_cut' tabIndex={0} >
						{translate('ariaNavigation.mainContent')} 
					</a>
				</li>
				<li>
					<a href="src/viewComponents#account" id="account-link" className='nav_short_cut' tabIndex={0} >
						{translate('ariaNavigation.accountCapsule')}
					</a>
				</li>
			</ul>
		</div>
	);
};

export default AriaNavigation;