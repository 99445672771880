import React from 'react';
import logo from '../../media/logo.svg';
import { changeDocumentTitle } from '../../utils/changeDocumentTitle';
import { translate } from '../../infrastructure/translations/translate';

/**
 * return a modal to indicate no support on mobile device
 * @constructor
 * @return JSX.Element
 *
 * @author
 */
const NoMobileView = (): JSX.Element => {
	changeDocumentTitle(translate('noMobile.title') as string);
	return (
		<div className={'card_container'} style={{ marginTop: '2rem' }}>
			<div>
				<div className={'card'} style={{ maxWidth: '18rem', padding: 0 }}>
					<img src={logo} height="100px" alt={'resilio logo'}/>
					<div className={'card_header text-center'}>{translate('noMobile.warning')}</div>
					<div className={'card_body'}>
						<h1>{translate('noMobile.support')}</h1>
						<p>{translate('noMobile.description')}</p>
					</div>
					<div className={'card_footer text-center'}>(c) Resilio SA </div>
				</div>
			</div>
		</div>
	);
};

export default NoMobileView;
