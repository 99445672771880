import React from 'react';

export type ResultArrowComparisonType = 'up' | 'down' | 'min' | 'max' | 'none' | undefined;

export const ResultComparison = (elem: { value: number, mean?: number, min?: number, max?: number }): ResultArrowComparisonType => {
	if (
		elem.mean !== undefined &&
		elem.min !== undefined &&
		elem.max !== undefined &&
		elem.mean !== null &&
		elem.min !== null &&
		elem.max !== null
	) {
		if (elem.value == 0) return 'min';
		if (elem.value <= elem.min) return 'min';
		if (elem.value >= elem.max) return 'max';
		if (elem.value > elem.mean) return 'up';
		if (elem.value < elem.mean) return 'down';
	}
};

export const ResultArrowComparison = ({ arrow, color, maturity }: { arrow: ResultArrowComparisonType, color?:string | null, maturity?: boolean }) => {
	const arrowIsUp = arrow === 'max' || arrow === 'up';
	const arrowIsDown = arrow === 'down' || arrow === 'min';
	const defaultColor = arrowIsUp ? (maturity ? 'green' : 'red') : (arrowIsDown ? (maturity ? 'red' : 'green') : 'black');

	return (
		<span className={'result_arrows'}>
			{arrow === 'max' && <i className="fa-solid fa-angle-double-up" color={color ?? defaultColor}/>}
			{arrow === 'up' && <i className="fa-solid fa-angle-up" color={color ?? defaultColor}/>}
			{arrow === 'down' && <i className="fa-solid fa-angle-down" color={color ?? defaultColor}/>}
			{arrow === 'min' && <i className="fa-solid fa-angle-double-down" color={color ?? defaultColor}/>}
			{arrow === 'none' && <i className="fa-solid fa-minus" color={color ?? defaultColor}/>}
		</span>
	);
};
