import { gql } from '@apollo/client';

export const QUERY_DATA_MATURITY = gql`
  query requestMaturity (
    $datasetId: String!
  )
  {
    datasetMaturity (id: $datasetId) {
      id,
      name,
      goodPractices (id: $datasetId) {
        id,
        name,
        help,
        kpi,
        kpiValue,
        difficulty,
        priority,
        grade,
        comment,
        originDatabaseId,
        flag,
      },
        priority (id: $datasetId)
    }
  }
`;
