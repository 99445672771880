import { ApolloClientProvider } from '../ApolloClient/ApolloClientProvider';
import { ResponseDataLambdaUserCreation } from '../../domain/data/ResponseDataLambdaUserCreation';
import { ILambdaUserCreation } from '../../domain/interfaces/ILambdaUserCreation';
import { IAPIProps } from '../../domain/interfaces/IAPIProps';
import { IMutateNotifyAssigneesInput, IMutateNotifyAssigneesOutput } from '../../domain/interfaces/IMutateNotifyAssignees';
import {
	ResponseLambdaMutateLine,
	ResponseLambdaRequestCode,
	ResponseLambdaVerifyCode, ResponseLambdaVerifyLink, ResponseLambdaVerifyToken
} from '../../domain/data/ResponseLambdaSuccess';
import { ILambdaMutateLine } from '../../domain/interfaces/ILambdaMutateLine';

export class ApolloClientMutateLambdaUserCreate extends ApolloClientProvider<ResponseDataLambdaUserCreation, ILambdaUserCreation> {
	exec({ token, lang, adapter }: IAPIProps, data: ILambdaUserCreation): Promise<ResponseDataLambdaUserCreation | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateNotifyAssignees extends ApolloClientProvider<IMutateNotifyAssigneesOutput, IMutateNotifyAssigneesInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateNotifyAssigneesInput): Promise<IMutateNotifyAssigneesOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateLambdaEquipmentLine extends ApolloClientProvider<ResponseLambdaMutateLine, ILambdaMutateLine> {
	exec({ token, lang, adapter }: IAPIProps, data: ILambdaMutateLine): Promise<ResponseLambdaMutateLine | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientQueryLambdaRequestCode extends ApolloClientProvider<ResponseLambdaRequestCode, { token: string }> {}
export class ApolloClientQueryLambdaVerifyCode extends ApolloClientProvider<ResponseLambdaVerifyCode, { token: string; code: string }> {}
export class ApolloClientQueryLambdaVerifyLink extends ApolloClientProvider<ResponseLambdaVerifyLink, { token: string }> {}
export class ApolloClientQueryLambdaVerifyToken extends ApolloClientProvider<ResponseLambdaVerifyToken, { token: string }> {}
