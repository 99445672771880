import { PrimaryController } from './PrimaryController';
import { EventController } from './EventController';
import { EventType } from './EventType';
import { ApplicationService } from './ApplicationService';

/**
 * Event Gateway
 *
 * The event system from the core.
 * Used to catch events and send them to the correct service.
 *
 * @author Maximilien Valenzano
 */
export class EventGateway {
	private primaryController: PrimaryController | undefined;

	setPrimaryController(controller: PrimaryController): void {
		this.primaryController = controller;
	}

	addUseCase(eventType: EventType, useCase: ApplicationService) {
		this.primaryController?.addUseCase(eventType, useCase);
	}

	sendEvent(event: EventController): Promise<boolean> {
		if (!this.primaryController) return Promise.reject('No primary controller provided');
		return this.primaryController.useCase(event.eventType).execute(event);
	}
}
