import { SideBarContentItemList } from './template/sidebar/sidebar';
import '../views/User/UserManageView/UserManageView.css';
import { translate } from '../infrastructure/translations/translate';
import { useParams } from 'react-router-dom';
import { useUser } from './hooks/useUser';

const UserManageViewsSidebar = (): SideBarContentItemList => {
	const { page } = useParams();
	const { data: user } = useUser();
	const list = [];
	if (user?.permissions.includes('login.can_manage_company')) list.push({
		id: 'companies',
		name: translate('userManage.companies') as string,
		current: page === 'companies',
		link: '/manage/companies'
	});
	list.push({
		id: 'users',
		name: translate('userManage.users') as string,
		current: page === 'users',
		link: '/manage/users'
	});
	if (user?.permissions.includes('login.can_manage_study')) {
		list.push({
			id: 'studies',
			name: translate('userManage.studies') as string,
			current: page === 'studies',
			link: '/manage/studies'
		});
	}
	if (user?.permissions.includes('login.can_manage_permission')) {
		list.push({
			id: 'permissions',
			name: translate('userManage.permissions') as string,
			current: page === 'permissions',
			link: '/manage/permissions'
		});
	}
	if (user?.permissions.includes('login.can_manage_result_list')) list.push({
		id: 'result_lists',
		name: translate('userManage.result_lists') as string,
		current: page === 'result_lists',
		link: '/manage/result_lists'
	});
	if (user?.permissions.includes('validation.change_sample')) list.push({
		id: 'samples',
		name: translate('userManage.samples') as string,
		current: page === 'samples',
		link: '/manage/samples'
	});
	if (user?.permissions.includes('studies.change_block')) list.push({
		id: 'blocks',
		name: translate('userManage.blocks') as string,
		current: page === 'blocks',
		link: '/manage/blocks'
	});
	if (user?.permissions.includes('maturity.can_manage_maturity_domains')) list.push({
		id: 'maturity',
		name: translate('userManage.maturityDomains') as string,
		current: page === 'maturity',
		link: '/manage/maturity'
	});
	if (user?.permissions.includes('login.can_manage_equipment_type')) list.push({
		id: 'database',
		name: translate('userManage.database') as string,
		current: page === 'database',
		link: '/manage/database'
	});
	if (user?.isSuperuser) list.push({
		id: 'equipment_categories_order',
		name: translate('userManage.equipmentCategoriesOrder') as string,
		current: page === 'equipment_categories_order',
		link: '/manage/equipment_categories_order'
	});
	if (user?.isSuperuser) list.push({
		id: 'equipment_type_order',
		name: translate('userManage.equipmentTypeOrder') as string,
		current: page === 'equipment_type_order',
		link: '/manage/equipment_type_order'
	});
	if (user?.isSuperuser) list.push({
		id: 'indicator_order',
		name: translate('userManage.indicatorOrder') as string,
		current: page === 'indicator_order',
		link: '/manage/indicator_order'
	});
	if (user?.isSuperuser) list.push({
		id: 'functional_unit',
		name: translate('userManage.functional_unit') as string,
		current: page === 'functional_unit',
		link: '/manage/functional_unit'
	});
	if (user?.permissions.includes('login.can_manage_lca_models')) list.push({
		id: 'lca_inventory',
		name: translate('userManage.lca_inventory') as string,
		current: page === 'lca_inventory',
		link: '/manage/lca_inventory'
	});
	if (user?.permissions.includes('login.can_manage_lca_models')) list.push({
		id: 'lca_maturity_model',
		name: translate('userManage.lca_maturity_model') as string,
		current: page === 'lca_maturity_model',
		link: '/manage/lca_maturity_model'
	});
	if (user?.permissions.includes('login.can_manage_lca_models')) list.push({
		id: 'lca_template',
		name: translate('userManage.lca_template') as string,
		current: page === 'lca_template',
		link: '/manage/lca_template'
	});
	if (user?.isSuperuser) list.push({
		id: 'error',
		name: translate('userManage.ErrorTestView') as string,
		current: page === 'error',
		link: '/manage/error'
	});

	return {
		content: {
			list
		}
	};
};

export default UserManageViewsSidebar;
