import React, { useEffect } from 'react';
import './notification.css';
import { useSelector } from 'react-redux';
import { State } from '../../../store';
import { INotification } from '../../../domain/interfaces/INotification';
import { removeNotification } from '../../../store/dispatchers';

const NotificationElement = ({ notif }: { notif: INotification }): JSX.Element => {
	const [show, setShow] = React.useState(false);
	const [visible, setVisible] = React.useState(true);
	useEffect(() => {
		setTimeout(() => {
			setShow(true);
		}, 1);
		setTimeout(() => {
			setShow(false);
			setTimeout(() => {
				setVisible(false);
				removeNotification(notif);
			}, 300);
		}, 5000);

	}, []);
	if (!visible) return (<></>);
	return (<div className={`element ${notif.type} ${show ? 'show' : ''}`}>
		{notif.message}
	</div>);
};

const Notification = (): JSX.Element => {
	const notifications = useSelector((state: State) => state.notifications);
	return (
		<div className={'notification'}>
			{notifications?.map((notification, idx) => <NotificationElement key={idx} notif={notification}/>)}
		</div>
	);
};

export default Notification;