import { gql } from '@apollo/client';

export const QUERY_DASHBOARD = gql`
    query QueryDashboard (
        $id: String!
    ) {
        dashboard (
            id: $id
        ) {
            id
            name
            date
            type
            data
            userId
            sharedUsers {
                id
                email
            }
        }
    }
`;
