import { ApolloClientProvider } from '../ApolloClient/ApolloClientProvider';
import { ResponseDataMaturity } from '../../domain/data/ResponseDataMaturity';
import { IAPIProps } from '../../domain/interfaces/IAPIProps';
import { ResponseMutateLineMaturitySave } from '../../domain/data/ResponseDatas';
import { DataMutate } from '../../domain/core/Entity';
import { GoodPractice } from '../../domain/data/entries/GoodPractice';
import {
	IMutateMaturityDomainsOrderInput,
	IMutateMaturityDomainsOrderOutput
} from '../../domain/interfaces/IMutateMaturityDomainsOrder';
import { IQueryGoodPracticesOutput } from '../../domain/interfaces/IQueryGoodPractices';
import { IQueryMaturityDomainsOutput } from '../../domain/interfaces/IQueryMaturityDomains';

export class ApolloClientQueryDataMaturity extends ApolloClientProvider<ResponseDataMaturity, { datasetId: string }> {}

export class ApolloClientMutateLineMaturity extends ApolloClientProvider<ResponseMutateLineMaturitySave, DataMutate<GoodPractice>> {
	exec({ token, lang, adapter }: IAPIProps, data: DataMutate<GoodPractice>): Promise<ResponseMutateLineMaturitySave | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientMutateMaturityDomainsOrder extends ApolloClientProvider<IMutateMaturityDomainsOrderOutput, IMutateMaturityDomainsOrderInput> {
	exec({ token, lang, adapter }: IAPIProps, data: IMutateMaturityDomainsOrderInput): Promise<IMutateMaturityDomainsOrderOutput | null | undefined> {
		return this.set({ token, lang, adapter }, data);
	}
}

export class ApolloClientQueryGoodPractices extends ApolloClientProvider<IQueryGoodPracticesOutput>{}
export class ApolloClientQueryMaturityDomains extends ApolloClientProvider<IQueryMaturityDomainsOutput>{}