import { gql } from '@apollo/client';

export const QUERY_INVENTORY_EXTRA = gql`
    query QueryInventoryExtra (
        $datasetId: String!
    ) {
        inventoryExtra(
            datasetId: $datasetId
        ) {
            indicators
            lifeCycleSteps
            blockEnergyMix {
                block {
                    id
                }
                mix {
                    id
                }
            }
            referenceFlux
            equipmentTypes {
                name
                shortName
                specification
                equipmentTypeId
                order
                factorSets {
                    id
                    name
                    version
                    updatedAt
                }
            }
            factorSets {
                equipmentTypeId
                factorSetId
            }
            energyMixFactorSets {
           		id
			}
        }
    }
`;
