import React from 'react';
import { ResultLetterScore } from './ResultLetterScore';
import { ResultCardError } from '../error/ResultCardError/ResultCardError';

export type ResultImpactScoreType = { data: { index: string, value: number | null, score: string }[] }
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isResultImpactScore = (o: any): o is ResultImpactScoreType => {
	if (typeof o === 'object') return 'schema' in o && 'data' in o;
	return false;
};
export const ResultImpactScore = ({ data }: { data: string }): JSX.Element => {
	if (!isResultImpactScore(data)) return (<ResultCardError/>);

	const scoreValue = data.data[0].score;
	if(!scoreValue) return (<ResultCardError/>);

	return (<ResultLetterScore value={scoreValue}/>);
};
