import { useSelector } from 'react-redux';
import { State } from '../../../store';
import React, { useState } from 'react';
import { IUserNotification } from '../../../domain/interfaces/IUserNotification';
import { removeUserNotification } from '../../../store/dispatchers';
import { translate } from '../../../infrastructure/translations/translate';
import { useNavigate } from 'react-router-dom';
import './userNotification.scss';
import ButtonLoading from '../../button/ButtonLoading/ButtonLoading';
import { addMailto } from '../../../utils/addMailto';

const UserNotificationElement = ({ notif }: { notif: IUserNotification }): JSX.Element => {
	const [loading, setLoading] = useState(false);
	const [displayList, setDisplayList] = useState(false);
	const navigate = useNavigate();

	const textContainEmail = () => {
		const emailRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g;
		return emailRegex.test(notif.message || '');
	};

	const onClose = () => {
		setLoading(false);
		if (notif.additionalCloseAction) notif.additionalCloseAction();
		removeUserNotification(notif);
	};

	const redirectUser = async () => {
		setLoading(true);
		if (notif.redirect && typeof notif.redirect === 'string') navigate(notif.redirect);
		// We are using await in case of async redirection function, allowing the loading spinner to be displayed
		else if (notif.redirect && typeof notif.redirect === 'function') await notif.redirect();
		onClose();
	};

	const displayLoadingMessage = !!(loading && notif.loadingMessage);

	return (
		<div className={'user_notification ' + (notif.level || 'success')}>
			<button className={'button_blank close'} disabled={loading} aria-label={translate('close') as string} onClick={onClose}>X</button>

			{displayLoadingMessage && notif.loadingMessage && <div className={'user_notification_message'}>
				{translate(notif.loadingMessage)}
			</div>}

			{!displayLoadingMessage && <>
				<div className={'user_notification_title'}>
					{translate(notif.title, {
						return: () => <br/>,
						bold: (text: string) => <b>{text}</b>
					})}
				</div>

				{notif.message && <div className={'user_notification_message'}>
					{textContainEmail() ? addMailto(notif.message) : translate(notif.message)}
				</div>}
			</>}

			{notif.listToDisplay && <button className={'show_list_button'} onClick={() => setDisplayList(!displayList)}>
				{displayList ? translate('close') : translate('showMoreDetails')}
			</button>}
			{displayList && notif.listToDisplay && notif.listToDisplay.length > 0 && <div className={'user_notification_list'}>
				{notif.titleListToDisplay && <h4>{translate(notif.titleListToDisplay)}</h4>}
				<div className={'user_notification_list_items'}>
					{notif.listToDisplay.map((item, index) => (
						<div key={index}>{item}</div>
					))}
				</div>
			</div>}

			{notif.footer && <div className={'user_notification_footer'}>
				<button className={'user_notification_close'} aria-label={translate('close') as string}
					onClick={onClose}>{translate('maybeLater') as string}</button>
				{notif.redirect && <ButtonLoading
					classname={'user_notification_redirection'}
					title={'yes!'}
					onClick={redirectUser}
					loading={loading}
				/>}
				{notif.additionalFooter}
			</div>}
		</div>
	);
};

const UserNotification = (): JSX.Element => {
	const notifications = useSelector((state: State) => state.userNotifications);
	if (!notifications?.length) return (<></>);

	// This can change if we want to implement a dynamic notification system (like a queue)
	const notification = notifications?.[0];

	return (
		<div className={`user_notification_wrapper ${notification ? 'active' : ''}`}>
			{notification && <UserNotificationElement notif={notification}/>}
		</div>
	);
};

export default UserNotification;