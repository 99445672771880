import React, { useState } from 'react';
import { translate } from '../../../infrastructure/translations/translate';
import { useModal } from '../useModal';
import { useSelector } from 'react-redux';
import { State } from '../../../store';

/**
 * @description return a modal to request a calculation
 * @param {boolean} open
 * @param {function} setOpen
 * @param {string} datasetId
 * @param {string} studyId
 * @param {string} company
 * @return {JSX.Element}
 * @author Yacine Bentayeb
 */
export const useRequestCalculationModal = (
	open: boolean,
	setOpen: (value: boolean) => void,
	datasetId: string,
	studyId: string,
	company: string,
) => {
	const { data: datasetEntity } = useSelector((state: State) => state.dataDatasetsStatus);
	const [message, setMessage] = useState<string | undefined>(undefined);

	if (!open || datasetId.length < 1 || studyId.length < 1) return;

	const clear = () => {
		setMessage(undefined);
		setOpen(false);
		useModal(false, { visible: false, body: undefined, header: '' });
	};

	const handleCalculateValidation = () => {
		datasetEntity?.mutateRequestCalculation({ datasetId: datasetId, studyId: studyId, company: company, message: message });
		clear();
	};

	const body = (
		<div className={'modal_body'}>
			<h4>{translate('validation.calculationRequest.text')}</h4>
			<div style={{ width: '100%' }}>
				<label>{translate('validation.calculationRequest.message')}</label>
				<input type={'text'} onChange={(e) => setMessage(e.target.value)}/>
			</div>
		</div>
	);

	return {
		visible: true,
		header: translate('validation.calculationRequest') as string,
		body: body,
		action: handleCalculateValidation,
		actionText: 'validate',
		onClose: clear,
	};
};