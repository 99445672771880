import { gql } from '@apollo/client';

export const QUERY_USER_DASHBOARD_USERS_CAN_ACCESS = gql`
	query UserDashboardUsersCanAccess (
		$userDashboardId: String!
	) {
		userDashboardUsersCanAccess(
			userDashboardId: $userDashboardId
		) {
      		id
      		email
    	}
  	}
`;