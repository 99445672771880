import { useNavigate } from 'react-router-dom';
import ErrorModal from '../../modal/ErrorModal/ErrorModal';
import React from 'react';
import { translate } from '../../../infrastructure/translations/translate';
import { useUser } from '../../hooks/useUser';

export const ErrorRenderer = ({ error }: { error: Error }): JSX.Element => {
	const { entity: userEntity } = useUser();
	const navigate = useNavigate();
	const perm = error.message == 'You do not have permission to perform this action';
	const auth = error.message == 'You need to be logged to perform this action';
	const net = error.message == 'Failed to fetch';
	let errorText = '';
	let alert = 'danger';
	let text = translate('errorRenderer.process') as string;
	if (perm || auth) {
		errorText = translate('errorRenderer.accessDenied') as string;
		alert = 'warning';
		text = perm ? translate('errorRenderer.accessReconnect') as string : translate('errorRenderer.userReconnect') as string;
	}
	if (net) {
		errorText = translate('errorRenderer.networkIssue') as string;
		alert = 'warning';
		text = translate('errorRenderer.networkReconnect') as string;
	}
	const handleHide = () => {
		if (perm || auth) {
			userEntity?.queryUserLogout();
			navigate('/login');
		} else navigate('/');
	};
	return (
		<ErrorModal
			errorText={errorText}
			alert={alert}
			secondButton={handleHide}
			backHome={!perm && !auth}
			text={text}
		/>
	);
};
