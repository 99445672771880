import { gql } from '@apollo/client';

export const QUERY_SEARCH_EQUIPMENT_EXPLORE = gql`
    query QuerySearchEquipmentExplore (
		$name: String!
		$datasetId: String!
    ) {
    	searchEquipmentExplore (
			name: $name
			datasetId: $datasetId
    	) {
			id
			type
			name
			specification
			description
    	}
    }
`;
