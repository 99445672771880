import React from 'react';
import { translate } from '../../infrastructure/translations/translate';
import { resultValue } from './utils/resultValue';
import { ResultCardError } from '../error/ResultCardError/ResultCardError';
import { IResultTableProps, ResultTable } from './ResultTable';
import { ResultArrowComparison, ResultComparison } from './ResultArrowComparison';

export type ResultFluxSummaryType = { data: { flux: string, total: number, per_functional_unit: number, unit?: string,  mean?: number, min?: number, max?: number, std?: number }[] }
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isResultFluxSummary = (o: any): o is ResultFluxSummaryType => {
	if (typeof o === 'object') return 'schema' in o && 'data' in o;
	return false;
};
export const ResultFluxSummary = ({ data }: { data: string }): JSX.Element => {
	if (!isResultFluxSummary(data)) return (<ResultCardError/>);

	// set the table for the ResultTable component
	const dataTable : IResultTableProps = {
		columns: [
			{ name: translate('results.index') as string, valueType: 'left' },
			{ name: translate('results.unit') as string, valueType: 'right' },
			{ name: translate('results.total') as string, valueType: 'right' },
			{ name: translate('results.perFunctionalUnit') as string, valueType: 'right' },
		],
		values: [
			...data.data.map((d) => [
				{ value: translate(`results.fluxSummary.${d.flux}`) as string, valueType: 'right' },
				{ value: translate(`results.fluxSummary.unit.${d.flux}`) as string, valueType: 'left' },
				{ value:(
					<>
						{d.total === 0 ? '-' : resultValue(d.total)}
						{d.total !== 0 && <ResultArrowComparison arrow={
							ResultComparison({
								value: d.total,
								mean: d.mean,
								max: d.max,
								min: d.min
							})}
						/>}
					</>
				), valueType: 'left' },	
				{ value: d.per_functional_unit === 0 ? '-' : resultValue(d.per_functional_unit), valueType: 'left' },
			]),
		]
	};
	return <ResultTable dataTable={dataTable}/>;
};
