import { IDataDTO } from '../../domain/interfaces/DTO/IDataDTO';
import { StaticPagesEntity } from '../../domain/entities/StaticPagesEntity';
import { useSelector } from 'react-redux';
import { State } from '../../store';
import { StaticPage } from '../../domain/data/entries/StaticPage';

type StaticPagesHookType = IDataDTO & { entity: StaticPagesEntity | undefined, pages: StaticPage[] };

export const useStaticPages = (): StaticPagesHookType => {
	const { loading, error, data: entity } = useSelector((state: State) => state.dataStaticPageStatus);
	const pages = entity?.get() ?? [];
	return { loading, error, entity, pages };
};