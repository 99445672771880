import { gql } from '@apollo/client';

export const MUTATE_INDICATOR_ORDER_MANAGEMENT = gql`
    mutation MutateIndicatorOrderManagement(
    	$indicatorList: [String!]!
    ) {
    	mutateIndicatorOrderManagement(
    		indicatorList: $indicatorList
		) {
			status
			indicators {
				id
				name
				shortName
				unit
				icon
				planetaryBoundary
				order
			}
		}
    }
`;
