import React, { useEffect } from 'react';
import Template from '../../../viewComponents/template/template/template';
import DashboardGlobalComparison from './Graphs/DashboardGlobalComparison';
import { useSelector } from 'react-redux';
import { State } from '../../../store';
import { translate } from '../../../infrastructure/translations/translate';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '../../../viewComponents/utils/Spinner/Spinner';
import DashboardDomainComparison from './Graphs/DashboardDomainComparison';
import DashboardIndicatorsComparison from './Graphs/DashboardIndicatorsComparison';
import DashboardEquipmentComparison from './Graphs/DashboardEquipmentComparison';
import DashboardMaturityDomainComparison from './Graphs/DashboardMaturityDomainComparison';
import { DashboardComparisonData, DashboardComparisonMaturity } from '../../../domain/interfaces/DashboardComparisonData';
import { SideBarContentItemList } from '../../../viewComponents/template/sidebar/sidebar';
import { Alert } from '../../../viewComponents/Alerts';
import { useIndicators } from '../../../viewComponents/hooks/useIndicators';
import { useLifeCycleSteps } from '../../../viewComponents/hooks/useLifeCycleSteps';
import { GroupedBarChartData } from '../../../viewComponents/graphs/bars/GroupedBarChart';
import { StackedBarChartData } from '../../../viewComponents/graphs/bars/StackedBarChart';
import { DashboardSaveDataButton, DeleteDashboardButton } from '../DashboardSaveData';
import { ReducedBarChartData } from '../../../viewComponents/graphs/bars/ReducedBarChart';
import { DashboardShareButton } from '../DashboardShare';
import { useSharedDashboardOwning } from '../SharedDashboardOwning';
import { useDashboards } from '../../../viewComponents/hooks/useDashboards';
import { changeDashboard } from '../../../store/dispatchers';
import { useModal } from '../../../viewComponents/modal/useModal';
import { createDashboardModalComparison } from './CreateDashboardModalComparison';
import { useStudies } from '../../../viewComponents/hooks/useStudies';
import { useTooltip } from '../../../viewComponents/tip/useTooltip';
import { maturityCorrectlyFilled } from '../maturityCorrectlyFilled';
import { DashboardCardAlert } from '../DashboardCardAlert';

/**
 * Return the value of the difference between a value and the value of the reference
 *
 * @param data
 * @param datasetRef
 * @param e
 */
export const dashboardCompareToReference = (
	data: (StackedBarChartData | GroupedBarChartData | ReducedBarChartData | null)[],
	datasetRef: string,
	e: number): React.ReactElement =>
{
	if (datasetRef === '') return <></>;
	const refValue = data.find(e => e?.label === datasetRef)?.values[0].value;

	if (!refValue || !(e > 0) || !(refValue > 0)) return <></>;

	const diff = e - refValue;
	const percentage = diff / refValue * 100;
	const text = `${diff < 0 ? '' : '+'}${percentage.toFixed(0)}%`;
	return <span style={{ color: diff > 0 ? '#f00' : '#00ad00' }}>{text}</span>;
};

const DashboardComparisonButton = (): JSX.Element => {
	const navigate = useNavigate();
	const { studies } = useStudies();
	const [openComparisonModal, setOpenComparisonModal] = React.useState<boolean>(false);

	const setComparisonData = (datasetsIds: string[]) => {
		changeDashboard({ type: 'comparison', data: datasetsIds });
		navigate('/dashboard/comparison/new');
	};
	useModal(openComparisonModal, createDashboardModalComparison(
		openComparisonModal,
		setOpenComparisonModal,
		setComparisonData,
		studies
	));

	return <button
		className={'button button_primary'}
		onClick={() => setOpenComparisonModal(true)}
		title={translate('dashboard.comparison.new') as string}
	>
		<i className={'fa-solid fa-plus'}/>
	</button>;
};

const DashboardComparison = (): JSX.Element => {
	const { dashboardId } = useParams<{ dashboardId: string }>();
	const { entity, dashboards } = useDashboards();
	const comparisonDashboards = dashboards?.filter(d => d.type === 'comparison');
	const datasetsIds = useSelector((state: State) => state.dashboard.comparison);
	const [newData, setNewData] = React.useState<{ data: DashboardComparisonData, maturity: DashboardComparisonMaturity }>();
	const [isAlertOpen, setIsAlertOpen] = React.useState<boolean>(true);
	const [showTooltip, setShowTooltip] = React.useState<boolean>(false);
	useTooltip(showTooltip, translate('dashboard.tooltip.saved'));

	useEffect(() => {
		if (!dashboardId) return;
		if (dashboardId == 'new') {
			if (datasetsIds.length) entity?.queryDashboardComparisonData({ datasetsIds }).then(res => {
				setNewData(res);
			});
		} else {
			const dashboard = comparisonDashboards?.find(d => d.id === dashboardId);
			if (!dashboard) return;
			if (!dashboard.data) entity?.queryDashboard(dashboardId);
		}
	}, [dashboardId, JSON.stringify(datasetsIds), JSON.stringify(comparisonDashboards)]);

	const dashboard = comparisonDashboards?.find(d => d.id == dashboardId);
	const owning = useSharedDashboardOwning(dashboard);
	const isSaved = dashboard && dashboard.data && dashboardId !== 'new';
	const data = !isSaved ? newData : JSON.parse(dashboard.data);
	const { selectedIndicators: selectedIndicators } = useIndicators(data?.data.indicators);
	const { lcs: selectedLifeCycleSteps } = useLifeCycleSteps(data?.data.lifeCycleSteps);

	const side: SideBarContentItemList = {
		content: {
			list: [
				{
					id: '',
					name: translate('dashboard.comparison.new') as string,
					current: dashboardId === 'new',
					link: '/dashboard/comparison/new',
				},
			]
		}
	};

	if (comparisonDashboards?.length) {
		comparisonDashboards.forEach(d => {
			side.content.list.push({
				id: d.id,
				name: d.name,
				current: d.id === dashboardId,
				link: `/dashboard/comparison/${d.id}`,
			});
		});
	}

	if (dashboardId == 'new' && !datasetsIds.length) return <Template core={<section>
		<h1>{translate('dashboard.comparison')}</h1>
		<p>{translate('dashboard.noDataset')}</p>
		<DashboardComparisonButton />
	</section>} side={side} />;

	if (!data) return <Template core={<section>
		<h1>{translate('dashboard.comparison')}</h1>
		<Spinner />
	</section>} />;

	const core = <section>
		<h1>{translate('dashboard.comparison.title')}</h1>
		{isSaved && dashboard.date && <div className={'dashboard_date'}>
			{translate('dashboard.savedOn', { date: new Date(dashboard.date).toLocaleString() })}
			<button
				className={'button_blank'}
				type={'button'}
				onMouseEnter={() => setShowTooltip(true)}
				onMouseLeave={() => setShowTooltip(false)}
			>
				<i className="fa-regular fa-circle-exclamation"></i>
			</button>
		</div>}
		{(owning || dashboardId == 'new') && <div className="dashboard_save_button">
			<DashboardComparisonButton />
			{!isSaved && <DashboardSaveDataButton type={'comparison'} data={data}/>}
			{isSaved && <DeleteDashboardButton dashboardId={dashboardId}/>}
			{isSaved && <DashboardShareButton sharedUsers={dashboard.sharedUsers} dashboard={dashboard} />}
		</div>}
		{isAlertOpen && (selectedIndicators.length > 0 || selectedLifeCycleSteps.length > 0) && <div className={'dashboard_card dashboard_card_info dashboard_card_color_blue'}>
			<button className={'close_button'} onClick={() => setIsAlertOpen(false)}>X</button>
			<Alert variant={'info'}>{translate('dashboard.alert.usedIndicatorsAndLifeCycleSteps')}</Alert>
			{selectedIndicators.length > 0 && <Alert variant={'info'}>
				<div>
					{translate('dashboard.indicators')}:
					<ul>
						{selectedIndicators.map(i => <li key={i.id}>
							{i.name} - {i.shortName}
						</li>)}
					</ul>
				</div>
			</Alert>}
			{selectedLifeCycleSteps.length > 0 && <Alert variant={'info'}>
				<div>
					{translate('dashboard.lifeCycleSteps')}:
					<ul>
						{selectedLifeCycleSteps.map(i => <li key={i.id}>
							{i.name}
						</li>)}
					</ul>
				</div>
			</Alert>}
		</div>}
		<DashboardGlobalComparison data={data.data} />
		<DashboardDomainComparison data={data.data} />
		<DashboardIndicatorsComparison data={data.data} />
		{data.maturity && !maturityCorrectlyFilled(data.maturity) && <DashboardCardAlert
			variant={'warning'}
			className={'dashboard_card_color_blue'}
		>
			{translate('dashboard.maturity.notCorrectlyFilled')}
		</DashboardCardAlert>}
		{maturityCorrectlyFilled(data.maturity) && <DashboardMaturityDomainComparison data={data.maturity}/>}
		<DashboardEquipmentComparison data={data.data} />
	</section>;
	return <Template core={core} side={side} /> ;
};

export default DashboardComparison;