import React, { useEffect, useState } from 'react';
import { translate } from '../../infrastructure/translations/translate';
import { ResultArrowComparison, ResultComparison } from './ResultArrowComparison';
import { resultValue } from './utils/resultValue';
import { ResultCardError } from '../error/ResultCardError/ResultCardError';
import { IResultTableProps, ResultTable } from './ResultTable';
import { useTransformedResultTableData } from '../../utils/useTransformedResultTableData';
import Spinner from '../utils/Spinner/Spinner';
import { RelativeDoughnutChart } from '../graphs/doughnuts/RelativeDoughnutChart';
import { groupSmallValues } from '../../utils/GraphsUtils';
import { DoughnutPluginWritePercentage } from '../graphs/plugins/DoughnutPluginWritePercentage';


export type ResultNormalizedFootprintPerBlockType = { schema: { fields: { name: string, type: string }[], primaryKey: string[], pandas_version: string }, data: { block: string, value: number, mean?: number, min?: number, max?: number, std?: number }[] }
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isResultNormalizedFootprintPerBlock = (o: any): o is ResultNormalizedFootprintPerBlockType => {
	if (typeof o === 'object') return 'schema' in o && 'data' in o;
	return false;
};
export const ResultNormalizedFootprintPerBlock = ({ data, isRelative }: { data: string, isRelative: boolean }): JSX.Element => {
	if (!isResultNormalizedFootprintPerBlock(data)) return (<ResultCardError/>);

	// transform data from id to name
	const transformedData = useTransformedResultTableData(data.data);
	const [dataTable, setDataTable] = useState<IResultTableProps | null>(null);
	useEffect(() => {
		if (!transformedData || transformedData instanceof Error) return;
		// set the table for the ResultTable component
		setDataTable({
			columns: [
				{ name: translate('results.block') as string },
				{ name: translate('results.unit') as string, style: { textAlign: 'left' } },
				{ name: translate('results.value') as string },
			],
			values: transformedData.map(d => [
				{ value: d.block, valueType: 'left' },
				{ value: isRelative ? '%' : translate('results.valuePBCI') as string, valueType: 'left',style: { textAlign: 'left' } },
				{ value: <>
					{resultValue(d.value as number)}
					{d.value ?<ResultArrowComparison arrow={ResultComparison({
						value: d.value as number,
						mean: d.mean,
						min: d.min,
						max: d.max
					})}/> : ''}
				</>, valueType: 'right' },
			]),
		});
	}, [transformedData]);

	if (transformedData instanceof Error) return <ResultCardError/>;
	if (!dataTable) return <Spinner/>;
	return <ResultTable dataTable={dataTable} />;
};

export const NormalizedFootprintPerBlockGraph = ({ data }: { data: string }): JSX.Element => {
	if (!isResultNormalizedFootprintPerBlock(data)) return (<ResultCardError/>);
	const transformedData = useTransformedResultTableData(data.data);
	if (transformedData instanceof Error || !transformedData) return <ResultCardError/>;
	const filteredValues = groupSmallValues(transformedData, 'block', 1);
	const datasets = filteredValues.map(d => ({ labels: String(d.block), values: Number(d.value), color: d.blockColor }));

	return <RelativeDoughnutChart
		datasets={datasets}
		plugins={[DoughnutPluginWritePercentage()]}
	/>;
};
