
import { gql } from '@apollo/client';

export const MUTATE_REQUEST_CALCULATION = gql`
mutation mutateRequestCalculation(
    $company: String!,
    $studyId: String!,
    $datasetId: String!,
    $message: String
) {
    mutateRequestCalculation(
        company: $company,
        studyId: $studyId,
        datasetId: $datasetId,
        message: $message
    ) {
        status,
    }
}`;
