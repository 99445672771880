/**
 * Converts hex color to rgb
 * @param {string} hex - hex color
 * @returns {[number, number, number]} - rgb color in a tuple
 *
 * @author Maximilien Valenzano
 */
export const hexToRgb = (hex: string): [number, number, number] => {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	if (result) return [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)];
	return [0, 0, 0];
};
