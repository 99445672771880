/**
 * This function calculates the median of an array of numbers.
 * It also scales the values and means to the appropriate units.
 * The units are assumed to be in kg or MJ.
 * If the units are in kg, the values are scaled to t.
 * If the units are in MJ, the values are scaled to GJ.
 * If the median is less than 1, the values are scaled to g or mg.
 * If the median is less than 0.001, the values are scaled to mg.
 * If the median is more than 1000, the values are scaled to t or GJ.
 *
 * @param values - array of numbers
 * @param units - kg or MJ
 * @param valuesMeans - array of numbers
 */

export function getMedian(
	values: number[],
	units: string,
	valuesMeans?: number[],
): { values: number[], units: string, valuesMeans?: number[] } {
	if (values.length === 0 || !units) return { values: [], units: '', valuesMeans: [] };

	// Create a copy of the values array and sort the copy
	const valuesCopy = [...values];
	valuesCopy.sort((a, b) => a - b);
	let median: number;

	// Add a condition to check if the length of the values array is 1
	if (valuesCopy.length === 1) {
		median = valuesCopy[0];
	} else {
		const half = Math.floor(valuesCopy.length / 2);
		if (valuesCopy.length % 2) {
			median = valuesCopy[half];
		} else {
			median = (valuesCopy[half - 1] + valuesCopy[half]) / 2.0;
		}
	}

	let unit = '';
	let scale = 1;

	const calculateUnits = (units: string) => {
		if (units.includes('kg')) {
			if (median < 0.001) {
				scale = 1000000;
				unit = 'mg';
			} else if (median < 1) {
				scale = 1000;
				unit = 'g';
			} else if (median >= 1000) {
				scale = 1 / 1000;
				unit = 't';
			}
		} else if (units.includes('MJ')) {
			if (median < 0.001) {
				scale = 1000000;
				unit = 'J';
			} else if (median < 1) {
				scale = 1000;
				unit = 'kJ';
			} else if (median >= 1000) {
				scale = 1 / 1000;
				unit = 'GJ';
			}
		}

		return unit !== '' ? units.replace(/kg|MJ/g, unit) : units;
	};

	units = calculateUnits(units);
	values = values.map(value => value * scale);
	valuesMeans ? valuesMeans = valuesMeans.map(value => value * scale) : [];

	return { values, units, valuesMeans };
}
